/* eslint-disable @typescript-eslint/no-explicit-any */
import { Log } from 'utilities/log';
import { FetchHeaders } from 'utilities/http/types';
import { logout } from 'utilities/refreshToken/refreshToken';

export function getConfig(headers?: FetchHeaders): RequestInit {
  headers = defaultHeaders(headers);
  return { method: 'GET', headers };
}

export function getRequest(url: string, headers?: FetchHeaders) {
  return request(url, getConfig(headers));
}

function stringifyBody<T>(body: T): string | null {
  return body ? JSON.stringify(body) : null;
}

export function postConfig<T>(payload: T, headers?: FetchHeaders): RequestInit {
  headers = defaultHeaders(headers);
  const body = stringifyBody(payload);
  return { method: 'POST', body, headers };
}

export function postRequest<B>(url: string, body: B, headers?: FetchHeaders) {
  return request(url, postConfig(body, headers));
}

export function deleteConfig<T>(headers?: FetchHeaders): RequestInit {
  headers = defaultHeaders(headers);
  return { method: 'DELETE', headers };
}

export function deleteRequest<B>(url: string, headers?: FetchHeaders) {
  return request(url, deleteConfig(headers));
}

export function putConfig<T>(payload: T, headers?: FetchHeaders): RequestInit {
  headers = defaultHeaders(headers);
  const body = stringifyBody(payload);
  return { method: 'PUT', body, headers };
}

export async function putRequest<B>(
  url: string,
  body: B,
  headers?: FetchHeaders,
) {
  return request(url, putConfig(body, headers));
}

export const defaultHeaders = (
  headers?: FetchHeaders | undefined,
): FetchHeaders => {
  if (!headers) {
    return { 'Content-Type': 'application/json' };
  }

  if (!headers['Content-Type']) {
    headers['Content-Type'] = 'application/json';
  }

  return headers;
};

export async function request(url: string, config: RequestInit) {
  let response;
  try {
    response = await fetch(url, config);
  } catch (e) {
    Log.error('fetch()', e);
  }

  if (!response) {
    throw new Error('No response, please check your network connection');
  }

  if (
    response.status === 401 &&
    !url.includes('/auth/login') &&
    !url.includes('/api/media')
  ) {
    logout();
  }

  let json: any = {};
  try {
    json = await response.json();
  } catch (e) {
    json = null;
    Log.debug('No JSON response from server');
  }

  let text: any = {};
  if (!json) {
    try {
      text = await response.text();
    } catch (e) {
      text = null;
      Log.debug('No text response from server');
    }
  }
  return {
    raw: response,
    status: response?.status,
    json,
    text,
  };
}

/**
 * Checks if request has errors.
 *
 * @param resp response from the request.
 * @return Returns true if request has errors, else false.
 */
export const requestHasErrors = (resp: any) => {
  return (
    resp.status !== 200 || resp.json?.errors || resp.json?.statusCode === 500
  );
};
