import React, { ReactElement } from 'react';

interface Props {
  title: string;
  host: string;
}

export function CollapsablePanelHeaderWebinars(props: Props): ReactElement {
  const { title, host } = props;
  return (
    <section className="CollapsablePanelHeaderWebinars">
      <h4 className="CollapsablePanelHeaderWebinars__title">{title}</h4>
      <span className="CollapsablePanelHeaderWebinars__subtitle">{host}</span>
    </section>
  );
}
