import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { PuppyDetailTile } from 'components/puppy';
import { Icon } from 'shared/icon/Icon';
import { RootState } from 'store/rootState';
import {
  puppyDueDatesIsPastDue,
  puppyDueDatesIsUpToDate,
} from 'utilities/puppy/puppyDueDates/puppyDueDates';
import { SetState } from 'shared/types/general';

interface Props {
  setShowPuppyForm: SetState<boolean>;
}

function PuppyDetailUpdatesTile(props: Props): ReactElement {
  const { setShowPuppyForm } = props;
  const puppy = useSelector((state: RootState) => state.puppy);

  const getDueDateStatusText = () => {
    if (!puppy?.dueDates) return;

    if (puppyDueDatesIsUpToDate(puppy?.dueDates)) {
      return 'Up-to-date';
    }

    if (puppyDueDatesIsPastDue(puppy?.dueDates)) {
      return 'Past due';
    }

    return 'Pending Updates';
  };

  const getDueDateStatus = () => {
    if (!puppy?.dueDates) return;

    if (puppyDueDatesIsUpToDate(puppy?.dueDates)) {
      return 'success';
    }

    if (puppyDueDatesIsPastDue(puppy?.dueDates)) {
      return 'error';
    }

    return 'warning';
  };

  return (
    <PuppyDetailTile
      title="Puppy Updates"
      icon={<Icon name="puppy" />}
      statusText={getDueDateStatusText()}
      status={getDueDateStatus()}
      onClick={() => setShowPuppyForm((prevState) => !prevState)}
    />
  );
}

export default PuppyDetailUpdatesTile;
