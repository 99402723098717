import React, { ReactElement } from 'react';
import { ReactComponent as BHLogo } from 'assets/img/breeder-hub.svg';
import loginImg from 'assets/img/login-img.svg';

interface Props {
  title?: string;
  subtitle?: string | undefined;
  mainPage: boolean;
}

export function AuthorizationHeader(props: Props): ReactElement {
  const { title, subtitle, mainPage } = props;
  return (
    <div className="Authorization__margin">
      <BHLogo className="Authorization__bh-logo" />
      <h1
        className="Authorization__title"
        dangerouslySetInnerHTML={{ __html: title as string }}
      />
      <h2
        className="Authorization__subtitle"
        dangerouslySetInnerHTML={{ __html: subtitle as string }}
      />
      {mainPage && (
        <img
          className="Authorization__img"
          alt="puppy illustration"
          src={loginImg}
        />
      )}
    </div>
  );
}
