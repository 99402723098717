import React, { ReactElement } from 'react';
import { Col, Row } from 'antd';

import { EntityListColumn } from 'components/entity';
import ListingsColumn from 'pages/myListings/listingsColumn/ListingsColumn';

export default function MyListingsLarge(): ReactElement {
  return (
    <Row gutter={12}>
      <Col span={12}>
        <EntityListColumn
          label="Past due Updates"
          content={<ListingsColumn columnName="pastDue" />}
        />
      </Col>
      <Col span={12}>
        <EntityListColumn
          label="Upcoming Updates"
          content={<ListingsColumn columnName="upcoming" />}
        />
      </Col>
    </Row>
  );
}
