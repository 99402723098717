import React, { ReactElement } from 'react';
import { isEmpty } from 'lodash';
import { Empty } from 'antd';

import LitterCard from 'components/litter/litterCard/LitterCard';
import LoadingSection from 'components/loadingSection/LoadingSection';
import LoadMore from 'shared/entitiesOverview/loadMore/LoadMore';
import { Nullable } from 'shared/types/general';
import { Litter, LitterStatus } from 'shared/types/litter';

interface Props {
  litters: Nullable<Litter[]>;
  columnName: LitterStatus;
}

export default function LittersColumn(props: Props): ReactElement {
  const { litters, columnName } = props;

  if (litters === null) {
    return <LoadingSection />;
  }

  if (isEmpty(litters)) {
    return <Empty />;
  }

  return (
    <>
      {litters?.map((l) => (
        <LitterCard litter={l} key={l.id} />
      ))}
      <LoadMore columnName={columnName} />
    </>
  );
}
