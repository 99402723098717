import React, { ReactElement, ReactNode } from 'react';
import { RightOutlined } from '@ant-design/icons';

interface CollapsableExpandIconProps {
  isActive: boolean | undefined;
  customExpandIcon: ReactNode;
}

export function CollapsableExpandIcon(
  props: CollapsableExpandIconProps,
): ReactElement {
  const { isActive, customExpandIcon } = props;
  return (
    <section className="CollapsableExpandIcon">
      {customExpandIcon ? (
        <span className="CollapsableExpandIcon__custom">
          {customExpandIcon}
        </span>
      ) : (
        <>
          <span>{isActive ? 'Close' : 'Open'}</span>
          <RightOutlined rotate={isActive ? 90 : 0} />
        </>
      )}
    </section>
  );
}
