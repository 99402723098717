import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';

import { EntityListColumn } from 'components/entity';
import LittersColumn from 'pages/myLitters/littersColumn/LittersColumn';
import { LitterStatuses } from 'shared/types/litter';
import { RootState } from 'store/rootState';
import { getBreederLitters } from 'store/myLitters/actions';

export default function MyLittersLarge() {
  const { past, current, upcoming, new: newLitter } = useSelector(
    (state: RootState) => state.myLitters,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBreederLitters(LitterStatuses.upcoming));
    dispatch(getBreederLitters(LitterStatuses.past));
    dispatch(getBreederLitters(LitterStatuses.current));
    dispatch(getBreederLitters(LitterStatuses.new));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row gutter={12}>
      <Col span={6}>
        <EntityListColumn
          label="Upcoming"
          content={
            <LittersColumn
              litters={upcoming}
              columnName={LitterStatuses.upcoming}
            />
          }
        />
      </Col>
      <Col span={6}>
        <EntityListColumn
          label="New"
          content={
            <LittersColumn
              litters={newLitter}
              columnName={LitterStatuses.new}
            />
          }
        />
      </Col>
      <Col span={6}>
        <EntityListColumn
          label="Current"
          content={
            <LittersColumn
              litters={current}
              columnName={LitterStatuses.current}
            />
          }
        />
      </Col>
      <Col span={6}>
        <EntityListColumn
          label="Past"
          content={
            <LittersColumn litters={past} columnName={LitterStatuses.past} />
          }
        />
      </Col>
    </Row>
  );
}
