/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, ReactElement } from 'react';
import { Form, Input } from 'antd';
import { isEmpty } from 'lodash';
import { regExp } from 'utilities/regExp';

interface Props {
  name: string;
  label: string;
  required?: boolean;
  setFieldsValue: (value: any) => void;
  placeholder?: string;
}

export const phoneFormatter = (value: string) => {
  const removeDashes = (value?.toString() || '').replace(/-/g, '');
  const numbers = removeDashes?.split('');

  const firstPart = numbers.splice(0, 3).join('');
  const secondPart = numbers.splice(0, 3).join('');
  const thirdPart = numbers.splice(0, 4).join('');

  const arrayToJoin = [];
  if (!isEmpty(firstPart)) arrayToJoin.push(firstPart);
  if (!isEmpty(secondPart)) arrayToJoin.push(secondPart);
  if (!isEmpty(thirdPart)) arrayToJoin.push(thirdPart);
  return arrayToJoin.join('-');
};

export default function FieldPhone(props: Props): ReactElement {
  const { name, label, setFieldsValue, required = false, placeholder } = props;
  const placeholderValue = placeholder || 'i.e 711-852-8162';

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          pattern: regExp.phoneFormat,
          message: 'Please format phone number as XXX-XXX-XXXX',
          required,
        },
      ]}
    >
      <Input
        placeholder={placeholderValue}
        style={{ width: '100%' }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const cleanPhone = e.target.value.replace(
            regExp.onlyNumbersAndDashes,
            '',
          );
          setFieldsValue?.({
            [name]: phoneFormatter(cleanPhone),
          });
        }}
      />
    </Form.Item>
  );
}
