import { isEmpty } from 'lodash';
import { User, UserRole, userRole } from 'shared/types/user';
import { Nullable } from 'shared/types/general';

/**
 * Check if User role is Breeder Admin Internal
 * @param user User auth info
 */
export const isUserRoleBreederAdminInternal = (
  user: Nullable<User>,
): boolean => {
  const userRoles = user?.roles || [];

  const role = userRoles.find(
    (role: UserRole) => role.slug === userRole.breederAdminInternal,
  );

  return !isEmpty(role);
};

/**
 * Check if User role is Breeder Admin External
 * @param user User auth info
 */
export const isUserRoleBreederAdminExternal = (
  user: Nullable<User>,
): boolean => {
  const userRoles = user?.roles || [];

  const role = userRoles.find(
    (role: UserRole) => role.slug === userRole.breederAdminExternal,
  );

  return !isEmpty(role);
};

/**
 * Check if user can impersonate any breeder
 * @param user User auth info
 */
export const userCanImpersonateAnyBreeder = (user: Nullable<User>): boolean => {
  return Boolean(user?.canImpersonateAnyBreeder);
};

/**
 * Check if user can impersonate managed breeder
 * @param user User auth info
 */
export const userCanImpersonateManagedBreeders = (
  user: Nullable<User>,
): boolean => {
  return Boolean(user?.canImpersonateManagedBreeders);
};
