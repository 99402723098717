import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Affix, Grid, Layout } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PhoneOutlined,
} from '@ant-design/icons';

import logoSymbolBlack from 'assets/img/logo-symbol-black.svg';
import UserDropDown from 'shared/baseLayout/baseLayoutHeader/UserDropDown';
import BreederImpersonateHeader from 'shared/roleBreederAdmin/breederImpersonateHeader/BreederImpersonateHeader';

import './BaseLayoutHeader.scss';

const { Header } = Layout;
const { useBreakpoint } = Grid;

export interface AsideMenuCollapsableProps {
  collapsed: boolean;
  setCollapsed: (boolean: boolean) => void | undefined;
}

export default function BaseLayoutHeader(
  props: AsideMenuCollapsableProps,
): ReactElement {
  const { setCollapsed, collapsed } = props;
  const screens = useBreakpoint();

  const handleToggleSideMenu = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Affix offsetTop={0} style={{ zIndex: 11 }}>
      <section className="BaseLayoutHeader__wrapper">
        <BreederImpersonateHeader />
        <Header className="BaseLayout__header">
          <div className="BaseLayout__header-content-left">
            {!screens.xl && (
              <Link className="BaseLayout__header-logo" to="/">
                <img
                  alt="Breeder Hub. Puppy Spot Logos"
                  src={logoSymbolBlack}
                />
              </Link>
            )}
            <span
              data-testid="base-layout-header-hamburger"
              className="BaseLayout__header-hamburger"
              onClick={handleToggleSideMenu}
            >
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </span>
          </div>
          <div className="BaseLayout__header-options">
            <div className="BaseLayout__header-search">
              <a
                className="BaseLayoutHeader__call-us-wrap"
                href="tel:+844-566-2886"
              >
                <PhoneOutlined />{' '}
                <span className="BaseLayoutHeader__call-us">
                  Call Us At (844) 566-2886
                </span>
              </a>
            </div>
            <UserDropDown />
          </div>
        </Header>
      </section>
    </Affix>
  );
}
