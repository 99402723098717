import React, { ReactElement, useState } from 'react';
import { Button, Col, Form, Input, notification, Row } from 'antd';
import { LoginOutlined } from '@ant-design/icons';

import { switchBreederImpersonateMode } from 'utilities/breederImpersonate/breederImpersonate';
import { userCanImpersonateAnyBreeder } from 'utilities/user/userRolesCheckers/userRolesCheckers';
import userStorage from 'utilities/user/userStorage';

import './BreederImpersonate.scss';

function BreederImpersonateInternal(): ReactElement {
  const [loading, setLoading] = useState(false);
  const loggedUserInfo = userStorage.getUser();

  const handleBreederImpersonate = async (values: { breederId: number }) => {
    setLoading(true);
    try {
      await switchBreederImpersonateMode(values.breederId);
    } catch (e) {
      setLoading(false);
      notification.error({
        message: e.message,
      });
    }
  };

  if (!userCanImpersonateAnyBreeder(loggedUserInfo)) {
    return <></>;
  }

  return (
    <Form className="BreederImpersonate" onFinish={handleBreederImpersonate}>
      <p className="BreederImpersonate__title">
        Please type breeder id account to continue to Breeder Hub
      </p>
      <Row gutter={12}>
        <Col span={17}>
          <Form.Item name="breederId">
            <Input placeholder="Type id to sign with that user" />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              icon={<LoginOutlined />}
              loading={loading}
            >
              Sign in
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default BreederImpersonateInternal;
