import React, { ReactElement } from 'react';
import { Form, Input } from 'antd';

interface Props {
  disabled?: boolean;
}

export function FieldMicrochip(props: Props): ReactElement {
  const { disabled } = props;

  return (
    <Form.Item name="microchipRfid" label="Microchip ID">
      <Input placeholder="Microchip ID" disabled={disabled} />
    </Form.Item>
  );
}
