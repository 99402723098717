import React, { ReactElement } from 'react';
import { Spin } from 'antd';

import { Icon } from 'shared/icon/Icon';
import { testId } from 'testsUtilities/dataTestId';

import './LoadingSection.scss';

function LoadingSection(): ReactElement {
  return (
    <Spin
      indicator={<Icon name="spinner" />}
      className="section-loading"
      data-testid={testId.sectionLoading}
    />
  );
}

export default LoadingSection;
