import React from 'react';

import TravelInfoItem from 'shared/puppy/puppyTravel/TravelInfoItem';
import { FlightSequence, Travel } from 'shared/types/puppyTravel';
import { testId } from 'testsUtilities/dataTestId';
import { getContentDataById } from 'utilities/staticContent/content';
import date from 'utilities/time/date';

interface Props {
  travelInfo: Partial<Travel>;
  flightSequence: FlightSequence;
}

function TravelSequenceInfoFlight(props: Props) {
  const { travelInfo, flightSequence } = props;
  return (
    <section data-testid={testId.travelSequenceInfoFlight}>
      <TravelInfoItem label="Airline" value={flightSequence.airline.name} />

      <TravelInfoItem
        label="Flight number"
        value={flightSequence.flightNumber}
      />

      <TravelInfoItem
        label="Flight confirmation"
        value={flightSequence.flightConfirmation}
      />

      <TravelInfoItem
        label="Air waybill"
        value={flightSequence.flightAirWaybill}
      />

      <TravelInfoItem
        label="Crate size"
        value={getContentDataById('crateSizes', travelInfo.crateSizeId)?.name}
      />

      <TravelInfoItem
        label="Departure airport"
        value={
          <>
            <b>
              {
                getContentDataById(
                  'states',
                  flightSequence?.departureAirport?.stateId,
                )?.abbr
              }
            </b>
            {flightSequence?.departureAirport?.name}
          </>
        }
      />

      <TravelInfoItem
        label="Departure date"
        value={date.toFullMonthDDYYYY(flightSequence.departureTime)}
      />

      <TravelInfoItem
        label="Departure time"
        value={date.toTimeHMMA(flightSequence.departureTime)}
      />

      <TravelInfoItem
        label="Arrival airport"
        value={
          <>
            <b>
              {
                getContentDataById(
                  'states',
                  flightSequence.arrivalAirport.stateId,
                )?.abbr
              }
            </b>
            {flightSequence.arrivalAirport.name}
          </>
        }
      />

      <TravelInfoItem
        label="Arrival date"
        value={date.toFullMonthDDYYYY(flightSequence.arrivalTime)}
      />

      <TravelInfoItem
        label="Arrival time"
        value={date.toTimeHMMA(flightSequence.arrivalTime)}
      />
    </section>
  );
}

export default TravelSequenceInfoFlight;
