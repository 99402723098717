import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

import { BreederRoutes } from 'shared/types/routes';
import userStorage from 'utilities/user/userStorage';

import './UserDropDown.scss';

function logOut() {
  userStorage.removeUserAndToken();
  window.location.href = BreederRoutes.Login;
}

function HeaderDropDown() {
  return (
    <Menu className="HeaderDropDown">
      <Menu.Item
        data-testid="user-menu-my-account"
        className="HeaderDropDown__Item"
        icon={<UserOutlined />}
      >
        <Link to={BreederRoutes.MyAccount}>My account</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        data-testid="user-menu-log-out"
        className="HeaderDropDown__Item"
        icon={<LogoutOutlined />}
        onClick={logOut}
      >
        Log Out
      </Menu.Item>
    </Menu>
  );
}

export default function UserDropDown() {
  const email = userStorage.getUser()?.email || 'User';
  const emailInitial = email.substring(0, 1);
  const emailWithoutDomain = email.split('@')[0];
  const screens = useBreakpoint();
  const dropDownPlacement = screens.xl ? 'bottomLeft' : 'bottomRight';
  return (
    <div className="UserDropDown">
      <Dropdown
        overlay={<HeaderDropDown />}
        placement={dropDownPlacement}
        trigger={['click']}
      >
        <span className="UserDropDown__name-wrap" data-testid="user-menu">
          <div className="UserDropDown__email-initial">{emailInitial}</div>
          <span className="UserDropDown__email">{emailWithoutDomain}</span>
        </span>
      </Dropdown>
    </div>
  );
}
