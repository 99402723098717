import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Collapse } from 'antd';

import { SetState } from 'shared/types/general';
import { PuppyDetailTileStatus } from 'shared/types/puppy';
import { CollapsableStatusTypes } from 'shared/types/statuses';
import { CollapsablePanelHeader } from './CollapsablePanelHeader';
import { CollapsableExpandIcon } from './CollapsableExpandIcon';

import './Collapsable.scss';

const { Panel } = Collapse;

interface CollapsableProps {
  id: string | number;
  title: string;
  icon: string;
  type?: CollapsableStatusTypes | PuppyDetailTileStatus;
  isOpen?: boolean;
  children: ReactNode;
  disabled?: boolean;
  handleCollapsableOpen?: SetState<boolean>;
  customExpandIcon?: ReactNode;
}

type ActivePanel = string | string[];

export function Collapsable(props: CollapsableProps): ReactElement {
  const {
    id,
    type,
    children,
    isOpen,
    disabled,
    handleCollapsableOpen,
    customExpandIcon,
  } = props;
  const [activePanel, setActivePanel] = useState<ActivePanel>([
    isOpen ? `${id}` : '',
  ]);

  useEffect(() => {
    setActivePanel(isOpen ? [id as string] : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, id]);

  const handleActivePanel = (key: ActivePanel) => {
    setActivePanel(key);
    handleCollapsableOpen && handleCollapsableOpen(!isEmpty(key));
  };

  return (
    <Collapse
      className={`Collapsable Collapsable--${type} Collapsable--${id}-form`}
      activeKey={activePanel}
      onChange={handleActivePanel}
      expandIconPosition="right"
      expandIcon={({ isActive }) =>
        !disabled && (
          <CollapsableExpandIcon
            isActive={isActive}
            customExpandIcon={customExpandIcon}
          />
        )
      }
    >
      <Panel
        className={`Collapsable__panel`}
        key={id}
        header={<CollapsablePanelHeader {...props} />}
        collapsible={disabled ? 'disabled' : undefined}
        forceRender
      >
        {children}
      </Panel>
    </Collapse>
  );
}
