import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Space, Table } from 'antd';
import {
  Key,
  SorterResult,
  SortOrder,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

import {
  ColumnHealthDocs,
  ColumnPuppyUpdates,
  ColumnPuppyDam,
  ColumnPuppyBirthdate,
  ColumnAskingPrice,
  ColumnSitesName,
  ColumnPuppyName,
  ColumnPuppyId,
  ColumnSearch,
} from 'components/myListings/myListiningsTable/myListingsTableColumns';
import {
  HealthDocDueDate,
  HealthDocumentBadgeStatusLegend,
  HealthDocDueDateTooltip,
  HealthDocDrawer,
} from 'components/healthDocs';
import { MyListingsFilters, MyListingsSorter } from 'shared/types/myListings';
import { Listing } from 'shared/types/puppy';
import {
  setMyListingsSoldFilters,
  setMyListingsSoldPagination,
  setMyListingsSoldSorter,
} from 'store/myListingsSold/actions';
import { initialStateMyListingsSoldSorter } from 'store/myListingsSold/myListingSoldReducer';
import { RootState } from 'store/rootState';
import { testId } from 'testsUtilities/dataTestId';
import { BreederDashboardStatus, SortDirection } from 'utilities/const';

import './MyListingsTableSold.scss';

function MyListingsTableSold(): ReactElement {
  const {
    myListingsSold: { listings, loading, pagination, sorter, filters },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [listing, setListing] = useState<Listing | null>(null);

  useEffect(() => {
    if (!isEmpty(listing) && !isEmpty(listings)) {
      const listingToUpdate = listings.find((l) => l.id === listing?.id);
      if (listingToUpdate) {
        setListing(listingToUpdate as Listing);
      }
    }
  }, [listings, listing]);

  const handleOnChangeTable = (
    tablePagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: SorterResult<Listing> | SorterResult<Listing>[],
  ) => {
    window.scrollTo({ top: 0 });

    dispatch(
      setMyListingsSoldPagination({
        ...pagination,
        current: tablePagination.current as number,
        pageSize: tablePagination.pageSize as number,
        loadMore: false,
      }),
    );

    const newSorter = sorter as SorterResult<Listing>;
    const listingSorter = newSorter.column
      ? ({
          sortBy: newSorter.columnKey,
          sortDirection: newSorter.order
            ? SortDirection[newSorter.order]
            : initialStateMyListingsSoldSorter.sortDirection,
          isSorting: true,
        } as MyListingsSorter)
      : (initialStateMyListingsSoldSorter as MyListingsSorter);

    dispatch(setMyListingsSoldSorter(listingSorter));

    const isFiltering = Boolean(filters.puppy_id || filters.puppy_name);

    dispatch(
      setMyListingsSoldFilters({
        ...filters,
        isFiltering,
      } as MyListingsFilters),
    );
  };

  const setDrawerPuppy = (listing: Listing | null) => {
    setListing(listing);
  };

  const healthDocsDueColumn = {
    title: 'Health Docs Due',
    key: 'document_due_date',
    render: (record: Listing) => (
      <Space>
        <HealthDocDueDate
          documentDueDate={record.documentDueDate}
          documents={record.puppy.documents}
        />
        <HealthDocDueDateTooltip
          documentDueDate={record.documentDueDate}
          documents={record.puppy.documents}
          setDrawerPuppy={() => setDrawerPuppy(record)}
        />
      </Space>
    ),
    sorter: true,
    sortOrder:
      sorter.sortBy === 'document_due_date'
        ? (`${sorter.sortDirection}end` as SortOrder)
        : null,
  };

  return (
    <>
      <Table
        data-testid={testId.myListingsSoldTable}
        className="MyListingsTableSold"
        loading={loading}
        rowKey="puppyId"
        dataSource={listings}
        scroll={{ x: true }}
        columns={[
          {
            title: 'Puppy ID',
            key: 'puppy_id',
            render: (record: Listing) => <ColumnPuppyId listing={record} />,
            sorter: true,
            sortOrder:
              sorter.sortBy === 'puppy_id'
                ? (`${sorter.sortDirection}end` as SortOrder)
                : null,
            filterIcon: <SearchOutlined />,
            filterDropdown: (props) => (
              <ColumnSearch
                {...props}
                numeric
                placeholder="Search by Puppy ID"
              />
            ),
            filteredValue: filters.puppy_id,
          },
          {
            title: 'Name',
            key: 'puppy_name',
            render: (record: Listing) => <ColumnPuppyName listing={record} />,
            sorter: true,
            sortOrder:
              sorter.sortBy === 'puppy_name'
                ? (`${sorter.sortDirection}end` as SortOrder)
                : null,
            filterIcon: <SearchOutlined />,
            filterDropdown: (props) => (
              <ColumnSearch {...props} placeholder="Search by Name" />
            ),
            filteredValue: filters.puppy_name,
          },
          {
            title: "Site's name",
            key: 'sites_name',
            render: (record: Listing) => <ColumnSitesName listing={record} />,
          },
          {
            title: 'Price',
            render: (record: Listing) => <ColumnAskingPrice listing={record} />,
          },
          {
            title: 'DOB',
            key: 'litter_birth_date',
            render: (record: Listing) => (
              <ColumnPuppyBirthdate listing={record} />
            ),
            sorter: true,
            sortOrder:
              sorter.sortBy === 'litter_birth_date'
                ? (`${sorter.sortDirection}end` as SortOrder)
                : null,
          },
          {
            title: 'Mom',
            render: (record: Listing) => <ColumnPuppyDam listing={record} />,
          },
          {
            title: 'Actions required',
            render: (record: Listing) => (
              <ColumnPuppyUpdates
                listing={record}
                breederDashboardStatus={BreederDashboardStatus.Sold}
              />
            ),
          },
          {
            title: 'Health Docs',
            render: (record: Listing) => (
              <ColumnHealthDocs
                listing={record}
                setDrawerPuppy={setDrawerPuppy}
              />
            ),
          },
          healthDocsDueColumn,
        ]}
        pagination={{
          hideOnSinglePage: true,
          showQuickJumper: true,
          defaultPageSize: pagination.pageSize,
          current: pagination.current,
          total: pagination.total,
        }}
        onChange={handleOnChangeTable}
        footer={() => <HealthDocumentBadgeStatusLegend />}
      />
      <HealthDocDrawer listing={listing} setDrawerPuppy={setDrawerPuppy} />
    </>
  );
}

export default MyListingsTableSold;
