/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'redux';
import { isEmpty } from 'lodash';

import { RootState } from 'store/rootState';
import { DispatchAny, Id, NoteTypes, SetState } from 'shared/types/general';
import showNotificationError from 'shared/notifications/showNotificationError';
import puppyFormHelpers from 'shared/puppy/puppyFormHelpers';
import apiPuppy from 'utilities/http/api/apiPuppy';
import media, { mediaConfig } from 'utilities/media/media';
import apiMedia from 'utilities/http/api/apiMedia';
import {
  RESET_PUPPY,
  SET_PUPPY,
  SET_PUPPY_ORDER,
  SET_PUPPY_PHOTOS_ADDITIONAL,
  SET_PUPPY_PHOTOS_COVER,
  SET_PUPPY_PHOTOS_HIDDEN,
  SET_SINGLE_PUPPY_PHOTO_HIDDEN,
  UPDATE_NEW_PUPPY_PHOTO_ID,
} from './types';
import { uploadVideoToCloudflare } from 'shared/puppy/puppyMedia/puppyVideo/apiPuppyVideo';
import { Media, MediaUploaderParams } from 'shared/types/media';
import {
  Puppy,
  PuppyFormFields,
  PuppyOrder,
  PuppyPhotos,
} from 'shared/types/puppy';
import { isPending } from 'utilities/entities/puppy';
import { getExistingVideo } from 'utilities/media/mediaCheckers';
import notes from 'utilities/notes/notes';
import { getPuppySiteName } from 'utilities/puppy/getPuppyUtils/getPuppyUtils';
import inventoryStatus from 'utilities/status/inventoryStatus';

export interface SetPuppy {
  type: typeof SET_PUPPY;
  puppy: Puppy;
}

export interface ResetPuppy {
  type: typeof RESET_PUPPY;
}

export function setPuppy(puppy: Puppy) {
  return {
    type: SET_PUPPY,
    puppy,
  };
}

export function resetPuppy() {
  return {
    type: RESET_PUPPY,
  };
}

export function getPuppy(id: Id, callback: () => void = () => null) {
  return async (dispatch: Dispatch) => {
    try {
      const puppyResp = await apiPuppy.getPuppy(id as number);
      const puppy: Puppy = puppyResp.json.puppy;

      const puppyPhotos = puppyFormHelpers.buildPuppyPhotos(
        puppy.listing?.media as Media[],
      );

      if (puppy.listing) {
        puppy.photos = puppyPhotos;
      }

      if (inventoryStatus.isSold(puppy)) {
        const puppyOrderResp = await apiPuppy.getPuppyOrder(puppy.id as number);
        const puppyOrder: PuppyOrder = puppyOrderResp.json.order;

        if (puppyOrder === null) {
          showNotificationError({
            error: `No order found for puppy ${puppy.id}`,
          });
        }

        puppy.order = puppyOrder;

        const puppyHealthDocsResp = await apiPuppy.getPuppyHealthDocs(
          puppy.id as number,
        );
        const documents = puppyHealthDocsResp.json.documents;

        if (isEmpty(documents)) {
          showNotificationError({
            error: `No health documents found for puppy ${puppy.id}`,
          });
        }

        puppy.documents = documents;

        const puppyHealthDocsDueDateResp = await apiPuppy.getPuppyHealthDocsDueDate(
          puppy.id as number,
        );
        const documentDueDate = puppyHealthDocsDueDateResp.json.documentDueDate;

        if (documentDueDate === '') {
          showNotificationError({
            error: `No documents due date found for puppy ${puppy.id}`,
          });
        }

        puppy.listing = {
          ...puppy.listing,
          documentDueDate: documentDueDate,
        };
      }

      dispatch(setPuppy(puppy));
      if (callback) {
        callback();
      }
    } catch (e) {
      showNotificationError({ error: "Couldn't load puppy" });
    }
  };
}

export function editPuppy(
  puppyFormData: PuppyFormFields,
  setSubmitProgress: SetState<number>,
  setIsSameName: SetState<boolean>,
) {
  return async (dispatch: DispatchAny, getState: () => RootState) => {
    try {
      const { puppy } = getState();
      setSubmitProgress(5);

      const puppyPayload = puppyFormHelpers.payloadBuilder(
        puppyFormData,
        puppy,
      );

      if (isPending(puppy)) {
        puppyPayload.salesName = puppyFormData.breederName;
      } else {
        puppyPayload.salesName = getPuppySiteName(puppy);
      }

      await puppyFormHelpers.putPuppy(
        puppy.id as number,
        puppyPayload,
        setIsSameName,
      );

      setSubmitProgress(15);

      const sortedNotes = notes.sortByOldest(puppy.listing?.notes);

      const noteId = sortedNotes?.find(
        (note) => note?.noteTypeId === NoteTypes.noteFromBreeder.id,
      )?.id;

      if (puppyFormData.noteFromBreeder) {
        noteId
          ? await puppyFormHelpers.putPuppyNote(
              noteId as number,
              puppyFormData.noteFromBreeder,
            )
          : await puppyFormHelpers.postPuppyNote(
              puppy.id as number,
              puppyFormData.noteFromBreeder as string,
            );
      }

      setSubmitProgress(20);

      const params: MediaUploaderParams = {
        mediaTypeId: mediaConfig.mediaType.photo,
        mediaEntityId: puppy.listing?.id,
        mediaEntity: 'listing',
      };

      const { puppyPhotos, puppyVideo } = puppyFormData;

      if (!isEmpty(puppyPhotos)) {
        await dispatch(
          puppyPhotosUploader(
            [
              ...(getState().puppy.photos?.coverPhotos as Media[]),
              ...(getState().puppy.photos?.additionalPhotos as Media[]),
            ],
            params,
          ),
        );

        setSubmitProgress(40);

        let justDeletedImages: any[] | undefined = [];
        if (!isEmpty(getState().puppy.photos?.hiddenPhotos)) {
          setSubmitProgress(45);
          justDeletedImages = await puppyFormHelpers.photosDelete(
            getState().puppy.photos?.hiddenPhotos,
          );
        }

        const result = await apiPuppy.getPuppy(puppy.id as number);
        const puppyResult = result.json.puppy;

        if (puppyResult.listing?.media) {
          puppyResult.listing.media = puppyFormHelpers.mapViewOrder(
            puppyResult.listing.media,
            puppy,
          );
          puppyResult.photos = puppyFormHelpers.buildPuppyPhotos(
            puppyResult.listing.media,
          );
        }

        setSubmitProgress(50);

        await puppyFormHelpers.puppyPhotosSetViewOrder(
          puppyResult,
          undefined,
          justDeletedImages,
        );

        setSubmitProgress(55);

        await puppyFormHelpers.puppyPhotoSetAsDefault(
          getState().puppy.listing?.id as number,
          getState().puppy.photos?.coverPhotos as Media[],
        );
      }

      setSubmitProgress(60);
      await uploadVideoToCloudflare(
        puppy.listing?.id,
        puppyVideo,
        getExistingVideo(puppy?.listing?.media),
        setSubmitProgress,
      );

      setSubmitProgress(98);
      await dispatch(getPuppy(getState().puppy.id));

      setSubmitProgress(100);
      return getState().puppy;
    } catch (error) {
      showNotificationError({
        error: "Couldn't edit puppy",
      });
    }
  };
}

export function createPuppy(
  puppyFormData: PuppyFormFields,
  setSubmitProgress: SetState<number>,
  setIsSameName: SetState<boolean>,
) {
  return async (dispatch: DispatchAny, getState: () => RootState) => {
    try {
      setSubmitProgress(5);

      const puppyPayload = puppyFormHelpers.payloadBuilder(puppyFormData);
      const puppyResp = await puppyFormHelpers.postPuppy(
        puppyPayload,
        setIsSameName,
      );

      setSubmitProgress(15);

      puppyFormData.noteFromBreeder &&
        (await puppyFormHelpers.postPuppyNote(
          puppyResp.listing.id,
          puppyFormData.noteFromBreeder,
        ));

      setSubmitProgress(20);

      const params: MediaUploaderParams = {
        mediaTypeId: mediaConfig.mediaType.photo,
        mediaEntityId: puppyResp.listing.id,
        mediaEntity: 'listing',
      };

      if (puppyFormData.puppyPhotos && puppyFormData.puppyPhotos.length > 0) {
        await dispatch(puppyPhotosUploader(puppyFormData.puppyPhotos, params));
        setSubmitProgress(50);

        const puppyResp2 = await apiPuppy.getPuppy(puppyResp.id as number);
        const puppy = puppyResp2.json.puppy;
        puppy.photos = puppyFormHelpers.buildPuppyPhotos(puppy.listing.media);

        await puppyFormHelpers.puppyPhotosSetViewOrder(
          puppy,
          puppyResp.listing.id,
        );
        setSubmitProgress(55);
        await puppyFormHelpers.puppyPhotoSetAsDefault(
          puppyResp.listing.id,
          getState().puppy.photos?.coverPhotos as Media[],
        );
        setSubmitProgress(60);
      }
      setSubmitProgress(60);

      if (!isEmpty(puppyFormData?.puppyVideo)) {
        await uploadVideoToCloudflare(
          puppyResp.listing.id,
          puppyFormData.puppyVideo,
          undefined,
          setSubmitProgress,
        );
      }
      setSubmitProgress(98);

      await dispatch(getPuppy(puppyResp.id));
      setSubmitProgress(100);
      return puppyResp.id;
    } catch (error) {
      showNotificationError({
        error: "Couldn't create puppy",
      });
    }
  };
}

export interface SetPuppyPhotosCover {
  type: typeof SET_PUPPY_PHOTOS_COVER;
  coverPhotos: Media[];
}

export function setPuppyPhotosCover(coverPhotos: Media[]): SetPuppyPhotosCover {
  return {
    type: SET_PUPPY_PHOTOS_COVER,
    coverPhotos,
  };
}

export interface SetPuppyPhotosAdditional {
  type: typeof SET_PUPPY_PHOTOS_ADDITIONAL;
  additionalPhotos: Media[];
}

export function setPuppyPhotosAdditional(
  additionalPhotos: Media[],
): SetPuppyPhotosAdditional {
  return {
    type: SET_PUPPY_PHOTOS_ADDITIONAL,
    additionalPhotos,
  };
}

export interface SetPuppyPhotosHidden {
  type: typeof SET_PUPPY_PHOTOS_HIDDEN;
  hiddenPhotos: Media[];
}

export function setPuppyPhotosHidden(
  hiddenPhotos: Media[],
): SetPuppyPhotosHidden {
  return {
    type: SET_PUPPY_PHOTOS_HIDDEN,
    hiddenPhotos,
  };
}

export interface SetPuppyPhotoHidden {
  type: typeof SET_SINGLE_PUPPY_PHOTO_HIDDEN;
  hiddenPhoto: Media;
}

export function setPuppyPhotoHidden(hiddenPhoto: Media): SetPuppyPhotoHidden {
  return {
    type: SET_SINGLE_PUPPY_PHOTO_HIDDEN,
    hiddenPhoto,
  };
}

export type UpdatePuppyPhotoId = {
  uid: string;
  id: number;
};

export interface UpdateNewPuppyPhotoId {
  type: typeof UPDATE_NEW_PUPPY_PHOTO_ID;
  payload: UpdatePuppyPhotoId;
}

export function updateNewPuppyPhotoId(
  payload: UpdatePuppyPhotoId,
): UpdateNewPuppyPhotoId {
  return {
    type: UPDATE_NEW_PUPPY_PHOTO_ID,
    payload,
  };
}

export function puppyPhotosUploader(
  puppyPhotosForm: Media[],
  params: MediaUploaderParams,
) {
  return async (dispatch: Dispatch) => {
    for (const mediaFile of puppyPhotosForm) {
      if (mediaFile?.file?.uid) {
        const respMediaUploadId = await media.uploader(mediaFile, params);
        if (typeof respMediaUploadId === 'number') {
          dispatch(
            updateNewPuppyPhotoId({
              uid: mediaFile.file?.uid,
              id: respMediaUploadId,
            }),
          );
        }
      } else {
        await apiMedia.putMedia(mediaFile);
      }
    }
  };
}

export const updateUploadedPhotoId = (
  payload: UpdatePuppyPhotoId,
  state: PuppyPhotos,
) => {
  const coverPhotos = state.coverPhotos.map((photo: Media) => {
    if (photo.uid === payload.uid) {
      delete photo.uid;
      return {
        ...photo,
        id: payload.id,
      };
    }

    return photo;
  });

  const additionalPhotos = state.additionalPhotos.map((photo: Media) => {
    if (photo.uid === payload.uid) {
      delete photo.uid;
      return {
        ...photo,
        id: payload.id,
      };
    }

    return photo;
  });

  return {
    coverPhotos,
    additionalPhotos,
    hiddenPhotos: state.hiddenPhotos,
  };
};

export function setPuppyOrder(order: PuppyOrder): SetPuppyOrder {
  return {
    type: SET_PUPPY_ORDER,
    order,
  };
}

export interface SetPuppyOrder {
  type: typeof SET_PUPPY_ORDER;
  order: PuppyOrder;
}

export function getPuppyOrder(puppyId: Id) {
  return async (dispatch: Dispatch) => {
    try {
      const puppyOrderResp = await apiPuppy.getPuppyOrder(puppyId as number);
      const puppyOrder: PuppyOrder = puppyOrderResp.json.order;

      dispatch(setPuppyOrder(puppyOrder));
    } catch (e) {
      showNotificationError({ error: "Couldn't load puppy order" });
    }
  };
}
