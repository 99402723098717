import { Listing, Puppy } from 'shared/types/puppy';
import {
  MyListingsFilters,
  MyListingsPagination,
  MyListingsSorter,
} from 'shared/types/myListings';

export const SET_MY_LISTINGS_APPROVED_LOADING =
  'SET_MY_LISTINGS_APPROVED_LOADING';
export const SET_MY_LISTINGS_APPROVED = 'SET_MY_LISTINGS_APPROVED';
export const SET_MY_LISTINGS_APPROVED_FILTERS =
  'SET_MY_LISTINGS_APPROVED_FILTERS';
export const SET_MY_LISTINGS_APPROVED_SORTER =
  'SET_MY_LISTINGS_APPROVED_SORTER';
export const SET_MY_LISTINGS_APPROVED_PAGINATION =
  'SET_MY_LISTINGS_APPROVED_PAGINATION';
export const UPDATE_MY_LISTINGS_APPROVED_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES =
  'UPDATE_MY_LISTINGS_APPROVED_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES';

export interface MyListingsMainState {
  loading: boolean;
  listings: Listing[];
  pagination: MyListingsPagination;
  filters: MyListingsFilters;
  sorter: MyListingsSorter;
}

interface SetMyListingsApprovedLoading {
  type: typeof SET_MY_LISTINGS_APPROVED_LOADING;
}

interface SetMyListingsApproved {
  type: typeof SET_MY_LISTINGS_APPROVED;
  listings: Listing[];
}

interface SetMyListingsApprovedFilters {
  type: typeof SET_MY_LISTINGS_APPROVED_FILTERS;
  filters: MyListingsFilters;
}

interface SetMyListingsApprovedSorter {
  type: typeof SET_MY_LISTINGS_APPROVED_SORTER;
  sorter: MyListingsSorter;
}

interface SetMyListingsApprovedPagination {
  type: typeof SET_MY_LISTINGS_APPROVED_PAGINATION;
  pagination: MyListingsPagination;
}

interface UpdateMyListingsApprovedPuppyRegistrationsWeightDueDates {
  type: typeof UPDATE_MY_LISTINGS_APPROVED_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES;
  puppy: Puppy;
}

export type MyListingsApprovedActions =
  | SetMyListingsApprovedLoading
  | SetMyListingsApproved
  | SetMyListingsApprovedFilters
  | SetMyListingsApprovedSorter
  | SetMyListingsApprovedPagination
  | UpdateMyListingsApprovedPuppyRegistrationsWeightDueDates;
