import { Alert, Col, Form, Row } from 'antd';
import { isEmpty } from 'lodash';
import { Store } from 'rc-field-form/lib/interface';
import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';

import AuthorizationForm from 'shared/authorizationForm/AuthorizationForm';
import FormItemSubmit from 'shared/authorizationForm/formItems/FormItemSubmit';
import FieldEmail from 'shared/fields/fieldEmail/FieldEmail';
import FieldPassword from 'shared/fields/fieldPassword/FieldPassword';
import { BreederRoutes } from 'shared/types/routes';
import { reactEnvironmentVariables } from 'utilities/environmentVariables';
import apiBreeder from 'utilities/http/api/apiBreeder';
import apiUser, { AuthLogin } from 'utilities/http/api/apiUser';
import userStorage from 'utilities/user/userStorage';

import { refreshStaticContent } from 'utilities/staticContent/refresh-static-content';
import './Login.scss';

export interface Credentials {
  email: string;
  password: string;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const acceptSmallPasswordsDueToLegacyUsers = 1;
export default function Login(): ReactElement {
  const [errorAlert, setErrorAlert] = useState<string | null>(null);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const onSubmit = async (info: Credentials | Store) => {
    setSubmitLoading(true);
    const { password, email } = info;
    const body: AuthLogin = {
      email,
      password,
      application: reactEnvironmentVariables.APPLICATION_ID,
    };
    try {
      const authResponse = await apiUser.authLogin(body);

      if (authResponse.status !== 200)
        throw new Error('Wrong login credentials. Please try again');
      userStorage.setToken(authResponse?.json?.token);

      const breederResponse = await apiBreeder.getBreeder();
      if (breederResponse.status !== 200)
        throw new Error('Wrong login credentials. Please try again');

      const user = breederResponse?.json?.user;

      if (!user?.canImpersonateAnyBreeder && isEmpty(user?.breeders)) {
        throw new Error(
          'User has no associated breeders. Please contact support',
        );
      }

      userStorage.setUser(user);

      await refreshStaticContent();

      setSubmitLoading(false);
      window.location.href = BreederRoutes.Dashboard;
    } catch (error) {
      userStorage.removeUserAndToken();
      if (error instanceof Error) {
        setErrorAlert(error.message);
      }
      setSubmitLoading(false);
    }
  };
  return (
    <AuthorizationForm
      title="Welcome!"
      mainPage={true}
      subtitle="Let us find a happy home for <strong>your puppies</strong>."
      className="LoginPage"
    >
      <Form
        className="LoginForm"
        {...layout}
        size="large"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
      >
        {errorAlert && (
          <Alert
            message={errorAlert}
            type="error"
            showIcon
            data-testid="login-alert"
          />
        )}
        <FieldEmail formId="login" placeholder="Type your email" />
        <FieldPassword
          placeholder="Enter your password"
          minimumPasswordLength={acceptSmallPasswordsDueToLegacyUsers}
          formId="login"
        />
        <Row gutter={{ sm: 16, md: 16, lg: 8 }}>
          <Col className="gutter-row" xs={24} sm={16} md={16} xl={24}>
            <Link to="/forgot-password" className="LoginForm__forgot">
              Forgot your password?
            </Link>
          </Col>
        </Row>
        <FormItemSubmit
          submitLoading={submitLoading}
          submitText="Submit"
          formId="login"
        />
        <div className="Login__register">
          Don&apos;t have an account yet?{' '}
          <Link to={BreederRoutes.Register}>Register here</Link>
        </div>
      </Form>
    </AuthorizationForm>
  );
}
