import React, { ReactElement, ReactNode, useCallback, useState } from 'react';
import { Badge, Card } from 'antd';

import 'components/entity/entityListColumn/EntityListColumn.scss';

interface Props {
  label: string;
  content: ReactNode;
  totalItems?: number;
}

function EntityListColumn(props: Props): ReactElement {
  const { label, content, totalItems } = props;
  const [contentDistanceFromTop, setContentDistanceFromTop] = useState(0);

  const contentRef = useCallback((node) => {
    if (node !== null) {
      setContentDistanceFromTop(node.getBoundingClientRect().top);
    }
  }, []);

  return (
    <Card
      className="EntityListColumn"
      bordered={false}
      title={
        <div className="EntityListColumn__header">
          {label} <Badge count={totalItems} />
        </div>
      }
    >
      <div
        className="EntityListColumn__content"
        ref={contentRef}
        style={{ height: `calc(98.4vh - ${contentDistanceFromTop}px` }}
      >
        {content}
      </div>
    </Card>
  );
}

export default EntityListColumn;
