import React, { ReactElement } from 'react';
import { Row, Col, Form, Checkbox } from 'antd';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import { PuppyParentActivityContent } from 'utilities/staticContent/types';

interface Props {
  disabled?: boolean;
}

export function FieldParentActivities(props: Props): ReactElement {
  const { disabled } = props;
  const puppyParentActivities = staticContentStorage.getContent()
    .puppyParentActivities;

  return (
    <Form.Item name="activities" label="Favorite activities">
      <Checkbox.Group disabled={disabled}>
        <Row gutter={[8, 24]}>
          {puppyParentActivities.map(
            (puppyParentActivity: PuppyParentActivityContent) => (
              <Col span={12} key={puppyParentActivity.id}>
                <Checkbox value={puppyParentActivity.id}>
                  {puppyParentActivity.name}
                </Checkbox>
              </Col>
            ),
          )}
        </Row>
      </Checkbox.Group>
    </Form.Item>
  );
}
