import { isEmpty, orderBy } from 'lodash';
import { Nullable } from 'shared/types/general';
import { Media, MediaUploaderParams } from 'shared/types/media';
import showNotificationError from 'shared/notifications/showNotificationError';
import { reactEnvironmentVariables } from 'utilities/environmentVariables';
import {
  isImage,
  isMediaDefault,
  isMediaPublic,
  isMediaStatusPendingUpload,
  isPedigreeFile,
  isMediaPrivate,
  isMediaStatusApproved,
  isMediaStatusPendingReview,
  isMediaStatusPublished,
} from 'utilities/media/mediaCheckers';
import apiMedia from 'utilities/http/api/apiMedia';

export const mediaConfig = {
  mediaType: {
    photo: 1,
    video: 2,
    audio: 3,
    file: 4,
    pedigreeFile: 5,
    cardPhoto: 6,
    healthDocument: 19,
  },
  mediaStatus: {
    pendingReview: 1,
    approved: 2,
    published: 3,
    hidden: 4,
    expired: 5,
    archived: 6,
    pendingUpload: 7,
    pendingProcessing: 8,
    rejected: 9,
    pendingSyncToLegacy: 10,
    pendingSyncToCore: 11,
  },
  visibilityId: {
    private: 1,
    public: 2,
    hidden: 3,
  },
  photoSize: {
    small: 1,
    medium: 2,
    large: 3,
    thumbnail: 4,
    largeResize: 6,
  },
  mediaDefault: 1,
};

const media = {
  getPedigreeDoc: (mediaFiles?: Media[]) => {
    const parentMediaPedigreeDocs = mediaFiles?.filter(
      (m) =>
        isPedigreeFile(m) && isMediaPublic(m) && !isMediaStatusPendingUpload(m),
    );
    const sortedPedigreeDocs = orderBy(
      parentMediaPedigreeDocs,
      ['uploadedAt'],
      ['desc'],
    );
    return sortedPedigreeDocs[0] as Media;
  },

  uploader: async (
    media: Media,
    params: MediaUploaderParams,
  ): Promise<number | undefined> => {
    try {
      const signResp = await apiMedia.signMediaUpload(media, params);
      await apiMedia.postMediaToS3(signResp.signature, media);
      await apiMedia.uploadComplete(signResp.media_id, params);

      return signResp.media_id;
    } catch (error) {
      if (error instanceof Error) {
        showNotificationError({
          message: 'Media upload error',
          error: error.message,
        });
      }
    }
  },

  getImagesFromMedia: (media: Nullable<Media[]>) => {
    if (media) {
      return media.filter((m) => isImage(m));
    } else {
      return null;
    }
  },

  getImageSize: (m: Nullable<Media>, size: number) => {
    if (m && m?.mediaVariants) {
      return m?.mediaVariants?.length > 0
        ? media.getImageUrl(
            m?.mediaVariants?.filter(
              (variant) => variant.photoSizeId === size,
            )?.[0]?.filePath,
          )
        : null;
    } else {
      return null;
    }
  },

  getImageUrl: (suffix: Nullable<string>) => {
    return suffix
      ? `${reactEnvironmentVariables.MEDIA_BUCKET_URL}/${suffix}`
      : undefined;
  },

  getImage: (m: Nullable<Media>, variant: number) => {
    const imageMedia: Nullable<Media> = m?.pendingEdit || m;
    return (
      media.getImageSize(imageMedia, variant) ||
      imageMedia?.encoded ||
      imageMedia?.dataUrl ||
      media.getImageUrl(imageMedia?.filePath)
    );
  },

  hasPendingEdits: (medias: Media[] | Partial<Media>[] | undefined) =>
    Boolean(medias?.find((m) => !isEmpty(m.pendingEdit))),

  getMainPhoto: (media: Nullable<Media[]>) => {
    const parentMediaPhotos = media?.filter(
      (m) =>
        isImage(m) &&
        (isMediaPublic(m) || isMediaPrivate(m)) &&
        !isEmpty(m.uploadedAt) &&
        !isMediaStatusPendingUpload(m),
    );

    const sortedPhotos = orderBy(parentMediaPhotos, ['uploadedAt'], ['desc']);
    return sortedPhotos[0] as Media;
  },

  getMainPhotoUrl: (m: Nullable<Media[]>, variant?: number) => {
    const mainPhoto = media.getMainPhoto(m);
    if (variant) {
      return media.getImage(mainPhoto, variant);
    } else {
      return mainPhoto.dataUrl || media.getImageUrl(mainPhoto.filePath);
    }
  },

  getDefaultPuppyPhoto: (
    puppyMedia: Media[] | undefined,
    photoSize?: number,
  ) => {
    const photos = puppyMedia?.filter((m) => isImage(m));
    const photo = photos?.find((p) => isMediaDefault(p));
    return media.getImage(
      photo,
      photoSize ? photoSize : mediaConfig.photoSize.thumbnail,
    );
  },

  buildParentMedia: (parentMedia: Nullable<Media[]>) => {
    return parentMedia?.filter(
      (media) =>
        isMediaStatusApproved(media) ||
        isMediaStatusPendingReview(media) ||
        isMediaStatusPublished(media),
    );
  },
};
export default media;
