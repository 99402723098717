import { isEmpty } from 'lodash';

import {
  ADD_MORE_LISTINGS,
  ListingsActionTypes,
  MyListingsState,
  SET_LISTINGS,
  SET_MODAL_LISTING,
  SEARCH_LISTING,
  SEARCH_LOADING,
  UPDATE_PUPPY_WEIGHT_REGISTRATION,
  SET_QUERY_OBJECT,
} from 'store/myListings/types';
import { listingUpdatePuppyRegistrationsWeightDueDates } from 'utilities/listing';

const initialState: MyListingsState = {
  pending: null,
  approved: null,
  sold: null,
  delivered: null,
  hidden: null,
  pastDue: null,
  upcoming: null,
  searchResult: null,
  searchCriteria: undefined,
  searchLoading: false,
  modalListing: null,
  queryObject: {
    pending: {
      pagination: {
        pageSize: 50,
        page: 1,
      },
      filters: {
        puppy_id: null,
        puppy_name: null,
      },
      sorter: [],
    },
  },
};

export function myListingsReducer(
  state = initialState,
  action: ListingsActionTypes,
): MyListingsState {
  switch (action.type) {
    case SET_LISTINGS: {
      const { columnName } = action;

      return {
        ...state,
        [columnName]: action.listings,
      };
    }
    case SET_MODAL_LISTING: {
      const { listing } = action;

      return {
        ...state,
        modalListing: listing,
      };
    }
    case ADD_MORE_LISTINGS: {
      const { breederDashboardStatus } = action;
      const initialListings = state[breederDashboardStatus] || [];
      return {
        ...state,
        [breederDashboardStatus]: [...initialListings, ...action.listings],
      };
    }
    case SEARCH_LISTING: {
      const { searchCriteria, searchResult } = action;
      return {
        ...state,
        searchCriteria,
        searchResult,
      };
    }

    case SEARCH_LOADING: {
      const { loading } = action;
      return {
        ...state,
        searchLoading: loading,
      };
    }
    case SET_QUERY_OBJECT: {
      const { breederDashboardStatus: page, queryObject } = action;
      return {
        ...state,
        queryObject: {
          ...state.queryObject,
          [page]: {
            ...queryObject,
          },
        },
      };
    }
    case UPDATE_PUPPY_WEIGHT_REGISTRATION: {
      const { puppy, columnName } = action;

      const columnListings = state?.[columnName] || [];
      const listings = columnListings.map((listing) =>
        listingUpdatePuppyRegistrationsWeightDueDates(listing, puppy),
      );

      let currentSearchResult = state?.searchResult;
      if (!isEmpty(currentSearchResult)) {
        const searchColumnListings = currentSearchResult?.[columnName];
        const searchListings =
          searchColumnListings?.map((listing) =>
            listingUpdatePuppyRegistrationsWeightDueDates(listing, puppy),
          ) || [];

        currentSearchResult = {
          ...currentSearchResult,
          [columnName]: [...searchListings],
        };
      }

      return {
        ...state,
        searchResult: currentSearchResult,
        [columnName]: listings,
      };
    }
    default:
      return state;
  }
}
