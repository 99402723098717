import { regExp } from 'utilities/regExp';

export const validationErrorMessage = {
  moreThan8Words: `Must be less than 8 words`,
  moreThan16Chars: `Must be no more than 16 characters`,
  alphabetical: `Don’t use numbers or special characters`,
  sameName: 'Please change your puppy name',
  empty: `Please fill out this field`,
  endsWith: 'Puppy name cannot end with a special character',
};

export const fieldNameValidationRules = (
  isSameName?: boolean,
  max16String?: boolean,
) => {
  return {
    validator: (_: unknown, value: string) => {
      if (!value) {
        return Promise.reject(validationErrorMessage.empty);
      }

      if (value.split(' ').length > 8) {
        return Promise.reject(validationErrorMessage.moreThan8Words);
      }

      if (!regExp.fieldName.test(value)) {
        return Promise.reject(validationErrorMessage.alphabetical);
      }

      if (value.trim().endsWith('.') || value.trim().endsWith("'")) {
        return Promise.reject(validationErrorMessage.endsWith);
      }

      if (isSameName) {
        return Promise.reject(validationErrorMessage.sameName);
      }

      if (max16String && value.length > 16) {
        return Promise.reject(validationErrorMessage.moreThan16Chars);
      }

      return Promise.resolve();
    },
  };
};
