import React, { ReactElement, useEffect, useRef, useState } from 'react';
import userStorage from 'utilities/user/userStorage';
import PageLayout from 'shared/baseLayout/pageLayout/PageLayout';
import showNotificationError from 'shared/notifications/showNotificationError';
import { isEmpty } from 'lodash';

import apiBreeder from 'utilities/http/api/apiBreeder';
import FullDashboard from './fullDashboard/FullDashboard';
import EmptyDashboard from './emptyDashboard/EmptyDashboard';
import { Nullable } from 'shared/types/general';
import { UpcomingUpdate } from 'shared/types/date';
import { Icon } from 'shared/icon/Icon';

import './Dashboard.scss';

export interface PuppySummary {
  mediaThumbnailUrl?: string;
  name?: string;
  puppyId?: number;
  views?: number;
}

export interface PuppyInventoryProps {
  approved?: number;
  delivered?: number;
  pending?: number;
  sold?: number;
  hidden?: number;
}

export interface DashboardSummary {
  mostViewedPuppies?: PuppySummary[];
  statusCounts?: PuppyInventoryProps;
  upcomingDueDates: Record<string, UpcomingUpdate>;
}

const breadCrumbs = [
  {
    name: `Welcome ${userStorage.getUser()?.firstName}`,
  },
];

export default function Dashboard(): ReactElement {
  const mountedRef = useRef(true);
  const [dashboardSummary, setDashboardSummary] = useState<
    Nullable<DashboardSummary>
  >(null);
  const [loading, setLoading] = useState(false);

  const fetchPageData = async () => {
    try {
      setLoading(true);
      const response = await apiBreeder.getDashboardSummary();
      if (mountedRef.current) {
        setDashboardSummary({ ...response?.json?.dashboardSummary });
        setLoading(false);
      }
    } catch (error) {
      if (error instanceof Error) {
        showNotificationError({ error: error.message });
      }
    }
  };

  useEffect(() => {
    fetchPageData();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <PageLayout className="Dashboard" breadCrumbsNav={breadCrumbs}>
      {loading ? (
        <section className="Dashboard__loading">
          <Icon name="spinner" className="Dashboard__spinner-icon" />
        </section>
      ) : isEmpty(dashboardSummary) ? (
        <EmptyDashboard />
      ) : (
        <FullDashboard dashboardSummary={dashboardSummary} />
      )}
    </PageLayout>
  );
}
