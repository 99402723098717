import React, { ReactElement } from 'react';
import { Typography } from 'antd';

import { Listing } from 'shared/types/puppy';
import { getPuppyBreed } from 'utilities/puppy/getPuppyUtils/getPuppyUtils';

interface Props {
  listing: Listing;
}

function ColumnBreed(props: Props): ReactElement {
  const {
    listing: { puppy },
  } = props;

  return <Typography.Text>{getPuppyBreed(puppy)}</Typography.Text>;
}

export default ColumnBreed;
