import React from 'react';
import { Form, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';

interface Props {
  label?: string;
  placeholder?: string;
  formId?: string;
  extra?: string;
  minimumPasswordLength?: number;
  withPrefix?: boolean;
}

export default function FieldPassword(props: Props) {
  const {
    label,
    formId,
    placeholder,
    extra,
    minimumPasswordLength,
    withPrefix = true,
  } = props;
  return (
    <Form.Item
      className="FieldPassword"
      name="password"
      data-testid={`password-input-${formId}`}
      label={label ?? 'Password'}
      extra={extra ?? 'Your password must be at least 8 characters long'}
      rules={[
        {
          required: true,
          min: minimumPasswordLength ?? 8,
          message: 'Password should be at least 8 characters long',
        },
      ]}
    >
      <Input.Password
        data-testid="credentials-password"
        prefix={withPrefix ? <LockOutlined /> : undefined}
        type="password"
        placeholder={
          placeholder ?? 'Type your new password and then click save'
        }
      />
    </Form.Item>
  );
}
