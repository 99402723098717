import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import { Empty } from 'antd';

import { RootState } from 'store/rootState';
import { PuppyPhotos } from 'shared/types/puppy';
import { handleOnDragEnd } from './puppyPhotoDragDropHelpers';
import PuppyPhotoDroppableSection from './PuppyPhotoDroppableSection';

interface Props {
  disabled?: boolean;
}

export default function PuppyPhotoDragDrop(props: Props): ReactElement {
  const { disabled } = props;
  const state = useSelector((state: RootState) => state);
  const { puppy } = state;
  const puppyPhotos: PuppyPhotos = {
    coverPhotos: puppy.photos?.coverPhotos,
    additionalPhotos: puppy.photos?.additionalPhotos,
    hiddenPhotos: puppy.photos?.hiddenPhotos,
  } as PuppyPhotos;

  const { coverPhotos, additionalPhotos } = puppyPhotos;
  const dispatch = useDispatch();

  return coverPhotos &&
    additionalPhotos &&
    coverPhotos.length + additionalPhotos?.length !== 0 ? (
    <DragDropContext
      onDragEnd={(result) => handleOnDragEnd(result, puppyPhotos, dispatch)}
    >
      <PuppyPhotoDroppableSection type="coverPhotos" disabled={disabled} />
      <PuppyPhotoDroppableSection type="additionalPhotos" disabled={disabled} />
    </DragDropContext>
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description="You haven't uploaded any images yet"
    />
  );
}
