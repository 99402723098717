import { isEmpty } from 'lodash';

import apiBreed from 'utilities/http/api/apiBreed';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import { MarkingContent } from 'utilities/staticContent/types';

export const getBreedMarkings = async (
  breedId: number,
): Promise<MarkingContent[]> => {
  const staticContent = staticContentStorage.getContent();
  const staticMarkings = staticContent.markings as MarkingContent[];
  const noMarkingsOption = staticMarkings?.filter(
    (m) => m.name === 'No Markings',
  );

  const resp = await apiBreed.getMarkingsByBreed(breedId);
  const markingsByBreed = (resp.json?.markings || []) as MarkingContent[];
  markingsByBreed?.sort((a, b) => a.name.localeCompare(b.name));

  if (isEmpty(markingsByBreed)) {
    return noMarkingsOption;
  } else {
    return [...noMarkingsOption, ...markingsByBreed];
  }
};
