import React, { ReactElement } from 'react';
import { Typography } from 'antd';

import { Listing } from 'shared/types/puppy';

interface Props {
  listing: Listing;
}

function ColumnViews(props: Props): ReactElement {
  const {
    listing: { listingTally },
  } = props;

  return (
    <Typography.Text>
      {listingTally?.viewCount === 0 ? 'None' : listingTally?.viewCount}
    </Typography.Text>
  );
}

export default ColumnViews;
