import React, { ReactElement } from 'react';

import { useParams } from 'react-router-dom';
import { TokenRouterParameter } from 'shared/types/general';
import ResetPassword from './resetPassword/ResetPassword';
import RecoveryPassword from './recoveryPassword/RecoveryPassword';

export default function ForgotPassword(): ReactElement {
  const { token } = useParams<TokenRouterParameter>();
  if (token) {
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get('email');

    if (email) {
      const breederEmail = decodeURIComponent(email);
      return <ResetPassword email={breederEmail} token={token} />;
    }
  }
  return <RecoveryPassword />;
}
