import { Dispatch } from 'redux';
import { notification } from 'antd';

import { Listing } from 'shared/types/puppy';
import {
  setMyListingsDelivered,
  setMyListingsDeliveredFilters,
  setMyListingsDeliveredLoading,
  setMyListingsDeliveredPagination,
  setMyListingsDeliveredSorter,
} from 'store/myListingsDelivered/actions';
import {
  initialStateMyListingsDeliveredFilters,
  initialStateMyListingsDeliveredPagination,
  initialStateMyListingsDeliveredSorter,
} from 'store/myListingsDelivered/myListingsDeliveredReducer';
import { RootState } from 'store/rootState';
import apiListing from 'utilities/http/api/apiListing';
import { BreederDashboardStatus } from 'utilities/const';
import { requestHasErrors } from 'utilities/http/http';

export function fetchMyListingsDelivered() {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setMyListingsDeliveredLoading());

    const {
      listings,
      pagination,
      sorter,
      filters,
    } = getState().myListingsDelivered;

    const myListingsResp = await apiListing.getMyListings(
      BreederDashboardStatus.Delivered,
      pagination,
      sorter,
      filters,
    );

    if (requestHasErrors(myListingsResp)) {
      if (!filters.isFiltering) {
        notification.error({ message: `Error fetching my listings delivered` });
      }

      dispatch(setMyListingsDelivered([]));
      dispatch(
        setMyListingsDeliveredPagination({
          ...pagination,
          total: 0,
        }),
      );
      return;
    }

    const listingsResp = myListingsResp?.json.listings as Listing[];
    const paginationResp = myListingsResp?.json.paginationMeta;

    if (pagination.loadMore) {
      dispatch(setMyListingsDelivered([...listings, ...listingsResp]));
    } else {
      dispatch(setMyListingsDelivered(listingsResp));
    }

    dispatch(
      setMyListingsDeliveredPagination({
        ...pagination,
        total: paginationResp.total,
      }),
    );
  };
}

export function setMyListingsDeliveredInitialState() {
  return (dispatch: Dispatch) => {
    dispatch(setMyListingsDelivered([]));
    dispatch(
      setMyListingsDeliveredPagination(
        initialStateMyListingsDeliveredPagination,
      ),
    );
    dispatch(
      setMyListingsDeliveredFilters(initialStateMyListingsDeliveredFilters),
    );
    dispatch(
      setMyListingsDeliveredSorter(initialStateMyListingsDeliveredSorter),
    );
  };
}
