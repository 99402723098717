import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Empty } from 'antd';
import { isEmpty } from 'lodash';

import LoadingSection from 'components/loadingSection/LoadingSection';
import MyParentsLoadMore from 'components/parents/myParentsLoadMore/MyParentsLoadMore';
import { MyParentsTypes } from 'shared/types/parent';
import { RootState } from 'store/rootState';
import ParentCard from '../parentCard/ParentCard';

interface Props {
  parentsType: MyParentsTypes;
}

export default function ParentsColumn(props: Props): ReactElement {
  const { parentsType } = props;
  const myParents = useSelector((state: RootState) => state.myParents);
  const parents = myParents[parentsType].data;

  if (myParents[parentsType].loading) {
    return <LoadingSection />;
  }

  if (isEmpty(parents)) {
    return <Empty />;
  }

  return (
    <>
      {parents?.map((p) => (
        <ParentCard key={p.id} parent={p} parentsType={parentsType} />
      ))}
      <MyParentsLoadMore myParentType={parentsType} />
    </>
  );
}
