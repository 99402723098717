export const RelativeSizeId = {
  Smallest: 1,
  Largest: 2,
  Neither: 3,
  OnlyPuppyInLitter: 4,
} as const;

export const InventoryStatusId = {
  Available: 1,
  PendingSale: 2,
  SoldByBreeder: 3,
  NotAvailable: 4,
  Reserved: 5,
  Replacement: 6,
  Sold: 7,
} as const;

export const InventoryStatusLabel = {
  Available: 'Available',
  PendingSale: 'Pending Sale',
  SoldByBreeder: 'Sold By Breeder',
  NotAvailable: 'Not Available',
  Reserved: 'Reserved',
  Replacement: 'Replacement',
  Sold: 'Sold',
} as const;

export const WorkflowId = {
  PlmListings: 1,
  PlmPuppyParents: 2,
  TravelPlaning: 3,
  Documents: 4,
} as const;

export const ListingWorkflowStatusId = {
  Submitted: 1,
  ApprovalInProgress: 2,
  Approved: 3,
  PendingWorkAcceptance: 4,
  Postponed: 5,
  Forwarded: 6,
  ForwardReviewInProgress: 14,
} as const;

export const ParentsWorkflowStatusId = {
  Submitted: 7,
  ApprovalInProgress: 8,
  Approved: 9,
  PendingWorkAcceptance: 10,
  Postponed: 11,
  Forwarded: 12,
  ForwardReviewInProgress: 13,
} as const;

export const WorkflowStatusLabel = {
  Submitted: 'Submitted',
  ApprovalInProgress: 'Approval in progress',
  Approved: 'Approved',
  PendingWorkAcceptance: 'Pending Work Acceptance',
  Postponed: 'Postponed',
  Forwarded: 'Forwarded',
  ForwardReviewInProgress: 'Forward Review in progress',
} as const;

export const ListingStatusId = {
  Created: 1,
  Live: 2,
  Sold: 3,
  Hidden: 4,
  Removed: 5,
} as const;

export const ListingStatusLabel = {
  Created: 'Created',
  Live: 'Live',
  Sold: 'Sold',
  Hidden: 'Hidden',
  Removed: 'Removed',
} as const;

export const BreederDashboardStatus = {
  Approved: 'approved',
  Delivered: 'delivered',
  Pending: 'pending',
  Sold: 'sold',
  Hidden: 'hidden',
} as const;

export const CollapsableStatus = {
  Disabled: 'disabled',
  Pending: 'pending',
  Success: 'success',
  Warning: 'warning',
} as const;

export const SortDirection = {
  ascend: 'asc',
  descend: 'desc',
} as const;

export const EntityType = {
  Listing: 'listing',
  Litter: 'litter',
  Parent: 'parent',
  Puppy: 'puppy',
} as const;

export const DocumentTypeId = {
  HealthCertificate: 1,
  VaccineRecord: 2,
  VeterinaryHealthReport: 3,
} as const;

export const DocumentTypeLabel = {
  HealthCertificate: 'Health Certificate',
  VaccineRecord: 'Vaccine Record',
  VeterinaryHealthReport: 'Veterinary Health Report',
} as const;

export const DocumentTypeLabelAbbr = {
  HealthCertificate: 'HC',
  VaccineRecord: 'VAX',
  VeterinaryHealthReport: 'VHR',
} as const;

export const DocumentWorkflowStatusId = {
  PendingReview: 23,
  HealthReview: 24,
  Approved: 25,
  Rejected: 26,
  AwaitingDisclosure: 27,
} as const;

export const DocumentWorkflowStatusLabel = {
  PendingReview: 'Pending Review',
  HealthReview: 'Health Review',
  Approved: 'Approved',
  Rejected: 'Rejected',
  AwaitingDisclosure: 'Awaiting Disclosure',
} as const;

export const ListingStatusReasonId = {
  Expired: 9,
  RemovedByBreeder: 10,
  HealthIssues: 11,
  PuppyTooOld: 12,
  PuppyDeceased: 13,
  NoBreederResponse: 14,
  Other: 15,
} as const;

export const HealthDocsDueDateDaysRange = {
  DueToday: 0,
  OneDay: 1,
  TwoDays: 2,
  ThreeDays: 3,
  FourDays: 4,
  FiveDays: 5,
  SixDays: 6,
  SevenDays: 7,
} as const;

export const HealthDocsDueDateStatusSlug = {
  UpToDate: 'up-to-date',
  DueLessSevenDays: 'due-less-seven-days',
  DaysToDue: 'days-to-due',
  DueToday: 'due-today',
  PastDue: 'past-due',
} as const;
