import { useEffect, useState } from 'react';

import { getElementHeight } from 'utilities/dom/element';

interface BaseLayoutAffixOffset {
  offsetTopPageLayoutHeader: number;
  offsetTopMyEntitySearch: number;
  offsetTopMyEntityTabBar: number;
}

function useBaseLayoutAffixOffset(): BaseLayoutAffixOffset {
  const [offsetTopPageLayoutHeader, setOffsetTopPageLayoutHeader] = useState(0);
  const [offsetTopMyEntityTabBar, setOffsetTopMyEntityTabBar] = useState(0);
  const [offsetTopMyEntitySearch, setOffsetTopMyEntitySearch] = useState(0);

  const handleSetOffsetTop = () => {
    const impersonateHeaderHeight = getElementHeight(
      'BreederImpersonateHeader',
    );
    const baseLayoutHeaderHeight = getElementHeight('BaseLayout__header');
    const pageLayoutHeaderHeight = getElementHeight('PageLayout__header');
    const myEntitySearchHeight = getElementHeight('MyListingsSearch');

    setOffsetTopPageLayoutHeader(
      impersonateHeaderHeight + baseLayoutHeaderHeight,
    );

    setOffsetTopMyEntitySearch(
      impersonateHeaderHeight + baseLayoutHeaderHeight + pageLayoutHeaderHeight,
    );

    setOffsetTopMyEntityTabBar(
      impersonateHeaderHeight +
        baseLayoutHeaderHeight +
        pageLayoutHeaderHeight +
        myEntitySearchHeight,
    );
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
    window.addEventListener('resize', handleSetOffsetTop);
  }, []);

  useEffect(() => {
    handleSetOffsetTop();
  });

  return {
    offsetTopPageLayoutHeader,
    offsetTopMyEntitySearch,
    offsetTopMyEntityTabBar,
  };
}

export default useBaseLayoutAffixOffset;
