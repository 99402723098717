import React, { ReactElement } from 'react';
import { Tag } from 'antd';
import { DollarOutlined } from '@ant-design/icons';

import { testId } from 'testsUtilities/dataTestId';

interface Props {
  className?: string;
}

function SoldTag(props: Props): ReactElement {
  const { className } = props;
  const classModifier = className
    ? `sold-tag sold-tag--${className}`
    : `sold-tag`;

  return (
    <Tag
      data-testid={testId.soldTag}
      className={classModifier}
      icon={<DollarOutlined />}
      color="processing"
    >
      Sold
    </Tag>
  );
}

export default SoldTag;
