import { UploadChangeParam } from 'antd/lib/upload';

const validationErrorMessage = {
  empty: `Please select a file`,
};

export const FieldMediaUploadEmptyValidationRule = () => {
  return {
    validator: (_: unknown, value: UploadChangeParam) => {
      if (!value) {
        return Promise.reject(validationErrorMessage.empty);
      }

      return Promise.resolve();
    },
  };
};

export const FieldPedigreeDocValidationRule = (isChampion: boolean) => {
  return {
    validator: (_: unknown, value: UploadChangeParam) => {
      if (!value) {
        if (isChampion) {
          return Promise.reject(validationErrorMessage.empty);
        }
        return Promise.resolve();
      }
      if (!value && isChampion) {
        return Promise.reject(validationErrorMessage.empty);
      }

      return Promise.resolve();
    },
  };
};
