import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Form, Input, notification, Radio } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import { isEmpty } from 'lodash';

import { FieldBreed, SubmitButton } from 'shared/fields';
import FieldPhone from 'shared/fields/fieldPhone/FieldPhone';
import showNotificationError from 'shared/notifications/showNotificationError';
import { Breeder, Veterinarian } from 'shared/types/breeder';
import { Breed, Id } from 'shared/types/general';
import { RootState } from 'store/rootState';
import { UsdaLicenseStatusesContent } from 'utilities/staticContent/types';
import apiBreeder from 'utilities/http/api/apiBreeder';
import { isUsdaLicenseActive } from 'utilities/license/license';
import { regExp } from 'utilities/regExp';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import userStorage from 'utilities/user/userStorage';

import './BreedingForm.scss';

const { TextArea } = Input;

interface BreederFormFields {
  breedingPracticesDescription: string;
  firstBreed?: Id;
  secondBreed?: Id;
  thirdBreed?: Id;
  fourthBreed?: Id;
  fifthBreed?: Id;
  sixthBreed?: Id;
  kennelName: string;
  hasStateLicense: boolean;
  stateLicenseNumber: string;
  usdaLicenseStatusId?: number;
  id?: Id;
  usdaNumber: string;
  officialIdentificationNumber?: string;
  websiteUrl: string;
  veterinarianName: string;
  veterinarianPhone: string;
}

async function updateContactInfo(fields: BreederFormFields) {
  const error = "Couldn't change your breeding information, please try again";
  try {
    const breeder: Partial<Breeder> = {
      id: userStorage?.getBreederId() as number,
      kennelName: fields.kennelName,
      websiteUrl: fields.websiteUrl,
      breedingPracticesDescription: fields.breedingPracticesDescription,
      usdaNumber: fields.usdaNumber,
      usdaLicenseStatusId: fields.usdaLicenseStatusId,
      breederVeterinarians: [
        {
          veterinarianName: fields.veterinarianName,
          veterinarianPhone: fields.veterinarianPhone,
          displayOrder: 1,
        },
      ],
    };
    if (fields.stateLicenseNumber) {
      breeder.stateLicenseNumber = fields.stateLicenseNumber?.toString();
    }
    if (fields.officialIdentificationNumber) {
      breeder.officialIdentificationNumber = fields.officialIdentificationNumber?.toString();
    }

    const updateResponse = await apiBreeder.updateBreeder(breeder as Breeder);
    if (updateResponse.status !== 200) {
      showNotificationError({ error });
    }
  } catch (e) {
    showNotificationError({ error });
  }
}

async function deleteBreedersBreed(breeds?: Partial<Breed>[]) {
  await Promise.all([
    breeds?.map((b: Partial<Breed>) => apiBreeder.deleteBreed(b.id)),
  ]);
}

async function postBreedsInOrder(fields: BreederFormFields) {
  await apiBreeder.postBreed(fields.firstBreed);
  await apiBreeder.postBreed(fields.secondBreed);
  await apiBreeder.postBreed(fields.thirdBreed);
  await apiBreeder.postBreed(fields.fourthBreed);
  await apiBreeder.postBreed(fields.fifthBreed);
  await apiBreeder.postBreed(fields.sixthBreed);
}

function onUncheckedAndSubmitShowError() {
  return (_: unknown, value: undefined | boolean) => {
    return value
      ? Promise.resolve()
      : Promise.reject(
          "You must have (or be exempt from having) a valid State Breeder's License to create an account",
        );
  };
}

export default function BreedingForm(): ReactElement {
  const { breeder, addresses } = useSelector((state: RootState) => ({
    breeder: state.breeder?.breeder,
    addresses: state.breeder?.breeder?.addresses,
  }));
  const staticContent = staticContentStorage.getContent();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showStateLicense, setShowStateLicense] = useState(true);
  const [showUsdaLicense, setShowUsdaLicense] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setShowUsdaLicense(isUsdaLicenseActive(breeder?.usdaLicenseStatusId));
  }, [breeder]);

  useEffect(() => {
    let fieldsToReset;
    if (showUsdaLicense) {
      fieldsToReset = { officialIdentificationNumber: '' };
    } else {
      fieldsToReset = { usdaNumber: '' };
    }
    form.setFieldsValue(fieldsToReset);
  }, [showUsdaLicense, form]);

  useEffect(() => {
    if (!breeder || !addresses) return;
    const hasStateLicense = Boolean(breeder.stateLicenseNumber);
    const breeds = breeder?.breeds;
    const veterinarian: Veterinarian | undefined =
      breeder?.breederVeterinarians?.[0];
    const veterinarianName = veterinarian?.veterinarianName;
    const veterinarianPhone = veterinarian?.veterinarianPhone;
    form.setFieldsValue({
      kennelName: breeder.kennelName,
      websiteUrl: breeder.websiteUrl,
      breedingPracticesDescription: breeder.breedingPracticesDescription?.toString(),
      firstBreed: breeds?.[0]?.id,
      secondBreed: breeds?.[1]?.id,
      thirdBreed: breeds?.[2]?.id,
      fourthBreed: breeds?.[3]?.id,
      fifthBreed: breeds?.[4]?.id,
      sixthBreed: breeds?.[5]?.id,
      usdaLicenseStatusId: breeder.usdaLicenseStatusId,
      officialIdentificationNumber: breeder.officialIdentificationNumber,
      hasStateLicense: hasStateLicense,
      stateLicenseNumber: breeder.stateLicenseNumber,
      usdaNumber: breeder.usdaNumber,
      veterinarianName,
      veterinarianPhone,
    });
    setShowStateLicense(hasStateLicense);
  }, [breeder, addresses, form]);

  const onBreedingFormChange = (changedFields: FieldData[]) => {
    if (!changedFields || isEmpty(changedFields)) return;
    const fieldName = changedFields[0]?.name;
    const value = changedFields[0]?.value;
    if (typeof fieldName !== 'object') return;

    switch (fieldName[0]) {
      case 'hasStateLicense': {
        setShowStateLicense(value);
        if (!value) {
          form.setFieldsValue({
            stateLicenseNumber: '',
          });
        }
        break;
      }
      case 'usdaLicenseStatusId': {
        setShowUsdaLicense(isUsdaLicenseActive(value));
        form.setFieldsValue({
          usdaLicenseStatusId: value,
        });
        break;
      }
    }
  };

  const onSubmit = async (fields: BreederFormFields) => {
    setIsSubmitting(true);
    await updateContactInfo(fields);

    await deleteBreedersBreed(breeder?.breeds);

    try {
      await postBreedsInOrder(fields);
      notification.success({ message: 'Breeding information changed' });
      setIsSubmitting(false);
    } catch (e) {
      showNotificationError({
        error: "Couldn't update your breeds, please try again later",
      });
      setIsSubmitting(false);
    }
  };

  return (
    <Form
      data-testid="breeding-form"
      name="breedingForm"
      layout="vertical"
      scrollToFirstError
      size="large"
      className="BreedingForm"
      form={form}
      hideRequiredMark={true}
      onFinish={onSubmit}
      onFieldsChange={onBreedingFormChange}
    >
      <Form.Item
        name="kennelName"
        label="Kennel names"
        rules={[
          {
            type: 'string',
            required: true,
            min: 2,
            message: 'Please use a valid kennel name',
          },
        ]}
      >
        <Input type="text" placeholder="Type here" />
      </Form.Item>
      <Form.Item
        name="websiteUrl"
        label="Website"
        rules={[
          {
            required: true,
            type: 'string',
            message: 'Please use a valid url',
            min: 3,
          },
        ]}
      >
        <Input type="text" placeholder="Type here url" />
      </Form.Item>
      <label>Breeding practices</label>
      <ul className="BreedingForm__practice-list">
        <li>How long have you been breeding?</li>
        <li>Why do you breed? Tell us about yourself.</li>
        <li>
          Do you microchip your puppies? If available upon request, what is the
          additional cost?
        </li>
        <li>
          If you could tell one thing a potential customer about you and your
          puppies, what would it be?
        </li>
      </ul>
      <Form.Item
        name="breedingPracticesDescription"
        rules={[
          {
            message:
              'Breeding Practices should contain at least 350 characters',
            min: 350,
            max: 500,
            required: true,
          },
        ]}
      >
        <TextArea
          readOnly
          placeholder="Type your answers here"
          maxLength={500}
          showCount={true}
          autoSize={{ minRows: 6 }}
        />
      </Form.Item>
      <FieldBreed name="firstBreed" label="First Breed" hasValidation={false} />
      <FieldBreed
        name="secondBreed"
        label="Second Breed"
        hasValidation={false}
      />
      <FieldBreed name="thirdBreed" label="Third Breed" hasValidation={false} />
      <FieldBreed
        name="fourthBreed"
        label="Fourth Breed"
        hasValidation={false}
      />
      <FieldBreed name="fifthBreed" label="Fifth Breed" hasValidation={false} />
      <FieldBreed name="sixthBreed" label="Sixth Breed" hasValidation={false} />
      <Form.Item
        name="usdaLicenseStatusId"
        className="BreedingForm__has-complementary BreedingForm__usda-radio"
        label="Are you an active USDA Dog Breeder?"
      >
        <Radio.Group>
          {staticContent.usdaLicenseStatuses
            .reverse()
            .map((l: UsdaLicenseStatusesContent) => (
              <Radio
                key={l.id}
                value={l.id}
                className="BreedingForm__usda-option"
              >
                {l.name}
              </Radio>
            ))}
        </Radio.Group>
      </Form.Item>
      {showUsdaLicense ? (
        <Form.Item
          name="usdaNumber"
          label="USDA license number"
          rules={[
            {
              pattern: regExp.usdaFormat,
              message: 'The field USDA Number must be in ##-X-#### format',
              required: true,
            },
          ]}
        >
          <Input type="text" placeholder="Type USDA license here" />
        </Form.Item>
      ) : (
        <Form.Item
          name="officialIdentificationNumber"
          label="Driver License or Official ID#"
          rules={[
            {
              required: true,
              message: 'Please use a valid Driver License or Official ID',
            },
          ]}
        >
          <Input type="text" placeholder="Type here" />
        </Form.Item>
      )}

      <Form.Item
        className="BreedingForm__has-complementary"
        name="hasStateLicense"
        label="You must have (or be exempt from having) a valid State Breeder's License to create an account"
      >
        <Radio.Group>
          <Radio value={false}>No</Radio>
          <Radio value={true}>Yes</Radio>
        </Radio.Group>
      </Form.Item>
      {showStateLicense ? (
        <Form.Item
          className="BreedingForm__state"
          name="stateLicenseNumber"
          label="State license number"
        >
          <Input type="text" placeholder="Type license here" />
        </Form.Item>
      ) : (
        <Form.Item
          className="BreedingForm__state"
          name="stateLicenseExempt"
          valuePropName="checked"
          rules={[
            {
              validator: onUncheckedAndSubmitShowError(),
            },
          ]}
        >
          <Checkbox>
            I am not required under current State regulations to be licensed
          </Checkbox>
        </Form.Item>
      )}

      <Form.Item
        name="veterinarianName"
        label="Vet's Name"
        rules={[
          {
            type: 'string',
            message: 'Please use a valid name',
            required: true,
          },
        ]}
      >
        <Input type="text" placeholder="Type here" />
      </Form.Item>
      <FieldPhone
        name="veterinarianPhone"
        label="Vet's phone number"
        required={true}
        setFieldsValue={form.setFieldsValue}
      />
      <SubmitButton label="Save " loading={isSubmitting} />
    </Form>
  );
}
