import React from 'react';

import {
  CharterTravelProposal,
  TravelProposals,
} from 'shared/types/puppyTravel';
import TravelInfoItem from 'shared/puppy/puppyTravel/TravelInfoItem';
import { testId } from 'testsUtilities/dataTestId';
import date from 'utilities/time/date';
import { getContentDataById } from 'utilities/staticContent/content';

interface Props {
  charterProposal: CharterTravelProposal;
  proposalData: Partial<TravelProposals>;
}

function TravelProposalInfoCharter(props: Props) {
  const { charterProposal, proposalData } = props;
  return (
    <section data-testid={testId.travelProposalInfoCharter}>
      <TravelInfoItem
        label="First mile transporter"
        value={charterProposal?.firstMileTransporterCompany?.name}
      />

      <TravelInfoItem
        label="Last mile transporter"
        value={charterProposal?.lastMileTransporterCompany?.name}
      />

      <TravelInfoItem
        label="Breeder Drop Off Location"
        value={charterProposal?.dropOffLocation}
      />

      <TravelInfoItem
        label="Crate size"
        value={
          getContentDataById('crateSizes', charterProposal?.crateSizeId)?.name
        }
      />

      <TravelInfoItem
        label="Departure date"
        value={date.toFullMonthDDYYYY(proposalData?.departureTime)}
      />

      <TravelInfoItem
        label="Arrival date"
        value={date.toFullMonthDDYYYY(proposalData?.arrivalTime)}
      />

      <TravelInfoItem
        label="Origin airport"
        value={
          charterProposal.originAirport ? (
            <>
              <b>
                {
                  getContentDataById(
                    'states',
                    charterProposal?.originAirport?.stateId,
                  )?.abbr
                }
              </b>
              {charterProposal?.originAirport?.name}
            </>
          ) : (
            'TBD'
          )
        }
      />

      <TravelInfoItem
        label="Arrival airport"
        value={
          <>
            <b>
              {
                getContentDataById(
                  'states',
                  charterProposal?.destinationAirport?.stateId,
                )?.abbr
              }
            </b>
            {charterProposal?.destinationAirport?.name}
          </>
        }
      />
    </section>
  );
}

export default TravelProposalInfoCharter;
