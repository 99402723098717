import { Dispatch } from 'redux';
import { Media } from 'shared/types/media';
import { DraggableLocation, DropResult } from 'react-beautiful-dnd';

import {
  setPuppyPhotosAdditional,
  setPuppyPhotosCover,
} from 'store/puppy/actions';
import { mediaConfig } from 'utilities/media/media';
import showNotificationError from 'shared/notifications/showNotificationError';
import { PuppyPhotosTypes } from './types';
import { PuppyPhotos } from 'shared/types/puppy';

const droppableSectionMaxLength = 4;
const coverPhotoInitOrder = 1;
const additionalPhotoInitOrder = 5;

const dragDropReorder = (
  list: Media[],
  startIndex: number,
  endIndex: number | undefined,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  if (endIndex != null) {
    result.splice(endIndex, 0, removed);
  }

  return result;
};

const droppableSectionMaxLengthError = (
  destination: DraggableLocation,
  source: DraggableLocation,
) => {
  const abbrevText = {
    coverPhotos: 'Cover',
    additionalPhotos: 'Additional',
  };
  return showNotificationError({
    message: `The ${
      abbrevText[destination.droppableId as PuppyPhotosTypes]
    } section is full`,
    error: `You’ll need to delete at least one image on the ${
      abbrevText[destination.droppableId as PuppyPhotosTypes]
    } section to clear up positions so you can drag the desired one from the ${
      abbrevText[source.droppableId as PuppyPhotosTypes]
    } section`,
  });
};

const dragDropMove = (
  source: Iterable<unknown> | ArrayLike<unknown>,
  destination: Iterable<unknown> | ArrayLike<unknown>,
  droppableSource: { index: number; droppableId: string | number },
  droppableDestination: { index: number; droppableId: string | number },
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: { [key: string]: Media[] } = {};

  result[droppableSource.droppableId] = sourceClone as Media[];
  result[droppableDestination.droppableId] = destClone as Media[];

  return result;
};

const setAdditionalPhotosResult = (additionalPhotos: Media[]) => {
  return additionalPhotos.map((photo, index) => ({
    ...photo,
    visibilityId: mediaConfig.visibilityId.private,
    viewOrder: index + additionalPhotoInitOrder,
    isDefault: 0,
  }));
};

const setCoverPhotosResult = (coverPhotos: Media[]) => {
  return coverPhotos.map((photo, index) => ({
    ...photo,
    visibilityId: mediaConfig.visibilityId.public,
    viewOrder: index + coverPhotoInitOrder,
    isDefault: index === 0 ? 1 : 0,
  }));
};

export const handleOnDragEnd = (
  result: DropResult,
  puppyPhotos: PuppyPhotos,
  dispatch: Dispatch,
) => {
  const { source, destination } = result;
  let newState = [];

  const getPhotosList = (id: string) => puppyPhotos[id as PuppyPhotosTypes];

  if (!destination) {
    return;
  }

  if (
    source.droppableId === 'additionalPhotos' &&
    destination.droppableId === 'coverPhotos'
  ) {
    const additionalClone = Array.from(puppyPhotos.additionalPhotos);
    const coverClone = Array.from(puppyPhotos.coverPhotos);
    const [lastItem] = coverClone.splice(coverClone.length - 1, 1);
    const [draggedPhoto] = additionalClone.splice(source.index, 1);

    if (puppyPhotos.coverPhotos.length >= 4) {
      if (
        lastItem.mediaStatusId !== mediaConfig.mediaStatus.approved &&
        lastItem.mediaStatusId !== mediaConfig.mediaStatus.published
      ) {
        additionalClone.splice(0, 0, lastItem);
        coverClone.splice(destination.index, 0, draggedPhoto);
        dispatch(setPuppyPhotosCover(setCoverPhotosResult(coverClone)));
        dispatch(
          setPuppyPhotosAdditional(setAdditionalPhotosResult(additionalClone)),
        );

        return;
      }
    } else if (
      draggedPhoto.mediaStatusId === mediaConfig.mediaStatus.approved ||
      draggedPhoto.mediaStatusId === mediaConfig.mediaStatus.published
    ) {
      return showNotificationError({
        message: "You can't drag this image",
        error: `Already approved photos in the additional section cannot be moved to the cover photos section.`,
      });
    }
  }

  if (
    source.droppableId === 'coverPhotos' &&
    destination.droppableId === 'additionalPhotos'
  ) {
    const draggedPhoto = puppyPhotos.coverPhotos.find(
      (photo) => photo.id === Number(result.draggableId),
    );
    if (
      draggedPhoto?.mediaStatusId === mediaConfig.mediaStatus.approved ||
      draggedPhoto?.mediaStatusId === mediaConfig.mediaStatus.published
    ) {
      return showNotificationError({
        message: "You can't drag this image",
        error: `Already approved photos in the cover section cannot be moved to the 
        additional photos section, to add new cover images please delete the current 
        ones first and then click the "Select Image" button.`,
      });
    } else if (puppyPhotos.additionalPhotos.length >= 4) {
      const additionalClone = Array.from(puppyPhotos.additionalPhotos);
      const coverClone = Array.from(puppyPhotos.coverPhotos);

      const [draggedPhoto] = coverClone.splice(source.index, 1);
      const [firstItem] = additionalClone.splice(0, 1);

      coverClone.splice(coverClone.length, 0, firstItem);
      additionalClone.splice(
        destination.index === 0 ? 0 : destination.index - 1,
        0,
        draggedPhoto,
      );
      dispatch(setPuppyPhotosCover(setCoverPhotosResult(coverClone)));
      dispatch(
        setPuppyPhotosAdditional(setAdditionalPhotosResult(additionalClone)),
      );

      return;
    }
  }

  if (source.droppableId === destination.droppableId) {
    const photos = dragDropReorder(
      getPhotosList(source.droppableId),
      result.source.index,
      result.destination && result.destination.index,
    );

    if (source.droppableId === 'coverPhotos') {
      newState = setCoverPhotosResult(photos);
      dispatch(setPuppyPhotosCover(newState));
    }

    if (source.droppableId === 'additionalPhotos') {
      newState = setAdditionalPhotosResult(photos);
      dispatch(setPuppyPhotosAdditional(newState));
    }
  } else {
    const result = dragDropMove(
      getPhotosList(source.droppableId),
      getPhotosList(destination.droppableId),
      source,
      destination,
    );

    if (
      result.coverPhotos.length > droppableSectionMaxLength ||
      result.additionalPhotos.length > droppableSectionMaxLength
    ) {
      return droppableSectionMaxLengthError(destination, source);
    }

    const newStateCover = setCoverPhotosResult(result.coverPhotos);
    const newStateAdditional = setAdditionalPhotosResult(
      result.additionalPhotos,
    );

    dispatch(setPuppyPhotosCover(newStateCover));
    dispatch(setPuppyPhotosAdditional(newStateAdditional));
  }
};
