import moment from 'moment';
import dateUtil from './date';

const time = {
  /**
   * Examples:
   * differenceWithTimeUnit(date, 'day')   // Output:   1 day,   2 days,   0 days
   * differenceWithTimeUnit(date, 'month') // Output:   1 month, 2 months, 0 months
   * differenceWithTimeUnit(date, 'year')  // Output:   1 year,  2 years,  0 years
   *
   * @param date
   * @param unitOfTime
   */
  differenceWithTimeUnit: (
    date: string,
    unitOfTime: moment.unitOfTime.Diff = 'year',
  ) => {
    const timeDifference = moment().utc().diff(date, unitOfTime);
    const timeUnitPluralOrSingular =
      timeDifference === 1 ? unitOfTime : `${unitOfTime}s`;
    return `${timeDifference} ${timeUnitPluralOrSingular}`;
  },
  differenceNowToFuture: (
    date: string,
    unitOfTime: moment.unitOfTime.Diff = 'year',
  ) => {
    const now = dateUtil.toYYYYMMDD(new Date());
    const timeDifference = moment(date).diff(now, unitOfTime);
    const timeUnitPluralOrSingular =
      timeDifference === 1 ? unitOfTime : `${unitOfTime}s`;
    return `${timeDifference} ${timeUnitPluralOrSingular}`;
  },
};

export default time;
