import React, { ReactElement } from 'react';
import { Space } from 'antd';

import './HealthDocBadgeStatusLegend.scss';

function HealthDocBadgeStatusLegend(): ReactElement {
  return (
    <section className="HealthDocumentBadgeStatusLegend">
      <Space size={14}>
        <span className="HealthDocumentBadgeStatusLegend__label">
          Health Documents:
        </span>
        <span className="HealthDocumentBadgeStatusLegend__item">
          <span className="badge badge--not-uploaded" /> Not uploaded
        </span>
        <span className="HealthDocumentBadgeStatusLegend__item">
          <span className="badge badge--pending-review" /> Pending review
        </span>
        <span className="HealthDocumentBadgeStatusLegend__item">
          <span className="badge badge--approved" /> Approved
        </span>
        <span className="HealthDocumentBadgeStatusLegend__item">
          <span className="badge badge--rejected" /> Rejected
        </span>
        <span className="HealthDocumentBadgeStatusLegend__item">
          <span className="badge badge--expired" /> Expired
        </span>
        <span className="HealthDocumentBadgeStatusLegend__item">
          <span className="badge badge--awaiting-disclosure" /> Awaiting
          Disclosure
        </span>
      </Space>
    </section>
  );
}

export default HealthDocBadgeStatusLegend;
