type Validation = {
  empty: { [key: string]: string };
};

const validationErrorMessage: Validation = {
  empty: {
    birthday: `Birthdate is required.`,
    list: `Please select an item in the list`,
    input: `Please fill out this field`,
    checkbox: `Please check this box`,
    agreement: `You need to accept the terms above to continue`,
    costConfirm: `You need to confirm the information below`,
  },
};

export const EmptyFieldValidationHandler = (type: string) => {
  return {
    validator: (_: unknown, value: string) => {
      if (!value) {
        return Promise.reject(validationErrorMessage.empty[type]);
      }

      return Promise.resolve();
    },
  };
};
