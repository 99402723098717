import React, { ReactElement } from 'react';
import { Form, Radio } from 'antd';

interface Props {
  disabled?: boolean;
}

export function FieldParentShown(props: Props): ReactElement {
  const { disabled } = props;
  return (
    <Form.Item name="hasBeenShown" label="Participated in shows">
      <Radio.Group size="large" disabled={disabled}>
        <Radio value={0}>No</Radio>
        <Radio value={1}>Yes</Radio>
      </Radio.Group>
    </Form.Item>
  );
}
