import { Id, Nullable } from 'shared/types/general';
import { User } from 'shared/types/user';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';

export const userToken = 'userToken';
export const userInformation = 'userInformation';
export const breederAdminToken = 'ba_tk';
export const breederAdminInfoKey = 'ba_inf';

export const getUserFullName = (user: User) =>
  user ? `${user.firstName} ${user.lastName}` : 'Anonymous';

const userStorage = {
  setBreederAdminToken() {
    const token = userStorage.getToken();
    localStorage.setItem(breederAdminToken, token as string);
  },

  getBreederAdminToken() {
    return localStorage.getItem(breederAdminToken);
  },

  removeBreederAdminToken() {
    localStorage.removeItem(breederAdminToken);
  },

  setBreederAdminInfo() {
    const breederAdminInfo = userStorage.getUser();
    localStorage.setItem(breederAdminInfoKey, JSON.stringify(breederAdminInfo));
  },

  getBreederAdminInfo() {
    const breederAdminInfo = localStorage.getItem(breederAdminInfoKey);
    return breederAdminInfo && JSON.parse(breederAdminInfo);
  },

  removeBreederAdminInfo() {
    localStorage.removeItem(breederAdminInfoKey);
  },

  setToken(token: Nullable<string>) {
    if (!token) return;
    localStorage.setItem(userToken, token);
  },

  getToken() {
    return localStorage.getItem(userToken);
  },

  getBreederId(): Id {
    return this.getUser()?.breeders[0].id;
  },

  getBreederUsdaLicenseStatusId(): number | undefined {
    return this.getUser()?.breeders[0].usdaLicenseStatusId;
  },

  setUser(user: User) {
    localStorage.setItem(userInformation, JSON.stringify(user));
  },

  removeUserAndToken() {
    localStorage.removeItem(userInformation);
    localStorage.removeItem(userToken);
    localStorage.removeItem(breederAdminToken);
    localStorage.removeItem(breederAdminInfoKey);
    staticContentStorage.removeContent();
  },

  getUser(): User | null {
    try {
      const userInfo = localStorage.getItem(userInformation);
      if (userInfo) {
        return JSON.parse(userInfo);
      }
      return null;
    } catch (e) {
      return null;
    }
  },
};

export default userStorage;
