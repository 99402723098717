import React, { ReactElement, useEffect, useState } from 'react';
import { StaticPageProps } from 'shared/staticPage/StaticPage';
import date from 'utilities/time/date';

export default function SimpleContent(props: StaticPageProps): ReactElement {
  const { staticData } = props;
  const [content, setContent] = useState({
    dateUpdated: '',
    descriptionSimplepage: '',
    mainContentSimplepage: '',
  });
  useEffect(() => {
    setContent({ ...staticData });
  }, [staticData]);

  return (
    <div
      className="StaticPage StaticPage--simplepage"
      data-testid="static-page"
    >
      {content.dateUpdated && (
        <p>
          Last Updated:
          {` ${date.timeZone(staticData.dateUpdated, 'America/New_York')}`}
        </p>
      )}
      {content.mainContentSimplepage && (
        <h3>{content.descriptionSimplepage}</h3>
      )}
      <div
        dangerouslySetInnerHTML={{ __html: content.mainContentSimplepage }}
      />
    </div>
  );
}
