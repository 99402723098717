import {
  INCREASE_PAGE,
  PaginationsActions,
  RESET_PAGE,
  SET_ITEMS_ON_PAGE_AND_PER_PAGE,
} from 'store/paginations/types';

export interface IncreasePage {
  type: typeof INCREASE_PAGE;
  columnName: string;
}

export function increasePage(columnName: string): PaginationsActions {
  return {
    type: INCREASE_PAGE,
    columnName,
  };
}

export interface ResetPage {
  type: typeof RESET_PAGE;
  columnName: string;
}

export function resetPagination(columnName: string): PaginationsActions {
  return {
    type: RESET_PAGE,
    columnName,
  };
}

export interface SetItemsOnPageAndPerPage {
  type: typeof SET_ITEMS_ON_PAGE_AND_PER_PAGE;
  columnName: string;
  itemsOnPage: number;
  itemsPerPage: number;
  totalItems?: number;
}

export function setItemsOnPageAndPerPage(
  columnName: string,
  itemsOnPage: number,
  itemsPerPage: number,
  totalItems?: number,
) {
  return {
    type: SET_ITEMS_ON_PAGE_AND_PER_PAGE,
    columnName,
    itemsOnPage,
    itemsPerPage,
    totalItems,
  };
}
