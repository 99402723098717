import showNotificationError from 'shared/notifications/showNotificationError';
import { Id, Media, SetState, Video } from 'shared/types';
import { apiPostRequest, apiPutRequest } from 'utilities/http/api/apiHttp';
import { requestHasErrors } from 'utilities/http/http';
import { mediaConfig } from 'utilities/media/media';

export interface VideoUploadStartPayload {
  entityType: string;
  entityId: number;
  name: string;
  visibilityId: number;
}

export interface VideoUploadCompletePayload {
  mediaId: number;
  metadata: Video | { name: string; hashed_id: string };
}

export const videoUploadStart = (body: VideoUploadStartPayload) => {
  return apiPostRequest('media/video/upload-start', body);
};

export const videoUploadComplete = (body: VideoUploadCompletePayload) => {
  return apiPutRequest('media/video/upload-complete', body);
};

export const uploadVideoToCloudflare = async (
  puppyId: Id,
  videos: Media[],
  existingVideo: Media | undefined,
  setSubmitProgress: SetState<number>,
): Promise<number[]> => {
  return new Promise(async (resolve) => {
    const video = videos?.[0];

    if (video?.uid) {
      const respVideoUploadStart = await videoUploadStart({
        entityType: 'listing',
        entityId: puppyId as number,
        name: video?.name || '',
        visibilityId: mediaConfig.visibilityId.public,
      });

      if (requestHasErrors(respVideoUploadStart)) {
        showNotificationError({ error: `Video upload start` });
      }

      setSubmitProgress(65);

      const {
        videoUpload: { mediaId, uploadUrl, cloudflareUid },
      } = respVideoUploadStart.json;

      const formData = new FormData();
      formData.append('file', video as File, video.name);

      const request = new XMLHttpRequest();
      request.open('POST', uploadUrl);

      request.upload.addEventListener('progress', (e) => {
        const percent_completed = (e.loaded / e.total) * 25;
        setSubmitProgress(65 + Math.floor(percent_completed));
      });

      request.addEventListener('load', async () => {
        if (request.status !== 200) {
          showNotificationError({ error: `Uploading video to cloudflare` });
        }

        setSubmitProgress(90);

        const respVideoUploadComplete = await videoUploadComplete({
          mediaId,
          metadata: {
            name: `${video.name}`,
            hashed_id: cloudflareUid,
          },
        });

        if (requestHasErrors(respVideoUploadComplete)) {
          showNotificationError({ error: `Video upload complete` });
        }

        setSubmitProgress(95);
        resolve([mediaId]);
      });

      request.send(formData);
    } else {
      resolve([]);
    }
  });
};
