/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  LitterActionTypes,
  RESET_LITTER,
  SET_LITTER,
} from 'store/litter/types';
import { Id } from 'shared/types/general';
import apiLitter from 'utilities/http/api/apiLitter';
import { Dispatch } from 'redux';
import showNotificationError from 'shared/notifications/showNotificationError';
import litterFormHelpers from 'shared/litter/litterFormHelpers';
import {
  Litter,
  LitterFormFields,
  LitterToUpdateFields,
} from 'shared/types/litter';
import { Gender } from 'shared/types/parent';

type LitterResponse = {
  json: { litter: Litter };
  status: number;
};

export interface SetLitter {
  type: typeof SET_LITTER;
  litter: Litter;
}

export interface ResetLitter {
  type: typeof RESET_LITTER;
}

export function setLitter(litter: Litter): LitterActionTypes {
  return {
    type: SET_LITTER,
    litter,
  };
}

export function resetLitter() {
  return {
    type: RESET_LITTER,
  };
}

export function getLitter(id: Id) {
  return async (dispatch: Dispatch) => {
    try {
      const { json } = await apiLitter.getLitterById(id);
      const { litter }: { litter: Litter } = json;

      dispatch(setLitter(litter));
    } catch (e) {
      showNotificationError({ error: "Couldn't load your litter details" });
    }
  };
}

export function editLitter(litterToUpdate: LitterFormFields, litter: Litter) {
  return async (dispatch: Dispatch<any>) => {
    try {
      const neurologicalStimulations = litterFormHelpers.buildNeurologicalStimulations(
        litterToUpdate,
      );

      const litterEdited: LitterToUpdateFields = {
        ...litterToUpdate,
        breederId: litter.breederId,
        breedId: litter.breedId,
        sireId: litter.sireId,
        damId: litter.damId,
        neurologicalStimulations,
      };
      const { json, status }: LitterResponse = await apiLitter.putLitter(
        litterEdited,
        litter.id as number,
      );

      if (json && json.litter && status === 200) {
        dispatch(getLitter(litter.id as number));
      }
    } catch (error) {
      showNotificationError({ error: "Couldn't update litter" });
    }
  };
}

export function changeLitterDamOrSire(
  litterId: number,
  parentId: number,
  gender: Gender,
) {
  return async () => {
    let sireId, damId;

    if (gender === 'dam') {
      damId = parentId;
    } else {
      sireId = parentId;
    }

    try {
      const litterToUpdate = {
        sireId,
        damId,
      };

      const { json, status }: LitterResponse = await apiLitter.putLitter(
        litterToUpdate,
        litterId,
      );

      if (json && json.litter && status === 200) {
        window.location.reload();
      }
    } catch (error) {
      showNotificationError({ error: `Couldn't update ${gender}` });
    }
  };
}
