import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, List, Space } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import {
  DownOutlined,
  EyeOutlined,
  UpOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { isEmpty } from 'lodash';

import DuplicatePuppy from 'components/duplicatePuppy/DuplicatePuppy';
import { DeliveredTag, SoldTag, UnlistedTag } from 'components/tags';
import { Puppy } from 'shared/types/puppy';
import { Icon } from 'shared/icon/Icon';
import EntityPhoto from 'shared/entitiesOverview/entityPhoto/EntityPhoto';
import { BreederRoutes } from 'shared/types/routes';
import { setNewPuppy } from 'store/newPuppy/actions';
import { RootState } from 'store/rootState';
import { testId } from 'testsUtilities/dataTestId';
import { isDelivered, isSold } from 'utilities/entities/puppy';
import inventoryStatus from 'utilities/status/inventoryStatus';
import media, { mediaConfig } from 'utilities/media/media';

import './LitterDetailPuppies.scss';

const maxToShowOnMobile = 2;
const maxToShowOnDesktop = 5;

export function isShowVisibilityToggle(
  puppiesLength: number,
  isMobile: boolean,
) {
  const showForMobile = puppiesLength > maxToShowOnMobile && isMobile;
  const showForDesktop = puppiesLength > maxToShowOnDesktop && !isMobile;
  return showForMobile || showForDesktop;
}

export default function LitterDetailPuppies() {
  const { litter } = useSelector((state: RootState) => ({
    litter: state.litter,
  }));
  const { puppies, sireId, damId, pendingPuppiesInLitter } = litter;
  const [showAll, setShowAll] = useState(false);
  const [flagPendingLitter, setFlagPendingLitter] = useState(true);
  const screens = useBreakpoint();
  const isMobile = !screens.xl;
  const dispatch = useDispatch();
  const history = useHistory();

  const renderPuppyStatusTag = (puppy: Puppy) => {
    if (inventoryStatus.isSoldByBreeder(puppy)) {
      return <UnlistedTag />;
    }

    if (isDelivered(puppy)) {
      return <DeliveredTag />;
    }

    if (isSold(puppy)) {
      return <SoldTag />;
    }
  };

  const renderPuppy = (puppy: Puppy, index: number) => {
    let shouldHide = false;
    if (isMobile && index > maxToShowOnMobile) {
      shouldHide = true;
    } else if (!isMobile && index > maxToShowOnDesktop) {
      shouldHide = true;
    }

    const puppyPhotoPath = media.getDefaultPuppyPhoto(
      puppy?.listing?.media,
      mediaConfig.photoSize.small,
    );

    const hideCurrentItem = shouldHide && !showAll ? `display-none` : '';

    return (
      <List.Item
        data-testid={testId.litterDetailPuppiesSummaryItem}
        className={hideCurrentItem}
      >
        <List.Item.Meta
          avatar={
            <Link to={`/puppy/${puppy.id}`}>
              <Space>
                <EntityPhoto
                  entity="puppy"
                  imageSrc={puppyPhotoPath}
                  height="32px"
                  width="32px"
                />
                {puppy.breederName}
                {renderPuppyStatusTag(puppy)}
              </Space>
            </Link>
          }
          description={
            <Space>
              <span
                className="PuppySummary__views"
                data-testid={testId.litterDetailPuppiesSummaryItemViews}
              >
                {puppy.listing?.listingTally?.viewCount || 0} <EyeOutlined />
              </span>
              <DuplicatePuppy
                litterId={litter.id as number}
                damId={damId as number}
                sireId={sireId as number}
                puppy={puppy}
              />
            </Space>
          }
        />
      </List.Item>
    );
  };

  const showVisibilityToggle = isShowVisibilityToggle(
    Number(puppies?.length),
    isMobile,
  );

  const toggleShowAll = () => setShowAll(!showAll);

  const sortByName = puppies?.sort((a: Puppy, b: Puppy) =>
    a.breederName.localeCompare(b.breederName),
  );

  if (pendingPuppiesInLitter && flagPendingLitter && sortByName) {
    pendingPuppiesInLitter.map((p) => {
      const puppyIds = sortByName?.filter((puppy) => puppy.id === p.id);
      if (isEmpty(puppyIds)) {
        sortByName.push(p);
      }

      return sortByName;
    });
    setFlagPendingLitter(false);
  }

  const puppySortResult = sortByName?.filter(
    (puppy) =>
      !(puppy?.pendingEdit && puppy?.pendingEdit?.litterId !== litter.id),
  );

  const title = isEmpty(puppies)
    ? "You don't have any puppies in this litter"
    : 'Puppies in this litter';

  const handleAddNewPuppy = async () => {
    dispatch(
      setNewPuppy({
        damId: damId as number,
        sireId: sireId as number,
        litterId: litter.id as number,
      }),
    );
    history.push(BreederRoutes.NewPuppy);
  };

  return (
    <Card className="PuppiesSummary" title={title}>
      {isEmpty(puppies) ? (
        <Icon name="litter" className="PuppiesSummary__litter-icon" />
      ) : (
        <List
          size="small"
          bordered={false}
          dataSource={puppySortResult}
          renderItem={renderPuppy}
          grid={{ gutter: 60, column: isMobile ? 1 : 2 }}
        />
      )}
      <div className="PuppiesSummary__tools">
        <div className="PuppiesSummary__add-puppy">
          <Button
            className="PuppiesSummary__add-btn"
            type="dashed"
            onClick={handleAddNewPuppy}
            icon={<PlusOutlined />}
          >
            Add new puppy
          </Button>
        </div>
        {showVisibilityToggle && (
          <div
            className="PuppiesSummary__show-toggle"
            data-testid="puppies-summary-show-toggle"
            onClick={toggleShowAll}
          >
            {showAll ? 'View less' : 'View all'}
            {showAll ? <UpOutlined /> : <DownOutlined />}
          </div>
        )}
      </div>
    </Card>
  );
}
