import { Note } from 'shared/types/general';
import { cloneDeep } from 'lodash';
import date from 'utilities/time/date';

const notes = {
  sortByOldest: (notes: Partial<Note>[] | undefined): Partial<Note>[] => {
    if (!notes) return [];
    const dataToSort = cloneDeep(notes);
    const sorted = dataToSort.sort((a, b) =>
      date.sortByOldest(b.updatedAt, a.updatedAt),
    );

    return sorted;
  },
};

export default notes;
