import React from 'react';
import { Form, Button } from 'antd';

interface SubmitButtonProps {
  label: string;
  testId?: string;
  loading?: boolean;
  disabled?: boolean;
}

export function SubmitButton(props: SubmitButtonProps) {
  const { label, loading, disabled, testId } = props;
  return (
    <Form.Item style={{ textAlign: 'right', paddingTop: '24px' }}>
      <Button
        data-testid={testId || 'submit-btn'}
        type="default"
        htmlType="submit"
        size="large"
        loading={loading}
        disabled={disabled}
      >
        {label}
      </Button>
    </Form.Item>
  );
}
