import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';

import { ParentForm, ParentFormProps } from 'shared/parents/ParentForm';
import PageLayout from 'shared/baseLayout/pageLayout/PageLayout';
import { Parent } from 'shared/types/parent';
import { CollapsableStatusTypes } from 'shared/types/statuses';
import { resetParents } from 'store/parents/actions';
import { RootState } from 'store/rootState';

export default function NewParent(props: ParentFormProps): ReactElement {
  const { gender } = props;
  const { parents } = useSelector((state: RootState) => ({
    parents: state.parents,
  }));

  const breadCrumbs = [
    {
      name: `New ${gender}`,
    },
  ];

  const dispatch = useDispatch();
  const [collapsableType, setCollapsableType] = useState<
    CollapsableStatusTypes
  >('warning');
  const parent: Partial<Parent> = parents[gender];

  useEffect(() => {
    setCollapsableType(parent.id ? 'success' : 'warning');
  }, [parent.id]);

  useEffect(() => {
    return () => {
      dispatch(resetParents());
    };
  }, [dispatch]);

  return (
    <PageLayout breadCrumbsNav={breadCrumbs}>
      <Row justify="center">
        <Col xs={24} xl={20}>
          <ParentForm
            action="create"
            gender={gender}
            singleForm={true}
            collapsableType={collapsableType}
            isOpen={true}
          />
        </Col>
      </Row>
    </PageLayout>
  );
}
