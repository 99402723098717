import React, { ReactElement, useEffect } from 'react';
import { Col, Row } from 'antd';

import PageLayout from 'shared/baseLayout/pageLayout/PageLayout';
import AccountForm from 'pages/myAccount/accountForm/AccountForm';

import './MyAccount.scss';
import { useDispatch } from 'react-redux';
import { fetchBreeder } from 'store/breeder/actions';

const breadCrumbs = [
  {
    name: 'My Account',
  },
];

export default function MyAccount(): ReactElement {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBreeder());
  }, [dispatch]);

  return (
    <PageLayout className="MyAccount" breadCrumbsNav={breadCrumbs}>
      <Row justify="center">
        <Col xs={24} xl={20}>
          <AccountForm />
        </Col>
      </Row>
    </PageLayout>
  );
}
