/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';
import apiCms from 'utilities/http/api/apiCms';
import showNotificationError from 'shared/notifications/showNotificationError';
import Faq from './pageType/Faq';
import SimpleContent from './pageType/SimpleContent';
import Webinar from './pageType/Webinar';
import './StaticPage.scss';
import PdfContent from './pageType/PdfContent';

export interface StaticPageProps {
  nameContent?: string;
  pageType?: string;
  staticData?: any;
}

export default function StaticPage(props: StaticPageProps): ReactElement {
  const [staticData, setStaticData] = useState();
  const { nameContent, pageType } = props;
  const fetchPageData = async () => {
    try {
      const response = await apiCms.getStaticPage(nameContent);
      setStaticData(response.json);
    } catch (error) {
      if (error instanceof Error) {
        showNotificationError({ error: error.message });
      }
    }
  };
  useEffect(() => {
    fetchPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameContent]);

  if (pageType === 'webinars') {
    return <Webinar staticData={staticData} />;
  } else if (pageType === 'faq') {
    return <Faq staticData={staticData} />;
  } else if (pageType === 'pdf') {
    return <PdfContent staticData={staticData} />;
  } else {
    return <SimpleContent staticData={staticData} />;
  }
}
