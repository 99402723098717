import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';

import { testId } from 'testsUtilities/dataTestId';
import { MyParentsTypes } from 'shared/types/parent';
import { myParentsGetParents } from 'store/myParents/actions';
import { RootState } from 'store/rootState';

import './MyParentsLoadMore.scss';

interface Props {
  myParentType: MyParentsTypes;
}

function MyParentsLoadMore(props: Props): ReactElement | null {
  const { myParentType } = props;
  const myParents = useSelector((state: RootState) => state.myParents);
  const dispatch = useDispatch();

  const handleLoadMore = () => {
    const currentPage = myParents[myParentType].pagination.currentPage;
    const nextPage = currentPage + 1;
    dispatch(
      myParentsGetParents(myParentType, nextPage, myParents.searchParams),
    );
  };

  if (
    myParents[myParentType].pagination.itemsOnPage !==
    myParents[myParentType].pagination.itemsPerPage
  ) {
    return null;
  }

  return (
    <Button
      className="MyParentsLoadMore"
      data-testid={testId.myParentsLoadMore}
      type="link"
      block
      onClick={handleLoadMore}
      loading={myParents[myParentType].loadingMore}
    >
      Load More
    </Button>
  );
}

export default MyParentsLoadMore;
