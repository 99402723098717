import React, { ReactElement } from 'react';
import { Tag } from 'antd';
import { EyeInvisibleOutlined } from '@ant-design/icons';

import { testId } from 'testsUtilities/dataTestId';

interface Props {
  className?: string;
}

function UnlistedTag(props: Props): ReactElement {
  const { className } = props;
  const classModifier = className
    ? `unlisted-tag unlisted-tag--${className}`
    : `unlisted-tag`;

  return (
    <Tag
      data-testid={testId.unlistedTag}
      className={classModifier}
      icon={<EyeInvisibleOutlined />}
    >
      Unlisted
    </Tag>
  );
}

export default UnlistedTag;
