import {
  isProduction,
  reactEnvironmentVariables,
} from 'utilities/environmentVariables';
import TagManager from 'react-gtm-module';

const { GTM_KEY } = reactEnvironmentVariables;

export default {
  init: () => {
    if (isProduction() && GTM_KEY) {
      const tagManagerArgs = {
        gtmId: GTM_KEY,
      };

      TagManager.initialize(tagManagerArgs);
    }
  },
};
