import React, { ReactElement, useEffect, useState } from 'react';
import { Typography } from 'antd';
import { isEmpty } from 'lodash';

import { HealthDocBadgeStatus } from 'components/healthDocs/index';
import { Icon, setFileExtensionIcon } from 'shared/icon/Icon';
import { Documents, HealthDocsFile } from 'shared/types/document';
import { buildHealthDocsFiles } from 'utilities/healthDocs';
import {
  formatReleaseDate,
  isBeforeReleaseDate,
} from 'utilities/healthDocs/healthDocsDueDateCheckers/healthDocsDueDateCheckers';
import date from 'utilities/time/date';

import './HealthDocsUploadsList.scss';

interface Props {
  documents: Documents;
  lastSoldAt: string;
}

function HealthDocsUploadsList(props: Props): ReactElement {
  const { documents, lastSoldAt } = props;
  const [healthDocsFiles, setHealthDocsFiles] = useState<HealthDocsFile[]>([]);

  useEffect(() => {
    setHealthDocsFiles(buildHealthDocsFiles(documents));
  }, [documents]);

  if (isBeforeReleaseDate(lastSoldAt)) {
    return (
      <div className="HealthDocsUploads__empty">
        <Typography.Paragraph type="secondary">
          This information is only available for puppies sold after{' '}
          {formatReleaseDate()}.
        </Typography.Paragraph>
      </div>
    );
  }

  if (!isEmpty(healthDocsFiles)) {
    return (
      <section className="HealthDocsUploads">
        <Typography.Paragraph strong>Your uploads:</Typography.Paragraph>
        <div className="HealthDocsUploads__list">
          {healthDocsFiles.map((file) => (
            <div className="HealthDocsUploads__list-item" key={file.id}>
              <div className="HealthDocsUploads__list-item__file">
                <Icon
                  name={setFileExtensionIcon(file.name)}
                  className="HealthDocsUploads__list-item__file-icon"
                />
                <Typography.Text
                  className="HealthDocsUploads__list-item__file-name"
                  strong
                >
                  {file.name}
                </Typography.Text>
                <Typography.Text
                  className="HealthDocsUploads__list-item__file-uploadedAt"
                  type="secondary"
                >
                  {date.toMMDDYY(file.createdAt)}
                </Typography.Text>
              </div>

              <div className="HealthDocsUploads__list-item__file-documents">
                {file.documents?.map((doc) => (
                  <HealthDocBadgeStatus
                    documentType={doc.documentTypeId}
                    documents={[doc]}
                    shape="fullInfo"
                    key={doc.id}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }

  return (
    <div className="HealthDocsUploads__empty">
      <Typography.Paragraph type="secondary">
        No uploads yet
      </Typography.Paragraph>
    </div>
  );
}

export default HealthDocsUploadsList;
