import React, { ReactElement, ReactNode } from 'react';
import { Col, Layout, Row } from 'antd';
import { Link } from 'react-router-dom';

import logoSymbolBlack from 'assets/img/logo-symbol-black.svg';
import UserDropDown from 'shared/baseLayout/baseLayoutHeader/UserDropDown';

import './BreederAdminLayout.scss';

const { Header, Content } = Layout;

interface Props {
  children: ReactNode;
}

function BreederAdminLayout(props: Props): ReactElement {
  const { children } = props;

  return (
    <Layout className="BaseLayout BaseLayout--BreederAdminLayout">
      <Header className="BaseLayout__header">
        <Link className="BaseLayout__header-logo" to="/">
          <img alt="Breeder Hub. Puppy Spot Logos" src={logoSymbolBlack} />
        </Link>
        <UserDropDown />
      </Header>
      <Content className="BaseLayout__body">
        <Row justify="center">
          <Col xs={24} lg={8}>
            {children}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default BreederAdminLayout;
