import React, { ReactElement } from 'react';
import { Form, Input } from 'antd';
import TextAreaCounter from '../textAreaCounter/TextAreaCounter';

interface Props {
  disabled?: boolean;
}

export function FieldHealthIssuesDisclaimer(props: Props): ReactElement {
  const { disabled } = props;
  const maxLength = 1000;
  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, curValues) =>
        prevValues.healthIssuesDisclaimer !== curValues.healthIssuesDisclaimer
      }
    >
      {(form) => {
        const value = form.getFieldValue(['healthIssuesDisclaimer']);
        const characterCounter = value?.length || 0;

        return (
          <Form.Item
            name="healthIssuesDisclaimer"
            label="Health or cosmetic issue"
            extra={<TextAreaCounter counter={maxLength - characterCounter} />}
          >
            <Input.TextArea
              placeholder="Explain briefly here if applies"
              autoSize={{ minRows: 6, maxRows: 6 }}
              maxLength={maxLength}
              disabled={disabled}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
}
