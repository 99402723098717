import React, { ReactElement } from 'react';
import { Typography } from 'antd';

import { Listing } from 'shared/types/puppy';
import { getPuppyBreederName } from 'utilities/puppy/getPuppyUtils/getPuppyUtils';
import { PuppyGenderIcon } from 'components/puppy';

interface Props {
  listing: Listing;
}

function ColumnPuppyName(props: Props): ReactElement {
  const {
    listing: { puppy },
  } = props;

  const isMale = 1;
  const gender = puppy?.pendingEdit?.genderId || puppy.genderId;

  return (
    <div>
      <PuppyGenderIcon gender={gender === isMale ? 'sire' : 'dam'} />
      <Typography.Text style={{ marginLeft: '8px' }}>
        {getPuppyBreederName(puppy)}
      </Typography.Text>
    </div>
  );
}

export default ColumnPuppyName;
