import React, { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, PageHeader, Space } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import {
  MyListingsTableApproved,
  MyListingsCardsApproved,
} from 'components/myListings';
import PageLayout from 'shared/baseLayout/pageLayout/PageLayout';
import { BreederRoutes } from 'shared/types/routes';
import {
  fetchMyListingsApproved,
  setMyListingsApprovedInitialState,
} from 'store/myListingsApproved/actions.thunk';
import {
  setMyListingsApprovedFilters,
  setMyListingsApprovedPagination,
  setMyListingsApprovedSorter,
} from 'store/myListingsApproved/actions';
import {
  initialStateMyListingsApprovedFilters,
  initialStateMyListingsApprovedSorter,
} from 'store/myListingsApproved/myListingsApprovedReducer';
import { RootState } from 'store/rootState';
import UpdatePriceModal from 'components/myListings/updatePriceModal/UpdatePriceModal';
import useListingUpdatePrice from 'hooks/useListingUpdatePrice/useListingUpdatePrice';

const breadcrumb = [
  {
    url: BreederRoutes.MyListings,
    name: 'My Puppy Listings',
  },
  {
    name: 'Approved',
  },
];

export default function MyListingsApproved(): ReactElement {
  const dispatch = useDispatch();
  const {
    myListingsApproved: { loading, pagination, sorter, filters },
  } = useSelector((state: RootState) => state);
  const { current: currentPage, pageSize, loadMore } = pagination;
  const screens = useBreakpoint();
  const { modalVisible, setModalVisible, openModal } = useListingUpdatePrice();

  const handleClearFilters = () => {
    dispatch(setMyListingsApprovedSorter(initialStateMyListingsApprovedSorter));
    dispatch(
      setMyListingsApprovedFilters(initialStateMyListingsApprovedFilters),
    );
    dispatch(
      setMyListingsApprovedPagination({
        ...pagination,
        current: 1,
      }),
    );
  };

  const refreshListings = useCallback(() => {
    dispatch(fetchMyListingsApproved());
  }, [dispatch]);

  useEffect(() => {
    !loadMore && refreshListings();
  }, [
    currentPage,
    pageSize,
    loadMore,
    sorter.sortBy,
    sorter.sortDirection,
    filters,
    refreshListings,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setMyListingsApprovedInitialState());
    };
  }, [dispatch]);

  return (
    <PageLayout
      className="MyListings MyListings--approved"
      breadCrumbsNav={breadcrumb}
    >
      <PageHeader
        className="MyListings__page-header"
        ghost={false}
        onBack={() => window.history.back()}
        title="Approved"
        extra={
          screens.xl ? (
            <Space>
              <Button
                type="link"
                onClick={handleClearFilters}
                disabled={
                  loading || (!sorter.isSorting && !filters.isFiltering)
                }
              >
                Clear filters
              </Button>
            </Space>
          ) : null
        }
      />
      <div className="MyListings__page-content">
        {screens.xl ? (
          <MyListingsTableApproved openModal={openModal} />
        ) : (
          <MyListingsCardsApproved openModal={openModal} />
        )}
      </div>
      <UpdatePriceModal
        callback={refreshListings}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </PageLayout>
  );
}
