import React, { ReactElement } from 'react';
import { Tooltip } from 'antd';
import { UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { Documents } from 'shared/types/document';
import { Nullable } from 'shared/types/general';
import { isHealthDocsDueDatePastDue } from 'utilities/healthDocs';

import './HealthDocDueDateTooltip.scss';

interface Props {
  documentDueDate: Nullable<string>;
  documents: Documents;
  setDrawerPuppy?: () => void;
}

function HealthDocDueDateTooltip(props: Props): ReactElement {
  const { documentDueDate, documents, setDrawerPuppy } = props;
  const isPastDue = isHealthDocsDueDatePastDue(documentDueDate, documents);

  const tooltipTitle = isPastDue
    ? 'The travel date has expired. The receipt date of your required health documents has expired; however, the puppy is still traveling. Please submit documents ASAP so we can review and clear your payment. '
    : undefined;

  return (
    <Tooltip placement="left" title={tooltipTitle}>
      {isPastDue && (
        <QuestionCircleOutlined className="HealthDocDueDateTooltip__icon" />
      )}
      <UploadOutlined
        className="HealthDocDueDateTooltip__icon"
        onClick={setDrawerPuppy}
      />
    </Tooltip>
  );
}

export default HealthDocDueDateTooltip;
