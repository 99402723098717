import { StaticContentPageRoute } from 'shared/types/routes';

export const staticContentPagesRoutesHandler = [
  {
    title: `Webinars`,
    path: StaticContentPageRoute.Webinars,
    nameContent: 'webinars',
    pageType: 'webinars',
  },
  {
    title: `COVID-19 FREQUENTLY ASKED QUESTIONS`,
    path: StaticContentPageRoute.FaqCovid19,
    nameContent: 'breeder-site',
    pageType: 'faq',
  },
  {
    title: `Breeder Helper`,
    path: StaticContentPageRoute.BreederHelper,
    nameContent: 'breeder-helper',
  },
  {
    title: `How Puppyspot works`,
    path: StaticContentPageRoute.HowPuppySpotWorks,
    nameContent: 'how-puppyspot-works',
  },
  {
    title: `The three “P”s to place your puppy`,
    path: StaticContentPageRoute.ThreePsPlaceYourPuppy,
    nameContent: 'the-three-ps-to-place-your-puppy',
  },
  {
    title: `Puppy Name Ideas`,
    path: StaticContentPageRoute.NameIdeas,
    nameContent: 'name-ideas',
  },
  {
    title: `Dos and Don'ts of puppy photos`,
    path: StaticContentPageRoute.PhotosDosAndDont,
    nameContent: 'photo-dos-and-donts',
  },
  {
    title: `Video Do's and Don'ts`,
    path: StaticContentPageRoute.VideosDosAndDont,
    nameContent: 'video-dos-and-donts',
  },
  {
    title: `Tips for writing great descriptions`,
    path: StaticContentPageRoute.DescriptionInspiration,
    nameContent: 'description-inspirations',
  },
  {
    title: `Puppyspot breeder agreement`,
    path: StaticContentPageRoute.MembershipAgreement,
    nameContent: 'agreement',
  },
  {
    title: `PuppySpot Breeders Standards`,
    path: StaticContentPageRoute.PuppySpotBreedersStandards,
    nameContent: 'puppyspot-breeders-standards',
    pageType: 'pdf',
  },
  {
    title: `Program of Veterinary Care`,
    path: StaticContentPageRoute.ProgramOfVeterinaryCare,
    nameContent: 'program-of-veterinary-care',
    pageType: 'pdf',
  },
  {
    title: `Vet health report`,
    path: StaticContentPageRoute.VetHealthReport,
    nameContent: 'vet-health-report',
    pageType: 'pdf',
  },
  {
    title: `Travel planning`,
    path: StaticContentPageRoute.TravelPlaning,
    nameContent: 'travel-phone-numbers',
  },
  {
    title: `Post Sale Checklist`,
    path: StaticContentPageRoute.PostSalesChecklist,
    nameContent: 'post-sale-checklist',
    pageType: 'pdf',
  },
];
