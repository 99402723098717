import {
  MyParentsState,
  MyParentsActionTypes,
  MY_PARENTS_RESET_STATE,
  MY_PARENTS_SET_PARENTS_LOADING,
  MY_PARENTS_SET_PARENTS,
  MY_PARENTS_SET_PARENTS_LOADING_MORE,
  MY_PARENTS_SET_MORE_PARENTS,
  MY_PARENTS_SET_PARENTS_SEARCH_PARAMS,
} from 'store/myParents/types';

const initialState: MyParentsState = {
  dams: {
    loading: false,
    loadingMore: false,
    data: [],
    pagination: {
      currentPage: 1,
      itemsOnPage: 0,
      itemsPerPage: 25,
    },
  },
  sires: {
    loading: false,
    loadingMore: false,
    data: [],
    pagination: {
      currentPage: 1,
      itemsOnPage: 0,
      itemsPerPage: 25,
    },
  },
  searchParams: null,
};

export function myParentsReducer(
  state = initialState,
  action: MyParentsActionTypes,
): MyParentsState {
  switch (action.type) {
    case MY_PARENTS_SET_PARENTS_LOADING: {
      const { myParentType } = action;

      return {
        ...state,
        [myParentType]: {
          ...state[myParentType],
          loading: true,
        },
      };
    }
    case MY_PARENTS_SET_PARENTS: {
      const { myParentType, parents, pagination } = action;

      return {
        ...state,
        [myParentType]: {
          ...state[myParentType],
          loading: false,
          data: parents,
          pagination,
        },
      };
    }
    case MY_PARENTS_SET_PARENTS_LOADING_MORE: {
      const { myParentType } = action;

      return {
        ...state,
        [myParentType]: {
          ...state[myParentType],
          loadingMore: true,
        },
      };
    }
    case MY_PARENTS_SET_MORE_PARENTS: {
      const { myParentType, parents, pagination } = action;
      const oldParents = state[myParentType].data;

      return {
        ...state,
        [myParentType]: {
          ...state[myParentType],
          loadingMore: false,
          data: [...oldParents, ...parents],
          pagination,
        },
      };
    }
    case MY_PARENTS_SET_PARENTS_SEARCH_PARAMS: {
      const { searchParams } = action;

      return {
        ...state,
        searchParams,
      };
    }
    case MY_PARENTS_RESET_STATE: {
      return initialState;
    }
    default:
      return state;
  }
}
