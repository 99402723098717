import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Empty } from 'antd';
import { isEmpty } from 'lodash';

import LoadingSection from 'components/loadingSection/LoadingSection';
import ListingCard from 'pages/myListings/listingCard/ListingCard';
import { fetchMyListingsApproved } from 'store/myListingsApproved/actions.thunk';
import { setMyListingsApprovedPagination } from 'store/myListingsApproved/actions';
import { RootState } from 'store/rootState';
import { testId } from 'testsUtilities/dataTestId';
import { Listing } from 'shared/types';

import '../MyListingsCards.scss';

interface Props {
  openModal?: (listing: Listing) => void;
}

function MyListingsCardsApproved({ openModal }: Props): ReactElement {
  const {
    myListingsApproved: { loading, listings, pagination },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const handleLoadMore = () => {
    dispatch(
      setMyListingsApprovedPagination({
        ...pagination,
        current: pagination.current + 1,
        loadMore: true,
      }),
    );
    dispatch(fetchMyListingsApproved());
  };

  return (
    <section
      className="MyListingsCards__wrapper"
      data-testid={testId.myListingsApprovedCardWrapper}
    >
      {loading && (
        <div className="MyListingsCards__loadingOverlay">
          <LoadingSection />
        </div>
      )}
      {!loading && isEmpty(listings) && <Empty />}
      {listings.map((l) => (
        <ListingCard
          key={l.puppyId}
          listing={l}
          columnName="approved"
          openModal={openModal}
          updatePrice
        />
      ))}
      {!isEmpty(listings) && listings.length !== pagination.total && (
        <Button
          type="link"
          className="LoadMore"
          onClick={handleLoadMore}
          loading={loading}
        >
          Load More
        </Button>
      )}
    </section>
  );
}

export default MyListingsCardsApproved;
