import React, { ReactElement, useEffect, useState } from 'react';

import { Documents, HealthDocsDueDateStatusColor } from 'shared/types/document';
import { Nullable } from 'shared/types/general';
import { testId } from 'testsUtilities/dataTestId';
import { buildHealthDocDueDateProps } from 'utilities/healthDocs';

import './HealthDocDueDate.scss';

interface Props {
  documents: Documents;
  documentDueDate: Nullable<string>;
}

function HealthDocDueDate(props: Props): ReactElement | null {
  const { documentDueDate, documents } = props;
  const [label, setLabel] = useState<string | undefined>();

  const [statusColor, setStatusColor] = useState<
    HealthDocsDueDateStatusColor | undefined
  >();

  useEffect(() => {
    const { statusLabel, statusColor } = buildHealthDocDueDateProps(
      documentDueDate,
      documents,
    );
    setLabel(statusLabel);
    setStatusColor(statusColor);
  }, [documentDueDate, documents]);

  return (
    <span
      data-testid={testId.healthDocDueDate}
      className={`HealthDocDueDate HealthDocDueDate--${statusColor}`}
    >
      {label}
    </span>
  );
}

export default HealthDocDueDate;
