import { useEffect, useState } from 'react';

import { Litter } from 'shared/types/litter';
import apiLitter from 'utilities/http/api/apiLitter';
import {
  isLitterStatusCurrent,
  isLitterStatusNew,
  isLitterStatusPast,
  isLitterStatusUpcoming,
  litterHasPuppies,
} from 'utilities/litter/litterCheckers';

export function useLittersListingStarter() {
  const [littersOptions, setLittersOptions] = useState<Litter[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const setLitters = async () => {
      const resp = await apiLitter.getLittersWithParents();
      const litters = resp.json?.litters || [];

      const currentLitters = litters.filter((l: Litter) =>
        isLitterStatusCurrent(l),
      );
      const upcomingLitters = litters.filter((l: Litter) =>
        isLitterStatusUpcoming(l),
      );
      const newLitters = litters.filter((l: Litter) => isLitterStatusNew(l));

      const pastLittersNoPuppies = litters.filter(
        (l: Litter) => isLitterStatusPast(l) && !litterHasPuppies(l),
      );

      const buildLitterOptions = [
        ...newLitters,
        ...currentLitters,
        ...upcomingLitters,
        ...pastLittersNoPuppies,
      ];

      setLittersOptions(buildLitterOptions);
      setIsLoading(false);
    };
    setLitters();
  }, []);

  return {
    isLoading,
    littersOptions,
  };
}
