import { Litter } from 'shared/types/litter';

export const isLitterStatusCurrent = (l: Litter) => {
  return l?.status === 'current';
};

export const isLitterStatusUpcoming = (l: Litter) => {
  return l?.status === 'upcoming';
};

export const isLitterStatusPast = (l: Litter) => {
  return l?.status === 'past';
};

export const isLitterStatusNew = (l: Litter) => {
  return l?.status === 'new';
};

export const litterHasPuppies = (l: Litter) => {
  return l?.puppyCount !== 0;
};
