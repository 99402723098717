import React, { ReactElement } from 'react';
import { Tag } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

import { testId } from 'testsUtilities/dataTestId';

interface Props {
  className?: string;
}

function DeliveredTag(props: Props): ReactElement {
  const { className } = props;
  const classModifier = className
    ? `delivered-tag delivered-tag--${className}`
    : `delivered-tag`;

  return (
    <Tag
      data-testid={testId.deliveredTag}
      className={classModifier}
      icon={<HomeOutlined />}
      color="success"
    >
      Delivered
    </Tag>
  );
}

export default DeliveredTag;
