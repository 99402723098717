import { isEmpty } from 'lodash';
import media from 'utilities/media/media';
import { Puppy } from 'shared/types/puppy';
export const neurologicalStimulationsIndex = {
  other: 6,
};

export const isSold = (p: Puppy) =>
  p?.breederDashboardStatus === 'sold' || p?.status === 'sold';

export const isDelivered = (p: Partial<Puppy> | undefined) =>
  p?.breederDashboardStatus === 'delivered';

export const isPending = (p: Partial<Puppy>) =>
  p?.breederDashboardStatus === 'pending';

export const hasPendingEdits = (p: Partial<Puppy>) => {
  const puppyHasPendingEdits = !isEmpty(p.pendingEdit);
  if (puppyHasPendingEdits) return true;

  const listingHasPendingEdits = !isEmpty(p?.listing?.pendingEdit);
  if (listingHasPendingEdits) return true;

  if (media.hasPendingEdits(p.listing?.media)) return true;
};

export const getBreedId = (puppy: Partial<Puppy>) => {
  return puppy.pendingEdit?.litter?.breedId
    ? puppy.pendingEdit?.litter?.breedId
    : puppy.litter?.breedId;
};
