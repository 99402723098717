import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Select } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import { isEmpty } from 'lodash';

import { showNotificationDamsLimitCreation } from 'shared/notifications/showNotificationDamsLimitCreation';
import { SetState } from 'shared/types/general';
import { Gender, Genders } from 'shared/types/parent';
import { RootState } from 'store/rootState';
import { getParent, resetParent } from 'store/parents/actions';
import userStorage from 'utilities/user/userStorage';
import { isUsdaLicenseActive } from 'utilities/license/license';
import { useExistingParents } from './useExistingParents';

import './FieldExistingParent.scss';

interface FieldExistingParentProps extends FormItemProps {
  gender: Gender;
  setCollapsableOpen: SetState<boolean>;
  setHideFormFields: SetState<boolean>;
}

export function FieldExistingParent(
  props: FieldExistingParentProps,
): ReactElement {
  const {
    gender,
    setCollapsableOpen,
    setHideFormFields,
    ...formItemProps
  } = props;
  const { parents } = useSelector((state: RootState) => ({
    parents: state.parents,
  }));
  const genderId = gender === 'sire' ? Genders.sire : Genders.dam;
  const existingParents = useExistingParents(genderId);
  const [disabledCreateNew, setDisabledCreateNew] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(parents[gender])) {
      setCollapsableOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parents[gender]]);

  useEffect(() => {
    if (disabledCreateNew) {
      showNotificationDamsLimitCreation();
    }
  }, [disabledCreateNew]);

  useEffect(() => {
    // Validate dams limit creation
    if (gender === 'dam') {
      setHideFormFields(true);
      if (existingParents.isLoading) return;

      const createDamLimit = existingParents.data.length >= 4;
      const usdaLicenseStatusId = userStorage.getBreederUsdaLicenseStatusId();

      if (!isUsdaLicenseActive(usdaLicenseStatusId) && createDamLimit) {
        setDisabledCreateNew(true);
      } else {
        setHideFormFields(false);
        setDisabledCreateNew(false);
      }

      if (!isEmpty(parents.dam)) {
        setHideFormFields(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingParents.data.length, existingParents.isLoading]);

  return (
    <Form.Item noStyle shouldUpdate={() => false}>
      {(form) => (
        <Form.Item
          label={`Create new or choose an existing ${gender}?`}
          className="FieldExistingParent"
          {...formItemProps}
        >
          <Select
            placeholder="Choose one..."
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => {
              if (value === 'new') {
                dispatch(resetParent(gender));
                form.resetFields();
              } else {
                dispatch(getParent(Number(value)));
              }
            }}
            disabled={existingParents.isLoading}
            loading={existingParents.isLoading}
            defaultValue={disabledCreateNew ? undefined : 'new'}
          >
            {disabledCreateNew ? (
              <Select.Option value="new" disabled={disabledCreateNew}>
                Create New (No available)
              </Select.Option>
            ) : (
              <Select.Option value="new">Create New</Select.Option>
            )}
            {existingParents.data?.map((parent) => (
              <Select.Option
                key={parent.id as number}
                value={parent.id as number}
              >
                {`${parent.name} - ${parent.breed.name}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </Form.Item>
  );
}
