import {
  Document,
  Documents,
  DocumentTypeIdTypes,
} from 'shared/types/document';
import date from 'utilities/time/date';

export const getLatestUpdatedHealthDoc = (
  documents: Documents,
  documentType: DocumentTypeIdTypes,
): Document | undefined => {
  const docs = documents?.filter((doc) => doc.documentTypeId === documentType);

  docs?.sort((a, b) => date.sortByNewest(a.createdAt, b.createdAt));

  return docs?.[0];
};
