import React, { ReactElement } from 'react';
import { Form, Input, Upload } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { FormInstance } from 'antd/lib/form';

import parentFormHelpers from 'shared/parents/parentFormHelpers';
import { Media, MediaUploadTypes } from 'shared/types/media';
import {
  FieldMediaUploadEmptyValidationRule,
  FieldPedigreeDocValidationRule,
} from './fieldMediaUploaderValidations';
import { MediaUploaderItem } from './MediaUploaderItem';
import { MediaUploaderBtn } from './MediaUploaderBtn';

interface Props {
  name: string;
  label?: string;
  disabled?: boolean;
  type?: MediaUploadTypes;
  testId?: string;
}

export const FieldMediaUploader = React.memo(function FieldMediaUploader(
  props: Props,
): ReactElement {
  const { type, name, label, disabled, testId } = props;

  const handleOnChange = (info: UploadChangeParam, form: FormInstance) => {
    parentFormHelpers.buildUploadedMedia(info.file as RcFile).then((media) => {
      form.resetFields([name]);
      form.setFieldsValue({ [name]: media });
      form.validateFields([name]);
    });
  };

  const handleOnClickDelete = (media: Media, form: FormInstance) => {
    !media.uid &&
      form.setFieldsValue({
        [`${name}HiddenId`]: media.id,
      });
    form.setFieldsValue({ [name]: undefined });
    form.validateFields([name]);
  };

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, curValues) =>
        prevValues[name] !== curValues[name] ||
        prevValues.isChampion !== curValues.isChampion
      }
    >
      {(form) => {
        const mediaValue = form.getFieldValue(name);
        const isChampion = Boolean(form.getFieldValue(['isChampion']));
        const uploadFileAccept = type === 'photo' ? 'image/*' : '';
        const rules =
          name === 'pedigreeDoc'
            ? [FieldPedigreeDocValidationRule(isChampion)]
            : [FieldMediaUploadEmptyValidationRule];

        return (
          <Form.Item
            name={name}
            label={label || 'Add Media'}
            rules={rules}
            className="FieldMediaUploader"
          >
            <>
              {mediaValue ? (
                <MediaUploaderItem
                  type={type}
                  mediaData={mediaValue}
                  testId={testId}
                  onClickDelete={() =>
                    handleOnClickDelete(mediaValue, form as FormInstance)
                  }
                  disabled={disabled}
                />
              ) : (
                <Upload
                  data-testid={`media-uploader-${name}`}
                  className="FieldMediaUploader"
                  accept={uploadFileAccept}
                  onChange={(file) =>
                    handleOnChange(file, form as FormInstance)
                  }
                  beforeUpload={() => false}
                  showUploadList={false}
                  disabled={disabled}
                >
                  <MediaUploaderBtn
                    type={type || 'media'}
                    disabled={disabled}
                  />
                </Upload>
              )}
              <Form.Item name={`${name}HiddenId`} noStyle>
                <Input hidden />
              </Form.Item>
            </>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
});
