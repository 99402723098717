import React, { ReactElement, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import { Button, notification, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { LoginOutlined } from '@ant-design/icons';

import { Breeder } from 'shared/types/breeder';
import { switchBreederImpersonateMode } from 'utilities/breederImpersonate/breederImpersonate';
import { userCanImpersonateManagedBreeders } from 'utilities/user/userRolesCheckers/userRolesCheckers';
import userStorage from 'utilities/user/userStorage';

import './BreederImpersonate.scss';

function BreederImpersonateExternal(): ReactElement {
  const [loading, setLoading] = useState(false);
  const [userBreedersList, setUserBreedersList] = useState<Breeder[]>([]);

  const loggedUserInfo = userStorage.getUser();
  const userBreeders = loggedUserInfo?.breeders || [];

  const handleBreederImpersonate = async (breederId: number) => {
    setLoading(true);
    try {
      await switchBreederImpersonateMode(breederId);
    } catch (e) {
      setLoading(false);
      notification.error({
        message: e.message,
      });
    }
  };

  const handleSearch = (value: string) => {
    value = value.toLocaleLowerCase();
    const breedersResult = userBreeders.filter((breeder) => {
      const breederFullName = breeder.userFullName.toLocaleLowerCase();
      const breederId = breeder.id.toString();

      if (breederFullName.includes(value) || breederId.includes(value)) {
        return breeder;
      }
      return null;
    });
    setUserBreedersList(breedersResult);
  };

  const breedersTableColumns: ColumnsType<Breeder> = [
    {
      title: 'User name',
      dataIndex: 'userFullName',
      key: 'userName',
      width: '55%',
      ellipsis: true,
    },
    {
      title: 'Breeder ID',
      dataIndex: 'id',
      key: 'id',
      width: '25%',
    },
    {
      key: 'actions',
      width: '20%',
      align: 'right',
      dataIndex: 'id',
      render: (breederId) => (
        <Button
          icon={<LoginOutlined />}
          type="link"
          size="small"
          onClick={() => handleBreederImpersonate(breederId)}
        />
      ),
    },
  ];

  useEffect(() => {
    setUserBreedersList(userBreeders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!userCanImpersonateManagedBreeders(loggedUserInfo)) {
    return <></>;
  }

  return (
    <section className="BreederImpersonate BreederImpersonate--External">
      <p className="BreederImpersonate__title">
        Please select one account to continue to Breeder Hub
      </p>
      <section className="BreederImpersonate__search">
        <Search
          placeholder="Search by breeder name or id"
          onSearch={handleSearch}
          allowClear
          enterButton="Search"
        />
      </section>
      <Table
        size="small"
        loading={loading}
        dataSource={userBreedersList}
        columns={breedersTableColumns}
        rowKey={(record) => record.id}
        pagination={false}
      />
    </section>
  );
}

export default BreederImpersonateExternal;
