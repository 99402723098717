import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { isEmpty } from 'lodash';

import {
  MyListingsDashboardStatusColumns,
  MyListingsDashboardStatusTabs,
  MyListingsSearch,
} from 'components/myListings';
import PageLayout from 'shared/baseLayout/pageLayout/PageLayout';
import {
  fetchMyListingsSold,
  setMyListingsSoldInitialState,
} from 'store/myListingsSold/actions.thunk';
import {
  fetchMyListingsApproved,
  setMyListingsApprovedInitialState,
} from 'store/myListingsApproved/actions.thunk';
import {
  fetchMyListingsPending,
  setMyListingsPendingInitialState,
} from 'store/myListingsPending/actions.thunk';
import {
  fetchMyListingsDelivered,
  setMyListingsDeliveredInitialState,
} from 'store/myListingsDelivered/actions.thunk';

import './MyListings.scss';
import UpdatePriceModal from 'components/myListings/updatePriceModal/UpdatePriceModal';
import useListingUpdatePrice from 'hooks/useListingUpdatePrice/useListingUpdatePrice';

const breadcrumb = [
  {
    name: 'My Puppy Listings',
  },
];

export default function MyListings(): ReactElement | null {
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const { modalVisible, setModalVisible, openModal } = useListingUpdatePrice();

  useEffect(() => {
    dispatch(fetchMyListingsPending());
    dispatch(fetchMyListingsApproved());
    dispatch(fetchMyListingsSold());
    dispatch(fetchMyListingsDelivered());
    return () => {
      dispatch(setMyListingsPendingInitialState());
      dispatch(setMyListingsApprovedInitialState());
      dispatch(setMyListingsSoldInitialState());
      dispatch(setMyListingsDeliveredInitialState());
    };
  }, [dispatch]);

  const refreshListings = () => {
    dispatch(fetchMyListingsPending());
    dispatch(fetchMyListingsApproved());
  };

  if (isEmpty(screens)) {
    return null;
  }

  return (
    <PageLayout className="MyListings" breadCrumbsNav={breadcrumb}>
      <MyListingsSearch />
      {screens.xl ? (
        <MyListingsDashboardStatusColumns openModal={openModal} />
      ) : (
        <MyListingsDashboardStatusTabs openModal={openModal} />
      )}
      <UpdatePriceModal
        callback={refreshListings}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </PageLayout>
  );
}
