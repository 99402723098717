import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Affix, Badge, Tabs } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
  MyListingsCardsApproved,
  MyListingsCardsDelivered,
  MyListingsCardsPending,
  MyListingsCardsSold,
} from 'components/myListings/myListingsCards';
import { useBaseLayoutAffixOffset } from 'hooks';
import { BreederDashboardStatusTypes } from 'shared/types/statuses';
import { RootState } from 'store/rootState';
import { BreederDashboardStatus } from 'utilities/const';
import { Listing } from 'shared/types';

import './MyListingsDashboardStatusTabs.scss';

interface Props {
  openModal?: (listing: Listing) => void;
}

const { TabPane } = Tabs;

function MyListingsDashboardStatusTab({
  dashboardStatus,
  totalListings,
  loading,
}: {
  dashboardStatus: BreederDashboardStatusTypes;
  totalListings: number;
  loading: boolean;
}): ReactElement {
  return (
    <div>
      <div className="MyListingsDashboardStatusTab__label">
        {dashboardStatus}
      </div>
      {loading ? <LoadingOutlined /> : <Badge count={totalListings} />}
    </div>
  );
}

function MyListingsDashboardStatusTabs({ openModal }: Props): ReactElement {
  const [activeTab, setActiveTab] = useState<BreederDashboardStatusTypes>(
    'pending',
  );
  const {
    myListingsPending: {
      loading: loadingListingsPending,
      pagination: { total: totalListingsPending },
    },
    myListingsApproved: {
      loading: loadingListingsApproved,
      pagination: { total: totalListingsApproved },
    },
    myListingsSold: {
      loading: loadingListingsSold,
      pagination: { total: totalListingsSold },
    },
    myListingsDelivered: {
      loading: loadingListingsDelivered,
      pagination: { total: totalListingsDelivered },
    },
  } = useSelector((state: RootState) => state);
  const { offsetTopMyEntityTabBar } = useBaseLayoutAffixOffset();

  return (
    <Tabs
      className="MyListingsDashboardStatusTabs"
      activeKey={activeTab}
      onChange={(e) => setActiveTab(e as BreederDashboardStatusTypes)}
      renderTabBar={(props, DefaultTabBar) => (
        <Affix offsetTop={offsetTopMyEntityTabBar}>
          <DefaultTabBar {...props} />
        </Affix>
      )}
    >
      <TabPane
        key={BreederDashboardStatus.Pending}
        tab={
          <MyListingsDashboardStatusTab
            dashboardStatus={BreederDashboardStatus.Pending}
            totalListings={totalListingsPending}
            loading={loadingListingsPending}
          />
        }
      >
        <MyListingsCardsPending openModal={openModal} />
      </TabPane>
      <TabPane
        key={BreederDashboardStatus.Approved}
        tab={
          <MyListingsDashboardStatusTab
            dashboardStatus={BreederDashboardStatus.Approved}
            totalListings={totalListingsApproved}
            loading={loadingListingsApproved}
          />
        }
      >
        <MyListingsCardsApproved openModal={openModal} />
      </TabPane>
      <TabPane
        key={BreederDashboardStatus.Sold}
        tab={
          <MyListingsDashboardStatusTab
            dashboardStatus={BreederDashboardStatus.Sold}
            totalListings={totalListingsSold}
            loading={loadingListingsSold}
          />
        }
      >
        <MyListingsCardsSold />
      </TabPane>
      <TabPane
        key={BreederDashboardStatus.Delivered}
        tab={
          <MyListingsDashboardStatusTab
            dashboardStatus={BreederDashboardStatus.Delivered}
            totalListings={totalListingsDelivered}
            loading={loadingListingsDelivered}
          />
        }
      >
        <MyListingsCardsDelivered />
      </TabPane>
    </Tabs>
  );
}

export default MyListingsDashboardStatusTabs;
