import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';

import { Nullable } from 'shared/types/general';
import { BreederRoutes } from 'shared/types/routes';
import { reactEnvironmentVariables } from 'utilities/environmentVariables';
import userStorage from 'utilities/user/userStorage';
import refreshToken from 'utilities/refreshToken/refreshToken';

const BH_CONFIG = {
  session: {
    sessionExpirationTime: 240, // four hours 240 mins
    waitingTimeAfterNotification: 120000, // two minutes 120000ms
    timeIntervalForCheckInactivity: 60000, // one minute 60000ms
    idleTimer: {
      notificationTitle: 'You have been idle for 4 hours',
      notificationMessage: 'Do you still want to continue using the App?',
      notificationOkButton: 'Yes keep me here',
      notificationCancelButton: 'Close session',
    },
  },
  body: {
    application: reactEnvironmentVariables.APPLICATION_ID,
  },
};

const IdleTimer = () => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [timeCounter, setTimeCounter] = useState(0);
  const [idleSecondsTimer, setIdleSecondsTimer] = useState<NodeJS.Timeout>();
  const [idleNotifyModal, setIdleNotifyModal] = useState<NodeJS.Timeout>();

  useEffect(() => {
    setUserRefreshmentSessionEvents();
    validateAndRefreshToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (timeCounter >= BH_CONFIG.session.sessionExpirationTime - 1) {
      checkIdleTime();
    }

    if (!isNotificationOpen) {
      clearTimer(idleNotifyModal, clearTimeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCounter, isNotificationOpen]);

  const clearTimer = (
    timer: Nullable<NodeJS.Timeout>,
    fn: (timer: NodeJS.Timeout) => void,
  ) => {
    if (timer) {
      fn(timer);
    }
  };

  const setUserRefreshmentSessionEvents = () => {
    setIdleSecondsTimer(startCounter());
    document.onclick = () => {
      reset();
    };

    document.onmousemove = () => {
      reset();
    };

    document.onkeypress = () => {
      reset();
    };

    document.onscroll = () => {
      reset();
    };
  };

  const logOut = () => {
    userStorage.removeUserAndToken();
    window.location.href = BreederRoutes.Login;
  };

  const reset = () => {
    if (!isNotificationOpen) {
      setTimeCounter(0);
    }
  };

  const onKeepSessionOpen = () => {
    clearTimer(idleNotifyModal, clearTimeout);
    setTimeCounter(0);
    setIsNotificationOpen(false);
    setIdleSecondsTimer(startCounter());
  };

  const onCancel = () => {
    clearTimer(idleSecondsTimer, clearInterval);
    logOut();
  };

  const toggleNotificationOpen = (status: boolean) => {
    setIsNotificationOpen(status);
  };

  const checkIdleTime = () => {
    clearTimer(idleSecondsTimer, clearInterval);
    openNotification();
  };

  const startCounter = () => {
    return setInterval(() => {
      setTimeCounter((timeCounter) => timeCounter + 1);
      validateAndRefreshToken();
    }, BH_CONFIG.session.timeIntervalForCheckInactivity);
  };

  const startCounterAfterNotification = () => {
    return setTimeout(() => {
      clearTimer(idleSecondsTimer, clearInterval);
      Modal.destroyAll();
      onCancel();
    }, BH_CONFIG.session.waitingTimeAfterNotification);
  };

  const validateAndRefreshToken = () => {
    if (refreshToken.isTokenExpired()) {
      refreshToken.requestToken();
    }
  };

  const openNotification = () => {
    toggleNotificationOpen(true);
    setIdleNotifyModal(startCounterAfterNotification());
    Modal.destroyAll();
    Modal.confirm({
      title: BH_CONFIG.session.idleTimer.notificationTitle,
      content: BH_CONFIG.session.idleTimer.notificationMessage,
      okText: BH_CONFIG.session.idleTimer.notificationOkButton,
      cancelText: BH_CONFIG.session.idleTimer.notificationCancelButton,
      onOk: () => onKeepSessionOpen(),
      onCancel: () => onCancel(),
    });
  };

  return <></>;
};

export default IdleTimer;
