import { BreederDashboardStatusTypes } from 'shared/types/statuses';
import number from 'utilities/number/number';
import { isListingStatusHidden } from 'utilities/listing';
import { Puppy } from 'shared/types/puppy';

export const fieldPuppyMinCostValue = 200;

export const fieldPuppyCostValidationMessage = {
  empty: `Please fill out this field`,
  undervalue: `Price can’t be less than ${number.displayCurrency(
    fieldPuppyMinCostValue,
  )}`,
  overvalue: (maxCostValue: number) =>
    `Price can’t be above ${number.displayCurrency(
      maxCostValue,
    )} for this breed`,
  increase: `You can’t increase an approved price`,
};

export const FieldPuppyCostValidationRules = (
  initialCost = 0,
  maxCostValue: number,
  puppy?: Puppy | Partial<Puppy>,
  breederDashboardStatus?: BreederDashboardStatusTypes,
  isDisabled?: boolean,
) => {
  return {
    validator: (_: unknown, value: number) => {
      if (isDisabled) {
        return Promise.resolve();
      }

      if (!value) {
        return Promise.reject(fieldPuppyCostValidationMessage.empty);
      }

      if (
        (breederDashboardStatus &&
          breederDashboardStatus !== 'pending' &&
          value > initialCost) ||
        (isListingStatusHidden(puppy?.listing) && value > initialCost)
      ) {
        return Promise.reject(fieldPuppyCostValidationMessage.increase);
      } else {
        if (value < fieldPuppyMinCostValue) {
          return Promise.reject(fieldPuppyCostValidationMessage.undervalue);
        }

        if (
          value > maxCostValue &&
          (breederDashboardStatus === 'pending' || !breederDashboardStatus)
        ) {
          return Promise.reject(
            fieldPuppyCostValidationMessage.overvalue(maxCostValue),
          );
        }
      }

      return Promise.resolve();
    },
  };
};
