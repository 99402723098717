import React, { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from 'shared/icon/Icon';
import './WidgetContainer.scss';

interface Props {
  children?: ReactNode;
  title: string;
  goto?: string;
  className?: string;
  linkLabel?: string;
  highlight?: boolean;
}

export function WidgetContainer(props: Props): ReactElement {
  const { title, goto, linkLabel, children, highlight, className } = props;
  const label = linkLabel || 'View all';
  const highlighted = highlight ? 'highlighted' : '';

  return (
    <div className={`WidgetContainer ${highlighted} ${className}`}>
      <div className="WidgetContainer__header">
        <div>
          {highlight && (
            <Icon
              name="warning"
              className={`WidgetContainer__header--icon ${highlighted}`}
            />
          )}
          <h4>{title}</h4>
        </div>
        {goto && <Link to={goto}>{label}</Link>}
      </div>
      <div className="WidgetContainer__content">{children}</div>
    </div>
  );
}
