import React, { ReactElement } from 'react';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { EmptyFieldValidationHandler } from 'shared/fields/fieldValidationHandler';
import { FormFieldProps } from 'shared/types/form';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import { NeurologicalStimulationContent } from 'utilities/staticContent/types';

export function FieldNeuroStimulation({
  disabled,
}: FormFieldProps): ReactElement {
  const neurologicalStimulations = staticContentStorage.getContent()
    .neurologicalStimulations;
  const otherStimulationId = 6;

  function handleOnchange(e: CheckboxChangeEvent, form: FormInstance) {
    if (e.target.value === otherStimulationId) {
      form.resetFields(['otherNeurologicalStimulationDescription']);
    }
  }

  return (
    <Form.Item noStyle shouldUpdate>
      {(form) => {
        const stimulationSelected = form.getFieldValue(
          'neurologicalStimulations',
        );
        const otherStimulationSelected = Boolean(
          stimulationSelected?.find((id: number) => id === otherStimulationId),
        );

        return (
          <Form.Item
            name="neurologicalStimulations"
            label="Neurological stimulation"
          >
            <Checkbox.Group disabled={disabled}>
              <Row gutter={[8, 24]}>
                {neurologicalStimulations?.map(
                  (neurologicalStimulation: NeurologicalStimulationContent) => (
                    <Col span={12} key={neurologicalStimulation.id}>
                      <Checkbox
                        value={neurologicalStimulation.id}
                        onChange={(e) =>
                          handleOnchange(e, form as FormInstance)
                        }
                      >
                        {neurologicalStimulation.name}
                      </Checkbox>
                      {neurologicalStimulation.name === 'Other' &&
                        otherStimulationSelected && (
                          <Form.Item
                            style={{ margin: `7px 0 0` }}
                            rules={[() => EmptyFieldValidationHandler('input')]}
                            name="otherNeurologicalStimulationDescription"
                          >
                            <Input placeholder="Other stimulations" />
                          </Form.Item>
                        )}
                    </Col>
                  ),
                )}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
}
