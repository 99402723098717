import React, { ReactElement, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { isEmpty } from 'lodash';

import { RootState } from 'store/rootState';
import {
  setPuppyPhotosAdditional,
  setPuppyPhotosCover,
} from 'store/puppy/actions';
import showNotificationError from 'shared/notifications/showNotificationError';
import { MediaUploaderBtn } from 'shared/fields/fieldMediaUploader/MediaUploaderBtn';
import { PuppyPhotos } from 'shared/types/puppy';
import { puppyPhotosValidationRules } from './puppyPhotosValidationRules';
import { buildUploadedPuppyPhotos } from './buildUploadedPuppyPhotoMedia';

interface Props {
  disabled?: boolean;
}

export default function PuppyPhotosUpload(props: Props): ReactElement {
  const { disabled } = props;
  const [showUploadBtn, setShowUploadBtn] = useState(true);
  const [isBuildingPhotos, setIsBuildingPhotos] = useState(false);

  const { puppy, puppyPhotos } = useSelector((state: RootState) => ({
    puppy: state.puppy,
    puppyPhotos: {
      coverPhotos: state.puppy.photos?.coverPhotos,
      additionalPhotos: state.puppy.photos?.additionalPhotos,
      hiddenPhotos: state.puppy.photos?.hiddenPhotos,
    },
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    const showUploaderBtn = () => {
      if (isEmpty(puppy)) {
        return true;
      }

      if (puppyPhotos) {
        const coverLength = puppyPhotos.coverPhotos
          ? puppyPhotos.coverPhotos.length
          : 0;
        const additionalLength = puppyPhotos.additionalPhotos
          ? puppyPhotos.additionalPhotos.length
          : 0;
        const isOnLimitRange = coverLength + additionalLength < 8;
        return isOnLimitRange;
      }

      return true;
    };
    setShowUploadBtn(showUploaderBtn());
  }, [puppy, puppyPhotos]);

  const setUploadPhotos = (uploadPhotos: UploadChangeParam) => {
    setIsBuildingPhotos(true);
    buildUploadedPuppyPhotos(uploadPhotos, puppyPhotos as PuppyPhotos)
      .then((resp) => {
        dispatch(setPuppyPhotosCover(resp.coverPhotos));
        dispatch(setPuppyPhotosAdditional(resp.additionalPhotos));
      })
      .catch((error) => {
        showNotificationError({
          error: error,
          key: 'uploadLimitNotification',
        });
      })
      .finally(() => {
        setIsBuildingPhotos(false);
      });
  };

  const setPuppyPhotosValue = () => {
    const coverPhotos = puppyPhotos.coverPhotos || [];
    const additionalPhotos = puppyPhotos.additionalPhotos || [];
    const photos = [...coverPhotos, ...additionalPhotos];

    return photos;
  };

  return (
    <Form.Item
      className="FieldPuppyPhotosUpload"
      name="puppyPhotos"
      rules={[puppyPhotosValidationRules]}
      valuePropName="fileList"
      getValueFromEvent={setPuppyPhotosValue}
    >
      <Upload
        data-testid="puppy-photo-upload"
        accept="image/*"
        multiple={true}
        showUploadList={false}
        beforeUpload={() => false}
        onChange={setUploadPhotos}
      >
        {showUploadBtn && (
          <MediaUploaderBtn
            type="photo"
            loading={isBuildingPhotos}
            label="Select or drag image here"
            disabled={disabled}
          />
        )}
      </Upload>
    </Form.Item>
  );
}
