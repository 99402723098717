import { isEmpty } from 'lodash';
import { DocumentRequestBody } from 'shared/types/document';
import { apiPostRequest } from './apiHttp';

const apiHealthDocument = {
  postHealthDocument: async (documentRequestBody: DocumentRequestBody) => {
    const payload = {
      document: documentRequestBody,
    };

    const resp = await apiPostRequest('document', payload);
    const respError = resp?.json?.errors;
    if (!isEmpty(respError) || resp.status === 500) {
      throw new Error(`Couldn't crate document`);
    }

    return resp;
  },
};

export default apiHealthDocument;
