import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, Progress } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { isEmpty } from 'lodash';

import { SetState } from 'shared/types/general';
import { Puppy, PuppyFormFields } from 'shared/types/puppy';
import { createPuppy, editPuppy } from 'store/puppy/actions';
import puppyFormHelpers from 'shared/puppy/puppyFormHelpers';
import { RootState } from 'store/rootState';

import './SubmitModal.scss';

interface Props {
  setShowSubmitModal: SetState<boolean>;
  showSubmitModal: boolean;
  isEditing: boolean;
  setIsFormFieldsLoaded: SetState<boolean>;
  setIsSameName: SetState<boolean>;
  form: FormInstance;
}

export default function SubmitModal(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { litter } = useSelector((state: RootState) => ({
    litter: state.litter,
  }));

  const [submitProgress, setSubmitProgress] = useState(0);
  const {
    setShowSubmitModal,
    showSubmitModal,
    isEditing,
    setIsFormFieldsLoaded,
    setIsSameName,
    form,
  } = props;

  const handleOkCreate = (puppyId: unknown) => {
    history.push(`/puppy/${puppyId}`);
  };

  const handleOkEdit = () => {
    window.scrollTo({ top: 0 });
  };

  const handleSubmitPuppy = async () => {
    const puppyFormData = form.getFieldsValue() as PuppyFormFields;

    // if discount is not now, then we are going to add some default values to change the previews values
    if (
      !puppyFormData?.listing?.discountOfferReason &&
      !puppyFormData?.listing?.discountOfferAmount
    ) {
      puppyFormData.listing.discountOfferReason = '';
      puppyFormData.listing.discountOfferAmount = 0;
    }

    let puppyResp = {};
    if (isEditing) {
      puppyResp = await dispatch(
        editPuppy(puppyFormData, setSubmitProgress, setIsSameName),
      );

      setShowSubmitModal(false);

      if (puppyResp) {
        puppyFormHelpers.setFormFields(
          puppyResp as Partial<Puppy>,
          litter,
          form,
          setIsFormFieldsLoaded,
        );
      }
    } else {
      puppyResp = await dispatch(
        createPuppy(puppyFormData, setSubmitProgress, setIsSameName),
      );
      setShowSubmitModal(false);
    }
    if (puppyResp) {
      Modal.success({
        className: 'Modal__confirm--success',
        title: `Puppy ${isEditing ? 'Updated' : 'Created'} Successfully`,
        content: (isEmpty(puppyFormData.puppyPhotos) ||
          puppyFormData.puppyPhotos.length < 3) && (
          <p>
            <b>
              Please remember to send at least three photos so we can approve
              and publish this puppy.
            </b>
          </p>
        ),
        autoFocusButton: null,
        okText: 'OK',
        afterClose: () =>
          isEditing ? handleOkEdit() : handleOkCreate(puppyResp),
        okButtonProps: { type: 'primary' },
      });
    }
    setSubmitProgress(0);
  };
  const isSubmitting = submitProgress > 0 && submitProgress < 100;
  return (
    <Modal
      className={
        isSubmitting ? `SubmitModal SubmitModal--hide-buttons` : 'SubmitModal'
      }
      title={isSubmitting ? `Saving...` : `Submit Puppy Listing`}
      visible={showSubmitModal}
      okText="Yes"
      maskClosable={!isSubmitting}
      closable={!isSubmitting}
      onCancel={() => setShowSubmitModal(false)}
      onOk={handleSubmitPuppy}
      cancelText="No, go back"
      okButtonProps={{ type: 'primary', size: 'middle' }}
      cancelButtonProps={{ type: 'default', size: 'middle' }}
    >
      {isSubmitting ? (
        <Progress percent={submitProgress} status="active" />
      ) : (
        <p>Are you sure the information provided is ok?</p>
      )}
    </Modal>
  );
}
