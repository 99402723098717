const validationErrorMessage = {
  empty: `Please select an item in the list`,
};

export const FieldChampionLineageValidationRules = () => {
  return {
    validator: (_: unknown, value: string) => {
      if (value === undefined) {
        return Promise.reject(validationErrorMessage.empty);
      }

      return Promise.resolve();
    },
  };
};
