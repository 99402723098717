import {
  apiPostRequest,
  apiGetRequest,
  apiPutRequest,
  apiDeleteRequest,
} from 'utilities/http/api/apiHttp';
import userStorage from 'utilities/user/userStorage';
import { Id } from 'shared/types/general';
import {
  Litter,
  LitterStatus,
  LitterToUpdateFields,
  LitterParentChangePayload,
} from 'shared/types/litter';

const apiLitter = {
  getLitterById: (litterId: Id) => {
    const params = `withPuppies=1&withStatus=1&withPuppyParents=1&withFood=1&withMedia=1&withTallies=1&withBreederDashboardStatus=1&showMinimumDateAllowedToAddPuppiesToDams=1&excludeCurrentLitterFromMinimumDateAllowedToAddPuppiesToDamsCalculation=1&withRecommendedPrice=1`;
    return apiGetRequest(`litter/${litterId}`, params);
  },
  getLittersByBreeder: (
    litterStatus: LitterStatus,
    pageSize = 100,
    page = 1,
  ) => {
    let parameters = `page=${page}&pageSize=${pageSize}&breederId=${userStorage.getBreederId()}&withParents=1&withStatus=1&withMedia=1`;
    if (litterStatus) {
      parameters += `&status=${litterStatus}`;
    }
    return apiGetRequest('litter', parameters);
  },
  postLitter: (litter: Partial<Litter>) => {
    const payload = { litter: litter };
    return apiPostRequest(`litter`, payload);
  },
  putLitter: (
    litterUpdated: LitterToUpdateFields | LitterParentChangePayload,
    litterId: number,
  ) => {
    return apiPutRequest(`litter/${litterId}`, { litter: litterUpdated });
  },
  deleteLitter: (litterId: number) => {
    return apiDeleteRequest(`litter/${litterId}`);
  },
  getLittersWithParents: () => {
    return apiGetRequest(
      'litter',
      `breederId=${userStorage.getBreederId()}&pageSize=1000&withStatus=1&withParents=1&excludeOldLitters=1`,
    );
  },
  getHybridBreed: (sireBreedId: number, damBreedId: number) => {
    return apiGetRequest(
      `breed/hybrid`,
      `sireBreedId=${sireBreedId}&damBreedId=${damBreedId}`,
    );
  },
};

export default apiLitter;
