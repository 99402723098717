import {
  BreederActions,
  SET_STATES,
  SET_BREEDER,
  SET_COUNTRY,
} from 'store/breeder/types';
import { Breeder } from 'shared/types/breeder';
import { Dispatch } from 'redux';
import apiBreeder from 'utilities/http/api/apiBreeder';
import apiStaticContent from 'utilities/http/api/apiStaticContent';
import { AbbrCountry, CountryId, State } from 'shared/types/geolocation';

export interface SetBreeder {
  type: typeof SET_BREEDER;
  breeder: Breeder;
}

export function setBreeder(breeder: Breeder): BreederActions {
  return {
    type: SET_BREEDER,
    breeder,
  };
}

export interface SetStates {
  type: typeof SET_STATES;
  states: State[];
}

export function setStates(states: State[]): BreederActions {
  return {
    type: SET_STATES,
    states,
  };
}

export interface SetCountry {
  type: typeof SET_COUNTRY;
  countryId: CountryId;
}

export function setCountry(countryId: CountryId): BreederActions {
  return {
    type: SET_COUNTRY,
    countryId,
  };
}

export function fetchBreeder() {
  return async (dispatch: Dispatch) => {
    const breederInformationResponse = await apiBreeder.getBreederInformation();
    dispatch(setBreeder(breederInformationResponse.json.breeder));
  };
}

export function fetchCountryStates(countryAbbreviation: AbbrCountry) {
  return async (dispatch: Dispatch) => {
    const states = await apiStaticContent.getStatesByCountry(
      countryAbbreviation,
    );
    dispatch(setStates(states.json.states));
  };
}
