import React, { ReactElement, useEffect } from 'react';
import { Form, Select, Input } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { Store } from 'rc-field-form/lib/interface';
import { isEmpty } from 'lodash';

import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import { BreedContent, RegistryContent } from 'utilities/staticContent/types';
import {
  FieldRegistryNumberValidationRules,
  FieldRegistryTypeValidationRules,
} from './FieldRegistryValidation';

import './FieldRegistry.scss';

interface Props {
  limitationLabelOn?: boolean;
  disabled?: boolean;
  isParent?: boolean;
  warning?: boolean;
  withLegacyRegistry?: RegistryContent;
}

export function FieldRegistry(props: Props): ReactElement {
  const { limitationLabelOn, disabled, warning, isParent } = props;
  const registries = [
    ...staticContentStorage.getContent().registries,
    ...(props.withLegacyRegistry ? [props.withLegacyRegistry] : []),
  ];
  const breeds = staticContentStorage.getContent().breeds;
  const registryContentNotRegistered = 1;
  const breedTypesPureBred = 1;

  const handleShouldUpdate = (prevValues: Store, curValues: Store) => {
    if (prevValues.registryId !== curValues.registryId) return true;

    if (prevValues.breedId !== curValues.breedId) return true;

    if (prevValues.registrationNumber !== curValues.registrationNumber)
      return true;

    return false;
  };

  const warningStatus = warning && {
    hasFeedback: true,
    validateStatus: 'warning' as ValidateStatus,
  };

  useEffect(() => {
    if (!isEmpty(registries)) {
      const positionReplace = 0;
      const labelNoRegistry = registries?.filter(
        (r: RegistryContent) => r.name === 'Not registered',
      );
      const labelPosition = registries.indexOf(labelNoRegistry[0]);
      registries.splice(labelPosition, 1);
      registries.splice(positionReplace, 0, labelNoRegistry[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form.Item noStyle shouldUpdate={handleShouldUpdate}>
      {(form) => {
        const breedId = form.getFieldValue('breedId');
        const registryId = form.getFieldValue('registryId');
        const isPurebred = breedId
          ? Boolean(
              breeds?.find((breed: BreedContent) => breed.id === breedId)
                ?.breedTypeId === breedTypesPureBred,
            )
          : false;
        const registryContent = registries.find(
          (registry: RegistryContent) => registry.id === registryId,
        );

        return (
          <>
            <Form.Item
              name="registryId"
              label="Registry type"
              rules={[FieldRegistryTypeValidationRules(isPurebred)]}
              style={{ width: '66%' }}
            >
              <Select
                disabled={disabled}
                data-testid="field-registry"
                placeholder="Select Registry"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {registries.map((registry: RegistryContent) => (
                  <Select.Option key={registry.id} value={registry.id}>
                    {registry.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {registryContent?.id !== registryContentNotRegistered && (
              <Form.Item
                name="registrationNumber"
                label={`Registry number ${isParent ? '' : '(optional)'}`}
                rules={[
                  FieldRegistryNumberValidationRules(registryContent, isParent),
                ]}
                extra={
                  registryContent?.example &&
                  `Example Format: ${registryContent.example}`
                }
                style={{ width: '66%' }}
                {...warningStatus}
              >
                <Input disabled={disabled} />
              </Form.Item>
            )}
            {registryContent && limitationLabelOn && (
              <label className="limitation-label">
                {registryContent.allowLimitation !== 0 &&
                  `Limited Registration`}
              </label>
            )}
          </>
        );
      }}
    </Form.Item>
  );
}
