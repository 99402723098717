import { Dispatch, SetStateAction } from 'react';

import { Gender } from 'shared/types/parent';
import { CollapsableStatusTypes } from 'shared/types/statuses';
import { EntityType } from 'utilities/const';

export type Nullable<T> = T | null | undefined;

export type Id = Nullable<number>;

export type ZeroOrOne = 0 | 1;

export interface Breed {
  id: number;
  name: string;
  slug?: string;
  coat?: string;
  about?: string;
  origin?: string;
  trivia?: string;
  shortName?: Nullable<string>;
  breedTypeId?: number;
  breedSizeId?: number;
  breedEnergyId?: number;
  weightRangeLow?: number;
  weightRangeHigh?: number;
  lifespanLow?: Nullable<number>;
  lifespanHigh?: Nullable<number>;
  tendencyToBarkScore?: Nullable<number>;
  trainabilityScore?: Nullable<number>;
  countryId?: Nullable<number>;
  hypoallergenicTypeId?: Nullable<number>;
  breedGroupId?: Nullable<number>;
  expandedDescription?: Nullable<string>;
  heroImageUrl?: string;
  stockImageUrl?: string;
  overviewImageUrl?: string;
  breederMaxCost?: number;
  isPriceRestricted?: number;
  breedCollections: number[];
}

export type PhoneTypeId =
  | /*  home => */ 1
  | /*  office => */ 2
  | /*  work => */ 3;

export enum PhoneType {
  home = 1,
  office = 2,
}

export interface Phone {
  id: number;
  phoneNumber: string;
  phoneTypeId?: PhoneTypeId;
  canReceiveSms: number;
  isPrimary: number;
  phoneExtension?: number;
}

export interface Registration {
  id: Id;
  puppyId: number;
  registryId: number;
  registrationTypeId: number;
  registrationNumber: string;
  isLimited: number;
}

export interface Note {
  id: Id;
  message: string;
  noteTypeId: number;
  noteableId: number;
  authorId: number;
  expiresAt: Nullable<string>;
  createdAt: string;
  updatedAt: string;
}

export interface Food {
  brandId: number;
  formulaId: number;
  id: number;
  litterId: number;
  puppyId: Nullable<number>;
  typeId: number;
}

export const NoteTypes = {
  internal: { id: 1, name: 'Internal' },
  general: { id: 2, name: 'General' },
  featured: { id: 3, name: 'Featured' },
  alert: { id: 4, name: 'Alert' },
  specialBreeder: { id: 5, name: 'Special Breeder' },
  circumstantialBreeder: { id: 6, name: 'Circumstantial Breeder' },
  noteFromBreeder: { id: 8, name: 'Note From Breeder' },
  financingDeclined: { id: 9, name: 'Financing declined' },
  breederShipping: { id: 10, name: 'Breeder Shipping' },
};

export interface FormProps {
  collapsableDisabled?: boolean | undefined;
  collapsableType?: CollapsableStatusTypes;
  isOpen?: boolean | undefined;
  action?: FormAction;
  singleForm?: boolean;
  setShowPuppyForm?: Nullable<SetState<boolean>>;
  scrollSummary?: string | undefined;
}

// useful to avoid setState boilerplate
export type SetState<T> = Dispatch<SetStateAction<T>>;

export type SetNullableState<T> = SetState<Nullable<T>>;

export type DispatchAny = Dispatch<unknown>;

export type ReactRouterParameter = string | undefined;

export interface ParentRouterParameter {
  id: string;
  gender: Gender;
}

export interface TokenRouterParameter {
  token: string;
}

export type FormsType = 'parent' | 'litter' | 'puppy';

export type FormAction = 'create' | 'edit';

export type DueDatesItems =
  | 'Weight'
  | 'Registration'
  | 'Photos'
  | 'Health'
  | 'Travel'
  | 'Docs';

export type MyUpcomingColumns = 'pastDue' | 'upcoming';

export type MyPuppyListingsColumns =
  | 'pending'
  | 'approved'
  | 'sold'
  | 'delivered'
  | 'hidden';

export interface RegisterBreederBody {
  user: {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
  };
  breeder: {
    kennel_name: string;
    breeds: number[];
  };
  membership_agreement: number;
  question_answers: unknown[];
  phone_number_home: string;
  phone_number_mobile?: Nullable<string>;
  phone_number_office?: Nullable<string>;
  address: {
    address: string;
    address2?: Nullable<string>;
    city: string;
    state_id: number;
    postal_code: string;
    country_id: number;
  };
}

export interface PaginationMeta {
  currentPage: number;
  itemsOnPage: number;
  itemsPerPage: number;
  totalItems?: number;
}

export type EntityTypes =
  | typeof EntityType.Litter
  | typeof EntityType.Listing
  | typeof EntityType.Parent
  | typeof EntityType.Puppy;
