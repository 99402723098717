import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Col, Row } from 'antd';

import { getParent, resetParents } from 'store/parents/actions';
import { RootState } from 'store/rootState';
import ParentCard from 'pages/myParents/parentCard/ParentCard';
import PageLayout from 'shared/baseLayout/pageLayout/PageLayout';
import { ParentRouterParameter } from 'shared/types/general';
import { ParentForm } from 'shared/parents/ParentForm';
import { Icon } from 'shared/icon/Icon';
import { Parent } from 'shared/types/parent';
import { BreederRoutes } from 'shared/types/routes';

const breadCrumbs = [
  {
    url: BreederRoutes.MyParents,
    name: 'My Dams & Sires',
  },
  {
    name: 'Parent Detail',
  },
];

export default function ParentDetail() {
  const { id, gender } = useParams<ParentRouterParameter>();
  const dispatch = useDispatch();

  const { parents } = useSelector((state: RootState) => state);

  const parent = parents[gender];

  useEffect(() => {
    dispatch(getParent(Number(id)));
    return () => {
      dispatch(resetParents());
    };
  }, [dispatch, id]);

  return (
    <PageLayout className="ParentDetail" breadCrumbsNav={breadCrumbs}>
      {!isEmpty(parent) ? (
        <Row justify="center">
          <Col xs={24} xl={20} xxl={15}>
            <ParentCard parent={parent as Parent} belongsToPageDetail />
            <ParentForm
              action="edit"
              gender={gender}
              collapsableType="success"
              isOpen={true}
            />
          </Col>
        </Row>
      ) : (
        <Row
          justify="center"
          align="middle"
          style={{ minHeight: '20vh' }}
          data-testid="puppy-detail-loading"
        >
          <Icon name="spinner" />
        </Row>
      )}
    </PageLayout>
  );
}
