import React, { ReactElement } from 'react';
import { Col, Row } from 'antd';

import {
  PuppyDetailTravelPlanTile,
  PuppyDetailHealthDocsTile,
  PuppyDetailUpdatesTile,
} from 'components/puppy';
import { SetState } from 'shared/types/general';

interface Props {
  setShowTravelPlan: SetState<boolean>;
  setShowPuppyForm: SetState<boolean>;
  setShowPuppyHealthDocs: SetState<boolean>;
}

function PuppyTilesNav(props: Props): ReactElement {
  const { setShowTravelPlan, setShowPuppyForm, setShowPuppyHealthDocs } = props;

  return (
    <div className="PuppyDetailTiles__navigation">
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} md={8}>
          <PuppyDetailHealthDocsTile
            setShowPuppyHealthDocs={setShowPuppyHealthDocs}
          />
        </Col>
        <Col xs={24} md={8}>
          <PuppyDetailTravelPlanTile setShowTravelPlan={setShowTravelPlan} />
        </Col>
        <Col xs={24} md={8}>
          <PuppyDetailUpdatesTile setShowPuppyForm={setShowPuppyForm} />
        </Col>
      </Row>
    </div>
  );
}

export default PuppyTilesNav;
