import React, { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { BreederRoutes } from 'shared/types/routes';

import './RemoveLitter.scss';
import apiLitter from 'utilities/http/api/apiLitter';
import LoadingSection from 'components/loadingSection/LoadingSection';

interface Props {
  litterId: number;
}

function RemoveLitter(props: Props): ReactElement {
  const { litterId } = props;
  const history = useHistory();
  const screen = Grid.useBreakpoint();
  const [loading, setLoading] = useState(false);
  const label = 'Remove Litter';

  const handleRemoveLitter = () => {
    Modal.confirm({
      title: 'Wait..',
      content: 'Are you sure you want to remove the litter? ',
      onOk: async () => {
        setLoading(true);
        await apiLitter.deleteLitter(litterId);
        history.push(BreederRoutes.MyLitters);
        setLoading(false);
      },
    });
  };

  return (
    <>
      {loading ? (
        <LoadingSection />
      ) : (
        <Button
          type="link"
          icon={<DeleteOutlined />}
          className="remove-litter-btn"
          onClick={handleRemoveLitter}
          title={label}
        >
          {screen.xs ? undefined : label}
        </Button>
      )}
    </>
  );
}

export default RemoveLitter;
