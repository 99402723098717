import React, { ReactElement } from 'react';
import { Modal } from 'antd';
import { HourglassOutlined, LockOutlined } from '@ant-design/icons';

import { EntityTypes } from 'shared/types/general';
import { Listing } from 'shared/types/puppy';
import { Parent } from 'shared/types/parent';
import { testId } from 'testsUtilities/dataTestId';
import { isListingLocked } from 'utilities/listing';
import { isParentLocked } from 'utilities/parent';
import { isDelivered, isPending } from 'utilities/entities/puppy';

import './EntityBadges.scss';

interface Props {
  title: string | React.ReactNode;
  icon: ReactElement;
  content: ReactElement;
}

function EntityBadge(props: Props): ReactElement {
  const { icon, title, content } = props;

  const showModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    Modal.info({
      icon,
      title,
      okText: 'OK',
      maskClosable: true,
      content,
    });
  };

  return (
    <span
      className="EntityBadge"
      data-testid={testId.entityBadge}
      onClick={showModal}
    >
      {icon}
    </span>
  );
}

export const lockedBadge = (entityType: EntityTypes): ReactElement => {
  return (
    <EntityBadge
      title={`This ${entityType} is currently being reviewed by our Profile Approval team.`}
      icon={<LockOutlined />}
      content={
        <div data-testid={`${entityType}-locked-badge-modal-content`}>
          <p>
            This {entityType} is under review by a profile approval team member.
            You can’t edit this {entityType} now, so please check back later. If
            you have any questions, please contact us at 844-566-2886.
          </p>
        </div>
      }
    />
  );
};

export const renderListingLockedBadge = (
  listing: Listing,
): ReactElement | null => {
  return isListingLocked(listing) ? lockedBadge('listing') : null;
};

export const renderParentLockedBadge = (
  parent: Parent,
): ReactElement | null => {
  return isParentLocked(parent) ? lockedBadge('parent') : null;
};

export const pendingEditBadge = (): ReactElement => {
  return (
    <EntityBadge
      title="Updates pending for review"
      icon={<HourglassOutlined />}
      content={
        <div data-testid={testId.pendingEditsBadgeModalContent}>
          <p>
            A card marked with the hourglass icon means the last updates sent
            are pending for review, so its content is not updated. Still, you
            can perform any upcoming updates.
          </p>
        </div>
      }
    />
  );
};

export const renderListingPendingEditBadge = (
  listing: Listing,
): ReactElement | null => {
  const { puppy, hasPendingEdits } = listing;

  if (isDelivered(puppy)) return null;
  if (isPending(puppy)) return null;
  if (isListingLocked(listing)) return null;

  return hasPendingEdits ? pendingEditBadge() : null;
};

export const renderParentPendingEditBadge = (
  parent: Parent,
): ReactElement | null => {
  return parent.hasPendingEdits ? pendingEditBadge() : null;
};
