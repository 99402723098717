import React from 'react';
import { Form, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import {
  puppyDueDatesIsPastDue,
  puppyDueDatesIsUpToDate,
} from 'utilities/puppy/puppyDueDates/puppyDueDates';
import { DueDate } from 'shared/types/date';

interface Props {
  puppyWeightRangeId: number | undefined;
  dueDates: DueDate[];
  disabled?: boolean;
}

export const FieldWeightSameRage = ({
  puppyWeightRangeId,
  dueDates,
  disabled,
}: Props) => {
  return (
    <Form.Item
      shouldUpdate={(prevValues, curValues) => {
        return (
          prevValues.weightRangeId !== curValues.weightRangeId ||
          prevValues.puppyWeightSameRange !== curValues.puppyWeightSameRange
        );
      }}
      noStyle
    >
      {(form) => {
        if (
          !(puppyWeightRangeId !== form.getFieldValue('weightRangeId')) &&
          puppyDueDatesIsPastDue(dueDates, 'Weight') &&
          !puppyDueDatesIsUpToDate(dueDates, 'Weight')
        ) {
          return (
            <Form.Item
              valuePropName="checked"
              name="puppyWeightSameRange"
              style={{ marginTop: -24 }}
            >
              <Checkbox
                disabled={disabled}
                onChange={(value: CheckboxChangeEvent) =>
                  form.setFieldsValue({
                    puppyWeightSameRange: value.target.checked,
                  })
                }
              >
                The puppy’s weight remains in the same range.
              </Checkbox>
            </Form.Item>
          );
        }
      }}
    </Form.Item>
  );
};
