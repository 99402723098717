import { isEmpty, omit } from 'lodash';
import { ParentsState } from 'store/parents/types';
import { Litter, LitterFormFields } from 'shared/types/litter';
import showNotificationError from 'shared/notifications/showNotificationError';
import userStorage from 'utilities/user/userStorage';
import apiLitter from 'utilities/http/api/apiLitter';
import litterFormHelpers from './litterFormHelpers';

const litterFormCreate = {
  createLitter: async (litter: LitterFormFields, parents: ParentsState) => {
    try {
      const litterPayload = await litterFormCreate.litterPayloadBuilder(
        litter,
        parents,
      );

      const respLitterId = await litterFormCreate.postLitter(litterPayload);
      return respLitterId;
    } catch (error) {
      showNotificationError({
        error: "Couldn't create litter",
      });
    }
  },

  litterPayloadBuilder: async (
    litter: LitterFormFields,
    parents: ParentsState,
  ) => {
    let litterBreedId;
    if (parents.dam.breedId !== parents.sire.breedId) {
      const respHybridBreed = await apiLitter.getHybridBreed(
        parents.sire.breedId as number,
        parents.dam.breedId as number,
      );
      litterBreedId = respHybridBreed.json?.hybridBreedId;
    } else {
      litterBreedId = parents.dam.breedId;
    }
    let newLitter = litter as Partial<Litter>;
    newLitter.breederId = userStorage.getBreederId();
    newLitter.breedId = litterBreedId;
    newLitter.damId = parents.dam.id;
    newLitter.sireId = parents.sire.id;
    newLitter.neurologicalStimulations = litterFormHelpers.buildNeurologicalStimulations(
      litter,
    );
    newLitter = omit(newLitter, ['otherNeurologicalStimulationDescription']);

    return newLitter;
  },

  postLitter: async (litter: Partial<Litter>) => {
    const resp = await apiLitter.postLitter(litter);
    const respError = resp?.json?.errors;
    if (!isEmpty(respError) || resp.status === 500) {
      showNotificationError({ error: "Couldn't create a new litter" });
    }
    return resp?.json?.litter?.id;
  },
};

export default litterFormCreate;
