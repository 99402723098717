import React, { ReactElement } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import NewLitter from 'pages/newLitter/NewLitter';
import Dashboard from 'pages/dashboard/Dashboard';
import NewParent from 'pages/newParent/NewParent';
import NewListing from 'pages/newListing/NewListing';
import MyParents from 'pages/myParents/MyParents';
import MyLitters from 'pages/myLitters/MyLitters';
import MyListings from 'pages/myListings/MyListings';
import MyListingsPending from 'pages/myListingsPending/MyListingsPending';
import MyListingsApproved from 'pages/myListingsApproved/MyListingsApproved';
import MyListingsDelivered from 'pages/myListingsDelivered/MyListingsDelivered';
import MyListingsSold from 'pages/myListingsSold/MyListingsSold';
import Transactions from 'pages/transactions/Transactions';
import ParentDetail from 'pages/parentDetail/ParentDetail';
import LitterDetail from 'pages/litterDetail/LitterDetail';
import MyUpdates from 'pages/myUpdates/MyUpdates';
import PuppyDetail from 'pages/puppyDetail/PuppyDetail';
import MyAccount from 'pages/myAccount/MyAccount';
import StaticContent from 'pages/staticsPages/StaticContent';
import NotFound from 'pages/notFound/NotFound';
import { BreederRoutes } from 'shared/types/routes';
import { staticContentPagesRoutesHandler } from 'shared/routes/staticContentPagesRoutesHandler';
import MyListingsHidden from 'pages/myListingHidden/MyListingHidden';

export default function RoutesHandler(): ReactElement {
  return (
    <Switch>
      <Route path={BreederRoutes.Dashboard} component={Dashboard} />
      <Route
        path={BreederRoutes.NewDam}
        render={() => <NewParent gender="dam" />}
      />
      <Route
        path={BreederRoutes.NewSire}
        render={() => <NewParent gender="sire" />}
      />
      <Route path={BreederRoutes.NewPuppy} component={NewListing} />
      <Route path={BreederRoutes.NewLitter} component={NewLitter} />
      <Route path="/puppy/:id" component={PuppyDetail} />
      <Route path="/litter/:id" component={LitterDetail} />
      <Route path="/parent/:gender/:id" component={ParentDetail} />

      <Route
        path={BreederRoutes.MyListingsPending}
        component={MyListingsPending}
      />
      <Route
        path={BreederRoutes.MyListingsApproved}
        component={MyListingsApproved}
      />
      <Route
        path={BreederRoutes.MyListingsDelivered}
        component={MyListingsDelivered}
      />
      <Route
        path={BreederRoutes.MyListingsHidden}
        component={MyListingsHidden}
      />
      <Route path={BreederRoutes.MyListingsSold} component={MyListingsSold} />
      <Route path={BreederRoutes.MyListings} component={MyListings} />

      <Route path={BreederRoutes.MyParents} component={MyParents} />
      <Route path={BreederRoutes.MyLitters} component={MyLitters} />
      <Route path={BreederRoutes.MyUpdates} component={MyUpdates} />
      <Route path={BreederRoutes.MyAccount} component={MyAccount} />
      <Route path={BreederRoutes.Transactions} component={Transactions} />
      {staticContentPagesRoutesHandler.map((page) => (
        <Route
          key={page.nameContent}
          path={page.path}
          render={() => (
            <StaticContent
              title={page.title}
              nameContent={page.nameContent}
              pageType={page.pageType}
            />
          )}
        />
      ))}
      <Route path={BreederRoutes.NotFound} component={NotFound} />
      <Redirect from={BreederRoutes.Login} exact to={BreederRoutes.Dashboard} />
      <Redirect from="/" exact to={BreederRoutes.Dashboard} />
      <Redirect from="*" exact to={BreederRoutes.NotFound} />
    </Switch>
  );
}
