import apiSystemSetting from 'utilities/http/api/apiSystemSetting';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';

interface SystemSetting {
  isHealthFeeEnabled: boolean;
  isUploadVideosToCloudflareEnabled: boolean;
}

export const getSystemSetting = async (): Promise<SystemSetting> => {
  const respIsHealthFeeEnabled = await apiSystemSetting.getSetting(
    'is-health-fee-feature-enabled',
  );
  const respIsUploadVideosToCloudflareEnabled = await apiSystemSetting.getSetting(
    'upload-videos-directly-to-cloudflare',
  );

  return {
    isHealthFeeEnabled: Boolean(respIsHealthFeeEnabled.json.value),
    isUploadVideosToCloudflareEnabled: Boolean(
      respIsUploadVideosToCloudflareEnabled.json.value,
    ),
  };
};

export const isHealthFeeEnabled = (): boolean => {
  const systemSetting = staticContentStorage.getContent().systemSetting;
  return Boolean(systemSetting?.isHealthFeeEnabled);
};
