import React from 'react';

import TravelInfoItem from 'shared/puppy/puppyTravel/TravelInfoItem';
import {
  ChaperoneTravelProposal,
  TravelProposals,
} from 'shared/types/puppyTravel';
import { testId } from 'testsUtilities/dataTestId';
import { getContentDataById } from 'utilities/staticContent/content';
import date from 'utilities/time/date';

interface Props {
  chaperoneProposal: ChaperoneTravelProposal;
  proposalData: Partial<TravelProposals>;
}

function TravelProposalInfoChaperone(props: Props) {
  const { chaperoneProposal, proposalData } = props;
  return (
    <section data-testid={testId.travelProposalInfoChaperone}>
      <TravelInfoItem
        label="Origin airport"
        value={
          chaperoneProposal.originAirport ? (
            <>
              <b>
                {
                  getContentDataById(
                    'states',
                    chaperoneProposal?.originAirport?.stateId,
                  )?.abbr
                }
              </b>
              {chaperoneProposal?.originAirport?.name}
            </>
          ) : (
            'TBD'
          )
        }
      />

      <TravelInfoItem
        label="Arrival airport"
        value={
          <>
            <b>
              {
                getContentDataById(
                  'states',
                  chaperoneProposal?.destinationAirport?.stateId,
                )?.abbr
              }
            </b>
            {chaperoneProposal?.destinationAirport?.name}
          </>
        }
      />

      <TravelInfoItem
        label="Arrival date"
        value={date.toFullMonthDDYYYY(proposalData?.arrivalTime)}
      />

      <TravelInfoItem
        label="Departure date"
        value={date.toFullMonthDDYYYY(proposalData?.departureTime)}
      />

      <TravelInfoItem
        label="Breeder Drop Off Location"
        value={chaperoneProposal?.dropOffLocation}
      />

      <TravelInfoItem
        label="Chaperone"
        value={chaperoneProposal?.puppyChaperoneCompany?.name}
      />
    </section>
  );
}

export default TravelProposalInfoChaperone;
