import React, { ReactElement, useEffect, useState } from 'react';
import { StaticPageProps } from 'shared/staticPage/StaticPage';
import date from 'utilities/time/date';
import apiBreeder from 'utilities/http/api/apiBreeder';
import showNotificationError from 'shared/notifications/showNotificationError';
import { getContentDataById } from 'utilities/staticContent/content';

export default function PdfContent(props: StaticPageProps): ReactElement {
  const { staticData } = props;
  const [isFlorida, setIsFlorida] = useState(false);

  const [content, setContent] = useState({
    datePublished: '',
    descriptionPdfTemplate: '',
    floridaPdf: '',
    generalPdf: '',
  });

  const urlPdf = isFlorida ? content?.floridaPdf : content?.generalPdf;

  const fetchStateData = async () => {
    try {
      const breederResponse = await apiBreeder.getBreederInformation();
      const breederInfoAddress = breederResponse.json.breeder.addresses[0];
      const countryId = breederInfoAddress.countryId;
      const stateData = breederInfoAddress.stateId;
      const countryAbbreviation = getContentDataById('countries', countryId)
        .abbr;
      const stateFloridaId = 12;

      if (stateData === stateFloridaId && countryAbbreviation === 'US') {
        setIsFlorida(true);
      }
    } catch (error) {
      showNotificationError({ error: error.message });
    }
  };

  useEffect(() => {
    fetchStateData();
  }, []);

  useEffect(() => {
    setContent({ ...staticData });
  }, [staticData]);

  return (
    <div className="StaticPage StaticPage--simplepage">
      {content.datePublished && (
        <p>
          Last Updated:
          {` ${date.timeZone(staticData.datePublished, 'America/New_York')}`}
        </p>
      )}
      <div
        dangerouslySetInnerHTML={{ __html: content.descriptionPdfTemplate }}
      />
      {content.floridaPdf && content.generalPdf && (
        <a href={urlPdf} target="_blank" rel="noopener noreferrer">
          View PDF
        </a>
      )}
    </div>
  );
}
