import React from 'react';

import { MeetupSequence } from 'shared/types/puppyTravel';
import TravelInfoItem from 'shared/puppy/puppyTravel/TravelInfoItem';
import { testId } from 'testsUtilities/dataTestId';
import date from 'utilities/time/date';

interface Props {
  meetUpSequence: MeetupSequence;
}

function TravelSequenceInfoMeetUp(props: Props) {
  const { meetUpSequence } = props;

  return (
    <section data-testid={testId.travelSequenceInfoMeetUp}>
      <TravelInfoItem
        label="Date"
        value={date.toFullMonthDDYYYY(meetUpSequence.pickupTime)}
      />

      <TravelInfoItem
        label="Time"
        value={date.toTimeHMMA(meetUpSequence.pickupTime)}
      />

      <TravelInfoItem label="Address" value={meetUpSequence.address} />

      {meetUpSequence.address2 && (
        <TravelInfoItem label="Address 2" value={meetUpSequence.address2} />
      )}

      <TravelInfoItem label="City" value={meetUpSequence.city} />

      <TravelInfoItem label="State" value={meetUpSequence.state.name} />
    </section>
  );
}

export default TravelSequenceInfoMeetUp;
