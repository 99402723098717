import { GenderId, Genders, MyParentsTypes, Parent } from 'shared/types/parent';
import date from 'utilities/time/date';
import { Litter } from 'shared/types/litter';
import { isEmpty } from 'lodash';

const inactiveNumber = 0;

export enum StatusReasonId {
  Created = 1,
  Live = 2,
  Retired = 3,
  Removed = 4,
}

export const isDam = (p: Parent) => p?.genderId === Genders.dam;

export const isSire = (p: Parent) => p?.genderId === Genders.sire;

export const isRetired = (p: Parent) =>
  p?.statusReasonId === StatusReasonId.Retired;

export const isInactive = (p: Parent) => p?.isActive === inactiveNumber;

export const isActive = (p: Parent) => !isInactive(p);

export const hasLiveListings = (p: Parent): boolean =>
  Boolean(p?.liveListings && p?.liveListings > 0);

export const hasActiveLitter = (p: Parent): boolean => {
  const litters = p?.litters.map((l: Partial<Litter>) => l.birthDate);
  const fiveMonths = litters?.filter((d) =>
    Boolean(date.diff(new Date(), d, 'months') <= 5),
  );
  return !isEmpty(fiveMonths);
};

export const hasSoldListings = (p: Parent): boolean =>
  Boolean(p?.soldListings > 0);

export const genderLabel = (p: Partial<Parent> | GenderId | undefined) => {
  if (typeof p === 'object') return p?.genderId === 1 ? 'sire' : 'dam';

  const genderId = p;
  return genderId === 1 ? 'sire' : 'dam';
};

export const gendersLabelPlural = (
  p: Partial<Parent> | GenderId | undefined,
): MyParentsTypes => {
  if (typeof p === 'object') return p?.genderId === 1 ? 'sires' : 'dams';

  const genderId = p;
  return genderId === 1 ? 'sires' : 'dams';
};
