import React, { ReactElement } from 'react';
import { Alert } from 'antd';

interface Props {
  hasVideo: boolean;
}

export default function PuppyVideoLabel(props: Props): ReactElement {
  return (
    <div
      className={`ant-form-item-label ${props.hasVideo ? '' : 'without-video'}`}
    >
      <label className="PuppyVideo__label">Add a video (max 1)</label>
      <p className="PuppyVideo__description">
        A video helps place puppies faster than photos only.
      </p>
      {!props.hasVideo && (
        <Alert
          message="Listings are 1.7x more likely to convert if a puppy video is uploaded."
          type="warning"
          showIcon
        />
      )}
    </div>
  );
}
