import React, { ReactElement } from 'react';
import { Form, Select } from 'antd';
import { FieldChampionLineageValidationRules } from './FieldChampionLineageValidation';
import './FieldChampionLineage.scss';

interface Props {
  disabled?: boolean;
  warning?: React.ReactNode;
}

export function FieldChampionLineage(props: Props): ReactElement {
  const { disabled, warning } = props;
  return (
    <>
      <Form.Item
        name="isChampion"
        label="Champions within the 3 past generations"
        rules={[FieldChampionLineageValidationRules]}
      >
        <Select
          style={{ width: '33%' }}
          disabled={disabled}
          data-testid="field-champion"
        >
          <Select.Option value={0}>No</Select.Option>
          <Select.Option value={1}>Yes</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item className="FieldChampionLineage__is-champion-warning">
        {disabled && warning}
      </Form.Item>
    </>
  );
}
