import React, { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, PageHeader, Space } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import PageLayout from 'shared/baseLayout/pageLayout/PageLayout';
import { BreederRoutes } from 'shared/types/routes';
import {
  fetchMyListingsHidden,
  setMyListingsHiddenInitialState,
} from 'store/myListingsHidden/actions.thunk';
import {
  setMyListingsHiddenFilters,
  setMyListingsHiddenPagination,
  setMyListingsHiddenSorter,
} from 'store/myListingsHidden/actions';
import {
  initialStateMyListingsHiddenFilters,
  initialStateMyListingsHiddenSorter,
} from 'store/myListingsHidden/myListingsHiddenReducer';
import MyListingsTableHidden from 'components/myListings/myListiningsTable/myListingsTableHidden/MyListingsTableHidden';
import MyListingsCardsHidden from 'components/myListings/myListingsCards/myListingsCardsHidden/MyListingsCardsHidden';
import { RootState } from 'store/rootState';
import useListingUpdatePrice from 'hooks/useListingUpdatePrice/useListingUpdatePrice';
import UpdatePriceModal from 'components/myListings/updatePriceModal/UpdatePriceModal';

const breadcrumb = [
  {
    url: BreederRoutes.MyListings,
    name: 'My Puppy Listings',
  },
  {
    name: 'Hidden',
  },
];

function MyListingsHidden(): ReactElement {
  const {
    myListingsHidden: { loading, pagination, sorter, filters },
  } = useSelector((state: RootState) => state);
  const { current: currentPage, pageSize, loadMore } = pagination;
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const { modalVisible, setModalVisible, openModal } = useListingUpdatePrice();

  const handleClearFilters = () => {
    dispatch(setMyListingsHiddenSorter(initialStateMyListingsHiddenSorter));
    dispatch(setMyListingsHiddenFilters(initialStateMyListingsHiddenFilters));
    dispatch(
      setMyListingsHiddenPagination({
        ...pagination,
        current: 1,
      }),
    );
  };

  const refreshListings = useCallback(() => {
    dispatch(fetchMyListingsHidden());
  }, [dispatch]);

  useEffect(() => {
    !loadMore && refreshListings();
  }, [
    currentPage,
    pageSize,
    loadMore,
    sorter.sortBy,
    sorter.sortDirection,
    filters,
    refreshListings,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setMyListingsHiddenInitialState());
    };
  }, [dispatch]);

  return (
    <PageLayout
      className="MyListings MyListings--hidden"
      breadCrumbsNav={breadcrumb}
    >
      <PageHeader
        className="MyListings__page-header"
        ghost={false}
        onBack={() => window.history.back()}
        title="Hidden"
        extra={
          screens.xl ? (
            <Space>
              <Button
                type="link"
                onClick={handleClearFilters}
                disabled={
                  loading || (!sorter.isSorting && !filters.isFiltering)
                }
              >
                Clear filters
              </Button>
            </Space>
          ) : null
        }
      />

      <div className="MyListings__page-content">
        {screens.xl ? (
          <MyListingsTableHidden openModal={openModal} />
        ) : (
          <MyListingsCardsHidden openModal={openModal} />
        )}
      </div>
      <UpdatePriceModal
        callback={refreshListings}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </PageLayout>
  );
}

export default MyListingsHidden;
