import { Breeder } from 'shared/types/breeder';
import { SetStates, SetBreeder, SetCountry } from 'store/breeder/actions';
import { State } from 'shared/types/geolocation';

export const SET_BREEDER = 'SET_BREEDER';
export const SET_STATES = 'SET_STATES';
export const SET_COUNTRY = 'SET_COUNTRY';

export interface BreederState {
  breeder?: Breeder;
  states?: State[];
}

export type BreederActions = SetBreeder | SetStates | SetCountry;
