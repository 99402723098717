import apiBreeder from 'utilities/http/api/apiBreeder';
import showNotificationError from 'shared/notifications/showNotificationError';
import userStorage from 'utilities/user/userStorage';

export async function getBreederPaymentsByDate(
  dateBegin: string,
  dateEnd: string,
) {
  try {
    const res = await apiBreeder.getPaymentsByDateRange(
      userStorage.getBreederId(),
      dateBegin,
      dateEnd,
    );

    const objectToArray = Array.from(Object.values(res.json.breederPayments));
    return objectToArray;
  } catch (e) {
    showNotificationError({
      error: "Couldn't load your transactions, please try again",
    });
  }
}
