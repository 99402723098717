import { applyMiddleware, compose, createStore, StoreEnhancer } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from 'store/rootState';
import { isProduction } from 'utilities/environmentVariables';

export default function configureStore() {
  const middleWares = [thunkMiddleware];

  const enhancers = [applyMiddleware(...middleWares)];

  let composeWithEnhancers: StoreEnhancer<unknown>;
  if (isProduction()) {
    composeWithEnhancers = compose(...enhancers);
  } else {
    composeWithEnhancers = composeWithDevTools(...enhancers);
  }

  return createStore(rootReducer, composeWithEnhancers);
}
