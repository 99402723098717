import React, { ReactElement, ReactNode } from 'react';

import { AuthorizationFooter } from './AuthorizationFooter';
import { AuthorizationHeader } from './AuthorizationHeader';
import loginImg from 'assets/img/login-img.svg';
import './AuthorizationForm.scss';

interface Props {
  children?: ReactNode;
  title?: string | undefined;
  subtitle?: string;
  mainPage: boolean;
  className?: string;
}

export default function AuthorizationForm(props: Props): ReactElement {
  const { children, title, subtitle, mainPage, className } = props;
  const centerContent = mainPage ? '' : 'Authorization__no-main-page';

  return (
    <div className={`Authorization ${className}`}>
      {mainPage && (
        <div className="Authorization__img-wrap--xl">
          <img
            className="Authorization__img--xl"
            alt="puppy illustration"
            src={loginImg}
          />
        </div>
      )}
      <div className={`Authorization__margin--xl ${centerContent}`}>
        <AuthorizationHeader
          title={title}
          subtitle={subtitle}
          mainPage={mainPage}
        />
        {children}
        <AuthorizationFooter mainPage={mainPage} />
      </div>
    </div>
  );
}
