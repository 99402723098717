import React, { ReactElement, useEffect, useState } from 'react';
import { Store } from 'antd/lib/form/interface';
import { useSelector } from 'react-redux';

import PuppyVideoLabel from './PuppyVideoLabel';
import PuppyVideoUpload from './puppyVideoUpload/PuppyVideoUpload';
import { getExistingVideo } from 'utilities/media/mediaCheckers';
import { Media, Nullable } from 'shared/types';
import { RootState } from 'store/rootState';
import { useCloudflareVideo } from 'hooks';

interface Props {
  setFieldsValue: (value: Store) => void;
  disabled?: boolean;
  isEditing: boolean;
}

export default function PuppyVideo(props: Props): ReactElement {
  const { setFieldsValue, disabled } = props;

  const [video, setVideo] = useState<Nullable<Media>>();
  const { puppy } = useSelector((state: RootState) => state);
  const existingVideo = getExistingVideo(puppy.listing?.media);
  const cloudflareVideo = useCloudflareVideo(existingVideo);

  useEffect(() => {
    setFieldsValue({
      puppyVideo: video ? [video] : [],
    });
  }, [video, setFieldsValue]);

  useEffect(() => {
    if (cloudflareVideo.cloudflareVideoSrc) {
      setVideo(existingVideo);
    }
  }, [existingVideo, cloudflareVideo]);

  return (
    <section className="PuppyVideo">
      <PuppyVideoLabel hasVideo={!!video} />
      <PuppyVideoUpload
        setVideo={setVideo}
        video={video}
        disabled={disabled}
        cloudflareVideo={cloudflareVideo}
      />
    </section>
  );
}
