import React from 'react';
import { Badge, Button, Tooltip } from 'antd';
import {
  CameraOutlined,
  CheckCircleFilled,
  DashboardOutlined,
  DownloadOutlined,
  ExclamationCircleFilled,
  HeartOutlined,
  ReadOutlined,
} from '@ant-design/icons';
import { isEmpty } from 'lodash';

import { DueDate } from 'shared/types/date';
import { DueDatesItems } from 'shared/types/general';
import { Media } from 'shared/types/media';
import { Icon } from 'shared/icon/Icon';
import {
  puppyDueDatesIsPastDue,
  puppyDueDatesIsUpToDate,
} from 'utilities/puppy/puppyDueDates/puppyDueDates';

import './UpToDateInfoBarButton.scss';
import { Listing, Puppy } from '../../../../../shared/types';
import { getPuppyBreedTypeId } from '../../../../../utilities/puppy/getPuppyUtils/getPuppyUtils';

interface UpToDateInfoBarButtonBase {
  btnType: DueDatesItems;
  dueDates: DueDate[] | undefined;
  copyLabel?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  listing?: Partial<Listing>;
  puppy?: Partial<Puppy>;
}

interface UpToDateInfoBarButtonTypePhotos {
  btnType: 'Photos';
  media: Media[] | undefined;
}

interface UpToDateInfoBarButtonTypeOther {
  btnType: 'Weight' | 'Registration' | 'Health' | 'Travel' | 'Docs';
  media?: Media[] | undefined;
}

export type UpToDateInfoBarButtonProps = UpToDateInfoBarButtonBase &
  (UpToDateInfoBarButtonTypeOther | UpToDateInfoBarButtonTypePhotos);

function UpToDateInfoBarButton(props: UpToDateInfoBarButtonProps) {
  const {
    dueDates,
    onClick,
    btnType,
    disabled,
    copyLabel,
    media,
    listing,
    puppy,
  } = props;

  const setBadgeIcon = () => {
    if (!dueDates || btnType === 'Docs') return;

    if (btnType === 'Travel' || btnType === 'Health') {
      return (
        <ExclamationCircleFilled className="badge-icon badge-icon--pending" />
      );
    }

    if (btnType === 'Photos' && isEmpty(media)) return;

    if (btnType === 'Registration') {
      let registrations = null;
      let birthDate = null;

      let isDesignerBreed = false;

      if (listing) {
        registrations = listing?.puppy?.registrations;
        birthDate = listing?.puppy?.litter?.birthDate;
        isDesignerBreed =
          getPuppyBreedTypeId(listing?.puppy) === 2 ||
          getPuppyBreedTypeId(listing?.puppy) === 3;
      } else if (puppy) {
        registrations = puppy?.registrations;
        birthDate = puppy?.litter?.birthDate;
        isDesignerBreed =
          getPuppyBreedTypeId(puppy) === 2 || getPuppyBreedTypeId(puppy) === 3;
      }

      if (isDesignerBreed) {
        return;
      }

      if (birthDate) {
        const eightWeeksAgo = new Date();
        eightWeeksAgo.setDate(eightWeeksAgo.getDate() - 56); // 8 weeks * 7 days

        if (new Date(birthDate) > eightWeeksAgo) {
          return (
            <CheckCircleFilled className="badge-icon badge-icon--success" />
          );
        }
      }

      if (Array.isArray(registrations) && registrations.length > 0) {
        const currentRegistration = registrations[0];

        if (
          currentRegistration?.registrationNumber ||
          listing?.puppy?.pendingEdit?.registrations?.[0]?.registrationNumber ||
          puppy?.pendingEdit?.registrations?.[0]?.registrationNumber
        ) {
          return (
            <CheckCircleFilled className="badge-icon badge-icon--success" />
          );
        }
      }

      if (puppyDueDatesIsPastDue(dueDates, btnType)) {
        return (
          <ExclamationCircleFilled className="badge-icon badge-icon--error" />
        );
      }

      return (
        <ExclamationCircleFilled className="badge-icon badge-icon--warning" />
      );
    }

    if (puppyDueDatesIsUpToDate(dueDates, btnType)) {
      return <CheckCircleFilled className="badge-icon badge-icon--success" />;
    }

    if (puppyDueDatesIsPastDue(dueDates, btnType)) {
      return (
        <ExclamationCircleFilled className="badge-icon badge-icon--error" />
      );
    }

    return (
      <ExclamationCircleFilled className="badge-icon badge-icon--warning" />
    );
  };

  const setButtonIcon = () => {
    if (btnType === 'Weight') {
      return <DashboardOutlined className="btn-icon" />;
    }

    if (btnType === 'Registration') {
      return <ReadOutlined className="btn-icon" />;
    }

    if (btnType === 'Photos') {
      return <CameraOutlined className="btn-icon" />;
    }

    if (btnType === 'Health') {
      return <HeartOutlined className="btn-icon" />;
    }

    if (btnType === 'Travel') {
      return <Icon name="travelCase" className="btn-icon" />;
    }

    if (btnType === 'Docs') {
      return <DownloadOutlined className="btn-icon" />;
    }
  };

  const setCopyLabel = () => {
    if (!dueDates) return;
    if (!puppyDueDatesIsUpToDate(dueDates, btnType)) {
      if (btnType === 'Weight') {
        return 'Weight needs updates';
      }

      if (btnType === 'Photos') {
        return 'Photos need updates';
      }
    }

    if (btnType === 'Registration') {
      let isDesignerBreed = false;
      let registrations = null;
      if (listing) {
        registrations = listing?.puppy?.registrations;
        isDesignerBreed =
          getPuppyBreedTypeId(listing?.puppy) === 2 ||
          getPuppyBreedTypeId(listing?.puppy) === 3;
      } else if (puppy) {
        registrations = puppy?.registrations;
        isDesignerBreed =
          getPuppyBreedTypeId(puppy) === 2 || getPuppyBreedTypeId(puppy) === 3;
      }

      if (isDesignerBreed) {
        return;
      }

      if (Array.isArray(registrations) && registrations.length > 0) {
        const currentRegistration = registrations[0];

        if (currentRegistration?.registrationNumber) {
          return 'Registry Updated';
        }
      }

      if (
        listing?.puppy?.pendingEdit?.registrations?.[0]?.registrationNumber ||
        puppy?.pendingEdit?.registrations?.[0]?.registrationNumber
      ) {
        return 'Registry Updated';
      }

      return 'Registry needs updates';
    }

    return `${btnType} updated`;
  };

  return (
    <Button
      className="UpToDateInfoBarButton"
      onClick={onClick}
      disabled={disabled}
      data-testid={`up-to-date-info-bar-button-${btnType.toLowerCase()}`}
    >
      <Tooltip title={btnType}>
        <Badge count={setBadgeIcon()}>{setButtonIcon()}</Badge>
      </Tooltip>
      {copyLabel && <p>{setCopyLabel()}</p>}
    </Button>
  );
}

export default UpToDateInfoBarButton;
