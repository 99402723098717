import { Breed, Food, Id, Nullable, ZeroOrOne } from 'shared/types/general';
import { Parent } from 'shared/types/parent';
import { Puppy } from 'shared/types/puppy';

export interface NeuroStimulation {
  id: number;
  otherNeurologicalStimulationDescription?: string | number | null;
}

export interface LitterFormFields<T = number[]> {
  birthDate: string;
  food: Partial<Food>;
  isCrateTrained: number;
  neurologicalStimulations?: T;
  otherNeurologicalStimulationDescription?: string;
  pottyTrainingTypes?: number[];
  size?: number;
  socializations?: number[];
}

export interface LitterToUpdateFields
  extends LitterFormFields<NeuroStimulation[]> {
  breederId?: Id;
  breed?: Partial<Breed>;
  breedId?: Id;
  sireId?: Id;
  damId?: Id;
}

export interface Litter {
  id?: Id;
  dam?: Partial<Parent>;
  sire?: Partial<Parent>;
  puppies?: Puppy[];
  puppyCount?: number;
  damId?: Id;
  sireId?: Id;
  breederId?: Id;
  breed?: Partial<Breed>;
  breedId?: Id;
  birthDate?: string;
  size?: Nullable<number>;
  food?: Partial<Food>;
  neurologicalStimulations?: NeuroStimulation[];
  pottyTrainingTypes?: Nullable<number[]>;
  pendingPuppiesInLitter?: Puppy[];
  socializations?: Nullable<number[]>;
  isCrateTrained?: ZeroOrOne;
  varietyId?: Id;
  updatedAt?: Nullable<string>;
  createdAt?: Nullable<string>;
  status?: LitterStatus;
  generationId?: Nullable<number>;
  deletedAt?: Nullable<string>;
  hasApprovedListings?: number;
  recommendedPrice?: number;
  minRecommendedPrice?: number;
  maxRecommendedPrice?: number;
}

export interface LitterParentChangePayload {
  damId?: Id;
  sireId?: Id;
}

export type LitterStatus = 'upcoming' | 'current' | 'past' | 'new';

export enum LitterStatuses {
  upcoming = 'upcoming',
  current = 'current',
  past = 'past',
  new = 'new',
}
