import React, { ReactElement } from 'react';
import { Form, Input } from 'antd';

export function FieldPuppyLitter(): ReactElement {
  return (
    <Form.Item name="litterId" noStyle>
      <Input hidden />
    </Form.Item>
  );
}
