import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, PageHeader, Space } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import {
  MyListingsTableDelivered,
  MyListingsCardsDelivered,
} from 'components/myListings';
import PageLayout from 'shared/baseLayout/pageLayout/PageLayout';
import { BreederRoutes } from 'shared/types/routes';
import {
  fetchMyListingsDelivered,
  setMyListingsDeliveredInitialState,
} from 'store/myListingsDelivered/actions.thunk';
import {
  setMyListingsDeliveredFilters,
  setMyListingsDeliveredPagination,
  setMyListingsDeliveredSorter,
} from 'store/myListingsDelivered/actions';
import {
  initialStateMyListingsDeliveredFilters,
  initialStateMyListingsDeliveredSorter,
} from 'store/myListingsDelivered/myListingsDeliveredReducer';
import { RootState } from 'store/rootState';

import './MyListingsDelivered.scss';

const breadcrumb = [
  {
    url: BreederRoutes.MyListings,
    name: 'My Puppy Listings',
  },
  {
    name: 'Delivered',
  },
];

export default function MyListingsDelivered(): ReactElement {
  const {
    myListingsDelivered: { loading, pagination, sorter, filters },
  } = useSelector((state: RootState) => state);
  const { current: currentPage, pageSize, loadMore } = pagination;
  const dispatch = useDispatch();
  const screens = useBreakpoint();

  const handleClearFilters = () => {
    dispatch(
      setMyListingsDeliveredSorter(initialStateMyListingsDeliveredSorter),
    );
    dispatch(
      setMyListingsDeliveredFilters(initialStateMyListingsDeliveredFilters),
    );
    dispatch(
      setMyListingsDeliveredPagination({
        ...pagination,
        current: 1,
      }),
    );
  };

  useEffect(() => {
    !loadMore && dispatch(fetchMyListingsDelivered());
  }, [
    currentPage,
    pageSize,
    loadMore,
    sorter.sortBy,
    sorter.sortDirection,
    filters,
    dispatch,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setMyListingsDeliveredInitialState());
    };
  }, [dispatch]);

  return (
    <PageLayout
      className="MyListings MyListings--delivered"
      breadCrumbsNav={breadcrumb}
    >
      <PageHeader
        className="MyListings__page-header"
        ghost={false}
        onBack={() => window.history.back()}
        title="Delivered"
        extra={
          screens.xl ? (
            <Space>
              <Button
                type="link"
                onClick={handleClearFilters}
                disabled={
                  loading || (!sorter.isSorting && !filters.isFiltering)
                }
              >
                Clear filters
              </Button>
            </Space>
          ) : null
        }
      >
        <span className="MyListings__page-header-content__subtitle">
          Puppies Delivered in the past year.
        </span>
      </PageHeader>
      <div className="MyListings__page-content">
        {screens.xl ? (
          <MyListingsTableDelivered />
        ) : (
          <MyListingsCardsDelivered />
        )}
      </div>
    </PageLayout>
  );
}
