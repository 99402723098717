import React from 'react';
import { useSelector } from 'react-redux';
import { Badge } from 'antd';
import { HourglassOutlined } from '@ant-design/icons';

import { RootState } from 'store/rootState';
import { Nullable } from 'shared/types/general';
import { Puppy } from 'shared/types/puppy';
import { Icon } from 'shared/icon/Icon';
import puppyTravelStatus from 'utilities/puppy/puppyTravelState';
import { testId } from 'testsUtilities/dataTestId';

import 'shared/puppy/puppyTravel/PuppyTravel.scss';
import date from 'utilities/time/date';

function copyEmpty(puppy: Puppy, currentStatusName: Nullable<string>) {
  if (puppyTravelStatus(puppy).isPuppyTravelRejected()) {
    return (
      <>
        <p>{`This travel plan was ${currentStatusName}.`}</p>
        <p>
          The PuppySpot Travel team is working on arranging travel for your
          puppy. We&apos;ll let you know as soon as we have a travel plan
          confirmed!
        </p>
        <p>
          In the meantime, don&apos;t forget to remove this puppy from any other
          external publications.
        </p>
      </>
    );
  } else {
    return (
      <>
        {puppy.order?.travels[0]?.travelProposals[0]?.departureTime && (
          <p>
            <b>
              Estimated departure date:{' '}
              {date.toMMDDYY(
                puppy.order?.travels[0].travelProposals[0].departureTime,
              )}
            </b>
          </p>
        )}
        <p>
          The PuppySpot Travel team is working on arranging travel for your
          puppy. We&apos;ll let you know as soon as we have a travel plan
          confirmed!
        </p>
        <p>
          In the meantime, don’t forget to remove this puppy from any other
          external publications.
        </p>
      </>
    );
  }
}

function PuppyTravelPreparing() {
  const puppy = useSelector((state: RootState) => state.puppy);

  const currentStatusName =
    puppy.order?.travels[0]?.workflowEntity?.currentStatus?.name;

  return (
    <>
      <section
        className="PuppyTravel_header"
        data-testid={testId.puppyTravelPlaning}
      >
        <h2 data-testid="puppy-travel-empty-title">
          We’re setting everything up!
        </h2>
      </section>
      <section className="PuppyTravel_body">
        <div
          className="PuppyTravel__empty"
          data-testid="puppy-travel-empty-copy"
        >
          {copyEmpty(puppy as Puppy, currentStatusName)}
          <Badge
            count={<HourglassOutlined className="PuppyTravel__empty-badge" />}
          >
            <Icon name="travelCase" className="PuppyTravel__empty-icon" />
          </Badge>

          <p>Planning travel</p>
        </div>
      </section>
    </>
  );
}

export default PuppyTravelPreparing;
