import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Empty } from 'antd';
import { isEmpty } from 'lodash';

import LoadingSection from 'components/loadingSection/LoadingSection';
import { HealthDocDrawer } from 'components/healthDocs';
import ListingCard from 'pages/myListings/listingCard/ListingCard';
import { Listing } from 'shared/types/puppy';
import { fetchMyListingsSold } from 'store/myListingsSold/actions.thunk';
import { setMyListingsSoldPagination } from 'store/myListingsSold/actions';
import { RootState } from 'store/rootState';
import { testId } from 'testsUtilities/dataTestId';

import '../MyListingsCards.scss';

function MyListingsCardsSold(): ReactElement {
  const [
    listingHealthDocsDrawer,
    setListingHealthDocsDrawer,
  ] = useState<Listing | null>(null);
  const {
    myListingsSold: { loading, listings, pagination },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const handleLoadMore = () => {
    dispatch(
      setMyListingsSoldPagination({
        ...pagination,
        current: pagination.current + 1,
        loadMore: true,
      }),
    );
    dispatch(fetchMyListingsSold());
  };

  const handleSetHealthDocsDrawer = (listing: Listing | null) => {
    setListingHealthDocsDrawer(listing);
  };

  useEffect(() => {
    if (!isEmpty(listingHealthDocsDrawer) && !isEmpty(listings)) {
      const listingToUpdate = listings.find(
        (l) => l.id === listingHealthDocsDrawer?.id,
      );
      if (listingToUpdate) {
        setListingHealthDocsDrawer(listingToUpdate as Listing);
      }
    }
  }, [listings, listingHealthDocsDrawer]);

  return (
    <section
      className="MyListingsCards__wrapper"
      data-testid={testId.myListingsSoldCardWrapper}
    >
      {loading && <LoadingSection />}
      {!loading && isEmpty(listings) && <Empty />}
      {listings.map((l) => (
        <ListingCard
          key={l.puppyId}
          listing={l}
          columnName="sold"
          setDrawerPuppy={handleSetHealthDocsDrawer}
        />
      ))}
      {!isEmpty(listings) && listings.length !== pagination.total && (
        <Button
          type="link"
          className="LoadMore"
          onClick={handleLoadMore}
          loading={loading}
        >
          Load More
        </Button>
      )}
      <HealthDocDrawer
        listing={listingHealthDocsDrawer}
        setDrawerPuppy={handleSetHealthDocsDrawer}
      />
    </section>
  );
}

export default MyListingsCardsSold;
