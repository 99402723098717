import React, { ReactElement } from 'react';
import { ReactComponent as Logo } from 'assets/img/breeder-hub.svg';

import './AsideLogo.scss';

export const AsideLogo = (props: { collapsed: boolean }): ReactElement => {
  const { collapsed } = props;
  return (
    <div
      className={`Navigation__ps-logo ${
        collapsed ? 'Navigation__ps-logo--uncollapsed' : ''
      }`}
    >
      <a href="/">
        <Logo />
      </a>
    </div>
  );
};
