import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Popover } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

import { RootState } from 'store/rootState';
import { testId } from 'testsUtilities/dataTestId';
import number from 'utilities/number/number';
import apiSystemSetting from 'utilities/http/api/apiSystemSetting';
import { isHealthFeeEnabled } from 'utilities/systemSetting/systemSetting';
import {
  isUserBreederTierCommitmentShortTerm,
  isUserBreederTierSpotOn,
} from 'utilities/user/userBreederCheckers/userBreederCheckers';
import { Listing } from 'shared/types';

import './PuppyHealthFee.scss';

interface Props {
  listingData?: Listing;
}

function HealthFeeLabel() {
  const content = (
    <div className="health-fee-popover-content">
      <p>
        <b>We&apos;ve got you covered!</b>
      </p>
      <p>
        You will not be obligated to make additional payments for any health
        claims that customers may have post sale.
      </p>
    </div>
  );
  return (
    <>
      Health Claim Fund
      <Popover content={content} trigger="hover">
        <ExclamationCircleOutlined />
      </Popover>
    </>
  );
}

function PuppyHealthFee({ listingData }: Props): ReactElement | null {
  const [feeAmount, setFeeAmount] = useState(0);
  const { listing } = useSelector((state: RootState) => state.puppy);

  const newListing = listingData || listing;

  useEffect(() => {
    const listingFees = newListing?.listingFees || [];

    if (!newListing || listingFees.length === 0) {
      let healthFeeSetting;

      if (isUserBreederTierSpotOn()) {
        healthFeeSetting = 'default-health-fee-for-spot-on-breeders';
      } else if (isUserBreederTierCommitmentShortTerm()) {
        healthFeeSetting =
          'default-health-fee-for-short-term-committed-breeders';
      } else {
        healthFeeSetting = 'default-health-fee-for-general-breeders';
      }

      apiSystemSetting.getSetting(healthFeeSetting).then((resp) => {
        setFeeAmount(resp.json.value);
      });
    }

    if (!isEmpty(listingFees)) {
      setFeeAmount(listingFees[0].feeAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newListing]);

  if (!isHealthFeeEnabled() || !feeAmount) {
    return null;
  }

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, curValues) =>
        prevValues.listing.cost !== curValues.listing.cost
      }
    >
      {(form) => {
        const cost = form.getFieldValue(['listing', 'cost']);
        const adjustedPrice = cost - feeAmount || undefined;

        return (
          <section
            className="puppy-health-fee"
            data-testid={testId.puppyHealthFee}
          >
            <Form.Item
              className="field-item-inline"
              label={<HealthFeeLabel />}
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
            >
              <span
                className="puppy-health-fee__value"
                data-testid={testId.puppyHealthFeeCoverageValue}
              >
                -{number.displayCurrency(feeAmount)}
              </span>
            </Form.Item>
            <Form.Item
              className="field-item-inline"
              label="Adjusted Breeder Price"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
            >
              <span
                className="puppy-health-fee__value puppy-health-fee__value--bold"
                data-testid={testId.puppyHealthFeeAdjustedAskingPriceValue}
              >
                {cost
                  ? number.displayCurrency(adjustedPrice || 0)
                  : number.displayCurrency(0)}
              </span>
            </Form.Item>
          </section>
        );
      }}
    </Form.Item>
  );
}

export default PuppyHealthFee;
