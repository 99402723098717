import React, { ReactElement } from 'react';
import './TextAreaCounter.scss';

interface Props {
  counter: number;
}

export default function TextAreaCounter(props: Props): ReactElement {
  const { counter } = props;

  return <p className="TextAreaCounter">{`${counter} Characters left`}</p>;
}
