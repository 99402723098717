import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { BreederDashboardStatusTypes } from 'shared/types/statuses';

interface Props {
  status?: BreederDashboardStatusTypes;
  color?: string;
  numberStatus?: number;
}

export default function PuppyInventoryItem(props: Props): ReactElement {
  const { status, numberStatus, color } = props;

  return (
    <div className="PuppyInventory__item">
      <Link to={`/my-listings/${status}`}>
        <p
          className={`PuppyInventory__circle PuppyInventory__circle--${color}`}
        >
          {numberStatus}
        </p>
        <span>{status}</span>
      </Link>
    </div>
  );
}
