import { Dispatch } from 'redux';

import { RootState } from 'store/rootState';
import { setPuppy } from 'store/puppy/actions';
import { Puppy } from 'shared/types/puppy';
import apiPuppy from 'utilities/http/api/apiPuppy';

export function updatePuppyDetailHealthDocs() {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const puppy = getState().puppy;

    if (puppy.id) {
      const puppyDocResponse = await apiPuppy.getPuppyHealthDocs(puppy.id);
      const documents = puppyDocResponse.json.documents;
      dispatch(setPuppy({ ...puppy, documents } as Puppy));
      return;
    }

    dispatch(setPuppy(puppy as Puppy));
  };
}
