import React from 'react';
import { Button, Form } from 'antd';

interface Props {
  submitLoading: boolean;
  submitText: string;
  formId: string;
}

const tailLayout = {
  wrapperCol: { span: 16 },
};

export default function FormItemSubmit(props: Props) {
  const { submitLoading, submitText, formId } = props;
  return (
    <Form.Item {...tailLayout} data-testid={`submit-${formId}`}>
      <Button
        block
        loading={submitLoading}
        type="primary"
        htmlType="submit"
        data-testid={`submit-btn-${formId}`}
      >
        {submitText}
      </Button>
    </Form.Item>
  );
}
