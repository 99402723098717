export const regExp = {
  nonDigit: /[^\d.-]/g,
  number2decimals: /(\.\d{2})\d+/g,
  number3Digits: /\B(?=(\d{3})+(?!\d))/g,
  fieldName: /^[a-zA-Z][a-zA-Z\s.']*$/,
  onlyNumber: /(^[0-9|\n\r]+$|^$)/,
  alphaAndNotSpecialChars: /^[a-zA-Z\s'.&]*$/,
  // only accepts: ###-###-####
  phoneFormat: /^(\d{3})-(\d{3})-(\d{4})$/g,
  onlyNumbersAndDashes: /[^\d-]/g,
  // USDA: ##-X-####
  usdaFormat: /^(\d{2})-([a-zA-Z])-(\d{4})$/g,
};
