import React, { ReactElement, useState } from 'react';
import { Alert, Form, notification } from 'antd';
import { Store } from 'rc-field-form/lib/interface';

import AuthorizationForm from 'shared/authorizationForm/AuthorizationForm';
import FormItemSubmit from 'shared/authorizationForm/formItems/FormItemSubmit';
import FieldPassword from 'shared/fields/fieldPassword/FieldPassword';
import { BreederRoutes } from 'shared/types/routes';
import apiUser, { Reset } from 'utilities/http/api/apiUser';
import userStorage from 'utilities/user/userStorage';
import { reactEnvironmentVariables } from 'utilities/environmentVariables';

import './ResetPassword.scss';

interface ResetPassword {
  token: string;
  email: string;
}

interface SubmitInfo {
  password: string;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default function RecoveryPassword(props: ResetPassword): ReactElement {
  const [errorAlert, setErrorAlert] = useState<string | null>(null);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const { token, email } = props;

  const onSubmit = async (info: SubmitInfo | Store) => {
    setSubmitLoading(true);
    const { password } = info;
    const body: Reset = {
      email: email,
      password,
      passwordConfirmation: password,
      token: token,
      application: reactEnvironmentVariables.APPLICATION_ID,
    };
    try {
      const authResponse = await apiUser.apiReset(body);

      if (authResponse.status !== 200)
        throw new Error('Wrong reset credentials. Please try again');

      setSubmitLoading(false);
      notification.open({
        message: 'Your new password has been saved',
        description: 'Please login again',
      });
      setTimeout(() => {
        window.location.replace(BreederRoutes.Login);
      }, 1500);
    } catch (e) {
      userStorage.removeUserAndToken();
      setErrorAlert(e.message);
      setSubmitLoading(false);
    }
  };
  return (
    <AuthorizationForm
      title="Password Recovery"
      mainPage={false}
      subtitle="Create and submit a new password"
      className="ResetPage"
    >
      <Form
        {...layout}
        className="ResetForm"
        size="large"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
      >
        {errorAlert && <Alert message={errorAlert} type="error" showIcon />}
        <FieldPassword placeholder="Enter your password" formId="reset" />
        <FormItemSubmit
          submitLoading={submitLoading}
          submitText="Submit"
          formId="reset"
        />
      </Form>
    </AuthorizationForm>
  );
}
