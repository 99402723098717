import React, { ReactElement, useState } from 'react';
import { Form, Upload, Modal, Empty } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { Stream } from '@cloudflare/stream-react';

import { UseCloudflareVideo } from 'hooks/useCloudflareVideo/useCloudflareVideo';
import { MediaUploaderBtn } from 'shared/fields/fieldMediaUploader/MediaUploaderBtn';
import { MediaUploaderItem } from 'shared/fields/fieldMediaUploader/MediaUploaderItem';
import { Icon } from 'shared/icon/Icon';
import { Media, Video, Nullable, SetNullableState } from 'shared/types';

interface Props {
  setVideo: SetNullableState<Media>;
  video: Nullable<Media | Video>;
  disabled?: boolean;
  cloudflareVideo: UseCloudflareVideo;
}

export default function PuppyVideoUpload(props: Props): ReactElement {
  const {
    video,
    setVideo,
    disabled,
    cloudflareVideo: { cloudflareVideoSrc, cloudflareVideoThumb },
  } = props;
  const [buffer, setBuffer] = useState<Nullable<string>>(null);
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  React.useEffect(() => {
    if (!modalVisibility) {
      setBuffer(null);
    }
  }, [modalVisibility, setBuffer]);

  const handleOpenPreviewVideo = () => {
    setModalVisibility(true);

    if (video?.uid) {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (video?.type?.includes('video')) {
          setBuffer(e.target?.result?.toString());
        }
      };

      reader.readAsDataURL(video as Blob);
    } else {
      const securedVideo = (video as Video)?.assets?.[0]?.url?.replace(
        'http://embed',
        'https://embed-ssl',
      );

      setBuffer(securedVideo);
    }
  };

  const setUploadVideo = (uploadVideo: UploadChangeParam) => {
    setVideo(uploadVideo.file);
  };

  const handleDeleteVideo = () => {
    setVideo(null);
  };

  return (
    <>
      {modalVisibility && (
        <Modal
          visible={modalVisibility}
          title="Video Player"
          okText="Close"
          onCancel={() => setModalVisibility(false)}
          onOk={() => setModalVisibility(false)}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          {cloudflareVideoSrc ? (
            <div className="cloudflareVideoWrapper">
              <Stream
                src={cloudflareVideoSrc}
                autoplay={true}
                muted={true}
                responsive={false}
                height="295"
                width="100%"
              />
            </div>
          ) : (
            <div className="previewVideo">
              {!Boolean(buffer) ? (
                <Icon name="spinner" />
              ) : (
                <video autoPlay controls>
                  <source src={buffer || ''} type="video/mp4" />
                </video>
              )}
            </div>
          )}
        </Modal>
      )}

      <Form.Item className="PuppyVideo__upload" name="puppyVideo">
        <Upload
          accept="video/*"
          beforeUpload={() => false}
          onChange={setUploadVideo}
          showUploadList={false}
          data-testid="upload-puppy-video-input"
          fileList={[]}
        >
          <MediaUploaderBtn
            disabled={disabled}
            type="video"
            label="Select or drag video here"
          />
        </Upload>
      </Form.Item>
      <h1 className="PuppyPhotos__section-title">
        Video
        <span className="PuppyPhotos__section-counter">{video ? 1 : 0}/1</span>
      </h1>
      {video ? (
        <MediaUploaderItem
          setUploadVideo={setUploadVideo}
          mediaData={video}
          type="video"
          onClickPreviewMedia={handleOpenPreviewVideo}
          onClickDelete={handleDeleteVideo}
          index={1}
          disabled={disabled}
          cloudflareVideoThumb={cloudflareVideoThumb}
        />
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="You haven't uploaded any video yet"
        />
      )}
    </>
  );
}
