import { regExp } from 'utilities/regExp';
import { Nullable } from 'shared/types/general';

const number = {
  isNumber: (value: number) => value && !isNaN(value),
  formatNumberAsCurrency: (value: Nullable<number | string>) => {
    value = `${value}`.replace(regExp.number2decimals, '$1');
    value = value ? `$${value}`.replace(regExp.number3Digits, ',') : '';
    return value;
  },

  isNegative: (amount: number) => amount < 0,

  /**
   * input: 2123   output: $2,123.00
   */
  displayCurrency(amount: number) {
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  },
  removeNonDigits: (value: Nullable<string>) => {
    value = value ? value.replace(regExp.nonDigit, '') : '';
    value = value.replace(regExp.number2decimals, '$1');
    return value;
  },
};

export default number;
