import React, { ReactElement } from 'react';

interface Props {
  mainPage?: boolean;
}

export function AuthorizationFooter(props: Props): ReactElement {
  const { mainPage } = props;
  return (
    <div className="Authorization__footer" data-testid="auth-footer">
      {mainPage && (
        <>
          <p>
            <strong>Do you need help or want to be a PuppySpot Breeder?</strong>
          </p>
          <p>
            Please contact us at <strong>(844) 566-2886</strong>
          </p>
        </>
      )}
      {!mainPage && (
        <p>
          Need support? Call us at <strong>(844) 566-2886</strong>
        </p>
      )}
      <p>
        PuppySpot 2020 all rights reserved |{' '}
        <a
          href="https://www.puppyspot.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.puppyspot.com
        </a>
      </p>
    </div>
  );
}
