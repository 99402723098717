import { RegistryContent } from 'utilities/staticContent/types';

const validationErrorMessage = {
  registrationNumber: `Check the example to match the format`,
  emptyRegistrationType: `Please select an item in the list`,
  emptyRegistrationNumber: `Please fill out this field`,
  isBreedPurebred: `Purebred Breeds must be Registered`,
};

const notRegistered = 1;

export const FieldRegistryTypeValidationRules = (isBreedPurebred: boolean) => {
  return {
    validator: (_: unknown, value: number) => {
      if (value === notRegistered && isBreedPurebred) {
        return Promise.reject(validationErrorMessage.isBreedPurebred);
      }

      if (!value) {
        return Promise.reject(validationErrorMessage.emptyRegistrationType);
      }

      return Promise.resolve();
    },
  };
};

export const FieldRegistryNumberValidationRules = (
  registrySelected: RegistryContent | undefined,
  isParent: boolean | undefined,
) => {
  return {
    validator: (_: unknown, value: string) => {
      if (isParent && !value) {
        return Promise.reject(validationErrorMessage.emptyRegistrationNumber);
      }

      if (registrySelected?.pattern) {
        const pattern = new RegExp(
          registrySelected?.pattern.replace(/%/g, ''),
          'm',
        );
        const isPattern = pattern.test(value);

        if (!value) {
          return Promise.resolve();
        }

        if (!isPattern) {
          return Promise.reject(validationErrorMessage.registrationNumber);
        }
      }

      return Promise.resolve();
    },
  };
};
