import {
  ResetPuppy,
  SetPuppy,
  SetPuppyPhotoHidden,
  SetPuppyPhotosAdditional,
  SetPuppyPhotosCover,
  SetPuppyPhotosHidden,
  UpdateNewPuppyPhotoId,
  SetPuppyOrder,
} from './actions';

export const SET_PUPPY = 'SET_PUPPY';
export const RESET_PUPPY = 'RESET_PUPPY';
export const SET_PUPPY_PHOTOS_HIDDEN = 'SET_PUPPY_PHOTOS_HIDDEN';
export const SET_SINGLE_PUPPY_PHOTO_HIDDEN = 'SET_PUPPY_PHOTO_HIDDEN';
export const SET_PUPPY_PHOTOS_COVER = 'SET_PUPPY_PHOTOS_COVER';
export const SET_PUPPY_PHOTOS_ADDITIONAL = 'SET_PUPPY_PHOTOS_ADDITIONAL';
export const UPDATE_NEW_PUPPY_PHOTO_ID = 'UPDATE_NEW_PUPPY_PHOTO_ID';
export const SET_PUPPY_ORDER = 'SET_PUPPY_ORDER';

export type puppyActionTypes =
  | SetPuppy
  | ResetPuppy
  | SetPuppyPhotosCover
  | SetPuppyPhotosAdditional
  | SetPuppyPhotosHidden
  | SetPuppyPhotoHidden
  | UpdateNewPuppyPhotoId
  | SetPuppyOrder;
