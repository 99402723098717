import { Id, Note } from 'shared/types/general';
import {
  MyListingsFilters,
  MyListingsPagination,
  MyListingsSorter,
} from 'shared/types/myListings';
import { BreederDashboardStatusTypes } from 'shared/types/statuses';
import { apiGetRequest, apiPostRequest } from 'utilities/http/api/apiHttp';
import userStorage from 'utilities/user/userStorage';
import { BreederDashboardStatus } from 'utilities/const';

const apiListing = {
  getMyListings: (
    breederDashboardStatus: BreederDashboardStatusTypes,
    pagination: MyListingsPagination,
    sorter: MyListingsSorter,
    filters: MyListingsFilters,
    extraUrlParamsRecord?: Record<string, string> | undefined,
  ) => {
    const { isFiltering, puppy_id, puppy_name } = filters;

    let urlParamsRecord: { [key: string]: string };
    urlParamsRecord = {
      breederId: `${userStorage.getBreederId()}`,
      pageSize: `${pagination.pageSize}`,
      page: `${pagination.current}`,
      sortBy: `${sorter.sortBy}`,
      sort_direction: `${sorter.sortDirection}`,
      withDueDates: '1',
      withTallies: '1',
      withMedia: '1',
      withHasPendingEdits: '1',
      withOrderData: '1',
      withPuppyParents: '1',
      withPuppyPendingEdits: '1',
      withContractTerms: '1',
    };

    if (breederDashboardStatus === BreederDashboardStatus.Hidden) {
      urlParamsRecord.listing_status_id = '4';
    } else if (breederDashboardStatus === BreederDashboardStatus.Delivered) {
      urlParamsRecord.only_newer_listings = '1';
    }

    if (breederDashboardStatus !== BreederDashboardStatus.Hidden) {
      urlParamsRecord.breederDashboardStatus = breederDashboardStatus;
    }

    if (extraUrlParamsRecord) {
      urlParamsRecord = {
        ...urlParamsRecord,
        ...extraUrlParamsRecord,
      };
    }

    if (isFiltering) {
      if (puppy_id) {
        urlParamsRecord = {
          ...urlParamsRecord,
          puppyId: `${puppy_id[0]}`,
        };
      }

      if (puppy_name) {
        urlParamsRecord = {
          ...urlParamsRecord,
          puppyBreederName: `${puppy_name[0]}`,
        };
      }
    }

    const appendParams = new URLSearchParams(urlParamsRecord).toString();

    return apiGetRequest('listing', appendParams);
  },

  getById: (listingId: Id) => {
    return apiGetRequest(`listing/${listingId}`);
  },

  postPuppyNote: (
    listingId: number,
    puppyNotePayload: { note: Partial<Note> },
  ) => {
    return apiPostRequest(`listing/${listingId}/note`, puppyNotePayload);
  },
};

export default apiListing;
