import React, { ReactElement } from 'react';
import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

import { BreadCrumb } from 'shared/baseLayout/pageLayout/PageLayout';
import { BreederRoutes } from 'shared/types/routes';

interface Props {
  breadCrumbsNav: BreadCrumb[] | undefined;
}

export default function BreadCrumbs(props: Props): ReactElement {
  const { breadCrumbsNav } = props;

  return (
    <Breadcrumb>
      <Breadcrumb.Item href={BreederRoutes.Dashboard}>
        <HomeOutlined />
        <span>Dashboard</span>
      </Breadcrumb.Item>
      {breadCrumbsNav?.map((b, i) => {
        return (
          <Breadcrumb.Item href={b.url} key={i} data-testid="breadcrumb-item">
            <span>{b.name}</span>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
}
