/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from 'utilities/http/http';
import { FetchHeaders } from 'utilities/http/types';
import userStorage from 'utilities/user/userStorage';
import { reactEnvironmentVariables } from 'utilities/environmentVariables';

interface ApiHeaders {
  Authorization: string;
}

export function apiGetRequest(url: string, appendParameters?: string) {
  const headers = getApiHeaders();
  return getRequest(buildUrl(url, appendParameters), headers);
}

export async function apiPutRequest<B>(
  url: string,
  body?: B,
  appendParameters?: string,
): Promise<any> {
  const headers = getApiHeaders();
  return putRequest(buildUrl(url, appendParameters), body, headers);
}

export function apiPostRequest<B>(
  url: string,
  body: B,
  appendParameters?: string,
): any {
  const headers = getApiHeaders();
  return postRequest(buildUrl(url, appendParameters), body, headers);
}

export function apiDeleteRequest<B>(
  url: string,
  appendParameters?: string,
): any {
  const headers = getApiHeaders();
  return deleteRequest(buildUrl(url, appendParameters), headers);
}

export function buildUrl(url: string, appendParameters?: string) {
  const host = `${reactEnvironmentVariables.API_HOST}/api/`;
  const appId = `application=${reactEnvironmentVariables.APPLICATION_ID}`;
  appendParameters = appendParameters ? `&${appendParameters}` : '';
  return `${host}${url}?${appId}${appendParameters}`;
}

export function getApiHeaders(): FetchHeaders & ApiHeaders {
  const Authorization = `Bearer ${userStorage.getToken()}`;
  return {
    Accept: 'application/json',
    Authorization,
  };
}
