import React, { ReactElement } from 'react';

import { Listing } from 'shared/types/puppy';
import {
  getPuppyCommerceSiteUrl,
  getPuppySiteName,
} from 'utilities/puppy/getPuppyUtils/getPuppyUtils';

interface Props {
  listing: Listing;
}

function ColumnSitesName(props: Props): ReactElement {
  const {
    listing: { puppy },
  } = props;

  return (
    <a
      href={getPuppyCommerceSiteUrl(puppy)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {getPuppySiteName(puppy)}
    </a>
  );
}

export default ColumnSitesName;
