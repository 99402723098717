import React, { ReactElement } from 'react';
import { Form, Input } from 'antd';
import TextAreaCounter from 'shared/fields/textAreaCounter/TextAreaCounter';
import { EmptyFieldValidationHandler } from 'shared/fields/fieldValidationHandler';

interface Props {
  disabled?: boolean;
}

export function FieldDescription(props: Props): ReactElement {
  const { disabled } = props;
  const maxLength = 1000;
  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, curValues) =>
        prevValues.description !== curValues.description
      }
    >
      {(form) => {
        const value = form.getFieldValue(['description']);
        const characterCounter = value?.length || 0;

        return (
          <Form.Item
            name="description"
            label="Puppy description"
            extra={<TextAreaCounter counter={maxLength - characterCounter} />}
            rules={[EmptyFieldValidationHandler('input')]}
          >
            <Input.TextArea
              placeholder="Type the description here"
              autoSize={{ minRows: 6, maxRows: 6 }}
              maxLength={maxLength}
              disabled={disabled}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
}
