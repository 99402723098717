import { FormInstance } from 'antd/lib/form';
import { FieldData } from 'rc-field-form/lib/interface';
import { Litter, LitterFormFields } from 'shared/types/litter';
import {
  isDelivered,
  isSold,
  neurologicalStimulationsIndex,
} from 'utilities/entities/puppy';
import { Puppy } from 'shared/types/puppy';

export const initCreateLitterRequiredFields = [
  'birthDate',
  ['food', 'typeId'],
  ['food', 'formulaId'],
  ['food', 'typeId'],
];

const litterFormHelpers = {
  setFormFields: (litter: Litter, form: FormInstance) => {
    form.setFieldsValue({
      birthDate: litter?.birthDate,
      size: litter?.size,
      food: {
        brandId: litter.food?.brandId,
        typeId: litter.food?.typeId,
        formulaId: litter.food?.formulaId,
      },
      pottyTrainingTypes: litter.pottyTrainingTypes,
      neurologicalStimulations: litter?.neurologicalStimulations?.map(
        (stimulation) => stimulation.id,
      ),
      otherNeurologicalStimulationDescription: litter?.neurologicalStimulations?.find(
        (i) => i.id === 6,
      )?.otherNeurologicalStimulationDescription,
      socializations: litter?.socializations,
      isCrateTrained: litter?.isCrateTrained,
    });
  },

  onFieldsChange: (changedFields: FieldData[], form: FormInstance) => {
    const fieldName = changedFields[0]?.name;
    if (typeof fieldName === 'object') {
      if (fieldName[0] === 'food' && fieldName[1] === 'brandId') {
        form.resetFields([
          ['food', 'typeId'],
          ['food', 'formulaId'],
        ]);
      }
      if (fieldName[0] === 'food' && fieldName[1] === 'typeId') {
        form.resetFields([['food', 'formulaId']]);
      }
    }
  },

  disableSubmit: (form: FormInstance) => {
    const requiredFieldsTouched = form.isFieldsTouched(
      initCreateLitterRequiredFields,
      true,
    );

    const hasError = Boolean(
      form.getFieldsError().find((item) => item.errors[0]),
    );
    return !requiredFieldsTouched || hasError;
  },

  buildNeurologicalStimulations: (litter: LitterFormFields) => {
    return litter.neurologicalStimulations?.map((stimulation) => ({
      id: stimulation,
      otherNeurologicalStimulationDescription:
        stimulation === neurologicalStimulationsIndex.other
          ? litter?.otherNeurologicalStimulationDescription
          : null,
    }));
  },

  getSoldOrDeliveredPuppies: (puppies: Puppy[]) => {
    return puppies.filter((puppy) => isSold(puppy) || isDelivered(puppy));
  },
};

export default litterFormHelpers;
