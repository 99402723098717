import React, { useState } from 'react';
import { notification } from 'antd';
import { Log } from 'utilities/log';

import { UploadPuppyHealthDoc } from 'shared/types/document';
import { uploadPuppyHealthDoc } from 'utilities/healthDocs';

function useHealthDocUploader() {
  const [updatePuppyHealthDocs, setUpdatePuppyHealthDocs] = useState(false);

  const handleUploadHealthDoc = async (props: UploadPuppyHealthDoc) => {
    const { healthDocType, healthDocument, orderId, puppyId } = props;

    try {
      await uploadPuppyHealthDoc({
        healthDocType,
        healthDocument,
        puppyId,
        orderId,
      });
    } catch (exception) {
      Log.error('uploadPuppyHealthDoc()', exception);
    }

    setUpdatePuppyHealthDocs(true);

    notification.success({
      message: '',
      description: `Your file ${healthDocument.name} was successfully uploaded`,
      closeIcon: <></>,
    });
  };

  return {
    updatePuppyHealthDocs,
    setUpdatePuppyHealthDocs,
    handleUploadHealthDoc,
  };
}

export default useHealthDocUploader;
