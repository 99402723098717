import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row, Space, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import {
  CalendarOutlined,
  ClearOutlined,
  TableOutlined,
} from '@ant-design/icons';

import { Litter, LitterStatuses } from 'shared/types/litter';
import EntityPhoto from 'shared/entitiesOverview/entityPhoto/EntityPhoto';
import { Icon } from 'shared/icon/Icon';
import { Parent } from 'shared/types/parent';
import { Nullable } from 'shared/types/general';
import { testId } from 'testsUtilities/dataTestId';
import media, { mediaConfig } from 'utilities/media/media';
import {
  getContentDataById,
  zeroOneToYesNo,
} from 'utilities/staticContent/content';
import date from 'utilities/time/date';
import { getLitterPottyTrainingTypes } from 'utilities/litter/getLitterPottyTrainingTypes/getLitterPottyTrainingTypes';
import { ChangeParentModal } from './ChangeParentModal';

import './LitterDetailSummary.scss';
import RemoveLitter from 'components/removeLitter/RemoveLitter';

interface Props {
  litter: Litter;
}

function LitterDetailSummary(props: Props): ReactElement {
  const [modalVisible, setModalVisible] = useState(false);
  const { litter } = props;
  const { dam, sire } = litter;
  const screens = useBreakpoint();
  const [parent, setParent] = useState<Nullable<Partial<Parent>>>(null);
  const allowParentChange = litter?.status !== LitterStatuses.past;

  const damPhotoSrc = media.getMainPhotoUrl(
    dam?.media,
    mediaConfig.photoSize.small,
  );
  const sirePhotoSrc = media.getMainPhotoUrl(
    sire?.media,
    mediaConfig.photoSize.small,
  );
  const crateTrainingTypes = getLitterPottyTrainingTypes(
    litter.pottyTrainingTypes,
  );

  const entityPhotoHeight = screens.md ? '105px' : '75px';
  const entityPhotoWidth = screens.md ? '120px' : '90px';
  const columnInfoSize = screens.md ? 12 : 24;

  const changeParent = (parent: Partial<Parent> | undefined) => {
    setModalVisible(true);
    setParent(parent);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      {modalVisible && (
        <ChangeParentModal
          hideModal={hideModal}
          modalVisible={modalVisible}
          parent={parent}
          litter={litter}
        />
      )}
      <section
        className="LitterDetailSummary"
        data-testid={testId.litterDetailSummary}
      >
        <Row gutter={16} align="middle" wrap={false}>
          <Col>
            <Space size={16} direction={screens.md ? 'horizontal' : 'vertical'}>
              <div className="parentLinkContainer">
                <Link
                  className="LitterDetailSummary__parent-link"
                  to={`/parent/dam/${dam?.id}`}
                >
                  <EntityPhoto
                    entity="dam"
                    imageSrc={damPhotoSrc}
                    height={entityPhotoHeight}
                    width={entityPhotoWidth}
                    label="Dam"
                  />
                </Link>
                {allowParentChange && (
                  <Button
                    type="link"
                    onClick={() => changeParent(dam)}
                    data-testid={testId.litterDetailSummaryChangeDam}
                  >
                    Change
                  </Button>
                )}
              </div>

              <div className="parentLinkContainer">
                <Link
                  className="LitterDetailSummary__parent-link"
                  to={`/parent/sire/${sire?.id}`}
                >
                  <EntityPhoto
                    entity="sire"
                    imageSrc={sirePhotoSrc}
                    height={entityPhotoHeight}
                    width={entityPhotoWidth}
                    label="Sire"
                  />
                </Link>
                {allowParentChange && (
                  <Button
                    type="link"
                    onClick={() => changeParent(sire)}
                    data-testid={testId.litterDetailSummaryChangeSire}
                  >
                    Change
                  </Button>
                )}
              </div>
            </Space>
          </Col>
          <Col flex="auto">
            <Row
              className="LitterDetailSummary__info-parents-name"
              data-testid={testId.litterDetailSummaryInfoParentsName}
            >
              <Col span={24}>
                <p>
                  {dam?.name} & {sire?.name}
                </p>
                {(litter.puppies?.length ?? 0) === 0 && (
                  <RemoveLitter litterId={litter?.id as number} />
                )}
              </Col>
            </Row>
            <Row>
              <Col span={columnInfoSize}>
                <p data-testid={testId.litterDetailSummaryInfoBreed}>
                  <Icon name="puppy" />
                  {getContentDataById('breeds', litter?.breedId)?.name}
                </p>
                <p data-testid={testId.litterDetailSummaryInfoLitterSize}>
                  <Icon name="litter" />
                  Litter size: {litter.size}
                </p>
                <p data-testid={testId.litterDetailSummaryInfoBirthDate}>
                  <CalendarOutlined />
                  DOB: {date.toMMDDYYYY(litter.birthDate)}
                </p>
              </Col>
              <Col span={columnInfoSize}>
                <p data-testid={testId.litterDetailSummaryInfoCrateTrained}>
                  <TableOutlined /> Crate trained:{' '}
                  {zeroOneToYesNo(litter?.isCrateTrained)}
                </p>
                {crateTrainingTypes && (
                  <p
                    data-testid={
                      testId.litterDetailSummaryInfoCrateTrainingTypes
                    }
                  >
                    <ClearOutlined /> {crateTrainingTypes}
                  </p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {!allowParentChange && (
          <div
            className="changingParentsAlert"
            data-testid={testId.litterDetailSummaryChangeParentsAlert}
          >
            <Typography.Text>
              You cannot <b>change</b> parents. <br /> Litter has <b>past</b>{' '}
              status.
            </Typography.Text>
          </div>
        )}
      </section>
    </>
  );
}

export default LitterDetailSummary;
