import React, { ReactElement } from 'react';
import PageLayout from 'shared/baseLayout/pageLayout/PageLayout';
import StaticPage from 'shared/staticPage/StaticPage';

interface Props {
  nameContent: string;
  title: string;
  pageType?: string;
}

export default function StaticContent(props: Props): ReactElement {
  const { nameContent, title, pageType } = props;

  const breadCrumbs = [
    {
      name: title,
    },
  ];

  return (
    <PageLayout breadCrumbsNav={breadCrumbs}>
      <StaticPage nameContent={nameContent} pageType={pageType} />
    </PageLayout>
  );
}
