import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import { Empty } from 'antd';
import { RootState } from 'store/rootState';
import { PuppyPhotoDragDropSectionProps } from './types';
import PuppyPhotoDraggableItem from './PuppyPhotoDraggableItem';
import { PuppyPhotos } from 'shared/types/puppy';
import { Media } from 'shared/types/media';

export default function PuppyPhotoDroppableSection(
  props: PuppyPhotoDragDropSectionProps,
): ReactElement {
  const { type, disabled } = props;

  const puppyPhotos = useSelector(
    (state: RootState) =>
      ({
        coverPhotos: state.puppy.photos?.coverPhotos,
        additionalPhotos: state.puppy.photos?.additionalPhotos,
        hiddenPhotos: state.puppy.photos?.hiddenPhotos,
      } as PuppyPhotos),
  );

  const title = {
    coverPhotos: 'Cover Photos',
    additionalPhotos: 'Additional Photos',
  };

  return (
    <>
      <h1 className="PuppyPhotos__section-title">
        {title[type]}
        <span className="PuppyPhotos__section-counter">
          {' '}
          {puppyPhotos[type]?.length}/4
        </span>
      </h1>
      <Droppable droppableId={type} isDropDisabled={disabled}>
        {(provided, snapshot) => (
          <div
            className={`PuppyPhotos__droppable-wrapper ${
              snapshot.isDraggingOver ? 'dragging-over' : ''
            }`}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {puppyPhotos[type]?.length === 0 ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
              puppyPhotos[type]?.map((photo: Media, index) => (
                <PuppyPhotoDraggableItem
                  key={photo.id || photo.uid}
                  index={index}
                  photo={photo}
                  type={type}
                  disabled={disabled}
                />
              ))
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
}
