import { Genders } from 'shared/types/parent';
import {
  ParentsActionTypes,
  SET_PARENT,
  ParentsState,
  RESET_PARENTS,
  RESET_PARENT,
} from 'store/parents/types';

const initialState: ParentsState = {
  dam: {},
  sire: {},
};

export function parentsReducer(
  state = initialState,
  action: ParentsActionTypes,
): ParentsState {
  switch (action.type) {
    case SET_PARENT:
      const gender = action?.parent?.genderId === Genders.sire ? 'sire' : 'dam';
      return {
        ...state,
        [gender]: action.parent,
      };
    case RESET_PARENTS:
      return initialState;
    case RESET_PARENT:
      return {
        ...state,
        [action.gender]: {},
      };
    default:
      return state;
  }
}
