export enum BreederRoutes {
  Login = '/login',
  Register = '/register',
  Dashboard = '/dashboard',
  NewDam = '/new-dam',
  NewSire = '/new-sire',
  NewLitter = '/new-litter',
  NewPuppy = '/new-puppy',
  MyListings = '/my-listings',
  MyListingsSold = '/my-listings/sold',
  MyListingsPending = '/my-listings/pending',
  MyListingsApproved = '/my-listings/approved',
  MyListingsDelivered = '/my-listings/delivered',
  MyListingsHidden = '/my-listings/hidden',
  MyParents = '/my-parents',
  MyLitters = '/my-litters',
  MyUpdates = '/my-updates',
  MyAccount = '/my-account',
  Transactions = '/transactions',
  NotFound = '/not-found',
}

export enum StaticContentPageRoute {
  Webinars = '/webinars',
  FaqCovid19 = '/faq-covid-19',
  BreederHelper = '/breeder-helper',
  HowPuppySpotWorks = '/how-puppyspot-works',
  ThreePsPlaceYourPuppy = '/three-ps-place-your-puppy',
  NameIdeas = '/name-ideas',
  PhotosDosAndDont = '/photo-dos-and-donts',
  VideosDosAndDont = '/video-dos-and-donts',
  DescriptionInspiration = '/description-inspirations',
  MembershipAgreement = '/agreement',
  PuppySpotBreedersStandards = '/puppyspot-breeders-standards',
  ProgramOfVeterinaryCare = '/program-of-veterinary-care',
  VetHealthReport = '/vet-health-report',
  TravelPlaning = '/travel-phone-numbers',
  PostSalesChecklist = '/post-sale-checklist',
}
