import { Listing } from 'shared/types/puppy';
import { ListingStatusIdTypes } from 'shared/types/statuses';
import { ListingStatusId } from 'utilities/const';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';

export const isListingStatusHidden = (
  listing: Listing | Partial<Listing> | undefined,
): boolean => {
  const { staticContent } = staticContentStorage;

  if (!listing || !staticContent) return false;

  const { listingStatusReasonId } = listing;

  const listingStatusId = staticContent.listingStatusReasons?.find(
    (statusReason: { id: number }) => statusReason.id === listingStatusReasonId,
  )?.listingStatusId as ListingStatusIdTypes | undefined;

  return listingStatusId === ListingStatusId.Hidden;
};

export const isListingLocked = (
  listing: Listing | Partial<Listing> | undefined,
): boolean => {
  return Boolean(listing?.lockedByUserId);
};
