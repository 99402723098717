import { ResetParent, ResetParents, SetParent } from 'store/parents/actions';
import { Parent } from 'shared/types/parent';

export interface ParentsState {
  dam: Partial<Parent>;
  sire: Partial<Parent>;
}

export const SET_PARENT = 'SET_PARENT';
export const RESET_PARENTS = 'RESET_PARENTS';
export const RESET_PARENT = 'RESET_PARENT';

export type ParentsActionTypes = SetParent | ResetParents | ResetParent;
