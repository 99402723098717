import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Col, Form, Modal, notification, Row, Grid } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import { InfoCircleOutlined, InfoCircleFilled } from '@ant-design/icons';

import {
  FieldBirthday,
  FieldBreed,
  FieldChampionLineage,
  FieldColor,
  FieldDogPersonality,
  FieldExistingParent,
  FieldMediaUploader,
  FieldName,
  FieldOFACertifications,
  FieldParentActivities,
  FieldParentShown,
  FieldRegistry,
  FieldWeightRange,
  SubmitButton,
} from 'shared/fields';
import parentFormHelpers from 'shared/parents/parentFormHelpers';
import { FormProps } from 'shared/types/general';
import { BreederRoutes } from 'shared/types/routes';
import { Collapsable } from 'shared/collapsable/Collapsable';
import { Gender, Genders, ParentFormFields } from 'shared/types/parent';
import showNotificationError from 'shared/notifications/showNotificationError';
import { RootState } from 'store/rootState';
import { createParent, editParent } from 'store/parents/actions';
import { capitalize } from 'utilities/dom/element';
import { isParentLocked } from 'utilities/parent';
import { legacyRegistries } from 'shared/fields/fieldRegistry/legacyRegistries';

export interface ParentFormProps extends FormProps {
  gender: Gender;
  singleForm?: boolean;
  fieldExistingParentOn?: boolean;
}

export function ParentForm(props: ParentFormProps): ReactElement | null {
  const {
    action = 'create',
    gender,
    isOpen,
    singleForm,
    fieldExistingParentOn,
    collapsableType,
    collapsableDisabled,
  } = props;

  const screens = Grid.useBreakpoint();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [collapsableOpen, setCollapsableOpen] = useState<boolean>(
    Boolean(isOpen),
  );
  const [isFormFieldsLoaded, setIsFormFieldsLoaded] = useState(false);
  const [hideFormFields, setHideFormFields] = useState(false);
  const { parents } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const pathname = history.location.pathname;
  const parent = parents[gender];
  const [form] = Form.useForm();
  const withLegacyRegistry = legacyRegistries.find(
    (registry) => registry.id === form.getFieldValue('registryId'),
  );

  const onModalClose = (parentId: unknown, gender: Gender) => {
    history.push(`/parent/${gender}/${parentId}`);
  };

  const handleSubmit = async (parentFormFields: ParentFormFields) => {
    setIsSubmitting(true);
    try {
      const genderId = gender === 'sire' ? Genders.sire : Genders.dam;
      parentFormFields = {
        ...parentFormFields,
        genderId,
      };

      if (action === 'create') {
        const parentCreatedId = await dispatch(createParent(parentFormFields));

        setCollapsableOpen(false);
        if (singleForm) {
          Modal.success({
            title: `${capitalize(gender)} Created`,
            content: `${capitalize(gender)} Created Successfully`,
            autoFocusButton: null,
            afterClose: () => onModalClose(parentCreatedId, gender),
            okButtonProps: { type: 'primary' },
          });
        } else {
          notification.success({
            message: `${capitalize(gender)} created successfully!`,
            className: 'notification-success-parent',
          });
        }
      } else {
        await dispatch(editParent(parent.id as number, parentFormFields));
        window.scrollTo({ top: 0 });
        notification.success({
          message: `${capitalize(gender)} has been edited successfully!`,
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        showNotificationError({
          error: error,
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOnFieldsChange = (changedFields: FieldData[]) => {
    parentFormHelpers.onFieldsChange(changedFields, form);
    setDisableSubmit(parentFormHelpers.disableSubmit(form));
  };

  useEffect(() => {
    if (action === 'edit') {
      const getErrors = async () => {
        if (!isFormFieldsLoaded) return;
        try {
          const isListingLocked = isParentLocked(parent);
          const validatedFields = await form.validateFields();
          setDisableSubmit(Boolean(validatedFields?.errorFields?.length));

          if (isListingLocked) {
            setDisableSubmit(isListingLocked);
          }
        } catch (err) {
          showNotificationError({
            error: 'Some fields have errors, please fix it',
          });
        }
      };
      getErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormFieldsLoaded]);

  useEffect(() => {
    if (!isEmpty(parent)) {
      parentFormHelpers.setFormFields(parent, form, setIsFormFieldsLoaded);
    } else {
      parentFormHelpers.setFormFields({}, form, setIsFormFieldsLoaded);
    }

    if (
      (!isEmpty(parent) && pathname === BreederRoutes.NewLitter) ||
      (!isEmpty(parent) && pathname === BreederRoutes.NewPuppy)
    ) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [parents, form, parent, pathname]);

  useEffect(() => {
    setCollapsableOpen(Boolean(isOpen));
  }, [isOpen]);

  const isDisabled = (name: string) => {
    if (isEmpty(parent)) return false;
    if (parent && isParentLocked(parent)) return true;
    if (name === 'pedigreeDocs' && parent.soldListings !== 0) return true;
    if (name === 'birthday' && isEmpty(parent.birthDate)) return false;

    return parentFormHelpers.setDisabledFields(name, parent, pathname);
  };

  const onInfoClick = () => {
    Modal.info({
      icon: <InfoCircleFilled />,
      width: screens.lg ? 500 : undefined,
      title:
        'Some Dams may not be listed because one of the following reasons: ',
      content: (
        <ul>
          <li>Has not passed 6 months since the Dam’s previous whelp.</li>
          <li>The Dam has already reached 7 years.</li>
        </ul>
      ),
    });
  };

  const onChampionInfoClick = () => {
    Modal.info({
      icon: <InfoCircleFilled />,
      width: screens.lg ? 500 : undefined,
      title:
        'This parent has sold puppies. Please contact PuppySpot to get more information.',
    });
  };

  const extra =
    gender === 'dam' ? (
      <span>
        Why not all dams are listed?
        <InfoCircleOutlined color="red" onClick={onInfoClick} />
      </span>
    ) : (
      'Sires older than 9 years are not listed'
    );

  const warningChampion = (
    <div data-testid="warning-field-champion">
      Why I cannot edit this field?<span> </span>
      <InfoCircleOutlined color="red" onClick={onChampionInfoClick} />
    </div>
  );

  return (
    <Collapsable
      id={gender}
      title={
        !isEmpty(parents[gender])
          ? `${parents[gender].name}`
          : `Upload ${gender}'s info`
      }
      icon={gender}
      isOpen={collapsableOpen}
      type={collapsableType}
      disabled={collapsableDisabled}
      handleCollapsableOpen={setCollapsableOpen}
    >
      <Row justify="center">
        <Col xs={24} xl={10} xxl={8}>
          <Form
            name={`${gender}-form`}
            data-testid={`${gender}-form`}
            layout="vertical"
            size="large"
            onFinish={handleSubmit}
            scrollToFirstError
            onFieldsChange={handleOnFieldsChange}
            form={form}
          >
            {fieldExistingParentOn && (
              <FieldExistingParent
                gender={gender}
                setCollapsableOpen={setCollapsableOpen}
                setHideFormFields={setHideFormFields}
                extra={extra}
              />
            )}
            {!hideFormFields && (
              <>
                <FieldName disabled={isDisabled('name')} />
                <FieldBirthday disabled={isDisabled('birthday')} />
                <FieldBreed disabled={isDisabled('breed')} />
                <FieldColor disabled={isDisabled('color')} />
                <FieldWeightRange disabled={isDisabled('weight')} />
                <FieldRegistry
                  disabled={isDisabled('registry')}
                  isParent={true}
                  withLegacyRegistry={withLegacyRegistry}
                />
                <FieldOFACertifications
                  form={form}
                  disabled={isDisabled('ofaCertifications')}
                />
                <FieldChampionLineage
                  disabled={isDisabled('isChampion')}
                  warning={warningChampion}
                />
                <FieldMediaUploader
                  type="file"
                  name="pedigreeDoc"
                  testId="pedigree"
                  label="Pedigree documentation"
                  disabled={isDisabled('pedigreeDocs')}
                />
                <FieldParentShown disabled={isDisabled('parentShown')} />
                <FieldParentActivities
                  disabled={isDisabled('parentActivities')}
                />
                <FieldDogPersonality disabled={isDisabled('dogPersonality')} />
                <FieldMediaUploader
                  type="photo"
                  name="mediaPhoto"
                  label="Add photo"
                  testId="photo"
                  disabled={isDisabled('mediaPhoto')}
                />
                <SubmitButton
                  label={`Save ${gender}'s info`}
                  loading={isSubmitting}
                  disabled={disableSubmit}
                />
              </>
            )}
          </Form>
        </Col>
      </Row>
    </Collapsable>
  );
}
