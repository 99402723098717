import { apiGetRequest } from 'utilities/http/api/apiHttp';
import { AbbrCountry } from 'shared/types/geolocation';

const apiStaticContent = {
  getStaticContent: () => apiGetRequest('breeder-hub/content'),
  getStatesByCountry: (countryAbbr: AbbrCountry) => {
    return apiGetRequest(`content/country/${countryAbbr.toLowerCase()}/states`);
  },
  getTravelAirports: () => apiGetRequest('travel/airport'),
};

export default apiStaticContent;
