import { combineReducers } from 'redux';

import { myListingsReducer } from 'store/myListings/myListingsReducer';
import { myListingsPendingReducer } from 'store/myListingsPending/myListingsPendingReducer';
import { myListingsApprovedReducer } from 'store/myListingsApproved/myListingsApprovedReducer';
import { myListingsDeliveredReducer } from 'store/myListingsDelivered/myListingsDeliveredReducer';
import { myListingsSoldReducer } from 'store/myListingsSold/myListingSoldReducer';
import { myListingsHiddenReducer } from './myListingsHidden/myListingsHiddenReducer';
import { myParentsReducer } from 'store/myParents/myParentsReducer';
import { myLittersReducer } from 'store/myLitters/myLittersReducer';
import { paginationsReducer } from 'store/paginations/paginationsReducer';
import { litterReducer } from 'store/litter/litterReducer';
import { parentsReducer } from 'store/parents/parentsReducer';
import { breederReducer } from 'store/breeder/breederReducer';
import { newPuppyReducer } from 'store/newPuppy/newPuppyReducer';
import { puppyReducer } from './puppy/puppyReducer';

const rootReducer = combineReducers({
  myListings: myListingsReducer,
  myListingsSold: myListingsSoldReducer,
  myListingsPending: myListingsPendingReducer,
  myListingsApproved: myListingsApprovedReducer,
  myListingsDelivered: myListingsDeliveredReducer,
  myListingsHidden: myListingsHiddenReducer,
  myParents: myParentsReducer,
  myLitters: myLittersReducer,
  paginations: paginationsReducer,
  litter: litterReducer,
  parents: parentsReducer,
  puppy: puppyReducer,
  breeder: breederReducer,
  newPuppy: newPuppyReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
