import React, { ReactElement } from 'react';
import { Layout, Drawer, Grid } from 'antd';

import { Navigation } from 'shared/navigation/Navigation';
import { AsideMenuCollapsableProps } from 'shared/baseLayout/baseLayoutHeader/BaseLayoutHeader';

const { Sider } = Layout;
const { useBreakpoint } = Grid;

export default function BaseLayoutAside(
  props: AsideMenuCollapsableProps,
): ReactElement {
  const { collapsed, setCollapsed } = props;
  const screens = useBreakpoint();

  const handleToggleSideMenu = () => {
    if (setCollapsed) {
      setCollapsed(!collapsed);
      document.body.removeAttribute('style');
    }
  };
  const componentAside = !screens.xl ? (
    <Drawer
      placement="left"
      className="BaseLayoutAside"
      closable={false}
      onClose={handleToggleSideMenu}
      visible={!collapsed}
      width={300}
    >
      <Navigation setCollapsed={handleToggleSideMenu} collapsed={collapsed} />
    </Drawer>
  ) : (
    <Sider
      className="BaseLayoutAside"
      trigger={null}
      collapsedWidth={0}
      collapsible
      collapsed={collapsed}
    >
      <Navigation setCollapsed={handleToggleSideMenu} collapsed={collapsed} />
    </Sider>
  );
  return componentAside;
}
