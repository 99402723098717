import React from 'react';
import { useHistory } from 'react-router-dom';

import { PuppyUpdateButton } from 'shared/types/puppy';
import { isListingLocked } from 'utilities/listing';
import UpToDateInfoBarButton from '../upToDateInfoBar/upToDateInfoBarButton/UpToDateInfoBarButton';

function UpdatePuppyPhotos(props: PuppyUpdateButton) {
  const { columnName, listing } = props;
  const dueDates = listing?.puppy?.dueDates || [];
  const history = useHistory();

  const setDisabled = () => {
    if (columnName === 'delivered') return true;

    if (isListingLocked(listing)) return true;
  };

  const goToDetail = () => {
    history.push(`/puppy/${listing?.id}`);
  };

  return (
    <UpToDateInfoBarButton
      btnType="Photos"
      dueDates={dueDates}
      onClick={goToDetail}
      disabled={setDisabled()}
      media={listing.media}
    />
  );
}

export default UpdatePuppyPhotos;
