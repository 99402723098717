import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormInstance } from 'antd/lib/form';

import { RootState } from 'store/rootState';
import { PuppyPhotos as puppyPhotosInterface } from 'shared/types/puppy';
import PuppyPhotoDragDrop from './puppyPhotoDragDrop/PuppyPhotoDragDrop';
import PuppyPhotosUpload from './puppyPhotosUpload/PuppyPhotosUpload';
import PuppyPhotosLabel from './PuppyPhotosLabel';
import PuppyPhotosDeleteAll from 'shared/puppy/puppyMedia/puppyPhotos/puppyPhotosDeleteAll/PuppyPhotosDeleteAll';

interface Props {
  form: FormInstance;
  disabled?: boolean;
}

export default function PuppyPhotos(props: Props): ReactElement {
  const { form, disabled } = props;
  const puppyPhotos = useSelector(
    (state: RootState) =>
      ({
        coverPhotos: state.puppy.photos?.coverPhotos,
        additionalPhotos: state.puppy.photos?.additionalPhotos,
        hiddenPhotos: state.puppy.photos?.hiddenPhotos,
      } as puppyPhotosInterface),
  );
  const { coverPhotos, additionalPhotos } = puppyPhotos;

  useEffect(() => {
    const photos = coverPhotos &&
      additionalPhotos && [...coverPhotos, ...additionalPhotos];
    if (form.isFieldTouched('puppyPhotos')) {
      form.setFieldsValue({
        puppyPhotos: photos,
      });
      form.validateFields(['puppyPhotos']);
    }
  }, [coverPhotos, additionalPhotos, form]);

  return (
    <section className="PuppyPhotos">
      <PuppyPhotosLabel />
      <PuppyPhotosUpload disabled={disabled} />
      <PuppyPhotosDeleteAll disabled={disabled} />
      <PuppyPhotoDragDrop disabled={disabled} />
    </section>
  );
}
