import {
  MyListingsFilters,
  MyListingsPagination,
  MyListingsSorter,
  MyListingsStateBase,
} from 'shared/types/myListings';
import { SortDirection } from 'utilities/const';
import {
  MyListingsDeliveredActions,
  SET_MY_LISTINGS_DELIVERED,
  SET_MY_LISTINGS_DELIVERED_FILTERS,
  SET_MY_LISTINGS_DELIVERED_LOADING,
  SET_MY_LISTINGS_DELIVERED_PAGINATION,
  SET_MY_LISTINGS_DELIVERED_SORTER,
  UPDATE_MY_LISTINGS_DELIVERED_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES,
} from 'store/myListingsDelivered/types';
import { listingUpdatePuppyRegistrationsWeightDueDates } from 'utilities/listing';

export const initialStateMyListingsDeliveredSorter: MyListingsSorter = {
  isSorting: false,
  sortBy: 'scheduled_delivery_at',
  sortDirection: SortDirection.descend,
};

export const initialStateMyListingsDeliveredFilters: MyListingsFilters = {
  isFiltering: false,
  puppy_id: null,
  puppy_name: null,
};

export const initialStateMyListingsDeliveredPagination: MyListingsPagination = {
  pageSize: 50,
  current: 1,
  total: 0,
  loadMore: false,
};

const initialState: MyListingsStateBase = {
  loading: false,
  listings: [],
  pagination: initialStateMyListingsDeliveredPagination,
  filters: initialStateMyListingsDeliveredFilters,
  sorter: initialStateMyListingsDeliveredSorter,
};

export function myListingsDeliveredReducer(
  state = initialState,
  action: MyListingsDeliveredActions,
): MyListingsStateBase {
  switch (action.type) {
    case SET_MY_LISTINGS_DELIVERED_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_MY_LISTINGS_DELIVERED: {
      const { listings } = action;
      return {
        ...state,
        loading: false,
        listings,
      };
    }
    case SET_MY_LISTINGS_DELIVERED_PAGINATION: {
      const { pagination } = action;
      return {
        ...state,
        pagination,
      };
    }
    case SET_MY_LISTINGS_DELIVERED_SORTER: {
      const { sorter } = action;
      return {
        ...state,
        sorter,
      };
    }
    case SET_MY_LISTINGS_DELIVERED_FILTERS: {
      const { filters } = action;
      return {
        ...state,
        filters,
      };
    }
    case UPDATE_MY_LISTINGS_DELIVERED_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES: {
      const { puppy } = action;

      const updatedListings = state.listings.map((listing) =>
        listingUpdatePuppyRegistrationsWeightDueDates(listing, puppy),
      );

      return {
        ...state,
        listings: updatedListings,
      };
    }
    default:
      return state;
  }
}
