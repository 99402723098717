import React, { ReactElement, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useHistory, useLocation } from 'react-router';

import ErrorFallback from 'pages/errorFallback/ErrorFallback';
import BaseLayout from 'shared/baseLayout/BaseLayout';
import BreederAdminExternalRoutesHandler from 'shared/routes/BreederAdminExternalRoutesHandler';
import BreederAdminInternalRoutesHandler from 'shared/routes/BreederAdminInternalRoutesHandler';
import PublicRoutesHandler from 'shared/routes/PublicRoutesHandler';
import RoutesHandler from 'shared/routes/RoutesHandler';
import { useCurrentHistoryPathname } from 'shared/routes/useCurrentHistoryPathname';
import { isBreederImpersonateMode } from 'utilities/breederImpersonate/breederImpersonate';
import raygun from 'utilities/externalServices/raygun/raygun.config';
import IdleTimer from 'utilities/idleTimer/idleTimer';
import refreshToken from 'utilities/refreshToken/refreshToken';
import {
  isUserRoleBreederAdminExternal,
  isUserRoleBreederAdminInternal,
} from 'utilities/user/userRolesCheckers/userRolesCheckers';
import userStorage from 'utilities/user/userStorage';

import 'App.scss';

function App(): ReactElement {
  const location = useLocation();
  const history = useHistory();

  document.body.className = useCurrentHistoryPathname().split('/')[0];
  const [hasRefreshed, setHasRefreshed] = useState(false);
  const isLogged = Boolean(userStorage.getToken());
  const loggedUserInfo = userStorage.getUser();
  const isUserBreederAdminInternal =
    isUserRoleBreederAdminInternal(loggedUserInfo) &&
    !isBreederImpersonateMode();
  const isUserBreederAdminExternal =
    isUserRoleBreederAdminExternal(loggedUserInfo) &&
    !isBreederImpersonateMode();

  useEffect(() => {
    if (hasRefreshed) {
      return;
    }

    if (isLogged && refreshToken.isTokenExpired()) {
      setHasRefreshed(true);
      refreshToken.requestToken();
    }
  }, [hasRefreshed, isLogged]);

  return (
    <div className="App">
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        resetKeys={[location.pathname]}
        onError={(err) => raygun.logError(err)}
        onReset={() => history.push('/dashboard')}
      >
        {isLogged ? (
          isUserBreederAdminInternal ? (
            <BreederAdminInternalRoutesHandler />
          ) : isUserBreederAdminExternal ? (
            <BreederAdminExternalRoutesHandler />
          ) : (
            <BaseLayout>
              <IdleTimer />
              <RoutesHandler />
            </BaseLayout>
          )
        ) : (
          <PublicRoutesHandler />
        )}
      </ErrorBoundary>
    </div>
  );
}

export default App;
