import { mediaConfig } from 'utilities/media/media';
import { Nullable } from 'shared/types/general';
import { Media } from 'shared/types/media';
import { isEmpty } from 'lodash';

export const isAudio = (m: Media) => {
  return m.mediaTypeId === mediaConfig.mediaType.audio;
};

export const isVideo = (m: Media) => {
  return m.mediaTypeId === mediaConfig.mediaType.video;
};

export const isPedigreeFile = (m: Media) => {
  return m?.mediaTypeId === mediaConfig.mediaType.pedigreeFile;
};

export const isImage = (m: Media) => {
  return m?.mediaTypeId === mediaConfig.mediaType.photo;
};

export const isMediaDefault = (m: Media) => {
  return (
    (m.isDefault === mediaConfig.mediaDefault &&
      m.mediaStatusId !== mediaConfig.mediaStatus.hidden) ||
    (m.listingMedia?.isDefault &&
      m.mediaStatusId !== mediaConfig.mediaStatus.hidden)
  );
};

export const isMediaPublic = (m: Media) => {
  return (
    m.visibilityId === mediaConfig.visibilityId.public && !isMediaDeleted(m)
  );
};

export const isMediaPrivate = (m: Media) => {
  return (
    m.visibilityId === mediaConfig.visibilityId.private && !isMediaDeleted(m)
  );
};

export const isMediaHidden = (m: Media) => {
  return (
    m.visibilityId === mediaConfig.visibilityId.hidden && !isMediaDeleted(m)
  );
};

export const isMediaDeleted = (m: Media) => {
  return !isEmpty(m?.mediaVisibilityDeferral);
};

export const isMediaStatusPendingUpload = (m: Media) => {
  return m.mediaStatusId === mediaConfig.mediaStatus.pendingUpload;
};

export const getExistingVideo = (media: Nullable<Media[]>) => {
  return media?.filter((m) => isVideo(m))?.pop();
};

export const isMediaStatusApproved = (m: Media) => {
  return m.mediaStatusId === mediaConfig.mediaStatus.approved;
};

export const isMediaStatusPublished = (m: Media) => {
  return m.mediaStatusId === mediaConfig.mediaStatus.published;
};

export const isMediaStatusPendingReview = (m: Media) => {
  return m.mediaStatusId === mediaConfig.mediaStatus.pendingReview;
};
