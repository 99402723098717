import { Listing, Puppy } from 'shared/types/puppy';
import {
  MyListingsFilters,
  MyListingsPagination,
  MyListingsSorter,
} from 'shared/types/myListings';
import { Document } from 'shared/types/document';

export const SET_MY_LISTINGS_SOLD_LOADING = 'SET_MY_LISTINGS_SOLD_LOADING';
export const SET_MY_LISTINGS_SOLD = 'SET_MY_LISTINGS_SOLD';
export const SET_MY_LISTINGS_SOLD_FILTERS = 'SET_MY_LISTINGS_SOLD_FILTERS';
export const SET_MY_LISTINGS_SOLD_SORTER = 'SET_MY_LISTINGS_SOLD_SORTER';
export const SET_MY_LISTINGS_SOLD_PAGINATION =
  'SET_MY_LISTINGS_SOLD_PAGINATION';
export const SET_PUPPY_HEALTH_DOC = 'SET_PUPPY_HEALTH_DOC';
export const UPDATE_MY_LISTINGS_SOLD_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES =
  'UPDATE_MY_LISTINGS_SOLD_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES';

export interface MyListingsMainState {
  loading: boolean;
  listings: Listing[];
  pagination: MyListingsPagination;
  filters: MyListingsFilters;
  sorter: MyListingsSorter;
}

interface SetMyListingsSoldLoading {
  type: typeof SET_MY_LISTINGS_SOLD_LOADING;
}

interface SetMyListingsSold {
  type: typeof SET_MY_LISTINGS_SOLD;
  listings: Listing[];
}

interface SetMyListingsSoldFilters {
  type: typeof SET_MY_LISTINGS_SOLD_FILTERS;
  filters: MyListingsFilters;
}

interface SetMyListingsSoldSorter {
  type: typeof SET_MY_LISTINGS_SOLD_SORTER;
  sorter: MyListingsSorter;
}

interface SetMyListingsSoldPagination {
  type: typeof SET_MY_LISTINGS_SOLD_PAGINATION;
  pagination: MyListingsPagination;
}

interface SetPuppyHealthDoc {
  type: typeof SET_PUPPY_HEALTH_DOC;
  documents: Document[];
  listingId: number;
}

interface UpdateMyListingsSoldPuppyRegistrationsWeightDueDates {
  type: typeof UPDATE_MY_LISTINGS_SOLD_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES;
  puppy: Puppy;
}

export type MyListingsSoldActions =
  | SetMyListingsSoldLoading
  | SetMyListingsSold
  | SetMyListingsSoldFilters
  | SetMyListingsSoldSorter
  | SetMyListingsSoldPagination
  | SetPuppyHealthDoc
  | UpdateMyListingsSoldPuppyRegistrationsWeightDueDates;
