import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import {
  Key,
  SorterResult,
  SortOrder,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { SearchOutlined } from '@ant-design/icons';

import {
  ColumnAskingPrice,
  ColumnBreed,
  ColumnPuppyBirthdate,
  ColumnPuppyId,
  ColumnPuppyDam,
  ColumnPuppyName,
  ColumnSearch,
  ColumnViews,
  ColumnPuppySire,
  ColumnPuppyUpdates,
} from 'components/myListings/myListiningsTable/myListingsTableColumns';
import { MyListingsFilters, MyListingsSorter } from 'shared/types/myListings';
import { Listing } from 'shared/types/puppy';
import {
  setMyListingsHiddenFilters,
  setMyListingsHiddenPagination,
  setMyListingsHiddenSorter,
} from 'store/myListingsHidden/actions';
import { RootState } from 'store/rootState';
import { testId } from 'testsUtilities/dataTestId';
import { getNameContentFieldById } from 'utilities/staticContent/content';
import { BreederDashboardStatus, SortDirection } from 'utilities/const';
import { initialStateMyListingsHiddenSorter } from 'store/myListingsHidden/myListingsHiddenReducer';

interface Props {
  openModal?: (listing: Listing) => void;
}

function MyListingsTableHidden({ openModal }: Props): ReactElement {
  const {
    myListingsHidden: { loading, listings, pagination, sorter, filters },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const handleOnChangeTable = (
    tablePagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: SorterResult<Listing> | SorterResult<Listing>[],
  ) => {
    window.scrollTo({ top: 0 });

    dispatch(
      setMyListingsHiddenPagination({
        ...pagination,
        current: tablePagination.current as number,
        pageSize: tablePagination.pageSize as number,
        loadMore: false,
      }),
    );

    const newSorter = sorter as SorterResult<Listing>;
    const listingSorter = newSorter.column
      ? ({
          sortBy: newSorter.columnKey,
          sortDirection: newSorter.order
            ? SortDirection[newSorter.order]
            : initialStateMyListingsHiddenSorter.sortDirection,
          isSorting: true,
        } as MyListingsSorter)
      : (initialStateMyListingsHiddenSorter as MyListingsSorter);

    dispatch(setMyListingsHiddenSorter(listingSorter));

    const isFiltering = Boolean(filters.puppy_id || filters.puppy_name);

    dispatch(
      setMyListingsHiddenFilters({
        ...filters,
        isFiltering,
      } as MyListingsFilters),
    );
  };

  return (
    <Table
      data-testid={testId.myListingsHiddenTable}
      loading={loading}
      rowKey="puppyId"
      dataSource={listings}
      scroll={{ x: true }}
      columns={[
        {
          title: 'Puppy ID',
          key: 'puppy_id',
          render: (record: Listing) => <ColumnPuppyId listing={record} />,
          sorter: true,
          sortOrder:
            sorter.sortBy === 'puppy_id'
              ? (`${sorter.sortDirection}end` as SortOrder)
              : null,
          filterIcon: <SearchOutlined />,
          filterDropdown: (props) => (
            <ColumnSearch {...props} numeric placeholder="Search by Puppy ID" />
          ),
          filteredValue: filters.puppy_id,
        },
        {
          title: 'Name',
          key: 'puppy_name',
          render: (record: Listing) => <ColumnPuppyName listing={record} />,
          sorter: true,
          sortOrder:
            sorter.sortBy === 'puppy_name'
              ? (`${sorter.sortDirection}end` as SortOrder)
              : null,
          filterIcon: <SearchOutlined />,
          filterDropdown: (props) => (
            <ColumnSearch {...props} placeholder="Search by Name" />
          ),
          filteredValue: filters.puppy_name,
        },
        {
          title: "Site's name",
          key: 'sites_name',
          render: () => 'Not published',
        },
        {
          title: 'Breed',
          key: 'breed_name',
          render: (record: Listing) => <ColumnBreed listing={record} />,
          sorter: true,
          sortOrder:
            sorter.sortBy === 'breed_name'
              ? (`${sorter.sortDirection}end` as SortOrder)
              : null,
        },
        {
          title: 'Views',
          render: (record: Listing) => <ColumnViews listing={record} />,
        },
        {
          title: 'Price',
          key: 'cost',
          sorter: true,
          sortOrder:
            sorter.sortBy === 'cost'
              ? (`${sorter.sortDirection}end` as SortOrder)
              : null,
          render: (record: Listing) => (
            <ColumnAskingPrice
              listing={record}
              updatePrice
              openModal={openModal}
            />
          ),
        },
        {
          title: 'Weight',
          dataIndex: ['puppy', 'weightRangeId'],
          render: (weightRangeId) =>
            getNameContentFieldById('weightRanges', weightRangeId),
        },
        {
          title: 'DOB',
          key: 'litter_birth_date',
          render: (record: Listing) => (
            <ColumnPuppyBirthdate listing={record} />
          ),
          sorter: true,
          sortOrder:
            sorter.sortBy === 'litter_birth_date'
              ? (`${sorter.sortDirection}end` as SortOrder)
              : null,
        },
        {
          title: 'Mom',
          render: (record: Listing) => <ColumnPuppyDam listing={record} />,
        },
        {
          title: 'Dad',
          render: (record: Listing) => <ColumnPuppySire listing={record} />,
        },
        {
          title: 'Actions required',
          render: (record: Listing) => (
            <ColumnPuppyUpdates
              listing={record}
              breederDashboardStatus={BreederDashboardStatus.Hidden}
            />
          ),
        },
      ]}
      pagination={{
        hideOnSinglePage: true,
        showQuickJumper: true,
        defaultPageSize: pagination.pageSize,
        current: pagination.current,
        total: pagination.total,
      }}
      onChange={handleOnChangeTable}
    />
  );
}

export default MyListingsTableHidden;
