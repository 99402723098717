import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import { Nullable } from 'shared/types/general';
import { Documents } from 'shared/types/document';
import { YYYYMMDD } from 'utilities/time/date';
import { hasValidHealthDocs } from 'utilities/healthDocs';
import { HealthDocsDueDateDaysRange } from 'utilities/const';

const { DueToday, SevenDays } = HealthDocsDueDateDaysRange;

const healthDocsReleaseDate = moment('2022-09-06');

export const isHealthDocsDueDateOnWindow = (
  documentDueDate: Nullable<string>,
) => {
  const dateNow = moment().format(YYYYMMDD);
  const daysToDueDate = moment(documentDueDate).diff(dateNow, 'days');

  return daysToDueDate >= DueToday && daysToDueDate <= SevenDays;
};

export const isHealthDocsDueDatePastDue = (
  documentDueDate: Nullable<string>,
  documents: Documents,
) => {
  const dateNow = moment().format(YYYYMMDD);
  const daysToDueDate = moment(documentDueDate).diff(dateNow, 'days');

  return daysToDueDate < DueToday && !hasValidHealthDocs(documents);
};

export const isBeforeReleaseDate = (soldDate: string | undefined) => {
  if (isEmpty(soldDate)) return false;
  return moment(soldDate).isBefore(healthDocsReleaseDate);
};

export const formatReleaseDate = () => {
  return moment(healthDocsReleaseDate).format('MMMM D, YYYY');
};
