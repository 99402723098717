import React from 'react';

import TravelInfoItem from 'shared/puppy/puppyTravel/TravelInfoItem';
import { ChaperoneTransportationSequence } from 'shared/types/puppyTravel';
import { getContentDataById } from 'utilities/staticContent/content';
import date from 'utilities/time/date';
import { testId } from 'testsUtilities/dataTestId';

interface Props {
  chaperoneSequence: ChaperoneTransportationSequence;
}

function TravelSequenceInfoChaperone(props: Props) {
  const { chaperoneSequence } = props;
  return (
    <section data-testid={testId.travelSequenceInfoChaperone}>
      <TravelInfoItem
        label="Chaperone name"
        value={chaperoneSequence.puppyChaperoneCompany.name}
      />

      <TravelInfoItem
        label="Phone"
        value={chaperoneSequence.puppyChaperoneCompany.phone}
      />

      {chaperoneSequence.additionalPhone && (
        <TravelInfoItem
          label="Additional phone"
          value={chaperoneSequence.additionalPhone}
        />
      )}

      <TravelInfoItem
        label="Airline"
        value={chaperoneSequence.flightSequence.airline.name}
      />

      <TravelInfoItem
        label="Flight number"
        value={chaperoneSequence.flightSequence.flightNumber}
      />

      <TravelInfoItem
        label="Flight confirmation"
        value={chaperoneSequence.flightSequence.flightConfirmation}
      />

      <TravelInfoItem
        label="Air waybill"
        value={chaperoneSequence.flightSequence.flightAirWaybill}
      />

      <TravelInfoItem
        label="Departure airport"
        value={
          <>
            <b>
              {
                getContentDataById(
                  'states',
                  chaperoneSequence.flightSequence.departureAirport.stateId,
                )?.abbr
              }
            </b>
            {chaperoneSequence.flightSequence.departureAirport.name}
          </>
        }
      />

      <TravelInfoItem
        label="Departure date"
        value={date.toFullMonthDDYYYY(
          chaperoneSequence.flightSequence.departureTime,
        )}
      />

      <TravelInfoItem
        label="Departure time"
        value={date.toTimeHMMA(chaperoneSequence.flightSequence.departureTime)}
      />

      <TravelInfoItem
        label="Arrival airport"
        value={
          <>
            <b>
              {
                getContentDataById(
                  'states',
                  chaperoneSequence.flightSequence.arrivalAirport.stateId,
                )?.abbr
              }
            </b>
            {chaperoneSequence.flightSequence.arrivalAirport.name}
          </>
        }
      />

      <TravelInfoItem
        label="Arrival date"
        value={date.toFullMonthDDYYYY(
          chaperoneSequence.flightSequence.arrivalTime,
        )}
      />

      <TravelInfoItem
        label="Arrival time"
        value={date.toTimeHMMA(chaperoneSequence.flightSequence.arrivalTime)}
      />
    </section>
  );
}

export default TravelSequenceInfoChaperone;
