import React, { ReactElement } from 'react';
import { Button, Col, Row, Tooltip, notification } from 'antd';
import { isEmpty } from 'lodash';
import {
  IdcardOutlined,
  CalendarOutlined,
  EyeInvisibleOutlined,
  GlobalOutlined,
  DashboardOutlined,
  LoginOutlined,
  DollarOutlined,
} from '@ant-design/icons';

import date from 'utilities/time/date';
import { MarkLabel } from 'shared/markLabel/MarkLabel';
import { DueDate } from 'shared/types/date';
import {
  MyPuppyListingsColumns,
  MyUpcomingColumns,
} from 'shared/types/general';
import {
  renderListingPendingEditBadge,
  renderListingLockedBadge,
} from 'components/entity';
import EntityPhoto from 'shared/entitiesOverview/entityPhoto/EntityPhoto';
import { EntityCardClassModifiers } from 'shared/entitiesOverview/types';
import { isDelivered, isPending } from 'utilities/entities/puppy';
import media from 'utilities/media/media';
import { Icon } from 'shared/icon/Icon';
import { Listing, Puppy } from 'shared/types/puppy';
import { isListingLocked } from 'utilities/listing';
import {
  getListingAskingPrice,
  getPuppyBirthDate,
  getPuppyBreed,
  getPuppyBreederName,
  getPuppyCommerceSiteUrl,
  getPuppyDamId,
  getPuppyDamName,
  getPuppySireId,
  getPuppySireName,
  getPuppySiteName,
} from 'utilities/puppy/getPuppyUtils/getPuppyUtils';
import { getNameContentFieldById } from 'utilities/staticContent/content';
import inventoryStatus from 'utilities/status/inventoryStatus';
import UpToDateInfoBar from './upToDateInfoBar/UpToDateInfoBar';
import { PuppyGenderIcon } from 'components/puppy';

import 'shared/entitiesOverview/entityCard.scss';

interface Props {
  listing: Listing;
  columnName: MyPuppyListingsColumns | MyUpcomingColumns;
  updatePrice?: boolean;
  setDrawerPuppy?: (listing: Listing | null) => void;
  openModal?: (listing: Listing) => void;
}

export default function ListingCard({
  listing,
  columnName,
  setDrawerPuppy,
  updatePrice = false,
  openModal,
}: Props): ReactElement {
  const { puppy } = listing;
  const views = listing.listingTally?.viewCount || 0;
  const isPuppyDelivered = isDelivered(puppy as Puppy);
  const deliveredAt = puppy?.deliveredAt;
  const isSoldByBreeder = inventoryStatus.isSoldByBreeder(puppy as Puppy);
  const isPendingSale = inventoryStatus.isPendingSale(puppy as Puppy);

  const cardLocked =
    isListingLocked(listing) || isSoldByBreeder ? 'entityCard--locked' : '';
  const cardPhotoText = isSoldByBreeder ? '--' : `${views} views`;

  const puppyPhotoPath = media.getDefaultPuppyPhoto(listing?.media);

  const handleEntityCardClassModifier = (
    dueDates: DueDate[] | undefined,
  ): EntityCardClassModifiers => {
    if (!dueDates || isEmpty(dueDates) || isDelivered(puppy as Puppy))
      return 'entityCard';

    const hasOneInPast = dueDates.find(
      (dueDateItem) => !date.isDateInFuture(dueDateItem.dueDate),
    );
    if (hasOneInPast) return 'entityCard entityCard--alert';

    return 'entityCard entityCard--warning';
  };
  const entityCardClassName = handleEntityCardClassModifier(puppy.dueDates);

  const disableLink = isPending(puppy as Puppy) ? 'disableLink' : '';
  const isMale = 1;
  const genderObj = puppy?.pendingEdit?.genderId || puppy.genderId;

  return (
    <section
      className={`${entityCardClassName}  ${cardLocked}`}
      data-testid={`entity-card-column-${columnName}`}
    >
      <Row align="middle" wrap={false}>
        <Col>
          <div className="entityCard__info-img">
            {renderListingLockedBadge(listing)}
            {renderListingPendingEditBadge(listing)}
            <EntityPhoto
              entity="puppy"
              imageSrc={puppyPhotoPath}
              height="75px"
              width="90px"
              label={cardPhotoText}
            />
          </div>
        </Col>
        <Col flex="auto">
          <section className="entityCard__info-content">
            <div className="entityCard__info-title-container">
              <PuppyGenderIcon gender={genderObj === isMale ? 'sire' : 'dam'} />
              <h2
                className="entityCard__info-title"
                data-testid="entity-card-info-name"
              >
                {getPuppyBreederName(puppy)}
              </h2>
            </div>
            <p>
              <GlobalOutlined />
              <a
                href={getPuppyCommerceSiteUrl(puppy)}
                target="_blank"
                className={`entityCard__link ${disableLink}`}
                rel="noopener noreferrer"
                data-testid="entity-card-info-consumer-site-name"
              >
                {getPuppySiteName(puppy)}
              </a>
            </p>
            <p data-testid="entity-card-info-id">
              <IdcardOutlined />
              ID: {puppy.id}
              {isPendingSale && columnName === 'approved' && (
                <Tooltip title="Pending sale">
                  <span
                    className="entityCard__info-content-pending-label"
                    data-testid="entity-card-info-pending-label"
                  >
                    PS
                  </span>
                </Tooltip>
              )}
              {isSoldByBreeder && (
                <MarkLabel label="Unlisted" icon={<EyeInvisibleOutlined />} />
              )}
            </p>
            {isPuppyDelivered ? (
              <p>
                <CalendarOutlined />
                Delivered:{' '}
                {!isEmpty(deliveredAt)
                  ? date.toMMDDYY(deliveredAt)
                  : ' --/--/--'}
              </p>
            ) : (
              <p data-testid="entity-card-info-birthdate">
                <CalendarOutlined />
                DOB: {getPuppyBirthDate(puppy)}
              </p>
            )}
          </section>
        </Col>
      </Row>
      <section className="entityCard__info-details">
        <Row>
          <Col span={12}>
            <section className="entityCard__info-content">
              <p>
                <Icon name="dam" />
                <a
                  className="entityCard__link"
                  href={`/parent/dam/${getPuppyDamId(puppy)}`}
                  data-testid="entity-card-info-dam-name"
                >
                  {getPuppyDamName(puppy)}
                </a>
              </p>
              <p data-testid="entity-card-info-breed-name">
                <Icon name="litter" />
                {getPuppyBreed(puppy)}
              </p>
              <p data-testid="entity-card-info-weight-range">
                <DashboardOutlined />
                {getNameContentFieldById('weightRanges', puppy.weightRangeId)}
              </p>
            </section>
          </Col>
          <Col span={12}>
            <section className="entityCard__info-content">
              <p data-testid="entity-card-info-sire-name">
                <Icon name="sire" />
                <a
                  className="entityCard__link"
                  href={`/parent/sire/${getPuppySireId(puppy)}`}
                >
                  {getPuppySireName(puppy)}
                </a>
              </p>
              {updatePrice ? (
                <Button
                  onClick={() => {
                    const isLocked = isListingLocked(listing);
                    if (isLocked) {
                      notification.warning({
                        message: `Warning!`,
                        description: `The listing id ${listing?.id} is locked`,
                      });
                      return;
                    }
                    if (openModal) {
                      openModal(listing);
                    }
                  }}
                  data-testid={`modal-change-price`}
                  className="price-button"
                >
                  <p data-testid="entity-card-info-asking-price">
                    <DollarOutlined />
                    {getListingAskingPrice(listing)}
                  </p>
                </Button>
              ) : (
                <p data-testid="entity-card-info-asking-price">
                  <DollarOutlined />
                  {getListingAskingPrice(listing)}
                </p>
              )}
              <p>
                <LoginOutlined />
                <a
                  className="entityCard__link"
                  data-testid="entity-card-link"
                  href={`/puppy/${puppy.id}`}
                >
                  Go to details
                </a>
              </p>
            </section>
          </Col>
        </Row>
      </section>
      <UpToDateInfoBar
        columnName={columnName}
        listing={listing}
        setDrawerPuppy={setDrawerPuppy}
      />
    </section>
  );
}
