import React, { ReactElement } from 'react';
import { Form, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';

interface Props {
  disabled?: boolean;
  label?: string;
  formId?: string;
  placeholder?: string;
  withPrefix?: boolean;
}

export default function FieldEmail(props: Props): ReactElement {
  const { disabled, label, formId, placeholder, withPrefix = true } = props;
  return (
    <Form.Item
      name="email"
      label={label ?? 'Email'}
      data-testid={`email-input-${formId}`}
      rules={[
        { required: true, message: 'Email not valid' },
        { type: 'email', message: 'Email not valid' },
      ]}
    >
      <Input
        type="email"
        prefix={withPrefix ? <UserOutlined /> : undefined}
        data-testid="credentials-email"
        disabled={disabled}
        placeholder={placeholder ?? 'Type email'}
      />
    </Form.Item>
  );
}
