import React, { ReactElement, ReactNode, useState } from 'react';
import { Layout } from 'antd';

import BaseLayoutHeader from 'shared/baseLayout/baseLayoutHeader/BaseLayoutHeader';
import { isBreederImpersonateMode } from 'utilities/breederImpersonate/breederImpersonate';
import BaseLayoutAside from './BaseLayoutAside';
import BaseLayoutContent from './BaseLayoutContent';

import './BaseLayout.scss';

interface PageLayoutProps {
  children: ReactNode;
}

export default function BaseLayout(props: PageLayoutProps): ReactElement {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const collapsedMenu = collapsed
    ? 'BaseLayout__container--collapsed'
    : 'BaseLayout__container--uncollapsed';
  const { children } = props;

  const baseLayoutClassModifier = isBreederImpersonateMode()
    ? 'BaseLayout--breeder-impersonate-mode'
    : '';

  return (
    <Layout className={`BaseLayout ${baseLayoutClassModifier}`}>
      <BaseLayoutAside collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout className={` BaseLayout__container ${collapsedMenu} `}>
        <BaseLayoutHeader collapsed={collapsed} setCollapsed={setCollapsed} />
        <BaseLayoutContent>{children}</BaseLayoutContent>
      </Layout>
    </Layout>
  );
}
