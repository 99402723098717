import React from 'react';
import { Form, Radio } from 'antd';
import { RadioGroupProps } from 'antd/lib/radio';
import { InfoCircleOutlined } from '@ant-design/icons';

export const extraInfoText =
  'Puppies bred from dogs purchased through PuppySpot are not eligible to be advertised with us.';

const ExtraInfo = () => (
  <div className="text-warning">
    <InfoCircleOutlined />{' '}
    <span style={{ color: 'rgba(0,0,0,0.65)' }}>{extraInfoText}</span>
  </div>
);

interface Props extends RadioGroupProps {
  label: string;
  selected: boolean;
}

export default function FieldDogsPurchasedFromPSpot(props: Props) {
  const { label, selected, ...radioGroupProps } = props;

  return (
    <Form.Item
      data-testid="purchased-from-ps"
      className="RegisterForm__long-label"
      name="$anyDogPurchasedFromPS"
      label={label}
      extra={selected && <ExtraInfo />}
    >
      <Radio.Group {...radioGroupProps} />
    </Form.Item>
  );
}
