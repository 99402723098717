import showNotificationError from 'shared/notifications/showNotificationError';
import { BreederRoutes } from 'shared/types/routes';
import apiUser from 'utilities/http/api/apiUser';
import { reactEnvironmentVariables } from 'utilities/environmentVariables';
import userStorage from 'utilities/user/userStorage';

const BH_CONFIG = {
  body: {
    application: reactEnvironmentVariables.APPLICATION_ID,
  },
};

export const logout = () => {
  userStorage.removeUserAndToken();
  window.location.href = BreederRoutes.Login;
};

const decodeToken = (token: string | null) => {
  if (!token) {
    return {};
  }
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  } catch (e) {
    logout();
    return {};
  }
};

const refreshToken = {
  isTokenExpired: () => {
    const decodedToken = decodeToken(userStorage.getToken());
    return new Date() > new Date(decodedToken.exp * 1000);
  },

  requestToken: async () => {
    try {
      const authResponse = await apiUser.apiRefreshToken(BH_CONFIG.body);
      if (authResponse?.json?.token) {
        userStorage.setToken(authResponse?.json?.token);
      } else {
        logout();
      }
    } catch (e) {
      showNotificationError({ error: "we couldn't update the token sorry" });
      logout();
    }
  },
};

export default refreshToken;
