import moment, { Moment, unitOfTime } from 'moment';
import { isEmpty } from 'lodash';
import { tz } from 'moment-timezone';
import { Nullable } from 'shared/types/general';

export const YYYYMMDD = 'YYYY-MM-DD';
export const MMDDYYYY = 'MM/DD/YYYY';
export const MMDDYY = 'MM/DD/YY';
export const fullMonthDDYYYY = 'MMMM DD, YYYY';
export const timeHMMA = 'h:mm a';
export const fullDate = 'YYYY-MM-DD HH:mm:ss';

const date = {
  // Preferred format to send to Back-end: YYYY-MM-DD
  toYYYYMMDD(date: Nullable<string | Moment | Date>) {
    return moment(date).utc().format(YYYYMMDD);
  },
  // Preferred format to display on Front-end: MM/DD/YYYY
  toMMDDYYYY(date: Nullable<string | Moment | Date>) {
    return moment(date).utc().format(MMDDYYYY);
  },
  toMMDDYY(date: Nullable<string | Moment | Date>) {
    return moment(date).utc().format(MMDDYY);
  },
  toFullDate(date: Nullable<string | Moment | Date>) {
    return moment(date).utc().format(fullDate);
  },
  toFullMonthDDYYYY(date: Nullable<string | Moment | Date>) {
    return moment(date).utc().format(fullMonthDDYYYY);
  },
  toTimeHMMA(date: Nullable<string | Moment | Date>) {
    return moment(date).utc().format(timeHMMA);
  },
  /**
   * This function operates the differences between two dates.
   * @param {moment.MomentInput} dateA - Early date.
   * @param {moment.MomentInput} dateA - Older date.
   * @param {moment.Moment} unit - Unit of time you need the difference.
   * @returns {number} number
   */
  diff(
    dateA: moment.MomentInput,
    dateB: moment.MomentInput,
    unit: unitOfTime.Diff,
  ): number {
    const now = moment(dateA);
    const end = moment(dateB);

    return now.diff(end, unit);
  },
  // Alternative format to display on Front-end: ddd, Do MMMM, YYYY Timezone
  timeZone(date: Nullable<string>, zone: string) {
    const formatDate = moment(date).format('ddd, Do MMMM, YYYY');
    const timeZone = tz(date, zone).format('ha z');

    return `${formatDate} at ${timeZone}`;
  },
  forDatePicker: (date: Nullable<string>) => moment(date, YYYYMMDD),
  sortByOldest: (dateA: Nullable<string>, dateB: Nullable<string>) => {
    const dateToNumber = (date: Nullable<string>) =>
      Number(new Date(date as string));

    return dateToNumber(dateA) - dateToNumber(dateB);
  },
  sortByNewest: (dateA: Nullable<string>, dateB: Nullable<string>) => {
    const dateToNumber = (date: Nullable<string>) =>
      Number(new Date(date as string));

    return dateToNumber(dateB) - dateToNumber(dateA);
  },
  isDateInFuture: (date: string) => moment(date).isAfter(moment(new Date())),
  nearestDateInFuture: (dates?: string[]): Nullable<string> => {
    if (!dates || isEmpty(dates)) return null;
    const futureDates = dates.filter((d) => date.isDateInFuture(d));
    const sorted = futureDates.sort((a, b) => date.sortByOldest(a, b));
    return sorted[0];
  },
};

export default date;
