import { notification } from 'antd';
import './showNotificationError.scss';

interface Props {
  message?: string;
  error: string | Error;
  key?: string;
}

export default function showNotificationError({ message, error, key }: Props) {
  const className = 'notification-error';
  const msg = message ? message : `Error!`;

  notification.error({
    className,
    message: msg,
    description: error instanceof Error ? JSON.stringify(error) : error,
    key,
  });
}
