import { Puppy } from 'shared/types/puppy';
import { Parent } from 'shared/types/parent';
import { InventoryStatusId } from 'utilities/const';

const inventoryStatus = {
  isSoldByBreeder: (entity: Partial<Puppy> | Partial<Parent>) => {
    return entity?.inventoryStatusId === InventoryStatusId.SoldByBreeder;
  },
  isPendingSale: (entity: Partial<Puppy> | Partial<Parent>) => {
    return entity?.inventoryStatusId === InventoryStatusId.PendingSale;
  },
  isSold: (entity: Partial<Puppy> | Partial<Parent>) => {
    return entity?.inventoryStatusId === InventoryStatusId.Sold;
  },
};
export default inventoryStatus;
