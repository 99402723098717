import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';

import { LitterStatuses } from 'shared/types/litter';
import { initialPage } from 'store/paginations/paginationsReducer';
import { getBreederLitters } from 'store/myLitters/actions';
import { increasePage } from 'store/paginations/actions';
import { RootState } from 'store/rootState';

import './LoadMore.scss';

interface Props {
  columnName: string;
}

export default function LoadMore({ columnName }: Props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { paginations } = useSelector((state: RootState) => ({
    paginations: state.paginations,
  }));
  const currentColumn = paginations[columnName];
  const currentPage = currentColumn?.currentPage;
  const itemsOnPage = currentColumn?.itemsOnPage;
  const itemsPerPage = currentColumn?.itemsPerPage;

  useEffect(() => {
    const handleOnPageChange = async () => {
      if (currentPage === initialPage) return;

      setIsLoading(true);
      switch (columnName) {
        case 'upcoming':
        case 'current':
        case 'past': {
          await dispatch(
            getBreederLitters(columnName as LitterStatuses, currentPage),
          );
          break;
        }
      }
      setIsLoading(false);
    };

    handleOnPageChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  function onLoadMore() {
    dispatch(increasePage(columnName));
  }

  const hasNoMoreItems = itemsOnPage < itemsPerPage;
  if (hasNoMoreItems || !currentColumn) return null;

  return (
    <Button
      onClick={onLoadMore}
      type="link"
      loading={isLoading}
      className="LoadMore"
      data-testid={`load-more-${columnName}`}
    >
      Load more
    </Button>
  );
}
