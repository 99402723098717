import React, { ReactElement } from 'react';

export default function PuppyPhotosLabel(): ReactElement {
  return (
    <div className="ant-form-item-label">
      <label className="PuppyPhotos__label">Add photos (min 3, max 8)</label>
      <p className="PuppyPhotos__description">
        You can suggest the publishing order by dragging the photo’s thumbnails
        up or down.
      </p>
    </div>
  );
}
