import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Divider, Drawer, Form, Typography } from 'antd';
import { HeartOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

import {
  HealthDocsStatus,
  HealthDocsUploadsList,
  HealthDocUploader,
  useHealthDocUploader,
} from 'components/healthDocs';
import { Listing } from 'shared/types/puppy';
import { updateListingPuppyHealthDocs } from 'store/myListingsSold/actions.thunk';
import date from 'utilities/time/date';
import { isDelivered } from 'utilities/entities/puppy';

import './HealthDocDrawer.scss';

interface Props {
  listing: Listing | null;
  setDrawerPuppy: (listing: Listing | null) => void;
}

function HealthDocDrawer({ listing, setDrawerPuppy }: Props): ReactElement {
  const {
    updatePuppyHealthDocs,
    setUpdatePuppyHealthDocs,
    handleUploadHealthDoc,
  } = useHealthDocUploader();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (updatePuppyHealthDocs) {
      dispatch(updateListingPuppyHealthDocs(listing?.id, listing?.puppy.id));
      setUpdatePuppyHealthDocs(false);
    }
  }, [updatePuppyHealthDocs, dispatch, setUpdatePuppyHealthDocs, listing]);

  return (
    <Drawer
      className="HealthDocDrawer"
      title={
        <div className="drawerHeader">
          <HeartOutlined />
          <span className="puppyNameContainer">
            <Typography.Text className="puppyName" strong>
              {listing?.puppy?.breederName}
            </Typography.Text>
            <Typography.Text> - Puppy ID:</Typography.Text>
            <Typography.Text strong>{listing?.puppy?.id}</Typography.Text>
          </span>
        </div>
      }
      placement="right"
      width={378}
      onClose={() => setDrawerPuppy(null)}
      visible={!isEmpty(listing?.puppy)}
      destroyOnClose
      afterVisibleChange={(visibility) => {
        if (!visibility) {
          form.resetFields();
        }
      }}
    >
      <div className="customerContainer">
        <div>
          <Typography.Text>Customer </Typography.Text>

          <Typography.Text strong>
            {listing?.order?.leads[0]?.firstName}{' '}
            {listing?.order?.leads[0]?.lastName}
          </Typography.Text>
        </div>
        <div>
          <Typography.Text>Travel estimated departure date </Typography.Text>
          <Typography.Text strong>
            {listing?.estimatedDepartureTime
              ? date.toMMDDYY(listing?.estimatedDepartureTime)
              : 'To be confirmed'}
          </Typography.Text>
        </div>
      </div>

      <Divider />

      <HealthDocsStatus
        puppyId={listing?.puppy.id as number}
        documents={listing?.puppy.documents}
        documentDueDate={listing?.documentDueDate}
        isPuppyDelivered={isDelivered(listing?.puppy)}
      />

      <HealthDocUploader
        documents={listing?.puppy.documents}
        documentDueDate={listing?.documentDueDate}
        puppyId={listing?.puppy.id}
        orderId={listing?.order?.id}
        lastSoldAt={listing?.lastSoldAt}
        handleUploadHealthDoc={handleUploadHealthDoc}
        form={form}
      />

      <Divider />

      {listing?.lastSoldAt ? (
        <HealthDocsUploadsList
          documents={listing?.puppy.documents}
          lastSoldAt={listing.lastSoldAt}
        />
      ) : null}
    </Drawer>
  );
}

export default HealthDocDrawer;
