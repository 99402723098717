import React from 'react';

import TravelInfoItem from 'shared/puppy/puppyTravel/TravelInfoItem';
import { GroundTransportationSequence } from 'shared/types/puppyTravel';
import { testId } from 'testsUtilities/dataTestId';
import date from 'utilities/time/date';

interface Props {
  groundSequence: GroundTransportationSequence;
}

function TravelSequenceInfoGround(props: Props) {
  const { groundSequence } = props;
  return (
    <section data-testid={testId.travelSequenceInfoGround}>
      <TravelInfoItem
        label="Ground transportation company"
        value={groundSequence.groundTransportationCompany.name}
      />

      <TravelInfoItem label="Driver name" value={groundSequence.driverName} />

      <TravelInfoItem
        label="Phone"
        value={groundSequence.groundTransportationCompany.phone}
      />

      {groundSequence.additionalPhone && (
        <TravelInfoItem
          label="Additional phone"
          value={groundSequence.additionalPhone}
        />
      )}

      <TravelInfoItem
        label="Departure date"
        value={date.toFullMonthDDYYYY(groundSequence.departureTime)}
      />

      <TravelInfoItem
        label="Departure time"
        value={date.toTimeHMMA(groundSequence.departureTime)}
      />

      <TravelInfoItem
        label="Arrival date"
        value={date.toFullMonthDDYYYY(groundSequence.arrivalTime)}
      />

      <TravelInfoItem
        label="Arrival time"
        value={date.toTimeHMMA(groundSequence.arrivalTime)}
      />
    </section>
  );
}

export default TravelSequenceInfoGround;
