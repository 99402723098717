import apiStaticContent from 'utilities/http/api/apiStaticContent';
import staticContentStorage from './staticContentStorage';
import { getSystemSetting } from 'utilities/systemSetting/systemSetting';

export const refreshStaticContent = async () => {
  const staticContentResponse = await apiStaticContent.getStaticContent();
  const airportsResponse = await apiStaticContent.getTravelAirports();
  const staticContent = staticContentResponse?.json?.content;
  const airports = airportsResponse?.json?.airports;
  const systemSetting = await getSystemSetting();
  const content = {
    ...staticContent,
    airports,
    systemSetting,
  };

  staticContentStorage.setContent(content);
};
