import { notification } from 'antd';
import { Store } from 'antd/lib/form/interface';

import { Breeder } from 'shared/types/breeder';
import { Address } from 'shared/types/geolocation';
import { User } from 'shared/types/user';
import showNotificationError from 'shared/notifications/showNotificationError';
import apiUser from 'utilities/http/api/apiUser';
import apiBreeder from 'utilities/http/api/apiBreeder';
import userStorage from 'utilities/user/userStorage';

export async function updatePhones(phoneFields: Partial<Breeder>) {
  const error = "Couldn't update your phones, please check phone fields";
  const breeder = {
    ...phoneFields,
    id: userStorage?.getBreederId() as number,
  };
  try {
    const updateResponse = await apiBreeder.updateBreeder(breeder);
    if (updateResponse.status !== 200) {
      showNotificationError({ error });
    }
  } catch (e) {
    showNotificationError({ error });
  }
}

export async function createAddress(contactInfo: Store) {
  const error = "Couldn't create your address";

  try {
    const preparedAddress = prepareAddress(contactInfo);
    const createAddressResponse = await apiBreeder.createAddress(
      preparedAddress,
    );
    if (createAddressResponse.status !== 200) {
      showNotificationError({ error });
    }
  } catch (e) {
    showNotificationError({ error });
  }
}

function prepareAddress(contactInfo: Store, addressId?: number) {
  const physicalAddressTypeId = 1;

  const preparedAddress = {
    address: contactInfo.address,
    address2: contactInfo.address2,
    city: contactInfo.city,
    countryId: contactInfo.country,
    postalCode: contactInfo.postalCode,
    stateId: contactInfo.state,
    addressTypeId: physicalAddressTypeId,
    salutation: 'Dear',
    isPrimary: 1,
  } as Address;

  if (addressId) {
    preparedAddress.id = addressId;
  }

  return preparedAddress;
}

export async function updateAddress(
  contactInfo: Store,
  addresses: Partial<Address>[] | undefined,
) {
  const error = "Couldn't update your address";
  try {
    const storedAddress = addresses?.[0];
    const preparedAddress = prepareAddress(contactInfo, storedAddress?.id);

    const updateAddressResponse = await apiBreeder.updateAddress(
      preparedAddress,
    );
    if (updateAddressResponse.status !== 200) {
      showNotificationError({ error });
    }
  } catch (e) {
    showNotificationError({ error });
  }
}

export async function updateCredentials(user: User) {
  const error = "Couldn't change your contact information, please try again";
  try {
    const userResp = await apiUser.update({
      firstName: user.firstName,
      lastName: user.lastName,
    } as User);

    if (userResp.status === 200) {
      notification.success({ message: 'Contact information changed' });
      const updatedUser = userStorage?.getUser();
      if (!updatedUser) return;
      updatedUser.firstName = user.firstName;
      updatedUser.lastName = user.lastName;
      userStorage.setUser(updatedUser);
    } else {
      showNotificationError({ error });
    }
  } catch (e) {
    showNotificationError({ error });
  }
}
