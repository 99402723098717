import React, { ReactElement } from 'react';
import { Form, Input } from 'antd';
import { fieldNameValidationRules } from './fieldNameValidation';

interface Props {
  label?: string;
  placeholder?: string;
  name?: string;
  testId?: string;
  disabled?: boolean;
  isSameName?: boolean;
  max16String?: boolean;
}
export function FieldName(props: Props): ReactElement {
  const {
    name,
    label,
    disabled,
    placeholder,
    testId,
    isSameName = false,
    max16String = false,
  } = props;
  const labelText = label ?? 'Name';
  const fieldName = name ?? 'name';
  return (
    <Form.Item
      name={fieldName}
      label={labelText}
      rules={[fieldNameValidationRules(isSameName, max16String)]}
    >
      <Input
        data-testid={`field-name${testId ?? ''}`}
        placeholder={placeholder ?? 'Type name'}
        disabled={disabled}
      />
    </Form.Item>
  );
}
