import {
  NewPuppyAction,
  NewPuppyState,
  SET_NEW_PUPPY,
} from 'store/newPuppy/types';

const initialState: NewPuppyState = null;

export function newPuppyReducer(
  state = initialState,
  action: NewPuppyAction,
): NewPuppyState {
  switch (action.type) {
    case SET_NEW_PUPPY: {
      const { newPuppy } = action;
      return newPuppy;
    }
    default:
      return state;
  }
}
