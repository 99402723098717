import { cloneDeep } from 'lodash';

import {
  MyListingsFilters,
  MyListingsPagination,
  MyListingsSorter,
  MyListingsStateBase,
} from 'shared/types/myListings';
import {
  MyListingsSoldActions,
  SET_MY_LISTINGS_SOLD,
  SET_MY_LISTINGS_SOLD_FILTERS,
  SET_MY_LISTINGS_SOLD_LOADING,
  SET_MY_LISTINGS_SOLD_PAGINATION,
  SET_MY_LISTINGS_SOLD_SORTER,
  SET_PUPPY_HEALTH_DOC,
  UPDATE_MY_LISTINGS_SOLD_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES,
} from 'store/myListingsSold/types';
import { SortDirection } from 'utilities/const';
import { listingUpdatePuppyRegistrationsWeightDueDates } from 'utilities/listing';

export const initialStateMyListingsSoldSorter: MyListingsSorter = {
  isSorting: false,
  sortBy: 'last_sold_at',
  sortDirection: SortDirection.descend,
};

export const initialStateMyListingsSoldFilters: MyListingsFilters = {
  isFiltering: false,
  puppy_id: null,
  puppy_name: null,
};

export const initialStateMyListingsSoldPagination: MyListingsPagination = {
  pageSize: 100,
  current: 1,
  total: 0,
  loadMore: false,
};

const initialState: MyListingsStateBase = {
  loading: false,
  listings: [],
  pagination: initialStateMyListingsSoldPagination,
  filters: initialStateMyListingsSoldFilters,
  sorter: initialStateMyListingsSoldSorter,
};

export function myListingsSoldReducer(
  state = initialState,
  action: MyListingsSoldActions,
): MyListingsStateBase {
  switch (action.type) {
    case SET_MY_LISTINGS_SOLD_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_MY_LISTINGS_SOLD: {
      const { listings } = action;
      return {
        ...state,
        loading: false,
        listings,
      };
    }
    case SET_MY_LISTINGS_SOLD_PAGINATION: {
      const { pagination } = action;
      return {
        ...state,
        pagination,
      };
    }
    case SET_MY_LISTINGS_SOLD_SORTER: {
      const { sorter } = action;
      return {
        ...state,
        sorter,
      };
    }
    case SET_MY_LISTINGS_SOLD_FILTERS: {
      const { filters } = action;
      return {
        ...state,
        filters,
      };
    }
    case SET_PUPPY_HEALTH_DOC: {
      const { documents, listingId } = action;
      const listingsClone = cloneDeep(state.listings);

      listingsClone.map((listing) => {
        if (listing.id === listingId) {
          listing.puppy.documents = documents;
        }

        return listing;
      });

      return {
        ...state,
        listings: listingsClone,
      };
    }
    case UPDATE_MY_LISTINGS_SOLD_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES: {
      const { puppy } = action;

      const updatedListings = state.listings.map((listing) =>
        listingUpdatePuppyRegistrationsWeightDueDates(listing, puppy),
      );

      return {
        ...state,
        listings: updatedListings,
      };
    }
    default:
      return state;
  }
}
