import React from 'react';

import { Layout } from 'antd';

import StaticPage from 'shared/staticPage/StaticPage';

import './Agreement.scss';

const { Content } = Layout;

export default function Agreement() {
  return (
    <Content className="content">
      <StaticPage nameContent="agreement" />
    </Content>
  );
}
