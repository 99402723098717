import React from 'react';
import {
  BookOutlined,
  NotificationOutlined,
  ContainerOutlined,
} from '@ant-design/icons';

import { StaticContentPageRoute } from 'shared/types/routes';

export const StaticContentPagesNavigation = [
  {
    title: `News & Events`,
    key: 'newsAndEvents',
    icon: <NotificationOutlined />,
    type: 'submenu',
    items: [
      {
        title: `WEBINARS`,
        url: StaticContentPageRoute.Webinars,
        key: 'webinars',
      },
      {
        title: `COVID-19 FAQ`,
        url: StaticContentPageRoute.FaqCovid19,
        key: 'covid19Faq',
      },
    ],
  },
  {
    title: `Learning Center`,
    key: 'learningCenter',
    icon: <BookOutlined />,
    type: 'submenu',
    items: [
      {
        title: `Breeder Helper`,
        url: StaticContentPageRoute.BreederHelper,
        key: 'breederHelper',
      },
      {
        title: `How PuppySpot Works`,
        url: StaticContentPageRoute.HowPuppySpotWorks,
        key: 'howPuppySpotWorks',
      },
      {
        title: `3 "P"s to place your puppy`,
        url: StaticContentPageRoute.ThreePsPlaceYourPuppy,
        key: '3PPlaceYourPuppy',
      },
      {
        title: `Names ideas`,
        url: StaticContentPageRoute.NameIdeas,
        key: 'nameIdeas',
      },
      {
        title: `Photos DOS and DON'TS`,
        url: StaticContentPageRoute.PhotosDosAndDont,
        key: 'photosDosAndDont',
      },
      {
        title: `Videos DOS and DON'TS`,
        url: StaticContentPageRoute.VideosDosAndDont,
        key: 'videosDosAndDont',
      },
      {
        title: `Description inspiration`,
        url: StaticContentPageRoute.DescriptionInspiration,
        key: 'descriptionInspiration',
      },
    ],
  },
  {
    title: `Documentation`,
    key: 'documentation',
    icon: <ContainerOutlined />,
    type: 'submenu',
    items: [
      {
        title: `Membership agreement`,
        url: StaticContentPageRoute.MembershipAgreement,
        key: 'membershipAgreement',
      },
      {
        title: `PuppySpot Breeders Standards`,
        url: StaticContentPageRoute.PuppySpotBreedersStandards,
        key: 'puppySpotBreedersStandards',
      },
      {
        title: `Program of Vet Care`,
        url: StaticContentPageRoute.ProgramOfVeterinaryCare,
        key: 'programOfVeterinaryCare',
      },
      {
        title: `Vet health report`,
        url: StaticContentPageRoute.VetHealthReport,
        key: 'vetHealthReport',
      },
      {
        title: `Travel planning`,
        url: StaticContentPageRoute.TravelPlaning,
        key: 'travelPlaning',
      },
      {
        title: `Post-sales checklist`,
        url: StaticContentPageRoute.PostSalesChecklist,
        key: 'postSalesChecklist',
      },
    ],
  },
];
