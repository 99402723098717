import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PageLayout from 'shared/baseLayout/pageLayout/PageLayout';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import MyLittersLarge from './myLittersLarge/MyLittersLarge';
import MyLittersSmall from './myLittersSmall/MyLittersSmall';
import { resetMyLitters } from 'store/myLitters/actions';

import './MyLitters.scss';
import { resetPagination } from 'store/paginations/actions';
import { LitterStatuses } from 'shared/types/litter';

const breadCrumbs = [
  {
    name: 'My Litters',
  },
];

export default function MyLitters() {
  const screens = useBreakpoint();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetPagination(LitterStatuses.current));
      dispatch(resetPagination(LitterStatuses.upcoming));
      dispatch(resetPagination(LitterStatuses.past));
      dispatch(resetPagination(LitterStatuses.new));
      dispatch(resetMyLitters());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (typeof screens.xl === 'undefined') return null;
  return (
    <PageLayout className="MyLitters" breadCrumbsNav={breadCrumbs}>
      {screens.xl ? <MyLittersLarge /> : <MyLittersSmall />}
    </PageLayout>
  );
}
