import React, { ReactElement } from 'react';
import { Form, Select } from 'antd';

import { State } from 'shared/types/geolocation';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';

export function FieldSelectState(): ReactElement {
  const states = staticContentStorage.getContent().states;

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, curValues) =>
        prevValues.country_id !== curValues.country_id
      }
    >
      {(form) => {
        const countrySelected = form.getFieldValue('country_id');

        return (
          <Form.Item
            name="state_id"
            label="State"
            rules={[
              {
                required: true,
                message: 'Please fill out this field',
              },
            ]}
          >
            <Select optionFilterProp="children" showSearch>
              {states
                ?.filter((state: State) => state.countryId === countrySelected)
                ?.map((state: State) => (
                  <Select.Option key={state.name} value={state.id || 0}>
                    {state.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
}
