import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Listing } from 'shared/types/puppy';
import {
  getPuppySireId,
  getPuppySireName,
} from 'utilities/puppy/getPuppyUtils/getPuppyUtils';

interface Props {
  listing: Listing;
}

function ColumnPuppySire(props: Props): ReactElement {
  const {
    listing: { puppy },
  } = props;

  return (
    <Link className="ParentLink" to={`/parent/sire/${getPuppySireId(puppy)}`}>
      {getPuppySireName(puppy)}
    </Link>
  );
}

export default ColumnPuppySire;
