import React, { ReactElement, useState } from 'react';
import { Form, Checkbox, Button, Typography, Space, Upload, Alert } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { isEmpty, isNumber } from 'lodash';
import { FormInstance } from 'antd/lib/form';

import { MediaUploaderBtn } from 'shared/fields/fieldMediaUploader/MediaUploaderBtn';
import { setFileExtensionIcon } from 'shared/icon/Icon';
import parentFormHelpers from 'shared/parents/parentFormHelpers';
import { Documents, UploadPuppyHealthDoc } from 'shared/types/document';
import { Nullable } from 'shared/types/general';
import {
  isHealthDocsDueDatePastDue,
  isHealthDocsDueDateOnWindow,
} from 'utilities/healthDocs';
import { DocumentTypeId, DocumentTypeLabel } from 'utilities/const';
import { isBeforeReleaseDate } from 'utilities/healthDocs/healthDocsDueDateCheckers/healthDocsDueDateCheckers';

import './HealthDocUploader.scss';
import showNotificationError from 'shared/notifications/showNotificationError';

interface Props {
  puppyId: Nullable<number>;
  orderId: Nullable<number>;
  documentDueDate: Nullable<string>;
  documents: Documents;
  handleUploadHealthDoc: (props: UploadPuppyHealthDoc) => Promise<void>;
  form: FormInstance;
  lastSoldAt?: string;
}

const healthDocOptions = [
  {
    value: DocumentTypeId.HealthCertificate,
    label: DocumentTypeLabel.HealthCertificate,
  },
  {
    value: DocumentTypeId.VeterinaryHealthReport,
    label: DocumentTypeLabel.VeterinaryHealthReport,
  },
  {
    value: DocumentTypeId.VaccineRecord,
    label: DocumentTypeLabel.VaccineRecord,
  },
];

const HealthDocUploader = React.memo(function HealthDocUploader(
  props: Props,
): ReactElement {
  const {
    documentDueDate,
    documents,
    orderId,
    puppyId,
    handleUploadHealthDoc,
    form,
    lastSoldAt,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const isPastDueDate = isHealthDocsDueDatePastDue(documentDueDate, documents);
  const disableUploadingVHR =
    isHealthDocsDueDatePastDue(documentDueDate, documents) ||
    isHealthDocsDueDateOnWindow(documentDueDate);
  const disableHealthDocUploader = isLoading || isBeforeReleaseDate(lastSoldAt);

  const handleOnFieldsChange = () => {
    setIsFileUploaded(!isEmpty(form.getFieldsValue().healthDocument));
    setIsOptionSelected(!isEmpty(form.getFieldsValue().healthDocType));
  };

  const handleOnClickSubmit = async () => {
    setIsLoading(true);
    const { healthDocType, healthDocument } = form.getFieldsValue();

    if (isNumber(puppyId) && isNumber(orderId) && handleUploadHealthDoc) {
      await handleUploadHealthDoc({
        healthDocType,
        healthDocument,
        puppyId,
        orderId,
      });
    } else {
      showNotificationError({
        error: `Upload missing requirements: PuppyId ${puppyId} OrderId ${orderId}`,
      });
    }

    setIsLoading(false);
    setIsFileUploaded(false);
    setIsOptionSelected(false);
    form.resetFields();
  };

  const handleOnChangeUpload = (info: UploadChangeParam) => {
    parentFormHelpers.buildUploadedMedia(info.file as RcFile).then((media) => {
      form.resetFields(['healthDocument']);
      form.setFieldsValue({
        healthDocument: {
          ...media,
          viewOrder: 0,
          isDefault: 0,
          visibilityId: 3,
        },
      });
      form.validateFields(['healthDocument']);
    });
  };

  return (
    <Form
      className="HealthDocUploaderForm"
      form={form}
      name="documentsHealthForm"
      onFieldsChange={handleOnFieldsChange}
    >
      <Typography.Title className="HealthDocUploaderForm__title" level={5}>
        Documents Upload
      </Typography.Title>

      {isPastDueDate && (
        <section className="HealthDocUploaderForm__warningMessage">
          <Alert
            message={
              <Typography.Title level={5}>
                The travel date has expired.
              </Typography.Title>
            }
            description="The receipt date of your required health documents has expired; however, the puppy is still traveling. Please submit documents ASAP so we can review and clear your payment."
            type="warning"
            showIcon
          />
        </section>
      )}

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          prevValues.healthDocument !== curValues.healthDocument
        }
      >
        {(form) => {
          const mediaValue = form.getFieldValue('healthDocument');

          return (
            <Form.Item name="healthDocument" className="FieldMediaUploader">
              <Upload
                data-testid="puppy-photo-upload"
                showUploadList={false}
                beforeUpload={() => false}
                onChange={(file) => handleOnChangeUpload(file)}
                disabled={disableHealthDocUploader}
                fileList={[]}
              >
                <MediaUploaderBtn
                  label={mediaValue?.name || 'Upload'}
                  disabled={disableHealthDocUploader}
                  type={setFileExtensionIcon(mediaValue?.name) || 'file'}
                />
              </Upload>
            </Form.Item>
          );
        }}
      </Form.Item>

      {isFileUploaded ? (
        <>
          <Form.Item name="healthDocType">
            <Checkbox.Group disabled={isLoading}>
              {healthDocOptions.map((docOption) => (
                <Checkbox
                  key={docOption.value}
                  value={docOption.value}
                  disabled={
                    docOption.value === DocumentTypeId.VeterinaryHealthReport &&
                    !disableUploadingVHR
                  }
                >
                  {docOption.label}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                onClick={handleOnClickSubmit}
                type="primary"
                loading={isLoading}
                disabled={!isOptionSelected}
              >
                SUBMIT
              </Button>
              {!isOptionSelected && (
                <Typography.Paragraph
                  className="HealthDocUploaderForm__alert"
                  type="danger"
                >
                  Please check the documents included.
                </Typography.Paragraph>
              )}
            </Space>
          </Form.Item>
        </>
      ) : null}
    </Form>
  );
});

export default HealthDocUploader;
