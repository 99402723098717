import React, { useEffect, useRef, useState } from 'react';

import puppyPlaceholder from 'assets/img/placeholder-puppy.svg';
import damPlaceholder from 'assets/img/placeholder-dam.svg';
import sirePlaceholder from 'assets/img/placeholder-sire.svg';
import { Icon } from 'shared/icon/Icon';
import { Nullable } from 'shared/types/general';
import { testId } from 'testsUtilities/dataTestId';
import { entityPhotoOnLoad } from './entityPhotoUtils';

import './EntityPhoto.scss';

interface Props {
  entity: 'puppy' | 'dam' | 'sire';
  imageSrc: Nullable<string>;
  height: string;
  width: string;
  label?: string;
}

function EntityPhoto(props: Props) {
  const { imageSrc, width, height, entity, label } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [onLoadError, setOnLoadError] = useState<boolean>(false);
  const mountedRef = useRef(true);

  const placeholder = {
    puppy: puppyPlaceholder,
    dam: damPlaceholder,
    sire: sirePlaceholder,
  };

  const entityPhotoImageClassModifier = `EntityPhoto__image ${
    onLoadError ? 'EntityPhoto__image--placeholder' : ''
  }`;

  useEffect(() => {
    entityPhotoOnLoad(imageSrc)
      .then(() => mountedRef.current && setLoading(false))
      .catch(() => {
        if (mountedRef.current) {
          setLoading(false);
          setOnLoadError(true);
        }
      });

    return () => {
      mountedRef.current = false;
    };
  }, [imageSrc]);

  return (
    <div className="EntityPhoto">
      {loading ? (
        <div
          className="EntityPhoto__image EntityPhoto__image--loading"
          style={{
            width: width,
            height: height,
          }}
        >
          <Icon name="spinner" />
        </div>
      ) : (
        <div
          data-testid={testId.entityPhoto}
          className={entityPhotoImageClassModifier}
          style={{
            width: width,
            height: height,
            backgroundImage: `url(${
              onLoadError ? placeholder[entity] : imageSrc
            }`,
          }}
        />
      )}
      {label && (
        <div
          className="EntityPhoto__label"
          data-testid={testId.entityPhotoLabel}
        >
          {' '}
          {label}
        </div>
      )}
    </div>
  );
}

export default EntityPhoto;
