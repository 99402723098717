import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Listing } from 'shared/types';
import { setModalListing } from 'store/myListings/actions';

export interface UseListingUpdatePrice {
  modalVisible: boolean;
  setModalVisible: (val: boolean) => void;
  openModal: (listing: Listing) => void;
}

function useListingUpdatePrice(): UseListingUpdatePrice {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const openModal = (listing: Listing) => {
    setModalVisible(true);
    dispatch(setModalListing(listing));
  };

  return {
    modalVisible,
    setModalVisible,
    openModal,
  };
}

export default useListingUpdatePrice;
