import React, { ReactElement } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';

import BreederAdminLayout from 'shared/roleBreederAdmin/breederAdminLayout/BreederAdminLayout';
import BreederImpersonateExternal from 'shared/roleBreederAdmin/breederImpersonate/BreederImpersonateExternal';

function BreederAdminExternalDashboard(): ReactElement {
  return (
    <BreederAdminLayout>
      <Breadcrumb>
        <Breadcrumb.Item>
          <HomeOutlined />
          <span>Admin Dashboard</span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <br />
      <h2 className="BaseLayout--BreederAdminDashboard-title">
        You are logged in as a Breeder Admin
      </h2>
      <BreederImpersonateExternal />
    </BreederAdminLayout>
  );
}

export default BreederAdminExternalDashboard;
