export function getFirstWithClassName(className: string): Element {
  return document.getElementsByClassName(className)[0];
}

export function getElementHeight(className: string): number {
  return getFirstWithClassName(className)?.clientHeight || 0;
}

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getElementOffsetTop = (className: string) => {
  const elem = document.getElementsByClassName(className)[0];
  return elem?.getBoundingClientRect().top || 0;
};
