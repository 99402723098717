import { Nullable } from 'shared/types/general';

export const entityPhotoOnLoad = (imageSrc: Nullable<string>) =>
  new Promise((resolve, reject) => {
    if (!imageSrc) reject();
    const img = new Image();
    img.addEventListener('load', () => resolve(img));
    img.addEventListener('error', (err) => reject(err));
    img.src = imageSrc || '';
  });
