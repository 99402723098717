import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import {
  HomeOutlined,
  CreditCardOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { Icon } from 'shared/icon/Icon';
import { AsideMenuCollapsableProps } from 'shared/baseLayout/baseLayoutHeader/BaseLayoutHeader';
import { StaticContentPagesNavigation } from 'shared/navigation/StaticContentPagesNavigation';
import { BreederRoutes } from 'shared/types/routes';
import { AsideLogo } from './asideLogo/AsideLogo';

import './Navigation.scss';

const { Item, SubMenu } = Menu;

export const Navigation = (props: AsideMenuCollapsableProps): ReactElement => {
  const { collapsed, setCollapsed } = props;

  const handleNavigation = () => {
    if (setCollapsed && !collapsed) {
      setCollapsed(!collapsed);
    }
  };

  return (
    <>
      <Menu
        className={`
          Navigation 
          ${collapsed ? 'Navigation--collapsed' : 'Navigation--uncollapsed'}
        `}
        mode="inline"
        onClick={handleNavigation}
      >
        <AsideLogo collapsed={collapsed} />
        <Item icon={<HomeOutlined />} className="Navigation__item">
          <Link
            to={BreederRoutes.Dashboard}
            className="Navigation__link"
            data-testid="navigation-link-dashboard"
          >
            Dashboard
          </Link>
        </Item>
        <Item icon={<CreditCardOutlined />} className="Navigation__item">
          <Link
            to={BreederRoutes.Transactions}
            className="Navigation__link"
            data-testid="navigation-link-my-transactions"
          >
            My Transactions
          </Link>
        </Item>
        <Item icon={<ExclamationCircleOutlined />} className="Navigation__item">
          <Link
            to={BreederRoutes.MyUpdates}
            className="Navigation__link"
            data-testid="navigation-link-my-updates"
          >
            My Upcoming Updates
          </Link>
        </Item>
        <SubMenu
          icon={<Icon name="puppy" />}
          className="Navigation__submenu"
          key="sub0"
          title="My Puppy Listings"
        >
          <Item>
            <Link
              to={BreederRoutes.MyListings}
              className="Navigation__link"
              data-testid="navigation-link-my-puppy-listings"
            >
              All
            </Link>
          </Item>
          <Item>
            <Link
              to={BreederRoutes.MyListingsPending}
              className="Navigation__link"
              data-testid="navigation-link-pending-puppies-dashboard"
            >
              Pending
            </Link>
          </Item>
          <Item>
            <Link
              to={BreederRoutes.MyListingsApproved}
              className="Navigation__link"
              data-testid="navigation-link-pending-puppies-dashboard"
            >
              Approved
            </Link>
          </Item>
          <Item>
            <Link
              to={BreederRoutes.MyListingsSold}
              className="Navigation__link"
              data-testid="navigation-link-sold-puppies-dashboard"
            >
              Sold
            </Link>
          </Item>
          <Item>
            <Link
              to={BreederRoutes.MyListingsDelivered}
              className="Navigation__link"
              data-testid="navigation-link-pending-puppies-dashboard"
            >
              Delivered
            </Link>
          </Item>
          <Item>
            <Link
              to={BreederRoutes.MyListingsHidden}
              className="Navigation__link"
              data-testid="navigation-link-pending-puppies-dashboard"
            >
              Hidden
            </Link>
          </Item>
        </SubMenu>
        <Item icon={<Icon name="litter" />} className="Navigation__item">
          <Link
            to={BreederRoutes.MyLitters}
            className="Navigation__link"
            data-testid="navigation-link-my-litters"
          >
            My Litters
          </Link>
        </Item>
        <Item icon={<Icon name="sire" />} className="Navigation__item">
          <Link
            to={BreederRoutes.MyParents}
            className="Navigation__link"
            data-testid="navigation-link-my-parents"
          >
            My Dams & Sires
          </Link>
        </Item>

        <div className="Navigation__divider" />

        {StaticContentPagesNavigation.map((navItem) => (
          <SubMenu
            className="Navigation__submenu"
            key={navItem.key}
            icon={navItem.icon}
            title={navItem.title}
          >
            {navItem.items.map((navItem) => (
              <Item key={navItem.key}>
                <Link
                  to={navItem.url}
                  className="Navigation__link"
                  data-testid="navigation-link-webinars"
                >
                  {navItem.title}
                </Link>
              </Item>
            ))}
          </SubMenu>
        ))}
      </Menu>
    </>
  );
};
