import { Nullable } from 'shared/types/general';
import {
  AddMoreLitters,
  ResetMyLitters,
  SetLitters,
} from 'store/myLitters/actions';
import { Litter } from 'shared/types/litter';

export const SET_LITTERS = 'SET_LITTERS';
export const RESET_MY_LITTERS = 'RESET_MY_LITTERS';
export const ADD_MORE_LITTERS = 'ADD_MORE_LITTERS';

export interface LittersState {
  past: Nullable<Litter[]>;
  current: Nullable<Litter[]>;
  upcoming: Nullable<Litter[]>;
  new: Nullable<Litter[]>;
}

export type LittersActionTypes = SetLitters | AddMoreLitters | ResetMyLitters;
