import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Affix, Tabs } from 'antd';
import { isEmpty } from 'lodash';

import { useBaseLayoutAffixOffset } from 'hooks';
import LittersColumn from 'pages/myLitters/littersColumn/LittersColumn';
import { LitterStatuses } from 'shared/types/litter';
import { getBreederLitters } from 'store/myLitters/actions';
import { RootState } from 'store/rootState';

const { TabPane } = Tabs;

export default function MyLittersSmall(): ReactElement {
  const [disableTabs, setDisableTabs] = useState(true);
  const [activeTab, setActiveTab] = useState<LitterStatuses>(
    LitterStatuses.current,
  );
  const state = useSelector((state: RootState) => ({
    past: state.myLitters.past,
    current: state.myLitters.current,
    upcoming: state.myLitters.upcoming,
    new: state.myLitters.new,
  }));
  const { past, current, upcoming, new: newLitter } = state;
  const { offsetTopMyEntityTabBar } = useBaseLayoutAffixOffset();
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchLittersWhenEmpty() {
      if (isEmpty(state[activeTab])) {
        setDisableTabs(true);
        await dispatch(getBreederLitters(activeTab));
        setDisableTabs(false);
      }
    }

    fetchLittersWhenEmpty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <div className="entity-columns-small">
      <Tabs
        className="entity-columns-small__tabs"
        defaultActiveKey={LitterStatuses.current}
        centered
        onChange={(status: string) => {
          setActiveTab(status as LitterStatuses);
        }}
        renderTabBar={(props, DefaultTabBar) => (
          <Affix offsetTop={offsetTopMyEntityTabBar}>
            <DefaultTabBar {...props} />
          </Affix>
        )}
      >
        <TabPane
          disabled={disableTabs}
          className="entity-columns-small__tab-column"
          tab="Upcoming"
          key={LitterStatuses.upcoming}
        >
          <LittersColumn
            litters={upcoming}
            columnName={LitterStatuses.upcoming}
          />
        </TabPane>
        <TabPane
          disabled={disableTabs}
          className="entity-columns-small__tab-column"
          tab="New"
          key={LitterStatuses.new}
        >
          <LittersColumn litters={newLitter} columnName={LitterStatuses.new} />
        </TabPane>

        <TabPane
          disabled={disableTabs}
          className="entity-columns-small__tab-column"
          tab="Current"
          key={LitterStatuses.current}
        >
          <LittersColumn
            litters={current}
            columnName={LitterStatuses.current}
          />
        </TabPane>
        <TabPane
          disabled={disableTabs}
          className="entity-columns-small__tab-column"
          tab="Past"
          key={LitterStatuses.past}
        >
          <LittersColumn litters={past} columnName={LitterStatuses.past} />
        </TabPane>
      </Tabs>
    </div>
  );
}
