import React, { ReactElement, useEffect, useState } from 'react';
import { Form, notification, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { isEmpty } from 'lodash';

import { useBreedColors } from 'hooks';
import { Nullable } from 'shared/types/general';
import { ColorContent } from 'utilities/staticContent/types';
import { EmptyFieldValidationHandler } from '../fieldValidationHandler';

interface Props {
  disabled?: boolean;
  activeHearingTest?: boolean;
  form?: FormInstance;
}

export function FieldColor(props: Props): ReactElement {
  const { disabled, activeHearingTest, form } = props;
  const [breedId, setBreedId] = useState();
  const [colorId, setColorId] = useState();
  const { breedColors, isBreedColorsLoading } = useBreedColors(breedId);

  const requiresHearingTest = (colorId: Nullable<number>) =>
    breedColors?.find((c) => c.id === colorId)?.requiresHearingTest;

  useEffect(() => {
    form?.setFieldsValue({
      requiresHearingTest: requiresHearingTest(colorId),
    });
  });

  useEffect(() => {
    if (!isEmpty(breedColors)) {
      const color = breedColors?.find((c) => c.id === colorId);

      form?.setFieldsValue({
        colorId: color?.id,
      });
    }
  }, [colorId, breedColors, form]);

  const handleHearingTest = (colorId: number) => {
    if (!activeHearingTest) return;

    if (requiresHearingTest(colorId)) {
      notification.info({
        message: `Hearing test mandatory`,
        description: `This breed and color combination requires to check the hearing test field on the physical conditions section.`,
      });
    }

    form?.setFieldsValue({ requiresHearingTest: requiresHearingTest(colorId) });
    form?.validateFields(['medicalDetails']);
  };

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, curValues) => {
        setBreedId(curValues.breedId);
        setColorId(curValues.colorId);
        return (
          prevValues.breedId !== curValues.breedId ||
          prevValues.colorId !== curValues.colorId
        );
      }}
    >
      {() => (
        <Form.Item
          name="colorId"
          label="Color"
          rules={[EmptyFieldValidationHandler('list')]}
        >
          <Select
            disabled={!breedId || disabled}
            placeholder="Select one..."
            showSearch
            data-testid="field-color"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            loading={isBreedColorsLoading}
            onChange={(value) => handleHearingTest(Number(value))}
          >
            {breedColors
              ?.filter((c) => c.id !== 0)
              .map((color: ColorContent, i) => (
                <Select.Option key={i} value={color.id}>
                  {color.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      )}
    </Form.Item>
  );
}
