import React, { ReactElement } from 'react';
import { Checkbox, Form } from 'antd';

import { testId } from 'testsUtilities/dataTestId';
import { EmptyFieldValidationHandler } from '../fieldValidationHandler';

export function FieldMemberAgreement(): ReactElement {
  return (
    <Form.Item noStyle shouldUpdate={true}>
      {() => {
        return (
          <>
            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[EmptyFieldValidationHandler('agreement')]}
            >
              <Checkbox
                data-testid={testId.fieldMemberAgreementsCheckboxAgreement}
              >
                By checking this box, you are electronically signing and
                certifying that you consent to the terms of our{' '}
                <a href="/agreement" target="_blank" rel="noopener noreferrer">
                  Membership Agreement
                </a>
                , that the information provided above is true and accurate, and
                that if you are exempt from USDA licensing, you maintain no more
                than four breeding female dogs and/or cats. You certify that all
                animals you are selling have been born and raised on your
                premises.
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="askingCost"
              valuePropName="checked"
              rules={[EmptyFieldValidationHandler('costConfirm')]}
            >
              <Checkbox
                data-testid={testId.fieldMemberAgreementsCheckboxAskingCost}
              >
                Yes, all of my puppy’s costs are accounted for in the asking
                price field.
              </Checkbox>
            </Form.Item>
          </>
        );
      }}
    </Form.Item>
  );
}
