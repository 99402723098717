import React, { useEffect, useState } from 'react';
import { Button, Input, Space } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

import { regExp } from 'utilities/regExp';
import './ColumnSearch.scss';

interface Props extends FilterDropdownProps {
  placeholder?: string;
  minLength?: number;
  numeric?: boolean;
}

function ColumnSearch(props: Props) {
  const [inputValue, setInputValue] = useState('');
  const {
    placeholder,
    setSelectedKeys,
    selectedKeys,
    confirm,
    minLength = 0,
    numeric = false,
  } = props;

  useEffect(() => {
    if (isEmpty(selectedKeys) || isEmpty(selectedKeys?.[0])) {
      setInputValue('');
    }
  }, [selectedKeys]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = numeric ? regExp.onlyNumber : regExp.alphaAndNotSpecialChars;

    if (regex.test(e.target.value)) {
      setSelectedKeys(e.target.value ? [e.target.value] : []);
      setInputValue(e.target.value);
    }
  };

  const handleReset = () => {
    setSelectedKeys([]);
    confirm();
  };

  const handleConfirm = () => {
    confirm();
  };

  return (
    <section className="ColumnSearch">
      <Input
        className="ColumnSearch__input"
        data-testid="columnSearch-input"
        placeholder={placeholder || 'Search'}
        value={inputValue ?? selectedKeys?.[0]}
        onChange={handleOnChange}
        onPressEnter={handleConfirm}
      />
      <Space>
        <Button
          size="small"
          onClick={handleReset}
          data-testid="columnSearch-reset"
        >
          Reset
        </Button>
        <Button
          size="small"
          type="primary"
          icon={<SearchOutlined />}
          onClick={handleConfirm}
          data-testid="columnSearch-search"
          disabled={
            isEmpty(selectedKeys?.[0]) ||
            selectedKeys?.[0].toString().length < minLength
          }
        >
          Search
        </Button>
      </Space>
    </section>
  );
}

export default ColumnSearch;
