import React, { ReactElement } from 'react';
import { Space } from 'antd';

import UpdatePuppyPhotos from 'pages/myListings/listingCard/updatePuppyPhotos/UpdatePuppyPhotos';
import UpdatePuppyWeight from 'pages/myListings/listingCard/updatePuppyWeight/UpdatePuppyWeight';
import UpdatePuppyRegistration from 'pages/myListings/listingCard/updatePuppyRegistration/UpdatePuppyRegistration';
import { Listing } from 'shared/types/puppy';
import { BreederDashboardStatusTypes } from 'shared/types/statuses';

import './ColumnPuppyUpdates.scss';

interface Props {
  listing: Listing;
  breederDashboardStatus: BreederDashboardStatusTypes;
}

function ColumnPuppyUpdates(props: Props): ReactElement {
  const { listing, breederDashboardStatus } = props;

  return (
    <section className="ColumnPuppyUpdates">
      <Space size={12}>
        <UpdatePuppyPhotos
          columnName={breederDashboardStatus}
          listing={listing}
        />
        <UpdatePuppyWeight
          columnName={breederDashboardStatus}
          listing={listing}
        />
        <UpdatePuppyRegistration
          columnName={breederDashboardStatus}
          listing={listing}
        />
      </Space>
    </section>
  );
}

export default ColumnPuppyUpdates;
