import { Listing, Puppy } from 'shared/types/puppy';
import {
  MyListingsFilters,
  MyListingsPagination,
  MyListingsSorter,
} from 'shared/types/myListings';
import {
  MyListingsApprovedActions,
  SET_MY_LISTINGS_APPROVED,
  SET_MY_LISTINGS_APPROVED_FILTERS,
  SET_MY_LISTINGS_APPROVED_LOADING,
  SET_MY_LISTINGS_APPROVED_PAGINATION,
  SET_MY_LISTINGS_APPROVED_SORTER,
  UPDATE_MY_LISTINGS_APPROVED_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES,
} from 'store/myListingsApproved/types';

export function setMyListingsApprovedLoading(): MyListingsApprovedActions {
  return {
    type: SET_MY_LISTINGS_APPROVED_LOADING,
  };
}

export function setMyListingsApproved(
  listings: Listing[],
): MyListingsApprovedActions {
  return {
    type: SET_MY_LISTINGS_APPROVED,
    listings,
  };
}

export function setMyListingsApprovedFilters(
  filters: MyListingsFilters,
): MyListingsApprovedActions {
  return {
    type: SET_MY_LISTINGS_APPROVED_FILTERS,
    filters,
  };
}

export function setMyListingsApprovedSorter(
  sorter: MyListingsSorter,
): MyListingsApprovedActions {
  return {
    type: SET_MY_LISTINGS_APPROVED_SORTER,
    sorter,
  };
}

export function setMyListingsApprovedPagination(
  pagination: MyListingsPagination,
): MyListingsApprovedActions {
  return {
    type: SET_MY_LISTINGS_APPROVED_PAGINATION,
    pagination,
  };
}

export function updateMyListingsApprovedPuppyRegistrationsWeightDueDates(
  puppy: Puppy,
): MyListingsApprovedActions {
  return {
    type: UPDATE_MY_LISTINGS_APPROVED_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES,
    puppy,
  };
}
