import React, { ReactElement, useEffect, useState } from 'react';
import { Form, notification, Modal } from 'antd';
import { SubmitButton } from 'shared/fields';
import userStorage from 'utilities/user/userStorage';
import { Credentials } from 'pages/login/Login';
import showNotificationError from 'shared/notifications/showNotificationError';
import apiUser from 'utilities/http/api/apiUser';
import FieldEmail from 'shared/fields/fieldEmail/FieldEmail';
import FieldPassword from 'shared/fields/fieldPassword/FieldPassword';
import { logout } from 'utilities/refreshToken/refreshToken';

const onSubmitError = (errorMessage?: string) =>
  showNotificationError({
    error: errorMessage || "Couldn't change your password, please try again",
  });

export default function CredentialsForm(): ReactElement {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form] = Form.useForm();
  const user = userStorage.getUser();
  useEffect(() => {
    if (!user) return;
    form.setFieldsValue({
      email: user?.email,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSubmit = async (credentials: Credentials) => {
    setIsSubmitting(true);
    try {
      const resp = await apiUser.updateUserCredentials({
        password: credentials.password,
        email: credentials.email,
      });
      if (resp.status === 200) {
        notification.success({
          message: 'Credentials changed',
          duration: 1000,
        });
        setTimeout(() => {
          logout();
        }, 1000);
      } else {
        onSubmitError(resp?.json?.errors?.email?.[0]);
      }
    } catch (e) {
      onSubmitError();
    } finally {
      setIsSubmitting(false);
    }
  };

  const showConfirmationBoard = (credentials: Credentials) => {
    let title = 'Are you sure you want to change your email and password?';
    let content = 'It requires you to sign in with the new email and password';

    if (credentials.email === user?.email) {
      title = 'Are you sure you want to change your password';
      content = 'It requires you to sign in with the new password.';
    }

    Modal.confirm({
      title,
      content,
      onOk: () => onSubmit(credentials),
    });
  };

  return (
    <Form
      data-testid="credentials-form"
      name="credentialsForm"
      layout="vertical"
      scrollToFirstError
      size="large"
      className="CredentialsForm"
      onFinish={showConfirmationBoard}
      form={form}
    >
      <FieldEmail />
      <FieldPassword label="Change Password" />
      <SubmitButton
        label="Save"
        loading={isSubmitting}
        testId="credentials-submit-btn"
      />
    </Form>
  );
}
