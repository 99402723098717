import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import MyParentsSearch from 'components/parents/myParentsSearch/MyParentsSearch';
import MyParentsLarge from 'pages/myParents/myParentsLarge/MyParentsLarge';
import MyParentsSmall from 'pages/myParents/myParentsSmall/MyParentsSmall';
import PageLayout from 'shared/baseLayout/pageLayout/PageLayout';
import {
  myParentsGetParents,
  myParentsResetState,
} from 'store/myParents/actions';
import { RootState } from 'store/rootState';

import './MyParents.scss';

const breadcrumb = [
  {
    name: 'My Dams & Sires',
  },
];

export default function MyParents(): ReactElement {
  const { searchParams } = useSelector((state: RootState) => state.myParents);
  const dispatch = useDispatch();
  const screens = useBreakpoint();

  useEffect(() => {
    return () => {
      dispatch(myParentsResetState());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(myParentsGetParents('dams', 1, searchParams));
    dispatch(myParentsGetParents('sires', 1, searchParams));
  }, [searchParams, dispatch]);

  return (
    <PageLayout className="MyParents" breadCrumbsNav={breadcrumb}>
      <MyParentsSearch />
      {screens.xl ? <MyParentsLarge /> : <MyParentsSmall />}
    </PageLayout>
  );
}
