import AntIcon, {
  CameraOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  FileExcelOutlined,
  FileGifOutlined,
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileWordOutlined,
  HeartOutlined,
  HourglassOutlined,
  IdcardOutlined,
  LoadingOutlined,
  PhoneOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import React, { ReactElement } from 'react';

import { ReactComponent as IconLitter } from 'assets/icons/icon-litter.svg';
import { ReactComponent as IconMicrochip } from 'assets/icons/icon-microchip.svg';
import { ReactComponent as IconDam } from 'assets/icons/icon-parent-dam.svg';
import { ReactComponent as IconSire } from 'assets/icons/icon-parent-sire.svg';
import { ReactComponent as IconPuppy } from 'assets/icons/icon-puppy.svg';
import { ReactComponent as IconTravelCase } from 'assets/icons/icon-travel-case.svg';

interface IconProps {
  name: string;
  className?: string;
}

interface IconTypeProps {
  [key: string]: ReactElement;
}

export function Icon(props: IconProps): ReactElement {
  const { name, className } = props;
  const iconList: IconTypeProps = {
    puppy: <AntIcon component={IconPuppy} className={className} />,
    sire: <AntIcon component={IconSire} className={className} />,
    dam: <AntIcon component={IconDam} className={className} />,
    litter: <AntIcon component={IconLitter} className={className} />,
    travelCase: <AntIcon component={IconTravelCase} className={className} />,
    spinner: <LoadingOutlined spin className={className} data-testid={name} />,
    warning: <ExclamationCircleOutlined className={className} />,
    success: <CheckCircleOutlined className={className} />,
    pending: <HourglassOutlined className={className} />,
    phone: <PhoneOutlined className={className} />,
    photo: <CameraOutlined className={className} />,
    video: <VideoCameraOutlined className={className} />,
    upload: <UploadOutlined className={className} />,
    media: <UploadOutlined className={className} />,
    file: <FileOutlined className={className} />,
    filePdf: <FilePdfOutlined className={className} />,
    fileImg: <FileImageOutlined className={className} />,
    filePng: <FileImageOutlined className={className} />,
    fileText: <FileTextOutlined className={className} />,
    fileGif: <FileGifOutlined className={className} />,
    fileWord: <FileWordOutlined className={className} />,
    fileExcel: <FileExcelOutlined className={className} />,
    userOutlined: <UserOutlined className={className} />,
    idcardOutlined: <IdcardOutlined className={className} />,
    microchip: <AntIcon component={IconMicrochip} className={className} />,
    healthDocs: <HeartOutlined className={className} />,
  };
  return iconList[name];
}

export const setFileExtensionIcon = (mediaName: string | undefined) => {
  const splittedByDots = mediaName?.split('.') || [];
  const fileExtension = splittedByDots[splittedByDots.length - 1];

  switch (fileExtension) {
    case 'pdf':
      return 'filePdf';
    case 'jpg':
    case 'jpeg':
    case 'png':
      return 'fileImg';
    case 'gif':
      return 'fileGif';
    case 'xls':
    case 'xlsx':
      return 'fileExcel';
    case 'docx':
    case 'doc':
      return 'fileWord';
    case 'txt':
      return 'fileText';
    default:
      return 'file';
  }
};
