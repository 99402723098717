import { Puppy, PuppyPhotos } from 'shared/types/puppy';
import {
  puppyActionTypes,
  RESET_PUPPY,
  SET_PUPPY,
  SET_SINGLE_PUPPY_PHOTO_HIDDEN,
  SET_PUPPY_PHOTOS_ADDITIONAL,
  SET_PUPPY_PHOTOS_COVER,
  SET_PUPPY_PHOTOS_HIDDEN,
  UPDATE_NEW_PUPPY_PHOTO_ID,
  SET_PUPPY_ORDER,
} from './types';
import { updateUploadedPhotoId } from './actions';

const initialState: Partial<Puppy> = {};
export function puppyReducer(
  state = initialState,
  action: puppyActionTypes,
): Partial<Puppy> {
  switch (action.type) {
    case SET_PUPPY:
      return action.puppy;
    case RESET_PUPPY:
      return initialState;
    case SET_PUPPY_PHOTOS_COVER:
      return {
        ...state,
        photos: {
          ...state.photos,
          coverPhotos: action.coverPhotos,
        } as PuppyPhotos,
      };
    case SET_PUPPY_PHOTOS_ADDITIONAL:
      return {
        ...state,
        photos: {
          ...state.photos,
          additionalPhotos: action.additionalPhotos,
        } as PuppyPhotos,
      };
    case SET_PUPPY_PHOTOS_HIDDEN:
      return {
        ...state,
        photos: {
          ...state.photos,
          hiddenPhotos: action.hiddenPhotos,
        } as PuppyPhotos,
      };
    case SET_SINGLE_PUPPY_PHOTO_HIDDEN:
      return {
        ...state,
        photos: {
          ...state.photos,
          hiddenPhotos: [
            ...(state.photos?.hiddenPhotos || []),
            action.hiddenPhoto,
          ],
        } as PuppyPhotos,
      };
    case UPDATE_NEW_PUPPY_PHOTO_ID:
      return {
        ...state,
        photos: updateUploadedPhotoId(
          action.payload,
          state.photos as PuppyPhotos,
        ),
      };
    case SET_PUPPY_ORDER:
      return {
        ...state,
        order: action.order,
      };
    default:
      return state;
  }
}
