import React, { ReactElement } from 'react';
import { Row, Col } from 'antd';

import PuppyInventory from 'shared/puppyInventory/PuppyInventory';
import PuppyPerformance from 'shared/puppyPerformance/PuppyPerformance';
import { DashboardSummary } from '../Dashboard';
import { UpcomingUpdates } from '../upcomingUpdates/UpcomingUpdates';
import { Nullable } from 'shared/types/general';

interface props {
  dashboardSummary?: Nullable<DashboardSummary>;
}

export default function FullDashboard(props: props): ReactElement | null {
  const { dashboardSummary } = props;
  if (!dashboardSummary) return null;
  return (
    <Row justify="center">
      <Col xs={24} xl={16}>
        <UpcomingUpdates upcomingDates={dashboardSummary.upcomingDueDates} />
        <PuppyInventory statusCounts={dashboardSummary.statusCounts} />
      </Col>
      <Col xs={24} xl={8}>
        <PuppyPerformance
          mostViewedPuppies={dashboardSummary.mostViewedPuppies}
        />
      </Col>
    </Row>
  );
}
