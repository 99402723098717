import React, { ReactElement } from 'react';
import { Row, Col, Form, Checkbox } from 'antd';

import { FormFieldProps } from 'shared/types/form';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import { PottyTrainingTypeContent } from 'utilities/staticContent/types';

export function FieldPottyTraining({ disabled }: FormFieldProps): ReactElement {
  const pottyTrainingTypes = staticContentStorage.getContent()
    .pottyTrainingTypes;

  return (
    <Form.Item name="pottyTrainingTypes" label="Potty training">
      <Checkbox.Group disabled={disabled}>
        <Row gutter={[8, 24]}>
          {pottyTrainingTypes.map(
            (pottyTrainingType: PottyTrainingTypeContent) => (
              <Col span={12} key={pottyTrainingType.id}>
                <Checkbox value={pottyTrainingType.id}>
                  {pottyTrainingType.name}
                </Checkbox>
              </Col>
            ),
          )}
        </Row>
      </Checkbox.Group>
    </Form.Item>
  );
}
