export const testId = {
  // Add Family Navigation
  addFamilyNavigation: 'add-family-navigation',
  // Litter Detail Summary
  litterDetailSummary: 'litter-detail-summary',
  litterDetailSummaryInfoParentsName: 'litter-detail-summary-info-parents-name',
  litterDetailSummaryInfoBreed: 'litter-detail-summary-info-breed',
  litterDetailSummaryInfoLitterSize: 'litter-detail-summary-info-litter-size',
  litterDetailSummaryInfoBirthDate: 'litter-detail-summary-info-birthdate',
  litterDetailSummaryInfoCrateTrained:
    'litter-detail-summary-info-crate-trained',
  litterDetailSummaryInfoCrateTrainingTypes:
    'litter-detail-summary-info-crate-training-types',
  litterDetailSummaryChangeDam: 'litter-detail-summary-change-dam',
  litterDetailSummaryChangeSire: 'litter-detail-summary-change-sire',
  litterDetailSummaryChangeParentsAlert:
    'litter-detail-summary-change-parents-alert',

  entityBadge: 'entity-badge',
  listingLockedBadgeModalContent: 'listing-locked-badge-modal-content',
  parentLockedBadgeModalContent: 'parent-locked-badge-modal-content',
  pendingEditsBadgeModalContent: 'pending-edits-badge-modal-content',

  // Entity Photo
  entityPhoto: 'entity-photo',
  entityPhotoLabel: 'entity-photo-label',
  // Entity Card Litter
  entityCardLitter: 'entity-card-litter',
  entityCardLitterInfoParentsName: 'entity-card-litter-info-parents-name',
  entityCardLitterInfoBreed: 'entity-card-litter-info-breed',
  entityCardLitterInfoLitterSize: 'entity-card-litter-info-litter-size',
  entityCardLitterInfoBirthdate: 'entity-card-litter-info-birthdate',
  entityCardLitterInfoCrateTrained: 'entity-card-litter-info-crate-trained',
  entityCardLitterInfoCrateTrainingTypes:
    'entity-card-litter-info-crate-training-types',
  // My Parents Search
  myParentsSearch: 'my-parents-search',
  myParentsSearchLabel: 'my-parents-search-label',
  myParentsSearchSelectSearchBy: 'my-parents-search-select-search-by',
  myParentsSearchInput: 'my-parents-search-input',
  myParentsLoadMore: 'my-parents-load-more',
  myParentsContentSmall: 'my-parents-content-small',
  myParentsContentLarge: 'my-parents-content-large',
  // Entity Card Parents
  entityCardParentSire: 'entity-card-parent-sire',
  entityCardParentDam: 'entity-card-parent-dam',
  entityCardParent: 'entity-card-parent',
  entityCardParentRetireButton: 'entity-card-parent-retire-button',
  // Travel
  puppyTravelPlaning: 'puppy-travel-planing',
  puppyTravelSequence: 'puppy-travel-sequence',
  puppyTravelProposal: 'puppy-travel-proposal',

  travelProposalInfoMeetUp: 'travel-proposal-info-meet-up',
  travelProposalInfoChaperone: 'travel-proposal-info-chaperone',
  travelProposalInfoGround: 'travel-proposal-info-ground',
  travelProposalInfoCharter: 'travel-proposal-info-charter',
  travelProposalInfoCargo: 'travel-proposal-info-cargo',

  travelSequenceInfoFlight: 'travel-sequence-info-flight',
  travelSequenceInfoGround: 'travel-sequence-info-ground',
  travelSequenceInfoChaperone: 'travel-sequence-info-chaperone',
  travelSequenceInfoMeetUp: 'travel-sequence-info-meet-up',
  travelSequenceInfoTransporter: 'travel-sequence-info-transporter',

  puppySold: 'puppy-sold',
  puppySoldTitle: 'puppy-sold-title',
  puppySoldStatusCopy: 'puppy-sold-status-copy',
  puppySoldStatusBadgeIconConfirmed: 'puppy-sold-status-badge-icon-confirmed',
  puppySoldStatusBadgeIconPending: 'puppy-sold-status-badge-icon-pending',
  puppySoldShowTravelPlanButton: 'puppy-sold-show-travel-plan-button',
  puppySoldShowPuppyFormButton: 'puppy-sold-show-puppy-form-button',

  fieldPuppyCostInput: 'field-puppy-cost-input',
  fieldPuppyCostInfoIcon: 'field-puppy-cost-info-icon',
  fieldPuppyCostInfoPopOver: 'field-puppy-cost-info-popover',

  fieldChangePuppyLitterInput: 'field-change-puppy-litter-input',
  fieldChangePuppyLitterSelect: 'field-change-puppy-litter-select',
  fieldChangePuppyLitterButtonChange: 'field-change-puppy-litter-button-change',
  fieldChangePuppyLitterButtonCancel: 'field-change-puppy-litter-button-cancel',

  fieldMemberAgreementsCheckboxAgreement:
    'field-member-agreements-checkbox-agreement',
  fieldMemberAgreementsCheckboxAskingCost:
    'field-member-agreements-checkbox-asking-cost',

  puppyHealthFee: 'puppy-health-fee',
  puppyHealthFeeCoverageValue: 'puppy-health-fee-coverage-value',
  puppyHealthFeeAdjustedAskingPriceValue:
    'puppy-health-fee-adjusted-asking-price-value',

  litterDetailPuppiesSummaryItem: 'litter-detail-puppies-summary-item',
  litterDetailPuppiesSummaryItemViews:
    'litter-detail-puppies-summary-item-views',

  sectionLoading: 'section-loading',

  // Tags
  deliveredTag: 'delivered-tag',
  soldTag: 'sold-tag',
  unlistedTag: 'unlisted-tag',

  unlistedRelistedPuppyBtn: 'unlisted-relisted-puppy-btn',

  myListingsPending: 'my-listings-pending',
  myListingsPendingCardWrapper: 'my-listings-pending-wrapper',
  myListingsApprovedCardWrapper: 'my-listings-approved-wrapper',
  myListingsDeliveredCardWrapper: 'my-listings-delivered-wrapper',
  myListingsHiddenCardWrapper: 'my-listings-hidden-wrapper',
  myListingsPendingTable: 'my-listings-pending-table',
  myListingsApprovedTable: 'my-listings-approved-table',
  myListingsDeliveredTable: 'my-listings-delivered-table',
  myListingsSold: 'my-listings-sold',
  myListingsSoldCardWrapper: 'my-listings-sold-wrapper',
  myListingsSoldTable: 'my-listings-sold-table',
  myListingsHiddenTable: 'my-listings-hidden-table',

  healthDocBadgeStatus: 'health-doc-badge-status',
  healthDocBadgeStatusFullInfo: 'health-doc-badge-status-full-info',
  healthDocDueDate: 'health-doc-due-date',
  downloadHealthDocs: 'download-health-docs',

  puppyDetailTileHealthDocs: 'puppy-detail-tile-health-documents',
  puppyDetailTileTravelPlans: 'puppy-detail-tile-travel-plans',
  puppyDetailTilePuppyUpdates: 'puppy-detail-tile-puppy-updates',
} as const;
