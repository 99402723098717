import React, { ReactElement } from 'react';
import { Form, InputNumber } from 'antd';

import { FormFieldProps } from 'shared/types/form';

export function FieldLitterSize({ disabled }: FormFieldProps): ReactElement {
  return (
    <Form.Item name="size" label="Litter size">
      <InputNumber min={1} max={18} disabled={disabled} />
    </Form.Item>
  );
}
