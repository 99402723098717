import React, { ReactElement, useEffect, useState } from 'react';
import { StaticPageProps } from 'shared/staticPage/StaticPage';
import { CalendarOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { isEmpty } from 'lodash';
import { CollapsablePanelHeaderWebinars } from './CollapsablePanelHeaderWebinars';
import date from 'utilities/time/date';

const { Panel } = Collapse;

interface WebinarCard {
  whoIsTheHost: string;
  whatTopic: string;
  descriptionWebinar: string;
  dateWebinar: string;
  linkNameWebinar: string;
  linkUrlWebinar: string;
  buttonLabel?: string;
}

export default function Webinar(props: StaticPageProps): ReactElement {
  const { staticData } = props;
  const [webinarCard, setwebinarCard] = useState<WebinarCard[]>([]);
  const [webinarDescription, setWebinarDescription] = useState('');
  const [faqDate, setFaqDate] = useState('');

  useEffect(() => {
    if (staticData) {
      setwebinarCard(staticData.webinarCard);
      setWebinarDescription(staticData.webinarDescription);
      setFaqDate(staticData.datePublished);
    }
  }, [staticData]);

  return (
    <div className="StaticPage StaticPage--webinar">
      {faqDate && (
        <p>Last updated: {date.timeZone(faqDate, 'America/New_York')}</p>
      )}
      <div dangerouslySetInnerHTML={{ __html: webinarDescription }} />
      <Collapse
        ghost
        className="StaticPage__collapse StaticPage__collapse--webinars"
        bordered={false}
      >
        {!isEmpty(webinarCard) &&
          webinarCard.map((item, idx) => (
            <Panel
              className="StaticPage__collapse-panel StaticPage__collapse--webinars-panel"
              key={idx}
              showArrow={false}
              header={
                <CollapsablePanelHeaderWebinars
                  host={item.whoIsTheHost}
                  title={item.whatTopic}
                />
              }
            >
              <p className="StaticPage__collapse-description" key={idx}>
                {item.descriptionWebinar}
              </p>
              <div className="StaticPage__collapse-content">
                <div className="StaticPage__collapse-date">
                  <CalendarOutlined />
                  <span>
                    {date.timeZone(item.dateWebinar, 'America/New_York')}
                  </span>
                </div>
                <div className="StaticPage__collapse-link">
                  <span>
                    <VideoCameraOutlined /> {item.linkNameWebinar} Webinar
                  </span>
                  <a
                    href={item.linkUrlWebinar}
                    target="_blank"
                    type="primary"
                    rel="noopener noreferrer"
                  >
                    {isEmpty(item.buttonLabel) ? 'Register' : item.buttonLabel}
                  </a>
                </div>
              </div>
            </Panel>
          ))}
      </Collapse>
    </div>
  );
}
