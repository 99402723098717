import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Divider, Form, Row, Typography } from 'antd';

import {
  HealthDocsStatus,
  HealthDocsUploadsList,
  HealthDocUploader,
  useHealthDocsTileStatus,
  useHealthDocUploader,
} from 'components/healthDocs';
import { SetState } from 'shared/types/general';
import { Collapsable } from 'shared/collapsable/Collapsable';
import { updatePuppyDetailHealthDocs } from 'store/puppy/actions.thunk';
import { RootState } from 'store/rootState';
import { isDelivered } from 'utilities/entities/puppy';

import './PuppyDetailHealthDocs.scss';

interface Props {
  setShowPuppyHealthDocs: SetState<boolean>;
}

function PuppyDetailHealthDocs(props: Props): ReactElement {
  const { setShowPuppyHealthDocs } = props;
  const [form] = Form.useForm();
  const puppy = useSelector((state: RootState) => state.puppy);
  const { healthDocsTileStatus } = useHealthDocsTileStatus(
    puppy.documents,
    puppy.listing?.documentDueDate,
    isDelivered(puppy),
  );
  const {
    updatePuppyHealthDocs,
    setUpdatePuppyHealthDocs,
    handleUploadHealthDoc,
  } = useHealthDocUploader();
  const dispatch = useDispatch();

  useEffect(() => {
    if (updatePuppyHealthDocs) {
      dispatch(updatePuppyDetailHealthDocs());
      setUpdatePuppyHealthDocs(false);
    }
  }, [updatePuppyHealthDocs, dispatch, setUpdatePuppyHealthDocs]);

  return (
    <Collapsable
      id="health-docs-collapsable"
      type={healthDocsTileStatus}
      title="Health Documents"
      icon="healthDocs"
      isOpen
      handleCollapsableOpen={setShowPuppyHealthDocs}
      customExpandIcon="Go Back"
    >
      <section className="PuppyDetailHealthDocs">
        <Row justify="center">
          <Col xs={24} sm={14} lg={10}>
            <p className="PuppyDetailHealthDocs__customer-name">
              <Typography.Text>Customer </Typography.Text>

              <Typography.Text strong>
                {puppy?.order?.leads[0]?.firstName}{' '}
                {puppy?.order?.leads[0]?.lastName}
              </Typography.Text>
            </p>

            <Divider />

            <HealthDocsStatus
              puppyId={puppy.id as number}
              documents={puppy.documents}
              documentDueDate={puppy.listing?.documentDueDate}
              isPuppyDelivered={isDelivered(puppy)}
            />

            <HealthDocUploader
              documents={puppy.documents}
              documentDueDate={puppy.listing?.documentDueDate}
              puppyId={puppy.id}
              orderId={puppy.order?.id}
              lastSoldAt={puppy.listing?.lastSoldAt}
              handleUploadHealthDoc={handleUploadHealthDoc}
              form={form}
            />

            <Divider />

            {puppy.listing && puppy.listing.lastSoldAt && (
              <HealthDocsUploadsList
                documents={puppy.documents}
                lastSoldAt={puppy.listing?.lastSoldAt}
              />
            )}
          </Col>
        </Row>
      </section>
    </Collapsable>
  );
}

export default PuppyDetailHealthDocs;
