import React, { ReactElement } from 'react';
import moment, { MomentInput } from 'moment';
import { DatePicker, Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';

import { EmptyFieldValidationHandler } from 'shared/fields/fieldValidationHandler';
import date, { MMDDYYYY } from 'utilities/time/date';

interface Props {
  disabled?: boolean;
}
export function FieldDateAvailable(props: Props): ReactElement {
  const { disabled } = props;

  const handleOnChange = (
    datePicked: moment.Moment | null,
    form: FormInstance,
  ) => {
    form.setFieldsValue({
      dateAvailable: datePicked ? date.toYYYYMMDD(datePicked) : undefined,
    });
    form.validateFields(['dateAvailable']);
    return;
  };

  return (
    <>
      <Form.Item name="litterBirthDate" noStyle>
        <Input hidden />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) => {
          return prevValues.litterBirthDate !== curValues.litterBirthDate;
        }}
      >
        {(form) => {
          const fieldValue = form.getFieldValue(['dateAvailable']);
          const litterBirthDate = form.getFieldValue('litterBirthDate');
          const datePickerValue = fieldValue ? moment(fieldValue) : undefined;

          const disabledDate = (current: MomentInput) => {
            return moment(current).isBefore(
              moment(litterBirthDate).add(8, 'weeks'),
            );
          };

          return (
            <Form.Item
              name="dateAvailable"
              label="Availability date"
              rules={[EmptyFieldValidationHandler('input')]}
            >
              <>
                <Input hidden />
                <Form.Item style={{ marginBottom: 0 }}>
                  <DatePicker
                    disabled={disabled}
                    value={datePickerValue}
                    disabledDate={disabledDate}
                    style={{ width: '50%' }}
                    format={MMDDYYYY}
                    onChange={(datePicked) =>
                      handleOnChange(datePicked, form as FormInstance)
                    }
                  />
                </Form.Item>
              </>
            </Form.Item>
          );
        }}
      </Form.Item>
    </>
  );
}
