import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Affix, Input, Select, Space } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import { useBaseLayoutAffixOffset } from 'hooks';
import { ParentSearchBy } from 'shared/types/parent';
import { myParentsSetParentsSearchParams } from 'store/myParents/actions';
import { testId } from 'testsUtilities/dataTestId';

import './MyParentsSearch.scss';

function MyParentsSearch(): ReactElement {
  const [searchBy, setSearchBy] = useState<ParentSearchBy>('name_contains');
  const { offsetTopMyEntitySearch } = useBaseLayoutAffixOffset();

  const screens = useBreakpoint();
  const dispatch = useDispatch();

  const label = screens.xl ? 'Search by' : 'Search';
  const myParentsSearchClassModifier = `MyParentsSearch my-entity-search  ${
    screens.xl ? 'MyParentsSearch--large' : 'MyParentsSearch--small'
  }`;

  const handleOnChangeSearchBy = (value: ParentSearchBy) => {
    setSearchBy(value);
  };

  const handleOnSearch = (value: string) => {
    if (value) {
      dispatch(
        myParentsSetParentsSearchParams({ searchBy, searchByValue: value }),
      );
    } else {
      dispatch(myParentsSetParentsSearchParams(null));
    }
  };

  return (
    <Affix offsetTop={offsetTopMyEntitySearch}>
      <section
        className={myParentsSearchClassModifier}
        data-testid={testId.myParentsSearch}
      >
        <Space size={8}>
          <label data-testid={testId.myParentsSearchLabel}>{label}</label>
          <Select
            data-testid={testId.myParentsSearchSelectSearchBy}
            value={searchBy}
            onChange={handleOnChangeSearchBy}
          >
            <Select.Option value="name_contains">Parent Name</Select.Option>
          </Select>
          <Input.Search
            data-testid={testId.myParentsSearchInput}
            placeholder="Type your search here"
            enterButton
            onSearch={handleOnSearch}
            allowClear
          />
        </Space>
      </section>
    </Affix>
  );
}

export default MyParentsSearch;
