import React, { ReactElement } from 'react';
import { Row, Col, Form, Checkbox } from 'antd';

import { FormFieldProps } from 'shared/types/form';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import { SocializationContent } from 'utilities/staticContent/types';

export function FieldSocialization({ disabled }: FormFieldProps): ReactElement {
  const socializations = staticContentStorage.getContent().socializations;

  return (
    <Form.Item name="socializations" label="Socialization type">
      <Checkbox.Group disabled={disabled}>
        <Row gutter={[8, 24]}>
          {socializations?.map((socialization: SocializationContent) => (
            <Col span={12} key={socialization.id}>
              <Checkbox value={socialization.id}>{socialization.name}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </Form.Item>
  );
}
