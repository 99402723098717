import React, { ReactElement } from 'react';
import { Col, Checkbox, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';

import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import { MedicalDetailContent } from 'utilities/staticContent/types';
import { FieldMedicalDetailsValidation } from './FieldMedicalDetailsValidation';

interface Props {
  disabled?: boolean;
}

export function FieldMedicalDetails(props: Props): ReactElement {
  const { disabled } = props;
  const medicalDetails = staticContentStorage.getContent().medicalDetails;

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, curValues) =>
        prevValues.colorId !== curValues.colorId ||
        prevValues.medicalDetails !== curValues.medicalDetails
      }
    >
      {(form) => {
        return (
          <>
            <Form.Item
              name="medicalDetails"
              label="Physical conditions"
              rules={[FieldMedicalDetailsValidation(form as FormInstance)]}
            >
              <Checkbox.Group disabled={disabled}>
                <Row gutter={[8, 24]}>
                  {medicalDetails?.map(
                    (medicalDetail: MedicalDetailContent) => {
                      return (
                        <Col xs={12} key={medicalDetail.id}>
                          <Checkbox value={medicalDetail.id}>
                            {medicalDetail.name}
                          </Checkbox>
                        </Col>
                      );
                    },
                  )}
                </Row>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              name="requiresHearingTest"
              valuePropName="checked"
              noStyle
            >
              <Checkbox style={{ display: 'none' }} />
            </Form.Item>
          </>
        );
      }}
    </Form.Item>
  );
}
