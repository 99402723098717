import { Dispatch } from 'redux';
import { notification } from 'antd';

import {
  Genders,
  MyParentsTypes,
  Parent,
  ParentsSearchParams,
} from 'shared/types/parent';
import { Nullable, PaginationMeta } from 'shared/types/general';
import {
  MyParentsActionTypes,
  MY_PARENTS_RESET_STATE,
  MY_PARENTS_SET_MORE_PARENTS,
  MY_PARENTS_SET_PARENTS_LOADING,
  MY_PARENTS_SET_PARENTS_LOADING_MORE,
  MY_PARENTS_SET_PARENTS,
  MY_PARENTS_SET_PARENTS_SEARCH_PARAMS,
} from 'store/myParents/types';
import apiParent from 'utilities/http/api/apiParent';

export const myParentsSetParentsLoading = (
  myParentType: MyParentsTypes,
): MyParentsActionTypes => {
  return {
    type: MY_PARENTS_SET_PARENTS_LOADING,
    myParentType,
  };
};

export const myParentsSetParents = (
  myParentType: MyParentsTypes,
  parents: Parent[],
  pagination: PaginationMeta,
): MyParentsActionTypes => {
  return {
    type: MY_PARENTS_SET_PARENTS,
    myParentType,
    parents,
    pagination,
  };
};

export const myParentsSetParentsLoadingMore = (
  myParentType: MyParentsTypes,
): MyParentsActionTypes => {
  return {
    type: MY_PARENTS_SET_PARENTS_LOADING_MORE,
    myParentType,
  };
};

export const myParentsSetMoreParents = (
  myParentType: MyParentsTypes,
  parents: Parent[],
  pagination: PaginationMeta,
): MyParentsActionTypes => {
  return {
    type: MY_PARENTS_SET_MORE_PARENTS,
    myParentType,
    parents,
    pagination,
  };
};

export const myParentsSetParentsSearchParams = (
  searchParams: Nullable<ParentsSearchParams>,
): MyParentsActionTypes => {
  return {
    type: MY_PARENTS_SET_PARENTS_SEARCH_PARAMS,
    searchParams,
  };
};

export const myParentsResetState = (): MyParentsActionTypes => {
  return {
    type: MY_PARENTS_RESET_STATE,
  };
};

export const myParentsGetParents = (
  myParentType: MyParentsTypes,
  page = 1,
  searchParams?: Nullable<ParentsSearchParams>,
) => {
  return async function (dispatch: Dispatch) {
    if (page > 1) {
      dispatch(myParentsSetParentsLoadingMore(myParentType));
    } else {
      dispatch(myParentsSetParentsLoading(myParentType));
    }
    const genderId = myParentType === 'dams' ? Genders.dam : Genders.sire;

    let urlParamsRecord = {
      excludeRetiredParents: `1`,
      active: `1`,
      genderId: `${genderId}`,
      withMedia: `1`,
      sortBy: `status`,
      page: `${page}`,
    };

    if (searchParams) {
      urlParamsRecord = {
        ...urlParamsRecord,
        [`${searchParams.searchBy}`]: `${searchParams.searchByValue}`,
      };
    }

    const appendParams = new URLSearchParams(urlParamsRecord).toString();

    const parentsResp = await apiParent.getParents(appendParams);

    const parents = parentsResp.json.puppyParents || [];
    const pagination = parentsResp.json.paginationMeta;

    if (page > 1) {
      dispatch(myParentsSetMoreParents(myParentType, parents, pagination));
      notification.success({
        message: `${pagination.itemsOnPage} ${
          pagination.itemsOnPage === 1 ? 'parent' : 'parents'
        } loaded`,
      });
    } else {
      dispatch(myParentsSetParents(myParentType, parents, pagination));
    }
  };
};
