import React, { ReactElement } from 'react';
import { Space, Tooltip, Typography } from 'antd';

import { Documents, DocumentTypeIdTypes } from 'shared/types/document';
import { testId } from 'testsUtilities/dataTestId';
import {
  getLatestUpdatedHealthDoc,
  isHealthDocExpired,
} from 'utilities/healthDocs';
import {
  DocumentTypeId,
  DocumentTypeLabel,
  DocumentTypeLabelAbbr,
  DocumentWorkflowStatusId,
  DocumentWorkflowStatusLabel,
} from 'utilities/const';
import date from 'utilities/time/date';

import './HealthDocBadgeStatus.scss';

type BadgeHealthDocumentShape = 'circle' | 'pill' | 'fullInfo';

interface HealthDocBadgeStatusComponentContent {
  label: string;
  classModifier: string;
  workflowStatusTooltipLabel: string;
  expiresAt?: string | null;
}

interface Props {
  documentType: DocumentTypeIdTypes;
  documents: Documents;
  shape?: BadgeHealthDocumentShape;
  withWorkflowStatusTooltip?: boolean;
}

function HealthDocBadgeStatus(props: Props): ReactElement {
  const {
    documentType,
    documents,
    shape = 'circle',
    withWorkflowStatusTooltip = true,
  } = props;

  const handleBuildComponentContent = (): HealthDocBadgeStatusComponentContent => {
    const handleGetLabel = (): string => {
      const abbrevLabel = shape === 'circle';
      const {
        HealthCertificate,
        VeterinaryHealthReport,
        VaccineRecord,
      } = DocumentTypeId;
      switch (documentType) {
        case HealthCertificate:
          return abbrevLabel
            ? DocumentTypeLabelAbbr.HealthCertificate
            : DocumentTypeLabel.HealthCertificate;
        case VeterinaryHealthReport:
          return abbrevLabel
            ? DocumentTypeLabelAbbr.VeterinaryHealthReport
            : DocumentTypeLabel.VeterinaryHealthReport;
        case VaccineRecord: {
          return abbrevLabel
            ? DocumentTypeLabelAbbr.VaccineRecord
            : DocumentTypeLabel.VaccineRecord;
        }
      }
    };

    const document = getLatestUpdatedHealthDoc(documents, documentType);
    const baseClass = `HealthDocBadge HealthDocBadge__shape--${shape}`;

    if (document) {
      const {
        Approved,
        HealthReview,
        PendingReview,
        Rejected,
        AwaitingDisclosure,
      } = DocumentWorkflowStatusId;

      const { expiresAt, workflowEntity } = document;

      if (isHealthDocExpired(document)) {
        return {
          label: handleGetLabel(),
          classModifier: `${baseClass} HealthDocBadge--expired`,
          workflowStatusTooltipLabel: 'Expired',
          expiresAt,
        };
      }

      switch (workflowEntity?.currentWorkflowStatusId) {
        case Approved:
          return {
            label: handleGetLabel(),
            classModifier: `${baseClass} HealthDocBadge--approved`,
            workflowStatusTooltipLabel: DocumentWorkflowStatusLabel.Approved,
            expiresAt,
          };
        case PendingReview:
        case HealthReview:
          return {
            label: handleGetLabel(),
            classModifier: `${baseClass} HealthDocBadge--pending-review`,
            workflowStatusTooltipLabel:
              DocumentWorkflowStatusLabel.PendingReview,
            expiresAt,
          };
        case Rejected:
          return {
            label: handleGetLabel(),
            classModifier: `${baseClass} HealthDocBadge--rejected`,
            workflowStatusTooltipLabel: DocumentWorkflowStatusLabel.Rejected,
            expiresAt,
          };
        case AwaitingDisclosure:
          return {
            label: handleGetLabel(),
            classModifier: `${baseClass} HealthDocBadge--awaiting-disclosure`,
            workflowStatusTooltipLabel:
              DocumentWorkflowStatusLabel.AwaitingDisclosure,
            expiresAt,
          };
      }
    }

    return {
      label: handleGetLabel(),
      classModifier: `${baseClass} HealthDocBadge--not-uploaded`,
      workflowStatusTooltipLabel: 'Not Uploaded',
    };
  };

  const {
    label,
    classModifier,
    workflowStatusTooltipLabel,
    expiresAt,
  } = handleBuildComponentContent();

  const renderBadge = () => {
    if (shape === 'fullInfo') {
      return (
        <div
          className="HealthDocBadge__fullInfo"
          data-testid={testId.healthDocBadgeStatusFullInfo}
        >
          <Space>
            <div
              data-testid={testId.healthDocBadgeStatus}
              className={classModifier}
            >
              {label}
            </div>
            <Typography.Text>{workflowStatusTooltipLabel}</Typography.Text>
          </Space>
          <p className="HealthDocBadge__fullInfo-expiresAt">
            Expires: {expiresAt ? date.toMMDDYY(expiresAt) : '--/--'}
          </p>
        </div>
      );
    } else {
      return (
        <div
          data-testid={testId.healthDocBadgeStatus}
          className={classModifier}
        >
          {label}
        </div>
      );
    }
  };

  return (
    <Tooltip
      placement="top"
      title={
        shape === 'fullInfo'
          ? undefined
          : withWorkflowStatusTooltip
          ? workflowStatusTooltipLabel
          : undefined
      }
    >
      {renderBadge()}
    </Tooltip>
  );
}

export default HealthDocBadgeStatus;
