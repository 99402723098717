import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Select } from 'antd';

import { RootState } from 'store/rootState';
import { FormFieldProps } from 'shared/types/form';
import { Food } from 'shared/types/general';
import { EmptyFieldValidationHandler } from 'shared/fields/fieldValidationHandler';
import showNotificationError from 'shared/notifications/showNotificationError';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import { getFoodFormula, getFoodTypes } from 'utilities/http/api/apiFood';
import {
  FoodBrandContent,
  FoodFormulaContent,
  FoodTypesContent,
} from 'utilities/staticContent/types';

export function FieldFood({ disabled }: FormFieldProps): ReactElement {
  const foodBrands = staticContentStorage.getContent().foodBrands;
  const [foodTypes, setFoodTypes] = useState([]);
  const [foodTypesLoading, setFoodTypesLoading] = useState(false);
  const [foodFormulas, setFoodFormulas] = useState([]);
  const [foodFormulasLoading, setFoodFormulasLoading] = useState(false);
  const [foodIsLoaded, setFoodIsLoaded] = useState(false);
  const { litterFood } = useSelector((state: RootState) => ({
    litterFood: state.litter?.food,
  }));

  const handleSetFoodTypes = async (foodBrandId: number) => {
    setFoodTypes([]);
    setFoodFormulas([]);
    setFoodTypesLoading(true);
    try {
      const resp = await getFoodTypes(foodBrandId);
      setFoodTypes(resp);
    } catch (error) {
      showNotificationError({ error: error.message });
    } finally {
      setFoodTypesLoading(false);
    }
  };

  const handleSetFoodFormulas = async (brandId: number, foodTypeId: number) => {
    setFoodFormulas([]);
    setFoodFormulasLoading(true);
    try {
      const resp = await getFoodFormula(brandId, foodTypeId);
      setFoodFormulas(resp);
    } catch (error) {
      showNotificationError({ error: error.message });
    } finally {
      setFoodFormulasLoading(false);
    }
  };

  useEffect(() => {
    const buildFood = async (litterFood: Partial<Food>) => {
      await handleSetFoodTypes(litterFood.brandId as number);
      await handleSetFoodFormulas(
        litterFood.brandId as number,
        litterFood.typeId as number,
      );
      setFoodIsLoaded(true);
    };

    litterFood && !foodIsLoaded && buildFood(litterFood);

    return () => {
      setFoodIsLoaded(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [litterFood]);

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, curValues) =>
        prevValues.food !== curValues.food
      }
    >
      {(form) => {
        const brandId = form.getFieldValue(['food', 'brandId']);

        return (
          <>
            <Form.Item
              name={['food', 'brandId']}
              label="Food brand"
              rules={[EmptyFieldValidationHandler('list')]}
            >
              <Select
                placeholder="Select a one"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleSetFoodTypes}
                disabled={disabled}
              >
                {foodBrands?.map((foodBrand: FoodBrandContent) => (
                  <Select.Option key={foodBrand.id} value={foodBrand.id}>
                    {foodBrand.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={['food', 'typeId']}
              label="Food type"
              rules={[EmptyFieldValidationHandler('list')]}
            >
              <Select
                loading={foodTypesLoading}
                disabled={
                  disabled || foodTypes.length === 0 || foodTypesLoading
                }
                placeholder="Select a Food Type"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) =>
                  handleSetFoodFormulas(brandId, value as number)
                }
              >
                {foodTypes?.map((foodType: FoodTypesContent) => (
                  <Select.Option key={foodType.id} value={foodType.id}>
                    {foodType.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={['food', 'formulaId']}
              label="Food formula"
              rules={[() => EmptyFieldValidationHandler('list')]}
            >
              <Select
                disabled={
                  disabled || foodFormulas.length === 0 || foodFormulasLoading
                }
                loading={foodFormulasLoading}
                placeholder="Select a Food Formula"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {foodFormulas.map((foodFormula: FoodFormulaContent) => (
                  <Select.Option key={foodFormula.id} value={foodFormula.id}>
                    {foodFormula.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        );
      }}
    </Form.Item>
  );
}
