import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Grid, notification } from 'antd';

import userStorage from 'utilities/user/userStorage';
import { apiGetRequest } from 'utilities/http/api/apiHttp';
import { BreederRoutes } from 'shared/types/routes';
import { Genders } from 'shared/types/parent';
import { Icon } from 'shared/icon/Icon';
import { isUsdaLicenseActive } from 'utilities/license/license';
import { resetLitter } from 'store/litter/actions';
import { resetParents } from 'store/parents/actions';
import { resetPuppy } from 'store/puppy/actions';
import { setNewPuppy } from 'store/newPuppy/actions';
import { testId } from 'testsUtilities/dataTestId';

import './AddFamilyNavigation.scss';

const { useBreakpoint } = Grid;

export default function AddFamilyNavigation(): ReactElement {
  const [verifyingAddingDam, setVerifyingAddingDam] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = userStorage.getUser();
  const screens = useBreakpoint();

  const cantAddDamNotification = () => {
    const key = 'blocked-add-dams';
    const btn = (
      <>
        <a
          className="ant-btn ant-btn-default AddFamilyNavigation__call-add-dams"
          href="tel:+844-566-2886"
        >
          Call Now
        </a>
        <Button
          className="ant-btn ant-btn-primary AddFamilyNavigation__call-add-dams"
          onClick={() => notification.close(key)}
        >
          Close
        </Button>
      </>
    );
    notification.warning({
      message: "You can't add a new Dam",
      description: 'Please contact PuppySpot to get more information.',
      btn,
      key,
    });
  };

  const handleAddDamValidation = async () => {
    setVerifyingAddingDam(true);
    const appendParameters = `breederId=${userStorage.getBreederId()}&excludeRetiredParents=1&active=1&pageSize=8&genderId=${
      Genders.dam
    }`;
    const parentsResp = await apiGetRequest('puppy/parent', appendParameters);
    const dams = parentsResp.json?.puppyParents || [];
    const noLicenseMinDams = 3;
    const noLicenseMaxDams = 4;

    if (
      dams.length <= noLicenseMinDams ||
      (dams.length >= noLicenseMaxDams &&
        isUsdaLicenseActive(user?.breeders[0].usdaLicenseStatusId))
    ) {
      setVerifyingAddingDam(false);
      history.push(BreederRoutes.NewDam);
    } else {
      setVerifyingAddingDam(false);
      cantAddDamNotification();
    }
  };

  const handleClassModifier = screens.xl
    ? 'AddFamilyNavigation'
    : 'AddFamilyNavigation AddFamilyNavigation--mobile';

  return (
    <>
      <section
        className={handleClassModifier}
        data-testid={testId.addFamilyNavigation}
      >
        <Button
          data-testid="add-dam"
          className="AddFamilyNavigation__btn"
          type="default"
          icon={<Icon name="dam" />}
          loading={verifyingAddingDam}
          onClick={handleAddDamValidation}
        >
          {verifyingAddingDam ? 'Validating…' : 'Add Dam'}
        </Button>

        <Button
          data-testid="add-sire"
          className="AddFamilyNavigation__btn"
          type="default"
          icon={<Icon name="sire" />}
          onClick={() => history.push(BreederRoutes.NewSire)}
        >
          Add Sire
        </Button>

        <Button
          data-testid="add-litter"
          className="AddFamilyNavigation__btn"
          type="default"
          icon={<Icon name="litter" />}
          onClick={() => {
            dispatch(resetLitter());
            history.push(BreederRoutes.NewLitter);
          }}
        >
          Add Litter
        </Button>

        <Button
          data-testid="add-puppy"
          className="AddFamilyNavigation__btn"
          type="default"
          icon={<Icon name="puppy" />}
          onClick={() => {
            dispatch(resetLitter());
            dispatch(resetParents());
            dispatch(resetPuppy());
            dispatch(setNewPuppy(null));
            history.push(BreederRoutes.NewPuppy);
          }}
        >
          Add Puppy
        </Button>
      </section>
    </>
  );
}
