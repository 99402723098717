import React, { ReactElement, useEffect, useState } from 'react';
import { isEmpty, range } from 'lodash';
import {
  Empty,
  Row,
  Select,
  Button,
  Col,
  Affix,
  DatePicker,
  Space,
} from 'antd';
import { SelectValue } from 'antd/lib/select';
import { RangeValue } from 'rc-picker/lib/interface';
import moment from 'moment';
import { PDFDownloadLink } from '@react-pdf/renderer';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import LoadingSection from 'components/loadingSection/LoadingSection';
import { useBaseLayoutAffixOffset } from 'hooks';
import TransactionCard from 'pages/transactions/transactionCard/TransactionCard';
import TransactionsPdfFile from 'pages/transactions/TransactionsPdfFile';
import { getBreederPaymentsByDate } from 'pages/transactions/transactionsHelpers';
import { Nullable, SetState } from 'shared/types/general';
import { Transaction } from 'shared/types/transaction';
import PageLayout from 'shared/baseLayout/pageLayout/PageLayout';
import date from 'utilities/time/date';

import './Transactions.scss';

const { Option } = Select;
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const searchByOptions = ['Search by month', 'Search by range'];
type SearchBy = 'Search by month' | 'Search by range';
const { RangePicker } = DatePicker;

const initialYear = 2020;

const breadCrumbs = [
  {
    name: 'Transactions',
  },
];

const currentMonth = new Date().getMonth();
const currentYear = new Date().getFullYear();
interface SelectedProps {
  setSelectedDate: SetState<number>;
}

export function SelectMonth({ setSelectedDate }: SelectedProps) {
  const onMonthChange = (e: SelectValue) => {
    setSelectedDate(Number(e));
  };

  return (
    <Select
      onChange={onMonthChange}
      defaultValue={currentMonth}
      data-testid="transaction-select-month"
    >
      {months.map((m, i) => (
        <Option key={i} value={i}>
          {m}
        </Option>
      ))}
    </Select>
  );
}

export function SelectYear({ setSelectedDate }: SelectedProps) {
  const [yearsArray, setYearsArray] = useState<number[]>([]);
  const onYearChange = (e: SelectValue) => {
    setSelectedDate(Number(e));
  };

  const yearCountArray = () => {
    const difference = currentYear - initialYear;
    if (difference > 0) {
      setYearsArray(range(initialYear, currentYear + 1));
    }
  };

  useEffect(() => {
    yearCountArray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      onChange={onYearChange}
      defaultValue={currentYear}
      data-testid="transaction-select-year"
    >
      {yearsArray.map((y, i) => (
        <Option key={i} value={y}>
          {y}
        </Option>
      ))}
    </Select>
  );
}

export default function Transactions(): ReactElement {
  const [selectedDateMonth, setSelectedDateMonth] = useState<number>(
    currentMonth,
  );
  const [selectedDateYear, setSelectedDateYear] = useState<number>(currentYear);
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState<Nullable<Transaction[]>>(
    null,
  );
  const [searchBy, setSearchBy] = useState<SearchBy>('Search by month');
  const [dateRange, setDateRange] = useState<RangeValue<moment.Moment>>();
  const screens = useBreakpoint();

  const { offsetTopMyEntitySearch } = useBaseLayoutAffixOffset();

  const submitDate = async () => {
    const getDate = new Date();

    getDate.setMonth(selectedDateMonth);
    getDate.setFullYear(selectedDateYear);

    let dateBegin = date.toFullDate(moment(getDate).startOf('month'));
    let dateEnd = date.toFullDate(moment(getDate).endOf('month'));

    if (!isEmpty(dateRange)) {
      dateBegin = date.toFullDate(dateRange?.[0]?.startOf('day'));
      dateEnd = date.toFullDate(dateRange?.[1]?.endOf('day'));
    }

    setLoading(true);
    const response = await getBreederPaymentsByDate(dateBegin, dateEnd);

    if (!response && !isEmpty(response)) {
      setTransactions([]);
    } else {
      setTransactions(response as Transaction[]);
    }

    setLoading(false);
  };

  useEffect(() => {
    submitDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchByChange = (value: SearchBy) => {
    setDateRange(null);
    setSearchBy(value);
  };

  const onRangePickerChange = (values: RangeValue<moment.Moment>) =>
    setDateRange(values);

  const getFileName = (): string => {
    let name = `${months[selectedDateMonth]} ${selectedDateYear}`;

    if (!isEmpty(dateRange)) {
      const dateBegin = date.toYYYYMMDD(dateRange?.[0]);
      const dateEnd = date.toYYYYMMDD(dateRange?.[1]);

      name = `${dateBegin} to ${dateEnd}`;
    }

    return name;
  };

  return (
    <PageLayout className="Transactions" breadCrumbsNav={breadCrumbs}>
      <Affix offsetTop={offsetTopMyEntitySearch}>
        <section className="Transaction__toolbar">
          <Row justify="center">
            <Space>
              <Select
                onChange={onSearchByChange}
                defaultValue={searchByOptions[0] as SearchBy}
                data-testid="transaction-select-year"
              >
                {searchByOptions.map((y, i) => (
                  <Option key={i} value={y}>
                    {y}
                  </Option>
                ))}
              </Select>
              {searchBy === 'Search by month' ? (
                <>
                  <SelectMonth setSelectedDate={setSelectedDateMonth} />
                  <SelectYear setSelectedDate={setSelectedDateYear} />
                </>
              ) : (
                <RangePicker
                  format={'YYYY-MM-DD'}
                  onChange={onRangePickerChange}
                />
              )}
              {screens.sm && (
                <>
                  <Button
                    type="default"
                    onClick={submitDate}
                    disabled={loading}
                  >
                    Find
                  </Button>
                  {transactions?.length ? (
                    <div className="Transaction__toolbar__download">
                      <PDFDownloadLink
                        document={
                          <TransactionsPdfFile
                            transactionDate={getFileName()}
                            transactions={transactions}
                          />
                        }
                        fileName={`transactions-${getFileName()}.pdf`}
                      >
                        {({ error }) =>
                          Boolean(error) ? error?.message : 'Download as PDF'
                        }
                      </PDFDownloadLink>
                    </div>
                  ) : null}
                </>
              )}
            </Space>
            {!screens.sm && (
              <div className="Transaction__toolbar__btn-container">
                <Button type="default" onClick={submitDate} disabled={loading}>
                  Find
                </Button>
                {transactions?.length ? (
                  <div className="Transaction__toolbar__download">
                    <PDFDownloadLink
                      document={
                        <TransactionsPdfFile
                          transactionDate={getFileName()}
                          transactions={transactions}
                        />
                      }
                      fileName={`transactions-${getFileName()}.pdf`}
                    >
                      {({ error }) =>
                        Boolean(error) ? error?.message : 'Download as PDF'
                      }
                    </PDFDownloadLink>
                  </div>
                ) : null}
              </div>
            )}
          </Row>
        </section>
      </Affix>

      <section className="Transactions__list Transactions__list--scrollable">
        <Row justify="center">
          <Col xs={23} xl={10} xxl={8}>
            {loading ? (
              <LoadingSection />
            ) : isEmpty(transactions) ? (
              <Empty
                description={<span>No transactions found on this date</span>}
              />
            ) : (
              transactions
                ?.reverse()
                .map((t) => (
                  <TransactionCard transaction={t} key={t.id as number} />
                ))
            )}
          </Col>
        </Row>
      </section>
    </PageLayout>
  );
}
