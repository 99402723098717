import React, { ReactElement, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { testId } from 'testsUtilities/dataTestId';
import { buildUrl, getApiHeaders } from 'utilities/http/api/apiHttp';

import './DownloadHealthDocs.scss';

interface Props {
  puppyId: number;
  iconOnly?: boolean;
  btnSize?: SizeType;
}

function DownloadHealthDocs(props: Props): ReactElement {
  const { puppyId, iconOnly, btnSize = 'middle' } = props;
  const [loading, setLoading] = useState(false);

  const downloadHealthDocsUrl = buildUrl(`puppy/${puppyId}/download-vhr`);

  const handleDownload = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setLoading(true);

    const headers = getApiHeaders();

    fetch(downloadHealthDocsUrl, { headers })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'VeterinaryHealthReport.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Button
      className="DownloadHealthDocs"
      data-testid={testId.downloadHealthDocs}
      type="link"
      size={btnSize}
      title="Download Docs"
      icon={<DownloadOutlined />}
      download
      loading={loading}
      onClick={handleDownload}
    >
      {!iconOnly && `Download VHR`}
    </Button>
  );
}

export default DownloadHealthDocs;
