import { Dispatch } from 'redux';
import showNotificationError from 'shared/notifications/showNotificationError';
import apiLitter from 'utilities/http/api/apiLitter';
import {
  ADD_MORE_LITTERS,
  LittersActionTypes,
  RESET_MY_LITTERS,
  SET_LITTERS,
} from 'store/myLitters/types';
import { Nullable } from 'shared/types/general';
import { Litter, LitterStatus } from 'shared/types/litter';
import { reactEnvironmentVariables } from 'utilities/environmentVariables';
import { showLoadedPuppiesNotification } from 'store/myListings/actions';
import { setItemsOnPageAndPerPage } from 'store/paginations/actions';
import { initialPage } from 'store/paginations/paginationsReducer';

export interface SetLitters {
  type: typeof SET_LITTERS;
  litters: Nullable<Litter[]>;
  litterStatus: LitterStatus;
}

export function setLitters(
  litters: Litter[],
  litterStatus: LitterStatus,
): LittersActionTypes {
  return {
    type: SET_LITTERS,
    litters,
    litterStatus,
  };
}

export interface AddMoreLitters {
  type: typeof ADD_MORE_LITTERS;
  litters: Litter[];
  litterStatus: LitterStatus;
}

export function addMoreLitters(
  litters: Litter[],
  litterStatus: LitterStatus,
): LittersActionTypes {
  return {
    type: ADD_MORE_LITTERS,
    litters,
    litterStatus,
  };
}

export interface ResetMyLitters {
  type: typeof RESET_MY_LITTERS;
}

export function resetMyLitters(): LittersActionTypes {
  return {
    type: RESET_MY_LITTERS,
  };
}

export function getBreederLitters(litterStatus: LitterStatus, page = 1) {
  return async function (dispatch: Dispatch) {
    try {
      const littersResponse = await apiLitter.getLittersByBreeder(
        litterStatus,
        reactEnvironmentVariables.PAGE_SIZE_MY_LITTERS,
        page,
      );
      const litters: Litter[] = littersResponse.json.litters;

      const paginationMeta = littersResponse.json?.paginationMeta;
      const itemsOnPage = paginationMeta?.itemsOnPage;
      const itemsPerPage = paginationMeta?.itemsPerPage;
      if (typeof itemsOnPage === 'number' && typeof itemsPerPage === 'number') {
        showLoadedPuppiesNotification(page, itemsOnPage, 'litter', 'litters');
        dispatch(
          setItemsOnPageAndPerPage(litterStatus, itemsOnPage, itemsPerPage),
        );
      }

      if (page && page > initialPage) {
        dispatch(addMoreLitters(litters, litterStatus));
      } else {
        dispatch(setLitters(litters, litterStatus));
      }
    } catch (e) {
      showNotificationError({
        error: "Couldn't retrieve litters, please try again",
      });
      dispatch(setLitters([], litterStatus));
    }
  };
}
