import React from 'react';
import { Button, notification, Row } from 'antd';

import './showNotificationDamsLimitCreation.scss';

export function showNotificationDamsLimitCreation() {
  return notification.warning({
    className: 'DamsLimitCreationNotification',
    message: `You can't add a new dam`,
    description:
      'You have reached your dams limit.\n' +
      'Please choose an existing one to start a new litter or listing.\n' +
      'Contact PuppySpot if you need more information.',
    key: 'createDamLimitNotification',
    duration: null,
    btn: (
      <Row>
        <Button
          type="default"
          size="small"
          onClick={() => {
            window.open('tel:+844-566-2886', '_self');
            notification.close('createDamLimitNotification');
          }}
        >
          Call Now
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => notification.close('createDamLimitNotification')}
        >
          Close
        </Button>
      </Row>
    ),
  });
}
