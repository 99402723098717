import React, { ReactElement } from 'react';
import { Icon } from 'shared/icon/Icon';

export default function EmptyDashboard(): ReactElement {
  return (
    <div className="Dashboard--empty" data-testid="dashboard-empty">
      <h2>Your kennel is empty!</h2>
      <Icon name="litter" />
      <p>
        This page will show you updates for your puppy listings as well as your
        puppies performance on PuppySpot.com
      </p>
      <a href="/">Create your puppy listing today</a>
    </div>
  );
}
