import { Listing, Puppy } from 'shared/types/puppy';
import {
  MyListingsFilters,
  MyListingsPagination,
  MyListingsSorter,
} from 'shared/types/myListings';

export const SET_MY_LISTINGS_DELIVERED_LOADING =
  'SET_MY_LISTINGS_DELIVERED_LOADING';
export const SET_MY_LISTINGS_DELIVERED = 'SET_MY_LISTINGS_DELIVERED';
export const SET_MY_LISTINGS_DELIVERED_FILTERS =
  'SET_MY_LISTINGS_DELIVERED_FILTERS';
export const SET_MY_LISTINGS_DELIVERED_SORTER =
  'SET_MY_LISTINGS_DELIVERED_SORTER';
export const SET_MY_LISTINGS_DELIVERED_PAGINATION =
  'SET_MY_LISTINGS_DELIVERED_PAGINATION';
export const UPDATE_MY_LISTINGS_DELIVERED_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES =
  'UPDATE_MY_LISTINGS_DELIVERED_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES';

export interface MyListingsMainState {
  loading: boolean;
  listings: Listing[];
  pagination: MyListingsPagination;
  filters: MyListingsFilters;
  sorter: MyListingsSorter;
}

interface SetMyListingsDeliveredLoading {
  type: typeof SET_MY_LISTINGS_DELIVERED_LOADING;
}

interface SetMyListingsDelivered {
  type: typeof SET_MY_LISTINGS_DELIVERED;
  listings: Listing[];
}

interface SetMyListingsDeliveredFilters {
  type: typeof SET_MY_LISTINGS_DELIVERED_FILTERS;
  filters: MyListingsFilters;
}

interface SetMyListingsDeliveredSorter {
  type: typeof SET_MY_LISTINGS_DELIVERED_SORTER;
  sorter: MyListingsSorter;
}

interface SetMyListingsDeliveredPagination {
  type: typeof SET_MY_LISTINGS_DELIVERED_PAGINATION;
  pagination: MyListingsPagination;
}

interface UpdateMyListingsDeliveredPuppyRegistrationsWeightDueDates {
  type: typeof UPDATE_MY_LISTINGS_DELIVERED_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES;
  puppy: Puppy;
}

export type MyListingsDeliveredActions =
  | SetMyListingsDeliveredLoading
  | SetMyListingsDelivered
  | SetMyListingsDeliveredFilters
  | SetMyListingsDeliveredSorter
  | SetMyListingsDeliveredPagination
  | UpdateMyListingsDeliveredPuppyRegistrationsWeightDueDates;
