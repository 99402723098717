import apiBreeder from 'utilities/http/api/apiBreeder';
import { apiGetRequest } from 'utilities/http/api/apiHttp';
import userStorage from 'utilities/user/userStorage';
import { requestHasErrors } from 'utilities/http/http';
import {
  isUserRoleBreederAdminExternal,
  isUserRoleBreederAdminInternal,
  userCanImpersonateAnyBreeder,
  userCanImpersonateManagedBreeders,
} from 'utilities/user/userRolesCheckers/userRolesCheckers';

/**
 * Check if breeder admin impersonate mode is active.
 */
export const isBreederImpersonateMode = (): boolean => {
  const breederAdminToken = userStorage.getBreederAdminToken();
  const breederAdminInfo = userStorage.getBreederAdminInfo();

  return Boolean(
    (breederAdminToken &&
      isUserRoleBreederAdminInternal(breederAdminInfo) &&
      userCanImpersonateAnyBreeder(breederAdminInfo)) ||
      (isUserRoleBreederAdminExternal(breederAdminInfo) &&
        userCanImpersonateManagedBreeders(breederAdminInfo)),
  );
};

/**
 * Action to active or disable breeder admin impersonate
 * @param breederId - breeder id with which will impersonate,
 * with no id it will disable impersonate mode.
 */
export const switchBreederImpersonateMode = async (breederId?: number) => {
  if (breederId) {
    /** Active breeder admin impersonate */
    const respImpersonate = await apiGetRequest(
      `breeder/${breederId}/impersonate`,
    );

    if (requestHasErrors(respImpersonate)) {
      throw new Error(
        respImpersonate.json?.errors
          ? respImpersonate.json?.errors.value[0]
          : respImpersonate.json?.message,
      );
    }

    const impersonateToken = respImpersonate?.json?.token;
    userStorage.setBreederAdminToken();
    userStorage.setBreederAdminInfo();
    userStorage.setToken(impersonateToken);
  } else {
    /** Disable breeder admin impersonate */
    const breedAdminToken = userStorage.getBreederAdminToken();
    userStorage.setToken(breedAdminToken);
    userStorage.removeBreederAdminToken();
    userStorage.removeBreederAdminInfo();
  }

  /** Get logged breeder info */
  const breederResponse = await apiBreeder.getBreeder();

  if (requestHasErrors(breederResponse)) {
    throw new Error(`Couldn't load breeder info.`);
  }

  const user = breederResponse?.json?.user;

  userStorage.setUser(user);
  window.location.reload();
};
