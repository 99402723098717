import React, { ReactElement } from 'react';
import { Form, Input } from 'antd';
import TextAreaCounter from '../textAreaCounter/TextAreaCounter';

interface Props {
  disabled?: boolean;
}

export function FieldSpecialFeedingInstructions(props: Props): ReactElement {
  const { disabled } = props;
  const maxLength = 1000;

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, curValues) =>
        prevValues.specialFeedingInstructions !==
        curValues.specialFeedingInstructions
      }
    >
      {(form) => {
        const value = form.getFieldValue(['specialFeedingInstructions']);
        const characterCounter = value?.length || 0;

        return (
          <Form.Item
            name="specialFeedingInstructions"
            label="Feeding instructions"
            extra={<TextAreaCounter counter={maxLength - characterCounter} />}
          >
            <Input.TextArea
              disabled={disabled}
              placeholder="Add your notes here"
              autoSize={{ minRows: 6, maxRows: 6 }}
              maxLength={maxLength}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
}
