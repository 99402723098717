import { Listing, Puppy } from 'shared/types/puppy';
import {
  MyListingsFilters,
  MyListingsPagination,
  MyListingsSorter,
} from 'shared/types/myListings';
import {
  MyListingsDeliveredActions,
  SET_MY_LISTINGS_DELIVERED,
  SET_MY_LISTINGS_DELIVERED_FILTERS,
  SET_MY_LISTINGS_DELIVERED_LOADING,
  SET_MY_LISTINGS_DELIVERED_PAGINATION,
  SET_MY_LISTINGS_DELIVERED_SORTER,
  UPDATE_MY_LISTINGS_DELIVERED_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES,
} from 'store/myListingsDelivered/types';

export function setMyListingsDeliveredLoading(): MyListingsDeliveredActions {
  return {
    type: SET_MY_LISTINGS_DELIVERED_LOADING,
  };
}

export function setMyListingsDelivered(
  listings: Listing[],
): MyListingsDeliveredActions {
  return {
    type: SET_MY_LISTINGS_DELIVERED,
    listings,
  };
}

export function setMyListingsDeliveredFilters(
  filters: MyListingsFilters,
): MyListingsDeliveredActions {
  return {
    type: SET_MY_LISTINGS_DELIVERED_FILTERS,
    filters,
  };
}

export function setMyListingsDeliveredSorter(
  sorter: MyListingsSorter,
): MyListingsDeliveredActions {
  return {
    type: SET_MY_LISTINGS_DELIVERED_SORTER,
    sorter,
  };
}

export function setMyListingsDeliveredPagination(
  pagination: MyListingsPagination,
): MyListingsDeliveredActions {
  return {
    type: SET_MY_LISTINGS_DELIVERED_PAGINATION,
    pagination,
  };
}

export function updateMyListingsDeliveredPuppyRegistrationsWeightDueDates(
  puppy: Puppy,
): MyListingsDeliveredActions {
  return {
    type: UPDATE_MY_LISTINGS_DELIVERED_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES,
    puppy,
  };
}
