import { Dispatch } from 'redux';
import { notification } from 'antd';

import { Listing } from 'shared/types/puppy';
import { Nullable } from 'shared/types/general';
import {
  setMyListingsSoldLoading,
  setMyListingsSold,
  setMyListingsSoldPagination,
  setPuppyHealthDoc,
  setMyListingsSoldFilters,
  setMyListingsSoldSorter,
} from 'store/myListingsSold/actions';
import {
  initialStateMyListingsSoldFilters,
  initialStateMyListingsSoldPagination,
  initialStateMyListingsSoldSorter,
} from 'store/myListingsSold/myListingSoldReducer';
import { RootState } from 'store/rootState';
import { requestHasErrors } from 'utilities/http/http';
import { BreederDashboardStatus } from 'utilities/const';
import apiListing from 'utilities/http/api/apiListing';
import apiPuppy from 'utilities/http/api/apiPuppy';

export function fetchMyListingsSold() {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setMyListingsSoldLoading());

    const { listings, pagination, sorter, filters } = getState().myListingsSold;

    const extraUrlParams = {
      withDocuments: '1',
      withDocumentDueDates: '1',
    };

    const myListingsResp = await apiListing.getMyListings(
      BreederDashboardStatus.Sold,
      pagination,
      sorter,
      filters,
      extraUrlParams,
    );

    if (requestHasErrors(myListingsResp)) {
      if (!filters.isFiltering) {
        notification.error({ message: `Error fetching my listings sold` });
      }

      dispatch(setMyListingsSold([]));
      dispatch(
        setMyListingsSoldPagination({
          ...pagination,
          total: 0,
        }),
      );
      return;
    }

    const listingsResp = myListingsResp?.json.listings as Listing[];
    const paginationResp = myListingsResp?.json.paginationMeta;

    if (pagination.loadMore) {
      dispatch(setMyListingsSold([...listings, ...listingsResp]));
    } else {
      dispatch(setMyListingsSold(listingsResp));
    }

    dispatch(
      setMyListingsSoldPagination({
        ...pagination,
        total: paginationResp.total,
      }),
    );
  };
}

export function updateListingPuppyHealthDocs(
  listingId: Nullable<number>,
  puppyId: Nullable<number>,
) {
  return async (dispatch: Dispatch) => {
    if (listingId && puppyId) {
      const puppyDocResponse = await apiPuppy.getPuppyHealthDocs(puppyId);
      const documents = puppyDocResponse.json.documents;

      dispatch(setPuppyHealthDoc(documents, listingId));
    }
  };
}

export function setMyListingsSoldInitialState() {
  return (dispatch: Dispatch) => {
    dispatch(setMyListingsSold([]));
    dispatch(setMyListingsSoldPagination(initialStateMyListingsSoldPagination));
    dispatch(setMyListingsSoldFilters(initialStateMyListingsSoldFilters));
    dispatch(setMyListingsSoldSorter(initialStateMyListingsSoldSorter));
  };
}
