import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldData } from 'rc-field-form/lib/interface';
import { Store } from 'antd/lib/form/interface';
import { Form, Input, Select } from 'antd';
import { isEmpty } from 'lodash';

import {
  createAddress,
  updateAddress,
  updateCredentials,
  updatePhones,
} from 'pages/myAccount/accountForm/contactForm/ContactFormHelpers';
import { CountryId, State } from 'shared/types/geolocation';
import { User } from 'shared/types/user';
import FieldPhone from 'shared/fields/fieldPhone/FieldPhone';
import { SubmitButton } from 'shared/fields';
import { fetchCountryStates, setCountry } from 'store/breeder/actions';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import { RootState } from 'store/rootState';
import userStorage from 'utilities/user/userStorage';
import { CountryContent } from 'utilities/staticContent/types';
import { getContentDataById } from 'utilities/staticContent/content';
import { Phone, PhoneType } from 'shared/types/general';

const getIsPrimary = <T extends { isPrimary?: number }>(info?: T[]) =>
  info?.find((item) => item?.isPrimary);

const isWorkPhone = (phone: Partial<Phone>) =>
  phone.phoneTypeId === PhoneType.office;

const getWorkPhone = (phones: Partial<Phone>[]) => phones.find(isWorkPhone);

export default function ContactForm(): ReactElement {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const staticContent = staticContentStorage.getContent();
  const user = userStorage.getUser();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { breeder, states, addresses } = useSelector((state: RootState) => ({
    breeder: state.breeder?.breeder,
    addresses: state.breeder?.breeder?.addresses,
    states: state.breeder?.states,
  }));

  useEffect(() => {
    if (form.isFieldsTouched()) return;
    if (!breeder || !addresses) return;
    const address = getIsPrimary(addresses);
    const phones = breeder?.phones || [];
    const phonePrimary = getIsPrimary(phones)?.phoneNumber;
    const phoneWork = getWorkPhone(phones)?.phoneNumber;
    form.setFieldsValue({
      firstName: user?.firstName,
      lastName: user?.lastName,
      address: address?.address,
      address2: address?.address2,
      postalCode: address?.postalCode?.toString(),
      state: address?.stateId,
      city: address?.city,
      country: address?.countryId,
      phonePrimary,
      phoneWork,
    });
  }, [breeder, addresses, user, form]);

  const countryId = addresses?.[0]?.countryId;
  useEffect(() => {
    if (!countryId) return;
    getStatesForCountry(countryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  function getStatesForCountry(countryId?: CountryId) {
    const countryAbbreviation = getContentDataById('countries', countryId).abbr;
    dispatch(fetchCountryStates(countryAbbreviation));
  }

  const onContactFormChange = (changedFields: FieldData[]) => {
    if (!changedFields || isEmpty(changedFields)) return;
    const fieldName = changedFields[0]?.name;
    const value = changedFields[0]?.value;
    if (typeof fieldName !== 'object') return;

    switch (fieldName[0]) {
      case 'country': {
        const countryId = value as CountryId;
        dispatch(setCountry(countryId));
        getStatesForCountry(countryId);

        form.setFieldsValue({
          city: '',
          state: '',
        });
        break;
      }
    }
  };

  const onSubmit = async (contactInfo: Store) => {
    setIsSubmitting(true);
    await updateCredentials(contactInfo as User);
    if (isEmpty(addresses)) {
      await createAddress(contactInfo);
    } else {
      await updateAddress(contactInfo, addresses);
    }
    await updatePhones({
      phone: contactInfo?.phonePrimary,
      phone2: contactInfo?.phoneWork,
    });

    setIsSubmitting(false);
  };

  return (
    <Form
      data-testid="contact-form"
      name="contactForm"
      layout="vertical"
      scrollToFirstError
      size="large"
      className="ContactForm"
      form={form}
      hideRequiredMark={true}
      onFinish={onSubmit}
      onFieldsChange={onContactFormChange}
    >
      <Form.Item
        name="firstName"
        label="First Name"
        rules={[{ message: 'Please use a valid name', min: 2, required: true }]}
      >
        <Input type="text" placeholder="Type here" />
      </Form.Item>
      <Form.Item
        name="lastName"
        label="Last Name"
        rules={[
          { message: 'Please use a valid last name', min: 2, required: true },
        ]}
      >
        <Input type="text" placeholder="Type here" />
      </Form.Item>
      <Form.Item
        name="address"
        label="Address"
        rules={[
          { min: 2, message: 'Please use a valid address', required: true },
        ]}
      >
        <Input type="text" placeholder="i.e 137 Ijne Way" />
      </Form.Item>
      <Form.Item name="address2" label="Suite or apt (optional)">
        <Input type="text" placeholder="i.e Suite 3060" />
      </Form.Item>
      <Form.Item
        name="postalCode"
        label="Zip code"
        rules={[
          { min: 5, message: 'Please use a valid Zip code', required: true },
        ]}
      >
        <Input type="text" placeholder="i.e 94442" />
      </Form.Item>
      <Form.Item name="country" label="Country">
        <Select data-testid="country-select" placeholder="Select a country">
          {staticContent.countries.map((country: CountryContent) => (
            <Select.Option key={country.id} value={country.id}>
              {country.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="state"
        label="State"
        rules={[
          {
            message: 'Please use a valid State',
            required: true,
          },
        ]}
      >
        <Select data-testid="field-state" placeholder="Select a State">
          {states?.map((s: State) => (
            <Select.Option key={s.id as number} value={s.id as number}>
              {s.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="city"
        label="City"
        rules={[
          {
            min: 2,
            type: 'string',
            message: 'Please use a valid city',
            required: true,
          },
        ]}
      >
        <Input type="text" />
      </Form.Item>

      <FieldPhone
        name="phonePrimary"
        label="Primary phone number"
        required={true}
        setFieldsValue={form.setFieldsValue}
      />
      <FieldPhone
        name="phoneWork"
        label="Work phone number"
        required={true}
        setFieldsValue={form.setFieldsValue}
      />
      <SubmitButton label="Save" loading={isSubmitting} />
    </Form>
  );
}
