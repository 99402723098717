import { isEmpty } from 'lodash';

import { mediaConfig } from 'utilities/media/media';
import { AwsS3Signature, Media, MediaUploaderParams } from 'shared/types/media';
import showNotificationError from 'shared/notifications/showNotificationError';
import { apiGetRequest, apiPostRequest, apiPutRequest } from './apiHttp';
import { Nullable } from 'shared/types/general';

const apiMedia = {
  putMedia: async (media: Media) => {
    const payload = {
      media: {
        ...media,
        uploadedAt: new Date(),
      },
    };
    const resp = await apiPutRequest(`media/${media.id}`, payload);
    const respError = resp?.json?.errors;
    if (!isEmpty(respError) || resp.status === 500) {
      showNotificationError({
        error: `Update media error!`,
      });
    }
    return resp?.json?.updatedMedia;
  },

  signMediaUpload: async (media: Media, params: MediaUploaderParams) => {
    const changeFileExtToJpg = (mediaName: string) => {
      return mediaName.replace('.jpeg', '.jpg');
    };
    const parsedMediaName = changeFileExtToJpg(
      media?.name?.replace(/[^0-9a-zA-Z.]/g, '') || '',
    );

    const appendParameters = `name=${parsedMediaName}&file-size=${
      media.size
    }&mediaTypeId=${params.mediaTypeId}&mediaStatusId=${1}&visibilityId=${
      media.visibilityId || mediaConfig.visibilityId.public
    }&type=${media.type}&mediaEntity=${params.mediaEntity}&mediaEntityId=${
      params.mediaEntityId
    }&viewOrder=${media.viewOrder || 1}&isDefault=${media.isDefault}`;

    const response = await apiGetRequest('media/sign', appendParameters);

    if (!response.json.media_id) {
      throw new Error(`Couldn't sign upload media`);
    }

    return response.json;
  },

  postMediaToS3: async (signature: AwsS3Signature, media: Media) => {
    const form = new FormData();
    Object.keys(signature).forEach((key) => {
      form.append(key, signature[key]);
    });
    form.append('file', media.file as Blob, media.name);

    const bucket =
      signature.bucket === 'photos.puppyspot.com'
        ? 'https://photos.puppyspot.com'
        : `https://${signature.bucket}.s3.us-west-2.amazonaws.com`;

    return await fetch(bucket, {
      method: 'POST',
      body: form,
    });
  },

  uploadComplete: async (mediaId: number, params: MediaUploaderParams) => {
    const payload = {
      mediaId: mediaId,
      mediaEntity: params.mediaEntity,
      mediaEntityId: params.mediaEntityId,
    };
    return await apiPostRequest('media/upload-complete', payload);
  },

  hideMedia: async (mediaId: number, deferVisibility = false) => {
    const payload = {
      mediaId: mediaId,
      mediaReasonId: [1],
      deferVisibility,
    };
    const resp = await apiPostRequest(`media/hide`, payload);
    const respError = resp?.json?.errors;
    if (!isEmpty(respError) || resp.status === 500) {
      throw new Error(`Couldn't delete media`);
    }
  },

  setMediaAsDefault: async (listingId: number, mediaId: number) => {
    return await apiPutRequest(
      `listing/${listingId}/media/${mediaId}/set-default`,
    );
  },

  // hides all media with uploadedAt as null,
  hideCorruptedMedia: async (mediaArray: Nullable<Media[]>) => {
    await Promise.all([
      mediaArray?.map((media) => {
        if (
          isEmpty(media.uploadedAt) &&
          media.visibilityId !== mediaConfig.visibilityId.hidden &&
          media.id
        ) {
          return apiMedia.hideMedia(media.id as number);
        }
      }),
    ]);
  },
};

export default apiMedia;
