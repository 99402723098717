import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { PuppyDetailTile } from 'components/puppy';
import { Icon } from 'shared/icon/Icon';
import { SetState } from 'shared/types/general';
import { RootState } from 'store/rootState';
import puppyTravelStatus from 'utilities/puppy/puppyTravelState';
import { isDelivered } from 'utilities/entities/puppy';

interface Props {
  setShowTravelPlan: SetState<boolean>;
}

function PuppyDetailTravelPlanTile(props: Props): ReactElement {
  const { setShowTravelPlan } = props;
  const puppy = useSelector((state: RootState) => state.puppy);
  const statusPuppyTravel = puppyTravelStatus(puppy);

  const getOrderStatusText = () => {
    if (isDelivered(puppy)) return 'Puppy Delivered';

    if (statusPuppyTravel.isPuppyTravelFinalized()) {
      return 'Confirmed';
    } else if (statusPuppyTravel.isPuppyTravelRejected()) {
      return 'Rejected, Replanning';
    } else if (statusPuppyTravel.isOrderCanceled()) {
      return 'On hold';
    } else {
      return 'Preparing';
    }
  };

  return (
    <PuppyDetailTile
      title="Travel Plans"
      icon={<Icon name="travelCase" />}
      status={
        statusPuppyTravel.isPuppyTravelFinalized() ? 'success' : 'pending'
      }
      statusText={getOrderStatusText()}
      disabled={isEmpty(puppy.order) || statusPuppyTravel.isOrderCanceled()}
      onClick={() => setShowTravelPlan((prevState) => !prevState)}
    />
  );
}

export default PuppyDetailTravelPlanTile;
