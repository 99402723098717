import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Empty } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import { WidgetContainer } from 'pages/dashboard/widgetContainer/WidgetContainer';
import { PuppySummary } from 'pages/dashboard/Dashboard';
import EntityPhoto from 'shared/entitiesOverview/entityPhoto/EntityPhoto';
import { Icon } from 'shared/icon/Icon';

import './PuppyPerformance.scss';

interface PuppyPerformanceProps {
  mostViewedPuppies?: PuppySummary[];
}

export default function PuppyPerformance(
  props: PuppyPerformanceProps,
): ReactElement | null {
  const { mostViewedPuppies } = props;

  if (mostViewedPuppies === undefined) {
    return <Icon name="spinner" />;
  }

  if (isEmpty(mostViewedPuppies)) {
    return <Empty />;
  }

  return (
    <WidgetContainer
      title="My Puppies Performance"
      className="PuppyPerformance"
    >
      <div className="PuppyPerformance__content">
        {!isEmpty(mostViewedPuppies) && (
          <ul>
            {mostViewedPuppies.slice(0, 8).map((puppy, idx) => (
              <li
                key={idx}
                data-testid={puppy.puppyId}
                className="PuppyPerformance__item"
              >
                <Link
                  className="PuppyPerformance__card"
                  to={`/puppy/${puppy.puppyId}`}
                >
                  <span className="PuppyPerformance__card-indicator">
                    {idx + 1}
                  </span>
                  <EntityPhoto
                    entity="puppy"
                    imageSrc={puppy.mediaThumbnailUrl}
                    height="34px"
                    width="34px"
                  />
                  <span className="PuppyPerformance__card-title">
                    {puppy.name}
                  </span>
                  <span className="PuppyPerformance__card-view-counter">
                    <EyeOutlined />
                    {puppy.views} views
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </WidgetContainer>
  );
}
