import React from 'react';

import TravelInfoItem from 'shared/puppy/puppyTravel/TravelInfoItem';
import {
  GroundTravelProposal,
  TravelProposals,
} from 'shared/types/puppyTravel';
import { testId } from 'testsUtilities/dataTestId';
import date from 'utilities/time/date';

interface Props {
  groundProposal: GroundTravelProposal;
  proposalData: Partial<TravelProposals>;
}

function TravelProposalInfoGround(props: Props) {
  const { groundProposal, proposalData } = props;
  return (
    <section data-testid={testId.travelProposalInfoGround}>
      <TravelInfoItem
        label="Transporter"
        value={groundProposal?.groundTransportationCompany?.name}
      />

      <TravelInfoItem
        label="Departure date"
        value={date.toFullMonthDDYYYY(proposalData?.departureTime)}
      />

      <TravelInfoItem
        label="Arrival date"
        value={date.toFullMonthDDYYYY(proposalData?.arrivalTime)}
      />
    </section>
  );
}

export default TravelProposalInfoGround;
