import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';

import { PuppyPhotos } from 'shared/types/puppy';
import {
  setPuppyPhotosAdditional,
  setPuppyPhotosCover,
  setPuppyPhotosHidden,
} from 'store/puppy/actions';
import { RootState } from 'store/rootState';
import { isListingLocked } from 'utilities/listing';

interface Props {
  disabled?: boolean;
}

function PuppyPhotosDeleteAll(props: Props): ReactElement {
  const { disabled } = props;
  const { puppy } = useSelector((state: RootState) => state);
  const puppyPhotos = {
    coverPhotos: puppy.photos?.coverPhotos,
    additionalPhotos: puppy.photos?.additionalPhotos,
    hiddenPhotos: puppy.photos?.hiddenPhotos,
  } as PuppyPhotos;
  const dispatch = useDispatch();

  const removeAllPhotos = () => {
    const photos = [
      ...puppyPhotos.coverPhotos,
      ...puppyPhotos.additionalPhotos,
      ...puppyPhotos.hiddenPhotos,
    ];

    dispatch(setPuppyPhotosHidden(photos));
    dispatch(setPuppyPhotosCover([]));
    dispatch(setPuppyPhotosAdditional([]));
  };

  return (
    <Button
      onClick={removeAllPhotos}
      data-testid="photo-delete-images"
      type="link"
      danger
      block
      disabled={
        disabled || Boolean(puppy.listing && isListingLocked(puppy.listing))
      }
    >
      Delete all images
    </Button>
  );
}

export default PuppyPhotosDeleteAll;
