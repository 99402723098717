export const SET_NEW_PUPPY = 'SET_NEW_PUPPY';

export type NewPuppyState = null | {
  sireId: number;
  damId: number;
  litterId: number;
  puppyId?: number;
};

export interface SetNewPuppy {
  type: typeof SET_NEW_PUPPY;
  newPuppy: NewPuppyState;
}

export type NewPuppyAction = SetNewPuppy;
