import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/rootState';
import { isDelivered } from 'utilities/entities/puppy';
import puppyTravelStatus from 'utilities/puppy/puppyTravelState';

import './PuppyDetailOrderStatus.scss';

function PuppyDetailOrderStatus(): ReactElement {
  const puppy = useSelector((state: RootState) => state.puppy);
  const statusPuppyTravel = puppyTravelStatus(puppy);

  const getOrderStatusTitle = () => {
    if (isDelivered(puppy)) return 'Puppy has been delivered!';

    if (statusPuppyTravel.isPuppyTravelFinalized()) {
      return 'Travel details ready!';
    } else if (statusPuppyTravel.isOrderCanceled()) {
      return 'Order cancelled!';
    } else {
      return 'Your puppy has been placed!';
    }
  };

  return (
    <section className="PuppyDetailOrderStatus">
      <h2 className="PuppyDetailOrderStatus__title">{getOrderStatusTitle()}</h2>
      {puppy?.order && (
        <p className="PuppyDetailOrderStatus__description">
          Customer
          <b>
            {` ${puppy?.order?.leads[0]?.firstName} ${puppy?.order?.leads[0]?.lastName}`}
          </b>
        </p>
      )}
    </section>
  );
}

export default PuppyDetailOrderStatus;
