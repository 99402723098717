import apiPuppy from 'utilities/http/api/apiPuppy';
import { Puppy } from 'shared/types/puppy';
import { RelativeSizeId } from 'utilities/const';

export const buildDuplicatePuppyData = async (
  puppyId: number,
): Promise<Partial<Puppy>> => {
  const puppyResp = await apiPuppy.getPuppy(puppyId);
  const puppy = puppyResp.json.puppy;
  const duplicatePuppyData: Partial<Puppy> = {
    breederName: undefined,
    genderId: puppy.genderId,
    colorId: puppy.colorId,
    markingId: puppy.markingId,
    weightRangeId: puppy.weightRangeId,
    relativeSizeId: RelativeSizeId.Neither,
    dateAvailable: puppy.dateAvailable,
    specialFeedingInstructions: puppy.specialFeedingInstructions,
    varietyId: puppy?.varietyId,
    registrations: [
      {
        registryId: puppy.registrations[0].registryId,
        registrationNumber: undefined,
      },
    ],
    medicalDetails: undefined,
    microchipRfid: undefined,
    healthIssuesDisclaimer: undefined,
    description: undefined,
    listing: {
      cost: puppy.listing?.cost,
      discountOfferAmount: puppy.listing?.discountOfferAmount,
      discountOfferReason: puppy.listing?.discountOfferReason,
      hasDiscountOffer: puppy.listing?.hasDiscountOffer,
      listingFees: puppy.listing.listingFees,
      notes: puppy.listing.notes,
    },
    litter: {
      breedId: puppy.litter.breedId,
      id: puppy.litter.id,
      food: puppy.litter.food,
    },
  };

  return duplicatePuppyData;
};
