import { chain } from 'lodash';

import { Documents, HealthDocsFile } from 'shared/types/document';
import date from 'utilities/time/date';

export const buildHealthDocsFiles = (
  documents: Documents,
): HealthDocsFile[] => {
  const healthDocsFiles = chain(documents)
    .groupBy((doc) => doc.media[0]?.id)
    .map((docs, key) => ({
      id: Number(key),
      name: docs[0].media[0]?.name,
      dataUrl: docs[0].media[0]?.dataUrl,
      createdAt: docs[0].createdAt,
      documents: docs,
    }))
    .value();

  return healthDocsFiles.sort((a, b) =>
    date.sortByNewest(a.createdAt, b.createdAt),
  );
};
