import { useEffect, useState } from 'react';

import { Documents } from 'shared/types/document';
import { Nullable } from 'shared/types/general';
import { PuppyDetailTileStatus } from 'shared/types/puppy';
import {
  hasAllHealthDocsApproved,
  hasValidHealthDocs,
  isHealthCertificateDocPendingReview,
  isVaccineRecordDocPendingReview,
  isVeterinaryHealthReportDocPendingReview,
  isHealthDocsDueDateOnWindow,
  isHealthDocsDueDatePastDue,
} from 'utilities/healthDocs';

function useHealthDocsTileStatus(
  documents: Documents,
  documentDueDate: Nullable<string>,
  isPuppyDelivered: boolean,
) {
  const [tileStatus, setTileStatus] = useState<PuppyDetailTileStatus>();
  const [tileStatusText, setTileStatusText] = useState<string>();

  useEffect(() => {
    if (isPuppyDelivered || hasAllHealthDocsApproved(documents)) {
      setTileStatus('success');
      setTileStatusText('Approved');
      return;
    }

    if (isHealthDocsDueDateOnWindow(documentDueDate)) {
      if (!hasValidHealthDocs(documents)) {
        setTileStatus('warning');
        setTileStatusText('Action required');
        return;
      }

      if (
        isHealthCertificateDocPendingReview(documents) ||
        isVeterinaryHealthReportDocPendingReview(documents) ||
        isVaccineRecordDocPendingReview(documents)
      ) {
        setTileStatus('pending');
        setTileStatusText('Pending Review');
        return;
      }
    }

    if (isHealthDocsDueDatePastDue(documentDueDate, documents)) {
      setTileStatusText('Past due');
      return;
    }
  }, [documentDueDate, documents, isPuppyDelivered]);

  return {
    healthDocsTileStatus: tileStatus,
    healthDocTileStatusText: tileStatusText,
  };
}

export default useHealthDocsTileStatus;
