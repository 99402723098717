import React, { ReactElement } from 'react';
import { Affix, Tabs } from 'antd';

import { useBaseLayoutAffixOffset } from 'hooks';
import ParentsColumn from 'pages/myParents/parentsColumn/ParentsColumn';
import { testId } from 'testsUtilities/dataTestId';

const { TabPane } = Tabs;

export default function MyParentsSmall(): ReactElement {
  const { offsetTopMyEntityTabBar } = useBaseLayoutAffixOffset();

  return (
    <div
      className="entity-columns-small"
      data-testid={testId.myParentsContentSmall}
    >
      <Tabs
        defaultActiveKey="dams"
        className="entity-columns-small__tabs"
        centered
        renderTabBar={(props, DefaultTabBar) => (
          <Affix offsetTop={offsetTopMyEntityTabBar}>
            <DefaultTabBar {...props} />
          </Affix>
        )}
      >
        <TabPane
          className="entity-columns-small__tab-column"
          tab="Dams"
          key="dams"
        >
          <ParentsColumn parentsType="dams" />
        </TabPane>
        <TabPane
          className="entity-columns-small__tab-column"
          tab="Sires"
          key="sires"
        >
          <ParentsColumn parentsType="sires" />
        </TabPane>
      </Tabs>
    </div>
  );
}
