import React, { ReactElement } from 'react';
import { Form, Input } from 'antd';
import TextAreaCounter from 'shared/fields/textAreaCounter/TextAreaCounter';

interface Props {
  disabled?: boolean;
}

export function FieldNoteFromBreeder(props: Props): ReactElement {
  const { disabled } = props;
  const maxLength = 1000;
  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, curValues) =>
        prevValues.noteFromBreeder !== curValues.noteFromBreeder
      }
    >
      {(form) => {
        const value = form.getFieldValue(['noteFromBreeder']);
        const characterCounter = value?.length || 0;

        return (
          <Form.Item
            name="noteFromBreeder"
            label="Notes to PuppySpot"
            extra={<TextAreaCounter counter={maxLength - characterCounter} />}
          >
            <Input.TextArea
              placeholder="Type your comments here"
              autoSize={{ minRows: 6, maxRows: 6 }}
              maxLength={maxLength}
              disabled={disabled}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
}
