import { useEffect, useState } from 'react';

import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import {
  BreedVarietyContent,
  VarietyContent,
} from 'utilities/staticContent/types';

interface UseBreedVarieties {
  breedVarieties: VarietyContent[];
}

function useBreedVarieties(breedId: number): UseBreedVarieties {
  const [breedVarieties, setBreedVarieties] = useState<VarietyContent[]>([]);
  breedVarieties?.sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    const contentBreedVarieties = staticContentStorage.getContent()
      .breedVarieties;
    const contentVarieties = staticContentStorage.getContent().varieties;
    const getBreedVarieties = contentBreedVarieties?.filter(
      (breedVariety: BreedVarietyContent) => breedVariety.breedId === breedId,
    );
    const breedVarietiesOptions: VarietyContent[] = getBreedVarieties?.map(
      (breedVariety: BreedVarietyContent) => {
        const variety = contentVarieties.find(
          (variety: VarietyContent) => variety.id === breedVariety.varietyId,
        );
        return {
          id: breedVariety.varietyId,
          name: variety.name,
        };
      },
    );

    setBreedVarieties(breedVarietiesOptions);
  }, [breedId]);

  return { breedVarieties };
}

export default useBreedVarieties;
