import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import { Id, Nullable, ZeroOrOne } from 'shared/types/general';

export function getContentDataById(fieldName: string, id: Nullable<Id>) {
  const contentField = staticContentStorage.getContent()[fieldName];
  return contentField?.filter((i: { id: number }) => i.id === id)?.[0];
}

export const getNameContentFieldById = (
  field: string,
  id: Nullable<number>,
) => {
  if (!id) return null;
  const matchingItem = staticContentStorage
    .getContent()
    [field]?.find((item: { id: number; name: string }) => {
      return item.id === id;
    });

  return matchingItem?.name || null;
};

export const getBreedTypeId = (breedId: Nullable<number>) => {
  const matchingItem = staticContentStorage
    .getContent()
    ['breeds']?.find((item: { id: number }) => {
      return item.id === breedId;
    });

  return matchingItem?.breedTypeId || null;
};

export function zeroOneToYesNo(numb: Nullable<ZeroOrOne>) {
  return numb ? 'Yes' : 'No';
}

export function booleanToYesNo(isTrue: Nullable<boolean>) {
  return isTrue ? 'Yes' : 'No';
}
