import React, { ReactElement } from 'react';
import { Popover } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { testId } from 'testsUtilities/dataTestId';

export function FieldPuppyCostPopOver(): ReactElement {
  const content = (
    <div
      className="PuppyCost__popover"
      data-testid={testId.fieldPuppyCostInfoPopOver}
    >
      <p>
        Price should include all costs associated with the care and placement of
        your puppy.
      </p>
      <p>Items Include:</p>
      <ul>
        <li>Microchip</li>
        <li>Health Certificate</li>
        <li>Fecal Check</li>
        <li>Final Vet Check</li>
        <li>Travel Crate or Carrier</li>
      </ul>
    </div>
  );
  return (
    <>
      Breeder Price
      <Popover content={content} trigger="hover">
        <ExclamationCircleOutlined
          data-testid={testId.fieldPuppyCostInfoIcon}
        />
      </Popover>
    </>
  );
}
