import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, PageHeader, Space } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import {
  MyListingsCardsSold,
  MyListingsTableSold,
} from 'components/myListings';
import PageLayout from 'shared/baseLayout/pageLayout/PageLayout';
import { BreederRoutes } from 'shared/types/routes';
import {
  fetchMyListingsSold,
  setMyListingsSoldInitialState,
} from 'store/myListingsSold/actions.thunk';
import {
  setMyListingsSoldFilters,
  setMyListingsSoldPagination,
  setMyListingsSoldSorter,
} from 'store/myListingsSold/actions';
import {
  initialStateMyListingsSoldFilters,
  initialStateMyListingsSoldSorter,
} from 'store/myListingsSold/myListingSoldReducer';
import { RootState } from 'store/rootState';

const breadcrumb = [
  {
    url: BreederRoutes.MyListings,
    name: 'My Puppy Listings',
  },
  {
    name: 'Sold',
  },
];

function MyListingsSold(): ReactElement {
  const {
    myListingsSold: { loading, pagination, sorter, filters },
  } = useSelector((state: RootState) => state);
  const { current: currentPage, pageSize, loadMore } = pagination;
  const dispatch = useDispatch();
  const screens = useBreakpoint();

  const handleClearFilters = () => {
    dispatch(setMyListingsSoldSorter(initialStateMyListingsSoldSorter));
    dispatch(setMyListingsSoldFilters(initialStateMyListingsSoldFilters));
    dispatch(
      setMyListingsSoldPagination({
        ...pagination,
        current: 1,
      }),
    );
  };

  useEffect(() => {
    !loadMore && dispatch(fetchMyListingsSold());
  }, [
    currentPage,
    pageSize,
    loadMore,
    sorter.sortBy,
    sorter.sortDirection,
    filters,
    dispatch,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setMyListingsSoldInitialState());
    };
  }, [dispatch]);

  return (
    <PageLayout
      className="MyListings MyListings--sold"
      breadCrumbsNav={breadcrumb}
    >
      <PageHeader
        className="MyListings__page-header"
        ghost={false}
        onBack={() => window.history.back()}
        title="Sold"
        extra={
          screens.xl ? (
            <Space>
              <Button
                type="link"
                onClick={handleClearFilters}
                disabled={
                  loading || (!sorter.isSorting && !filters.isFiltering)
                }
              >
                Clear filters
              </Button>
            </Space>
          ) : null
        }
      />

      <div className="MyListings__page-content">
        {screens.xl ? <MyListingsTableSold /> : <MyListingsCardsSold />}
      </div>
    </PageLayout>
  );
}

export default MyListingsSold;
