import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const useCurrentHistoryPathname = () => {
  const [pathname, setPathname] = useState<string>('');
  const history = useHistory();

  function updatePathnameOnBrowserRefresh() {
    return () => {
      if (history.location.pathname) {
        setPathname(history.location.pathname);
      }
    };
  }

  useEffect(updatePathnameOnBrowserRefresh(), []);

  history.listen((location) => {
    if (location.pathname) {
      setPathname(location.pathname);
    }
  });

  return pathname.replace('/', '');
};
