import rg4js from 'raygun4js';
import {
  isProduction,
  reactEnvironmentVariables,
} from 'utilities/environmentVariables';

const { RAYGUN_KEY } = reactEnvironmentVariables;

export default {
  init: () => {
    if (RAYGUN_KEY) {
      rg4js('enableCrashReporting', true);
      rg4js('apiKey', RAYGUN_KEY);
    }
  },
  logError: (error: Error) => {
    if (RAYGUN_KEY && isProduction()) {
      rg4js('send', error);
    }
  },
};
