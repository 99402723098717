export const legacyRegistries = [
  {
    id: 53,
    name: 'World Dog Registration Club',
    title: 'WDRC',
    pattern: null,
    example: null,
    allow_limitation: 0,
    requires_registration: 1,
  },
];
