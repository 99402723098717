import { Nullable, PaginationMeta } from 'shared/types/general';
import {
  Parent,
  MyParentsTypes,
  ParentsSearchParams,
} from 'shared/types/parent';

export const MY_PARENTS_SET_PARENTS_LOADING = 'MY_PARENTS_SET_PARENTS_LOADING';
export const MY_PARENTS_SET_PARENTS = 'MY_PARENTS_SET_PARENTS';
export const MY_PARENTS_SET_PARENTS_LOADING_MORE =
  'MY_PARENTS_SET_PARENT_LOADING_MORE';
export const MY_PARENTS_SET_MORE_PARENTS = 'MY_PARENTS_SET_MORE_PARENTS';
export const MY_PARENTS_SET_PARENTS_SEARCH_PARAMS =
  'MY_PARENTS_SET_PARENTS_SEARCH_PARAMS';
export const MY_PARENTS_RESET_STATE = 'MY_PARENTS_RESET_STATE';

export interface MyParentState {
  loading: boolean;
  loadingMore: boolean;
  data: Parent[];
  pagination: PaginationMeta;
}

export interface MyParentsState {
  sires: MyParentState;
  dams: MyParentState;
  searchParams: Nullable<ParentsSearchParams>;
}

export interface MyParentsSetParentsLoading {
  type: typeof MY_PARENTS_SET_PARENTS_LOADING;
  myParentType: MyParentsTypes;
}

export interface MyParentsSetParents {
  type: typeof MY_PARENTS_SET_PARENTS;
  myParentType: MyParentsTypes;
  parents: Parent[];
  pagination: PaginationMeta;
}

export interface MyParentsSetParentsLoadingMore {
  type: typeof MY_PARENTS_SET_PARENTS_LOADING_MORE;
  myParentType: MyParentsTypes;
}

export interface MyParentsSetMoreParents {
  type: typeof MY_PARENTS_SET_MORE_PARENTS;
  myParentType: MyParentsTypes;
  parents: Parent[];
  pagination: PaginationMeta;
}

export interface MyParentsSetParentsSearchParams {
  type: typeof MY_PARENTS_SET_PARENTS_SEARCH_PARAMS;
  searchParams: Nullable<ParentsSearchParams>;
}

export interface MyParentsResetState {
  type: typeof MY_PARENTS_RESET_STATE;
}

export type MyParentsActionTypes =
  | MyParentsSetParentsLoading
  | MyParentsSetParents
  | MyParentsSetParentsLoadingMore
  | MyParentsSetMoreParents
  | MyParentsSetParentsSearchParams
  | MyParentsResetState;
