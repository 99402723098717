import { Dispatch } from 'redux';
import { DispatchAny, Nullable } from 'shared/types/general';
import {
  ParentsActionTypes,
  RESET_PARENTS,
  SET_PARENT,
  RESET_PARENT,
} from 'store/parents/types';
import apiParent from 'utilities/http/api/apiParent';
import apiMedia from 'utilities/http/api/apiMedia';
import media, { mediaConfig } from 'utilities/media/media';
import showNotificationError from 'shared/notifications/showNotificationError';
import parentFormHelpers from 'shared/parents/parentFormHelpers';
import { Gender, Parent, ParentFormFields } from 'shared/types/parent';
import { RootState } from 'store/rootState';
import { isEmpty } from 'lodash';

export interface SetParent {
  type: typeof SET_PARENT;
  parent: Partial<Parent>;
}

export interface ResetParents {
  type: typeof RESET_PARENTS;
}

export interface ResetParent {
  type: typeof RESET_PARENT;
  gender: Gender;
}

export function setParent(parent: Partial<Parent>): ParentsActionTypes {
  return {
    type: SET_PARENT,
    parent,
  };
}

export function resetParents(): ParentsActionTypes {
  return {
    type: RESET_PARENTS,
  };
}

export function resetParent(gender: Gender): ParentsActionTypes {
  return {
    type: RESET_PARENT,
    gender,
  };
}

export const getParent = (id: number) => {
  return async (dispatch: Dispatch) => {
    let parent: Partial<Parent> = {};
    try {
      const parentResponse = await apiParent.getParentDetails(id);
      if (parentResponse.status !== 200) {
        showNotificationError({ error: `Couldn't get parent with id: ${id}` });
      }
      parent = parentResponse.json.puppyParent;
      parent.media = media.buildParentMedia(parent.media);
    } catch (e) {
      showNotificationError({ error: `Couldn't get parent with id: ${id}` });
    } finally {
      dispatch(setParent(parent));
    }
  };
};

export function createParent(parentFormFields: ParentFormFields) {
  return async (dispatch: DispatchAny) => {
    try {
      const parentPayload = parentFormHelpers.payloadBuilder(parentFormFields);
      const respParentId = await parentFormHelpers.postParent(parentPayload);

      const mediaUploaderParams = {
        mediaEntityId: respParentId,
        mediaEntity: 'puppyParent',
      };

      const photoUploaderParams = {
        ...mediaUploaderParams,
        mediaTypeId: mediaConfig.mediaType.photo,
      };
      await media.uploader(parentFormFields.mediaPhoto, photoUploaderParams);

      if (parentFormFields.pedigreeDoc) {
        const pedigreeDocUploaderParams = {
          ...mediaUploaderParams,
          mediaTypeId: mediaConfig.mediaType.pedigreeFile,
        };
        await media.uploader(
          parentFormFields.pedigreeDoc,
          pedigreeDocUploaderParams,
        );
      }

      dispatch(getParent(respParentId));
      return respParentId;
    } catch (error) {
      throw error;
    }
  };
}

export function editParent(
  parentId: Nullable<number>,
  parentFormFields: ParentFormFields,
) {
  return async (dispatch: DispatchAny, getState: () => RootState) => {
    try {
      if (!parentId) {
        throw new Error(`Parent id undefined`).message;
      }
      const { dam, sire } = getState().parents;
      const parent = !isEmpty(dam) ? dam : sire;

      const parentPayload = parentFormHelpers.payloadBuilder(parentFormFields);
      await parentFormHelpers.putParent(parentId as number, parentPayload);

      const mediaUploaderParams = {
        mediaEntityId: parentId,
        mediaEntity: 'puppyParent',
      };

      if (parentFormFields.mediaPhoto.uid) {
        const photoUploaderParams = {
          ...mediaUploaderParams,
          mediaTypeId: mediaConfig.mediaType.photo,
        };
        await media.uploader(parentFormFields.mediaPhoto, photoUploaderParams);
      }

      if (parentFormFields.pedigreeDoc?.uid) {
        const pedigreeDocUploaderParams = {
          ...mediaUploaderParams,
          mediaTypeId: mediaConfig.mediaType.pedigreeFile,
        };
        await media.uploader(
          parentFormFields.pedigreeDoc,
          pedigreeDocUploaderParams,
        );
      }

      if (parentFormFields.mediaPhotoHiddenId) {
        await apiMedia.hideMedia(parentFormFields.mediaPhotoHiddenId);
      }

      if (parentFormFields.pedigreeDocHiddenId) {
        await apiMedia.hideMedia(parentFormFields.pedigreeDocHiddenId);
      }

      await apiMedia.hideCorruptedMedia(parent.media);

      await dispatch(getParent(parentId as number));
    } catch (error) {
      throw error;
    }
  };
}
