import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Button, Tooltip } from 'antd';
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  HeartOutlined,
  HourglassOutlined,
} from '@ant-design/icons';

import { useHealthDocsTileStatus } from 'components/healthDocs';
import { Listing, Puppy } from 'shared/types/puppy';
import { BreederRoutes } from 'shared/types/routes';
import { isDelivered, isSold } from 'utilities/entities/puppy';

interface Props {
  listing: Partial<Listing>;
  setDrawerPuppy?: (listing: Listing | null) => void;
}

function HealthDocsListingCardBadge(props: Props): ReactElement | null {
  const { listing, setDrawerPuppy } = props;
  const [badgeIcon, setBadgeIcon] = useState<ReactNode>();
  const { healthDocsTileStatus } = useHealthDocsTileStatus(
    listing.puppy?.documents,
    listing.documentDueDate,
    isDelivered(listing.puppy),
  );
  const {
    location: { pathname },
  } = useHistory();

  useEffect(() => {
    switch (healthDocsTileStatus) {
      case 'success':
        setBadgeIcon(
          <CheckCircleFilled className="badge-icon badge-icon--success" />,
        );
        break;
      case 'pending':
        setBadgeIcon(
          <HourglassOutlined className="badge-icon badge-icon--pending" />,
        );
        break;
      case 'error':
      case 'warning':
        setBadgeIcon(
          <ExclamationCircleFilled className="badge-icon badge-icon--warning" />,
        );
        break;
      default:
        setBadgeIcon(undefined);
    }
  }, [healthDocsTileStatus]);

  if (pathname === BreederRoutes.MyUpdates || !isSold(listing.puppy as Puppy)) {
    return null;
  }

  return (
    <Button
      className="UpToDateInfoBarButton"
      onClick={() => setDrawerPuppy?.(listing as Listing)}
    >
      <Tooltip title={'Open health docs drawer'} placement="topRight">
        <Badge count={badgeIcon}>
          <HeartOutlined className="btn-icon" />
        </Badge>
      </Tooltip>
    </Button>
  );
}

export default HealthDocsListingCardBadge;
