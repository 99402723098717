import { useEffect, useState } from 'react';

import { Media, Nullable } from 'shared/types';

export interface UseCloudflareVideo {
  cloudflareVideoSrc: string | undefined;
  cloudflareVideoThumb: string | undefined;
  cloudflareVideoDownload: string | undefined;
}

const cloudflareCustomerCode = '4tih2bjymu20uxxn';
const cloudflareAttributeName = 'Cloudflare Uid';

function useCloudflareVideo(video: Nullable<Media>): UseCloudflareVideo {
  const [cloudflareVideo, setCloudflareVideo] = useState<UseCloudflareVideo>({
    cloudflareVideoSrc: undefined,
    cloudflareVideoThumb: undefined,
    cloudflareVideoDownload: undefined,
  });

  useEffect(() => {
    const cloudflareAttr = video?.attributes?.find(
      (attribute) => attribute.name === cloudflareAttributeName,
    ) || { value: video?.filePath, mediaId: video?.name };

    if (cloudflareAttr) {
      const cloudflareStreamUrl = `https://customer-${cloudflareCustomerCode}.cloudflarestream.com/${cloudflareAttr.value}`;

      setCloudflareVideo({
        cloudflareVideoSrc: `${cloudflareStreamUrl}/iframe`,
        cloudflareVideoThumb: `${cloudflareStreamUrl}/thumbnails/thumbnail.jpg?height=270`,
        cloudflareVideoDownload: `${cloudflareStreamUrl}/downloads/default.mp4?filename=${cloudflareAttr.mediaId}`,
      });
    }
  }, [video]);

  return cloudflareVideo;
}

export default useCloudflareVideo;
