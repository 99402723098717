import React from 'react';

import { DueDate } from 'shared/types/date';
import {
  daysPastDue,
  daysToDueDate,
  puppyDueDatesIsPastDue,
  puppyDueDatesIsUpToDate,
} from 'utilities/puppy/puppyDueDates/puppyDueDates';

import './UpToDateInfoBarLabel.scss';

interface Props {
  dueDates: DueDate[] | undefined;
}

function UpToDateInfoBarLabel(props: Props) {
  const { dueDates } = props;

  const dueDateLabel = () => {
    if (!dueDates) return;
    if (puppyDueDatesIsUpToDate(dueDates)) {
      return <h3>Up to date</h3>;
    }

    if (puppyDueDatesIsPastDue(dueDates)) {
      return (
        <div>
          <p>Past due</p>
          <h3>{daysPastDue(dueDates)}</h3>
        </div>
      );
    }

    return (
      <div>
        <p>Next due</p>
        <h3>{daysToDueDate(dueDates)}</h3>
      </div>
    );
  };

  return (
    <section
      className="UpToDateInfoBar__label"
      data-testid="up-to-date-info-bar-label"
    >
      {dueDateLabel()}
    </section>
  );
}

export default UpToDateInfoBarLabel;
