import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Empty } from 'antd';
import { isEmpty } from 'lodash';

import LoadingSection from 'components/loadingSection/LoadingSection';
import ListingCard from 'pages/myListings/listingCard/ListingCard';
import { fetchMyListingsDelivered } from 'store/myListingsDelivered/actions.thunk';
import { setMyListingsDeliveredPagination } from 'store/myListingsDelivered/actions';
import { RootState } from 'store/rootState';
import { testId } from 'testsUtilities/dataTestId';

import '../MyListingsCards.scss';

function MyListingsCardsDelivered(): ReactElement {
  const {
    myListingsDelivered: { loading, listings, pagination },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const handleLoadMore = () => {
    dispatch(
      setMyListingsDeliveredPagination({
        ...pagination,
        current: pagination.current + 1,
        loadMore: true,
      }),
    );
    dispatch(fetchMyListingsDelivered());
  };

  return (
    <section
      className="MyListingsCards__wrapper"
      data-testid={testId.myListingsDeliveredCardWrapper}
    >
      {loading && <LoadingSection />}
      {!loading && isEmpty(listings) && (
        <Empty
          description={
            <>
              No Data <br />
              Puppies Delivered in the past 6 months.
            </>
          }
        />
      )}
      {listings.map((l) => (
        <ListingCard key={l.puppyId} listing={l} columnName="delivered" />
      ))}
      {!isEmpty(listings) && listings.length !== pagination.total && (
        <Button
          type="link"
          className="LoadMore"
          onClick={handleLoadMore}
          loading={loading}
        >
          Load More
        </Button>
      )}
    </section>
  );
}

export default MyListingsCardsDelivered;
