import React, { ReactElement } from 'react';
import { Form, Select } from 'antd';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import { EmptyFieldValidationHandler } from '../fieldValidationHandler';
import { BreedContent } from 'utilities/staticContent/types';

interface FieldBreedProps {
  disabled?: boolean;
  hasValidation?: boolean;
  label?: string;
  name?: string;
}

export function FieldBreed(props: FieldBreedProps): ReactElement {
  const { disabled, label, name, hasValidation = true } = props;
  const staticContent = staticContentStorage.getContent();
  const breeds = staticContent.breeds as BreedContent[];
  breeds?.sort((a, b) => a.name.localeCompare(b.name));

  const validations = hasValidation
    ? [EmptyFieldValidationHandler('list')]
    : [];
  return (
    <Form.Item
      name={name || 'breedId'}
      label={label || 'Breed'}
      rules={validations}
    >
      <Select
        data-testid="field-breed"
        disabled={disabled}
        placeholder="Select a Breed"
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {breeds?.map((breed: BreedContent) => (
          <Select.Option key={breed.id} value={breed.id}>
            {breed.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}
