import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Empty } from 'antd';
import { isEmpty } from 'lodash';

import LoadingSection from 'components/loadingSection/LoadingSection';
import ListingCard from 'pages/myListings/listingCard/ListingCard';
import LoadMore from 'shared/entitiesOverview/loadMore/LoadMore';
import {
  MyPuppyListingsColumns,
  MyUpcomingColumns,
  Nullable,
} from 'shared/types/general';
import { Listing } from 'shared/types/puppy';
import { RootState } from 'store/rootState';

interface Props {
  columnName: MyPuppyListingsColumns | MyUpcomingColumns;
}

export default function ListingsColumn({ columnName }: Props): ReactElement {
  const { myListings } = useSelector((state: RootState) => state);
  const { searchLoading, searchResult } = myListings;

  const listings: Nullable<Listing[]> = !isEmpty(searchResult)
    ? searchResult?.[columnName]
    : myListings[columnName];

  if (listings === null || searchLoading) {
    return <LoadingSection />;
  }

  if (isEmpty(listings))
    return <Empty data-testid={`empty-updates-${columnName}`} />;

  return (
    <>
      {listings?.map((l) => {
        return <ListingCard key={l.id} listing={l} columnName={columnName} />;
      })}
      {isEmpty(searchResult) ? <LoadMore columnName={columnName} /> : null}
    </>
  );
}
