import {
  BreederActions,
  BreederState,
  SET_STATES,
  SET_BREEDER,
  SET_COUNTRY,
} from 'store/breeder/types';

const initialState: BreederState = {};

export function breederReducer(
  state = initialState,
  action: BreederActions,
): BreederState {
  switch (action.type) {
    case SET_BREEDER:
      return { ...state, breeder: action.breeder };
    case SET_STATES:
      return { ...state, states: action.states };
    case SET_COUNTRY:
      if (state.breeder?.addresses?.[0]) {
        state.breeder.addresses[0].countryId = action.countryId;
      }
      return state;
    default:
      return state;
  }
}
