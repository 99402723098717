import React, { ReactElement } from 'react';
import { Icon } from 'shared/icon/Icon';

import './PuppyGenderIcon.scss';

interface Props {
  gender: 'sire' | 'dam';
}

export default function PuppyGenderIcon({ gender }: Props): ReactElement {
  return (
    <Icon
      className={`PuppyGenderIcon PuppyGenderIcon__${gender}`}
      name={gender}
    />
  );
}
