import React, { ReactElement } from 'react';
import { cloneDeep } from 'lodash';
import { Button, Col, Row } from 'antd';

import EntityPhoto from 'shared/entitiesOverview/entityPhoto/EntityPhoto';
import { DueDate, UpcomingUpdate } from 'shared/types/date';
import { Icon } from 'shared/icon/Icon';
import date from 'utilities/time/date';

import './UpcomingCard.scss';

interface UpcomingCardProps {
  upcoming: UpcomingUpdate;
}

export function UpcomingCard(props: UpcomingCardProps): ReactElement {
  const {
    upcoming: { name, mediaThumbnailUrl, dueDates },
  } = props;

  const sortByOld = (data: DueDate[]): DueDate[] => {
    const dataToSort = cloneDeep(data);
    const sorted = dataToSort.sort((a, b) =>
      date.sortByOldest(a.dueDate, b.dueDate),
    );

    return sorted;
  };

  const oldestUpdate = sortByOld(dueDates);
  const dueDateFormatted = date.toMMDDYY(oldestUpdate[0].dueDate);

  const diff = date.diff(new Date(), oldestUpdate[0].dueDate, 'days');

  const updateLabel =
    oldestUpdate.length > 1 ? 'Multiple Updates' : oldestUpdate[0].item;

  let dueDateLabel = `Due by ${dueDateFormatted}`;
  let upcomingCardPastDueClass = '';

  if (diff > 0) {
    upcomingCardPastDueClass = 'UpcomingCard--past-due';
    dueDateLabel = `Past due ${diff} days`;
  }

  return (
    <div className={`UpcomingCard ${upcomingCardPastDueClass}`}>
      <Row align="middle" wrap={false}>
        <Col flex="65px">
          <EntityPhoto
            entity="puppy"
            imageSrc={mediaThumbnailUrl}
            height="65px"
            width="65px"
          />
        </Col>
        <Col flex="auto">
          <div className="UpcomingCard__info">
            <Row>
              <Col xs={14} lg={13}>
                <span className="UpcomingCard__info-title">{name}</span>
              </Col>
              <Col xs={10} lg={11} className="text-right">
                <Button type="default" size="small">
                  Update
                </Button>
              </Col>
              <Col xs={14} lg={13}>
                <span
                  className="UpcomingCard__info-text"
                  data-testid="updateLabel"
                >
                  <Icon name="warning" className="UpcomingCard__info-icon" />
                  {updateLabel}
                </span>
              </Col>
              <Col xs={10} lg={11} className="text-right">
                <span
                  className="UpcomingCard__info-due-date-label"
                  data-testid="dueDateLabel"
                >
                  {dueDateLabel}
                </span>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}
