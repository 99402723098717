import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, Modal, notification, Row } from 'antd';
import { ReadOutlined } from '@ant-design/icons';

import { PuppyUpdateButton } from 'shared/types/puppy';
import { FieldRegistry } from 'shared/fields';
import { setUpdateMyListingsPuppyRegistrationsWeightDueDates } from 'store/myListings/actions.thunk';
import { fetchMyListingsHidden } from 'store/myListingsHidden/actions.thunk';
import { fetchMyListingsApproved } from 'store/myListingsApproved/actions.thunk';
import { fetchMyListingsDelivered } from 'store/myListingsDelivered/actions.thunk';
import { fetchMyListingsPending } from 'store/myListingsPending/actions.thunk';
import { fetchMyListingsSold } from 'store/myListingsSold/actions.thunk';

import apiPuppy from 'utilities/http/api/apiPuppy';
import { isListingLocked } from 'utilities/listing';
import UpToDateInfoBarButton from '../upToDateInfoBar/upToDateInfoBarButton/UpToDateInfoBarButton';

import './UpdatePuppyRegistration.scss';

function UpdatePuppyRegistration(props: PuppyUpdateButton) {
  const { columnName, listing } = props;
  const dueDates = listing?.puppy?.dueDates || [];
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const puppyId = listing?.puppy?.id;
  const puppyName = listing?.puppy?.breederName;
  const puppyRegistration =
    listing?.puppy?.registrations && listing?.puppy?.registrations[0];
  const registrationId = puppyRegistration && puppyRegistration.id;
  const registryId = puppyRegistration && puppyRegistration.registryId;
  const registrationNumber =
    listing.puppy?.pendingEdit?.registrations?.[0]?.registrationNumber ??
    puppyRegistration?.registrationNumber;

  const setDisabled = () => {
    return Boolean(columnName === 'delivered' || isListingLocked(listing));
  };

  const handleOnExit = () => {
    setLoading(false);
    setModalVisible(false);
  };

  const handleRefreshListing = () => {
    if (!listing.puppy?.breederDashboardStatus) return;

    const refreshListing = {
      approved: fetchMyListingsHidden,
      delivered: fetchMyListingsApproved,
      hidden: fetchMyListingsDelivered,
      pending: fetchMyListingsPending,
      sold: fetchMyListingsSold,
    };

    dispatch(refreshListing[listing.puppy.breederDashboardStatus]());
  };

  const handleUpdateRegistration = async (value: {
    registryId: number;
    registrationNumber: string;
  }) => {
    const errorMessage = `Couldn't update ${puppyName} registration`;
    const successMessage = `${puppyName} registration updated successfully`;
    try {
      if (!registrationId) {
        throw new Error(errorMessage);
      }
      setLoading(true);

      const puppyResp = await apiPuppy.putPuppy(puppyId as number, {
        registrations: [
          {
            id: registrationId,
            registryId: value.registryId,
            registrationNumber: value.registrationNumber?.toString() || '',
          },
        ],
      });

      if (puppyResp.json.errors) {
        throw new Error(errorMessage);
      }

      dispatch(
        setUpdateMyListingsPuppyRegistrationsWeightDueDates(
          puppyResp.json.puppy,
          columnName,
        ),
      );
      notification.success({
        message: successMessage,
      });
    } catch (error) {
      if (error instanceof Error) {
        notification.error({
          message: `Error!`,
          description: error.message,
        });
      }
    } finally {
      handleRefreshListing();
      handleOnExit();
    }
  };

  return (
    <>
      <UpToDateInfoBarButton
        btnType="Registration"
        dueDates={dueDates}
        listing={listing}
        onClick={() => setModalVisible(true)}
        disabled={setDisabled()}
      />
      {modalVisible && (
        <Modal
          className="updatePuppyRegistration__modal"
          visible={modalVisible}
          closable={false}
          footer={false}
          width={380}
          afterClose={() => form.resetFields()}
        >
          <h3 className="updatePuppyWeight__modal__title">
            <ReadOutlined />
            <b>{puppyName}</b> registration update
          </h3>
          <Form
            onFinish={handleUpdateRegistration}
            initialValues={{ registryId, registrationNumber }}
            layout="vertical"
            form={form}
          >
            <FieldRegistry />
            <Form.Item noStyle>
              <Row justify="end">
                <Button type="default" htmlType="button" onClick={handleOnExit}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
}

export default UpdatePuppyRegistration;
