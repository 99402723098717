import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';

import { EntityListColumn } from 'components/entity';
import {
  MyListingsCardsApproved,
  MyListingsCardsDelivered,
  MyListingsCardsPending,
  MyListingsCardsSold,
} from 'components/myListings';
import { RootState } from 'store/rootState';
import { Listing } from 'shared/types';

interface Props {
  openModal?: (listing: Listing) => void;
}

function MyListingsDashboardStatusColumns({ openModal }: Props): ReactElement {
  const {
    myListingsPending: {
      pagination: { total: totalListingsPending },
    },
    myListingsApproved: {
      pagination: { total: totalListingsApproved },
    },
    myListingsSold: {
      pagination: { total: totalListingsSold },
    },
    myListingsDelivered: {
      pagination: { total: totalListingsDelivered },
    },
  } = useSelector((state: RootState) => state);

  return (
    <Row gutter={12}>
      <Col span={6}>
        <EntityListColumn
          label="Pending"
          totalItems={totalListingsPending}
          content={<MyListingsCardsPending openModal={openModal} />}
        />
      </Col>
      <Col span={6}>
        <EntityListColumn
          label="Approved"
          totalItems={totalListingsApproved}
          content={<MyListingsCardsApproved openModal={openModal} />}
        />
      </Col>
      <Col span={6}>
        <EntityListColumn
          label="Sold"
          totalItems={totalListingsSold}
          content={<MyListingsCardsSold />}
        />
      </Col>
      <Col span={6}>
        <EntityListColumn
          label="Delivered"
          totalItems={totalListingsDelivered}
          content={<MyListingsCardsDelivered />}
        />
      </Col>
    </Row>
  );
}

export default MyListingsDashboardStatusColumns;
