import React, { ReactElement } from 'react';
import { Col, Row } from 'antd';

import { EntityListColumn } from 'components/entity';
import { testId } from 'testsUtilities/dataTestId';
import ParentsColumn from '../parentsColumn/ParentsColumn';

export default function MyParentsLarge(): ReactElement {
  return (
    <Row gutter={12} data-testid={testId.myParentsContentLarge}>
      <Col span={12}>
        <EntityListColumn
          label="Dams"
          content={<ParentsColumn parentsType="dams" />}
        />
      </Col>
      <Col span={12}>
        <EntityListColumn
          label="Sires"
          content={<ParentsColumn parentsType="sires" />}
        />
      </Col>
    </Row>
  );
}
