import React from 'react';
import { useSelector } from 'react-redux';
import { Collapse, Row } from 'antd';
import { isEmpty } from 'lodash';

import { RootState } from 'store/rootState';
import { PuppyOrder } from 'shared/types/puppy';
import TravelSequenceInfoFlight from 'shared/puppy/puppyTravel/sequence/sequenceInfoContent/TravelSequenceInfoFlight';
import TravelSequenceInfoGround from 'shared/puppy/puppyTravel/sequence/sequenceInfoContent/TravelSequenceInfoGround';
import TravelSequenceInfoChaperone from 'shared/puppy/puppyTravel/sequence/sequenceInfoContent/TravelSequenceInfoChaperone';
import TravelSequenceInfoMeetUp from 'shared/puppy/puppyTravel/sequence/sequenceInfoContent/TravelSequenceInfoMeetUp';
import TravelSequenceInfoTransporter from 'shared/puppy/puppyTravel/sequence/sequenceInfoContent/TravelSequenceInfoTransporter';
import { testId } from 'testsUtilities/dataTestId';

import 'shared/puppy/puppyTravel/PuppyTravel.scss';

function PuppyTravelSequence() {
  const { puppy } = useSelector((state: RootState) => state);

  const { travels } = puppy?.order as PuppyOrder;
  const travelInfo = travels[0] || {};

  const renderCollapsablePanelHeader = (idx: number, title: string) => {
    return (
      <Row align="middle">
        <span className="sequence-count">{idx + 1}</span>
        {title}
      </Row>
    );
  };

  if (isEmpty(travelInfo)) return null;

  return (
    <>
      <section
        className="PuppyTravel_header"
        data-testid={testId.puppyTravelSequence}
      >
        <h2>{travelInfo.travelMethod?.name} confirmed!</h2>
      </section>
      <section
        className="PuppyTravel_body"
        data-testid="puppy-travel-sequence-box"
      >
        <Collapse
          className="PuppyTravel_collapse"
          defaultActiveKey={['0']}
          expandIconPosition="right"
        >
          {travelInfo.sequences?.map((sequence, idx) => {
            if (!isEmpty(sequence.flightSequences)) {
              const flightSequence = sequence.flightSequences[0];

              return (
                <Collapse.Panel
                  header={renderCollapsablePanelHeader(
                    idx,
                    'Flight Transportation',
                  )}
                  key={idx}
                >
                  <TravelSequenceInfoFlight
                    travelInfo={travelInfo}
                    flightSequence={flightSequence}
                  />
                </Collapse.Panel>
              );
            } else if (!isEmpty(sequence.groundTransportationSequences)) {
              const groundSequence = sequence.groundTransportationSequences[0];
              return (
                <Collapse.Panel
                  header={renderCollapsablePanelHeader(
                    idx,
                    'Ground Transportation',
                  )}
                  key={idx}
                >
                  <TravelSequenceInfoGround groundSequence={groundSequence} />
                </Collapse.Panel>
              );
            } else if (!isEmpty(sequence.puppyChaperoneFlightSequences)) {
              const chaperoneSequence =
                sequence.puppyChaperoneFlightSequences[0];
              return (
                <Collapse.Panel
                  header={renderCollapsablePanelHeader(idx, 'Chaperone')}
                  key={idx}
                >
                  <TravelSequenceInfoChaperone
                    chaperoneSequence={chaperoneSequence}
                  />
                </Collapse.Panel>
              );
            } else if (!isEmpty(sequence.meetupSequences)) {
              const meetUpSequence = sequence.meetupSequences[0];
              return (
                <Collapse.Panel
                  header={renderCollapsablePanelHeader(idx, 'Meet Up')}
                  key={idx}
                >
                  <TravelSequenceInfoMeetUp meetUpSequence={meetUpSequence} />
                </Collapse.Panel>
              );
            } else if (!isEmpty(sequence.transporterSequences)) {
              const transporterSequences = sequence.transporterSequences[0];
              return (
                <Collapse.Panel
                  header={renderCollapsablePanelHeader(idx, 'Transporter')}
                  key={idx}
                >
                  <TravelSequenceInfoTransporter
                    transporterSequence={transporterSequences}
                  />
                </Collapse.Panel>
              );
            }
          })}
        </Collapse>
      </section>
    </>
  );
}

export default PuppyTravelSequence;
