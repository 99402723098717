import { Id, Nullable } from 'shared/types/general';

export interface Travel {
  id: Id;
  crateSizeId: number;
  travelTypeId: TravelTypeIds;
  travelType: TravelType;
  travelMethod: TravelMethod;
  travelProposals: TravelProposals[];
  sequences: TravelSequence[];
  workflowEntity: TravelWorkflowEntity;
}

export interface TravelMethod {
  id: Id;
  name: string;
  slug: string;
}

export interface TravelType {
  name: string;
  longDescription: string;
  shortDescription: string;
}

export interface TravelProposals {
  arrivalTime: string;
  departureTime: string;
  id: Id;
  meetupTravelProposal: Nullable<MeetupTravelProposal>;
  chaperoneTravelProposal: Nullable<ChaperoneTravelProposal>;
  groundTravelProposal: Nullable<GroundTravelProposal>;
  charterTravelProposal: Nullable<CharterTravelProposal>;
  cargoTravelProposal: Nullable<CargoTravelProposal>;
}

export interface TravelSequence {
  id: number;
  flightSequences: FlightSequence[];
  groundTransportationSequences: GroundTransportationSequence[];
  puppyChaperoneFlightSequences: ChaperoneTransportationSequence[];
  meetupSequences: MeetupSequence[];
  transporterSequences: TransporterSequence[];
}

export interface TravelWorkflowEntity {
  currentStatus: WorkFlowCurrentStatus;
  currentWorkflowStatusId: number;
  entityId: number;
  id: number;
  workflowId: number;
}

export interface WorkFlowCurrentStatus {
  id: number;
  name: string;
  workflowId: number;
}

export enum WorkflowEntityIdsTravel {
  Submitted = 1,
  ApprovalInProgress = 2,
  Approved = 3,
  PendingWorkAcceptance = 4,
  Postponed = 5,
  Forwarded = 6,
  ForwardReviewinprogress = 14,
  Created = 15,
  Proposed = 16,
  RejectedByBreeder = 17,
  RejectedByCustomer = 18,
  RejectedByPuppySpot = 19,
  Confirmed = 20,
  Finalized = 21,
  Canceled = 22,
}

export enum WorkflowEntityIds {
  Meetup = 10,
  PickNearHome = 15,
  HomeDelivery = 16,
  ChaperoneAirportDelivery = 17,
  ChaperoneHomeDelivery = 18,
}

export interface FlightSequence {
  id: Id;
  airline: Airline;
  arrivalAirport: AirportInfo;
  arrivalTime: Nullable<string>;
  departureAirport: AirportInfo;
  departureTime: Nullable<string>;
  flightAirWaybill: Nullable<string>;
  flightConfirmation: Nullable<string>;
  flightNumber: Nullable<string>;
}

export interface Airline {
  id: Id;
  phone?: string;
  name: string;
  additionalInformation?: Nullable<string>;
}

export interface AirportInfo {
  id: Id;
  code: string;
  name: string;
  stateId: number;
  isInternational?: number;
}

export interface GroundTransportationSequence {
  id: Id;
  additionalPhone: string;
  arrivalTime: string;
  departureTime: string;
  driverName: string;
  groundTransportationCompany: TransportationCompany;
}

export interface TransportationCompany {
  id: Id;
  name: string;
  phone: string;
  position?: Id;
}

export interface ChaperoneTransportationSequence {
  id: Id;
  additionalPhone: string;
  driverName: string;
  puppyChaperoneCompany: Airline;
  flightSequence: FlightSequence;
}

export interface MeetupSequence {
  id: Id;
  address: string;
  address2: string;
  city: string;
  departureTime: Nullable<string>;
  pickupLocationName: Nullable<string>;
  pickupTime: Nullable<string>;
  postalCode: Nullable<number>;
  state: StateInfo;
}

export interface StateInfo {
  id: Id;
  abbr: string;
  countryId: number;
  name: string;
  timezone: string;
}

export interface TransporterSequence {
  id: Id;
  additionalPhone: string;
  arrivalTime: string;
  departureTime: string;
  transporterCompany: TransportationCompany;
}

export interface MeetupTravelProposal {
  id: number;
  meetupLocation: string;
  travelProposalId: number;
}

export interface ChaperoneTravelProposal {
  destinationAirport: DestinationAirport;
  destinationAirportId: Id;
  dropOffLocation: string;
  id: Id;
  originAirport: AirportInfo;
  originAirportId: Id;
  puppyChaperoneCompany: Airline;
  puppyChaperoneCompanyId: Id;
  travelProposalId: Id;
}

export interface DestinationAirport {
  canReceiveCargoShipments: number;
  code: string;
  hasWeatherRestrictions: number;
  id: Id;
  isInternational: number;
  name: string;
  stateId: Id;
}

export interface GroundTravelProposal {
  groundTransportationCompany: Airline;
  groundTransportationCompanyId: Id;
  id: Id;
  travelProposalId: Id;
}

export interface CharterTravelProposal {
  crateSize: Nullable<CrateSizeTravel>;
  crateSizeId: Id;
  destinationAirportId: Id;
  destinationAirport: DestinationAirport;
  dropOffLocation: Nullable<string>;
  firstMileTransporterCompany: Nullable<TransportationCompany>;
  firstMileTransporterCompanyId: Id;
  id: Id;
  pickupLocationName: null;
  lastMileTransporterCompany: Nullable<TransportationCompany>;
  lastMileTransporterCompanyId: Id;
  originAirport: AirportInfo;
  originAirportId: Id;
  travelProposalId: Id;
}

export interface CrateSizeTravel {
  id: Id;
  name: string;
  orderNumber: Id;
}

export interface CargoTravelProposal {
  airWaybill?: null;
  airline: Airline;
  airlineId?: 1;
  crateSize?: CrateSizeTravel;
  crateSizeId?: Id;
  destinationAirport?: DestinationAirport;
  destinationAirportId?: Id;
  dropOffLocation?: string;
  firstMileTransporterCompany?: TransportationCompany;
  firstMileTransporterCompanyId?: Id;
  id: Id;
  lastMileTransporterCompany?: TransportationCompany;
  lastMileTransporterCompanyId?: Id;
  originAirport?: AirportInfo;
  originAirportId?: Id;
  travelProposalId: Id;
}

export enum TravelTypeIds {
  Meetup = 10,
  PickNearHome = 15,
  HomeDelivery = 16,
  ChaperoneAirportDelivery = 17,
  ChaperoneHomeDelivery = 18,
}
