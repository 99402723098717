import { FormInstance } from 'antd/lib/form';

const validationErrorMessage = {
  requiresHearingTest: `Hearing test is required for this breed and color combination`,
};

export const FieldMedicalDetailsValidation = (form: FormInstance) => {
  return {
    validator: (_: unknown, value: number[]) => {
      const hearingTestId = 5;
      const requiresHearingTest = form.getFieldValue('requiresHearingTest');
      const isHearingTestChecked = Boolean(value?.includes(hearingTestId));

      if (requiresHearingTest && !isHearingTestChecked) {
        return Promise.reject(validationErrorMessage.requiresHearingTest);
      }

      return Promise.resolve();
    },
  };
};
