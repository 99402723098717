import React, {
  ReactElement,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Col, Row, Tag } from 'antd';

import DuplicatePuppy from 'components/duplicatePuppy/DuplicatePuppy';
import LoadingSection from 'components/loadingSection/LoadingSection';
import {
  PuppyDetailOrderStatus,
  PuppyTilesNav,
  PuppyDetailHealthDocs,
  PuppyDetailViewsCounter,
} from 'components/puppy';
import PageLayout from 'shared/baseLayout/pageLayout/PageLayout';
import PuppyForm from 'shared/puppy/PuppyForm';
import PuppySummary from 'shared/puppy/puppySummary/PuppySummary';
import PuppyTravel from 'shared/puppy/puppyTravel/PuppyTravel';
import { DueDate } from 'shared/types/date';
import { ReactRouterParameter } from 'shared/types/general';
import { Puppy } from 'shared/types/puppy';
import { CollapsableStatusTypes } from 'shared/types/statuses';
import { getPuppy, resetPuppy } from 'store/puppy/actions';
import { RootState } from 'store/rootState';
import inventoryStatus from 'utilities/status/inventoryStatus';
import { puppyDueDatesIsUpToDate } from 'utilities/puppy/puppyDueDates/puppyDueDates';

import './PuppyDetail.scss';

const breadCrumbs = [
  {
    url: '/my-listings',
    name: 'My Puppy Listings',
  },
  {
    name: 'Puppy Detail',
  },
];

export default function PuppyDetail(): ReactElement {
  const [showPuppySummary, setShowPuppySummary] = useState(true);
  const [showPuppyForm, setShowPuppyForm] = useState(false);
  const [showPuppyOrderStatus, setShowPuppyOrderStatus] = useState(false);
  const [showPuppyTilesNav, setShowPuppyTilesNav] = useState(false);
  const [showTravelPlan, setShowTravelPlan] = useState(false);
  const [showPuppyHealthDocs, setShowPuppyHealthDocs] = useState(false);
  const [scrollSummary, setScrollSummary] = useState('');
  const [soldView, setSoldView] = useState(false);
  const { id } = useParams<{ id: ReactRouterParameter }>();
  const { puppy } = useSelector((state: RootState) => ({
    puppy: state.puppy,
  }));
  const [collapsableType, setCollapsableType] = useState<
    CollapsableStatusTypes
  >('success');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPuppy((id as unknown) as number));
    return () => {
      dispatch(resetPuppy());
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (isEmpty(puppy)) return;

    if (puppyDueDatesIsUpToDate(puppy.dueDates as DueDate[])) {
      setCollapsableType('success');
    }

    if (!puppyDueDatesIsUpToDate(puppy.dueDates as DueDate[], 'Weight')) {
      setCollapsableType('warning');
    }

    if (!puppyDueDatesIsUpToDate(puppy.dueDates as DueDate[], 'Registration')) {
      setCollapsableType('warning');
    }

    if (inventoryStatus.isSold(puppy)) {
      setSoldView(true);
    } else {
      setShowPuppyForm(true);
    }
  }, [puppy]);

  useLayoutEffect(() => {
    if (soldView) {
      if (showPuppyHealthDocs || showTravelPlan || showPuppyForm) {
        setShowPuppySummary(false);
        setShowPuppyOrderStatus(false);
        setShowPuppyTilesNav(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }

      setShowPuppySummary(true);
      setShowPuppyOrderStatus(true);
      setShowPuppyTilesNav(true);
    }
  }, [showPuppyForm, showTravelPlan, soldView, showPuppyHealthDocs]);

  return (
    <PageLayout className="PuppyDetail" breadCrumbsNav={breadCrumbs}>
      <Row justify="center">
        {isEmpty(puppy) ? (
          <LoadingSection />
        ) : (
          <Col xs={24} xl={20} xxl={15}>
            <section className="PuppyDetail__header">
              <div className="PuppyDetail__header-left">
                <section className="PuppyDetail__header-status">
                  {puppy.breederDashboardStatus && (
                    <Tag className="PuppyDetail__tag-breeder-dashboard-status">
                      {puppy.breederDashboardStatus}
                    </Tag>
                  )}
                </section>
                <PuppyDetailViewsCounter
                  breederDashboardStatus={puppy.breederDashboardStatus}
                  leadsCount={puppy.interestedCustomerCount}
                  viewsCount={puppy.listing?.listingTally?.viewCount}
                  listing={puppy.listing}
                />
              </div>
              <div className="PuppyDetail__header-right">
                <section className="PuppyDetail__header-tools">
                  <DuplicatePuppy
                    label="Duplicate puppy"
                    litterId={puppy.litter?.id as number}
                    damId={puppy.litter?.damId as number}
                    sireId={puppy.litter?.sireId as number}
                    puppy={puppy as Puppy}
                  />
                </section>
              </div>
            </section>

            {showPuppySummary && (
              <PuppySummary
                setShowPuppyForm={setShowPuppyForm}
                setScrollSummary={setScrollSummary}
                soldView={soldView}
              />
            )}

            {showPuppyForm && (
              <PuppyForm
                action="edit"
                collapsableType={collapsableType}
                setShowPuppyForm={soldView ? setShowPuppyForm : null}
                isOpen={true}
                scrollSummary={scrollSummary}
              />
            )}

            {showPuppyOrderStatus && <PuppyDetailOrderStatus />}

            {showPuppyTilesNav && (
              <PuppyTilesNav
                setShowTravelPlan={setShowTravelPlan}
                setShowPuppyForm={setShowPuppyForm}
                setShowPuppyHealthDocs={setShowPuppyHealthDocs}
              />
            )}

            {showTravelPlan && (
              <PuppyTravel setShowTravelPlan={setShowTravelPlan} />
            )}

            {showPuppyHealthDocs && (
              <PuppyDetailHealthDocs
                setShowPuppyHealthDocs={setShowPuppyHealthDocs}
              />
            )}
          </Col>
        )}
      </Row>
    </PageLayout>
  );
}
