import React, { ReactElement } from 'react';
import { Row } from 'antd';
import { CalendarOutlined, DollarOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import {
  BreederPaymentAllocation,
  Transaction,
} from 'shared/types/transaction';
import { getContentDataById } from 'utilities/staticContent/content';
import date from 'utilities/time/date';
import number from 'utilities/number/number';

import './TransactionCard.scss';

interface TransactionDetailProps {
  paymentAllocation: BreederPaymentAllocation;
}

function TransactionDetail({ paymentAllocation }: TransactionDetailProps) {
  const isNegative = number.isNegative(paymentAllocation.amount);
  const textAlert = isNegative ? 'Transaction_Card__payment-detail--alert' : '';
  const reasonName = getContentDataById(
    'breederPaymentReasons',
    paymentAllocation?.breederPaymentReason?.id,
  )?.name;

  const showIsReason = isNegative && reasonName;
  const reason = showIsReason && <strong>({reasonName})</strong>;

  return (
    <Row
      className={`Transaction_Card__payment-detail ${textAlert}`}
      justify="space-between"
    >
      <span
        data-testid="transaction-puppy-detail"
        className="Transaction_Card__payment-name"
      >
        ID: {paymentAllocation.puppyId} {reason} -{' '}
        <Link to={`/puppy/${paymentAllocation.puppyId}`}>
          {paymentAllocation.puppy.breederName}
        </Link>
      </span>
      <span>{number.displayCurrency(paymentAllocation.amount)}</span>
    </Row>
  );
}

interface Props {
  transaction: Transaction;
}
export default function TransactionCard({ transaction }: Props): ReactElement {
  const paymentType = getContentDataById(
    'breederPaymentTypes',
    transaction.breederPaymentType.id,
  )?.name;

  const paymentDate = date.toMMDDYYYY(transaction.paymentDate);
  const paymentAllocations = transaction.breederPaymentAllocations;

  return (
    <>
      <section className="Transaction_Card">
        <Row className="Transaction_Card__payment" justify="space-between">
          <span className="Transaction_Card__payment-type">
            <DollarOutlined /> {paymentType}
          </span>
          <span>
            <CalendarOutlined /> {paymentDate}
          </span>
        </Row>
        {paymentAllocations.map((p, i) => (
          <TransactionDetail paymentAllocation={p} key={i} />
        ))}
        <Row className="Transaction_Card__total" justify="space-between">
          <span>Total</span>
          <span className="Transaction_Card__total-number">
            {number.displayCurrency(transaction.amount)}
          </span>
        </Row>
      </section>
    </>
  );
}
