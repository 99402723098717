import React, { ReactElement } from 'react';
import { Form, Input, InputNumber, Radio } from 'antd';

import number from 'utilities/number/number';
import { EmptyFieldValidationHandler } from '../fieldValidationHandler';

interface Props {
  disabled?: boolean;
}

export function FieldDiscount(props: Props): ReactElement | null {
  const { disabled } = props;

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, curValues) => {
        return (
          prevValues?.listing?.hasDiscountOffer !==
          curValues?.listing?.hasDiscountOffer
        );
      }}
    >
      {(form) => {
        const hasDiscount = Boolean(
          form.getFieldValue(['listing', 'hasDiscountOffer']),
        );

        return (
          <>
            <Form.Item
              name={['listing', 'hasDiscountOffer']}
              label="Are you willing to provide a discount for well-qualified buyers?"
              data-testid="field-discount-offering"
              help="Increased chance of converting browsers to buyers by up to 60%. This will give flexibility to allow our team to offer discounts to individuals such as military personnel, etc."
              style={{ marginTop: '20px' }}
              className="help-above"
            >
              <Radio.Group disabled={disabled}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>Not now</Radio>
              </Radio.Group>
            </Form.Item>
            {hasDiscount && (
              <>
                <Form.Item
                  name={['listing', 'discountOfferAmount']}
                  label="Discount amount"
                  rules={[EmptyFieldValidationHandler('input')]}
                >
                  <InputNumber
                    disabled={disabled}
                    formatter={number.formatNumberAsCurrency}
                    parser={number.removeNonDigits}
                    placeholder="i.e. $50.00"
                    style={{ width: '100%' }}
                    step={50}
                    min={0}
                  />
                </Form.Item>
                <Form.Item
                  name={['listing', 'discountOfferReason']}
                  label="When are you willing to offer this discount"
                  rules={[EmptyFieldValidationHandler('input')]}
                >
                  <Input
                    placeholder="i.e. Military Discount or If over 12 weeks"
                    disabled={disabled}
                  />
                </Form.Item>
              </>
            )}
          </>
        );
      }}
    </Form.Item>
  );
}
