import { Dispatch } from 'redux';
import { notification } from 'antd';

import { Listing } from 'shared/types/puppy';
import {
  setMyListingsPending,
  setMyListingsPendingFilters,
  setMyListingsPendingLoading,
  setMyListingsPendingPagination,
  setMyListingsPendingSorter,
} from 'store/myListingsPending/actions';
import {
  initialStateMyListingsPendingFilters,
  initialStateMyListingsPendingPagination,
  initialStateMyListingsPendingSorter,
} from 'store/myListingsPending/myListingsPendingReducer';
import { RootState } from 'store/rootState';
import apiListing from 'utilities/http/api/apiListing';
import { BreederDashboardStatus } from 'utilities/const';
import { requestHasErrors } from 'utilities/http/http';

export function fetchMyListingsPending() {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setMyListingsPendingLoading());

    const {
      listings,
      pagination,
      sorter,
      filters,
    } = getState().myListingsPending;

    const myListingsResp = await apiListing.getMyListings(
      BreederDashboardStatus.Pending,
      pagination,
      sorter,
      filters,
    );

    if (requestHasErrors(myListingsResp)) {
      if (!filters.isFiltering) {
        notification.error({ message: `Error fetching my listings pending` });
      }

      dispatch(setMyListingsPending([]));
      dispatch(
        setMyListingsPendingPagination({
          ...pagination,
          total: 0,
        }),
      );
      return;
    }

    const listingsResp = myListingsResp?.json.listings as Listing[];
    const paginationResp = myListingsResp?.json.paginationMeta;

    if (pagination.loadMore) {
      dispatch(setMyListingsPending([...listings, ...listingsResp]));
    } else {
      dispatch(setMyListingsPending(listingsResp));
    }

    dispatch(
      setMyListingsPendingPagination({
        ...pagination,
        total: paginationResp.total,
      }),
    );
  };
}

export function setMyListingsPendingInitialState() {
  return (dispatch: Dispatch) => {
    dispatch(setMyListingsPending([]));
    dispatch(
      setMyListingsPendingPagination(initialStateMyListingsPendingPagination),
    );
    dispatch(setMyListingsPendingFilters(initialStateMyListingsPendingFilters));
    dispatch(setMyListingsPendingSorter(initialStateMyListingsPendingSorter));
  };
}
