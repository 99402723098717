import { Listing, Puppy } from 'shared/types/puppy';

export const listingUpdatePuppyRegistrationsWeightDueDates = (
  listing: Listing,
  puppy: Puppy,
) => {
  const { id, dueDates, registrations, weightRangeId } = puppy;

  if (listing.puppy.id === id) {
    return {
      ...listing,
      puppy: {
        ...listing.puppy,
        dueDates,
        registrations,
        weightRangeId,
      },
    };
  }
  return listing;
};
