import { BreederTierId } from 'shared/types/breeder';
import userStorage from '../userStorage';

export const isUserBreederTierGeneral = (): boolean => {
  const breeder = userStorage.getUser()?.breeders[0];

  return breeder?.breederTierId === BreederTierId.general;
};

export const isUserBreederTierSpotOn = (): boolean => {
  const breeder = userStorage.getUser()?.breeders[0];

  return breeder?.breederTierId === BreederTierId.spotOn;
};

export const isUserBreederTierCommitmentShortTerm = (): boolean => {
  const breeder = userStorage.getUser()?.breeders[0];

  return breeder?.breederTierId === BreederTierId.commitmentShortTerm;
};
