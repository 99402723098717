import {
  INCREASE_PAGE,
  PaginationsActions,
  PaginationsState,
  RESET_PAGE,
  SET_ITEMS_ON_PAGE_AND_PER_PAGE,
} from 'store/paginations/types';
import { reactEnvironmentVariables } from 'utilities/environmentVariables';

const initializePagination = (pageSize = 10) => ({
  currentPage: 1,
  itemsOnPage: 0,
  itemsPerPage: pageSize,
  totalItems: 0,
});

const initialState: PaginationsState = {
  // My Listings
  pending: initializePagination(),
  approved: initializePagination(),
  sold: initializePagination(),
  delivered: initializePagination(),

  // My Parents
  dams: initializePagination(reactEnvironmentVariables.PAGE_SIZE_MY_PARENTS),
  sires: initializePagination(reactEnvironmentVariables.PAGE_SIZE_MY_PARENTS),

  // My Litters
  upcoming: initializePagination(
    reactEnvironmentVariables.PAGE_SIZE_MY_LITTERS,
  ),
  past: initializePagination(reactEnvironmentVariables.PAGE_SIZE_MY_LITTERS),
  current: initializePagination(reactEnvironmentVariables.PAGE_SIZE_MY_LITTERS),
};

export const initialPage = 1;

export function paginationsReducer(
  state = initialState,
  action: PaginationsActions,
): PaginationsState {
  switch (action.type) {
    case INCREASE_PAGE: {
      const { columnName } = action;
      const currentColumn = {
        ...state[columnName],
        currentPage: state[columnName].currentPage + 1,
      };
      return {
        ...state,
        [columnName]: currentColumn,
      };
    }
    case RESET_PAGE: {
      const { columnName } = action;

      let pageSize = undefined;
      if (columnName === 'sires' || columnName === 'dams') {
        pageSize = reactEnvironmentVariables.PAGE_SIZE_MY_PARENTS;
      }

      return {
        ...state,
        [columnName]: initializePagination(pageSize),
      };
    }
    case SET_ITEMS_ON_PAGE_AND_PER_PAGE: {
      const { columnName } = action;

      const currentColumn = {
        ...state[columnName],
        itemsOnPage: action.itemsOnPage,
        itemsPerPage: action.itemsPerPage,
        totalItems: action.totalItems,
      };
      return {
        ...state,
        [columnName]: currentColumn,
      };
    }
    default:
      return state;
  }
}
