import React, { ReactElement } from 'react';
import { EyeOutlined } from '@ant-design/icons';

import { BreederDashboardStatusTypes, Listing } from 'shared/types';
import { BreederDashboardStatus } from 'utilities/const';
import { isListingStatusHidden } from 'utilities/listing';

import './PuppyDetailViewsCounter.scss';

interface Props {
  breederDashboardStatus: BreederDashboardStatusTypes | undefined;
  leadsCount: number | undefined;
  viewsCount: number | undefined;
  listing: Partial<Listing> | undefined;
}

function PuppyDetailViewsCounter({
  breederDashboardStatus,
  leadsCount,
  viewsCount,
  listing,
}: Props): ReactElement | null {
  return isListingStatusHidden(listing) ||
    breederDashboardStatus === BreederDashboardStatus.Approved ? (
    <section className="PuppyDetailViewsCounter" data-testid="1">
      <EyeOutlined />
      <span className="PuppyDetailViewsCounter__text">
        {leadsCount ?? 0} leads · {viewsCount ?? 0} views
      </span>
    </section>
  ) : null;
}

export default PuppyDetailViewsCounter;
