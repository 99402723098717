import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { cloneDeep } from 'lodash';

import { WidgetContainer } from '../widgetContainer/WidgetContainer';
import { UpcomingCard } from 'pages/dashboard/upcomingUpdates/upcomingCard/UpcomingCard';
import { UpcomingDates, UpcomingUpdate } from 'shared/types/date';
import { BreederRoutes } from 'shared/types/routes';
import date from 'utilities/time/date';

import './UpcomingUpdates.scss';

export function UpcomingUpdates(props: UpcomingDates) {
  const { upcomingDates } = props;

  const upcomingUpdateInfo: UpcomingUpdate[] = useMemo(
    () => Array.from(Object.values(upcomingDates)),
    [upcomingDates],
  );

  const sortByOld = (data: UpcomingUpdate[]): UpcomingUpdate[] => {
    const dataToSort = cloneDeep(data);
    const sorted = dataToSort.sort((a, b) =>
      date.sortByOldest(a.oldestDueDate, b.oldestDueDate),
    );

    return sorted;
  };

  const sorted = sortByOld(upcomingUpdateInfo);

  if (sorted.length === 0) {
    return null;
  }

  return (
    <WidgetContainer
      title="My Upcoming Updates"
      goto={BreederRoutes.MyUpdates}
      highlight
    >
      <div className="UpcomingUpdates__container">
        <Row>
          {sorted.slice(0, 4).map((upcoming, index) => (
            <Col xs={24} md={12} key={index} className="border">
              <Link
                to={`/puppy/${upcoming.puppyId}`}
                className="UpcomingUpdates__link"
              >
                <UpcomingCard upcoming={upcoming} />
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </WidgetContainer>
  );
}
