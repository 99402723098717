import React, { ReactElement } from 'react';
import { Form, Select } from 'antd';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import { EmptyFieldValidationHandler } from '../fieldValidationHandler';
import { GenderContent } from 'utilities/staticContent/types';

interface Props {
  disabled?: boolean;
}
export function FieldGenders(props: Props): ReactElement {
  const { disabled } = props;
  const genders = staticContentStorage.getContent().genders;

  return (
    <Form.Item
      name="genderId"
      label="Gender"
      rules={[EmptyFieldValidationHandler('list')]}
    >
      <Select placeholder="Select one..." disabled={disabled}>
        {genders?.map((gender: GenderContent) => (
          <Select.Option key={gender.id} value={gender.id}>
            {gender.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}
