import {
  apiGetRequest,
  apiPostRequest,
  apiPutRequest,
} from 'utilities/http/api/apiHttp';
import { Parent } from 'shared/types/parent';
import userStorage from 'utilities/user/userStorage';

const apiParent = {
  getParents: (appendParameters: string) => {
    const urlParamsRecord = {
      breederId: `${userStorage.getBreederId()}`,
    };
    const appendParamsBase = new URLSearchParams(urlParamsRecord).toString();
    const appendParams = `${appendParamsBase}${
      appendParameters ? `&${appendParameters}` : ''
    }`;

    return apiGetRequest('puppy/parent', appendParams);
  },

  getParentDetails: (parentId: number) => {
    const urlParamsRecord = {
      withMedia: `1`,
      withActivities: `1`,
      withListingStats: `1`,
      showMinimumDateAllowedToAddPuppiesToDams: `1`,
    };
    const appendParams = new URLSearchParams(urlParamsRecord).toString();

    return apiGetRequest(`puppy/parent/${parentId}`, appendParams);
  },

  postParent: (parent: Partial<Parent>) => {
    const payload = { puppyParent: parent };
    return apiPostRequest(`puppy/parent`, payload);
  },

  putMarkParentAsRetired: async (parentId: number, payload: unknown) => {
    return apiPutRequest(`puppy/parent/${parentId}`, payload);
  },

  putParent: (parentId: number, parent: Partial<Parent>) => {
    const payload = { puppyParent: parent };
    return apiPutRequest(`puppy/parent/${parentId}`, payload);
  },
};

export default apiParent;
