import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Space, Tooltip } from 'antd';

import {
  renderListingLockedBadge,
  renderListingPendingEditBadge,
} from 'components/entity';
import EntityPhoto from 'shared/entitiesOverview/entityPhoto/EntityPhoto';
import { Listing, Puppy } from 'shared/types/puppy';
import media from 'utilities/media/media';
import { isListingLocked } from 'utilities/listing';
import inventoryStatus from 'utilities/status/inventoryStatus';

import './ColumnPuppyId.scss';

interface Props {
  listing: Listing;
}

function ColumnPuppyId(props: Props): ReactElement {
  const { listing } = props;
  const photoUrl = media.getDefaultPuppyPhoto(listing.media);
  const photoWrapperClassModifier = isListingLocked(listing)
    ? `ColumnPuppyId__photo-wrapper--is-locked`
    : ``;

  const isPendingSale = inventoryStatus.isPendingSale(listing.puppy as Puppy);

  return (
    <Space>
      <div
        className={`ColumnPuppyId__photo-wrapper ${photoWrapperClassModifier}`}
      >
        {renderListingLockedBadge(listing)}
        {renderListingPendingEditBadge(listing)}
        <EntityPhoto
          entity="puppy"
          height="52px"
          width="52px"
          imageSrc={photoUrl}
        />
      </div>
      <Link to={`/puppy/${listing.id}`}>{listing?.puppyId}</Link>
      {isPendingSale && (
        <Tooltip title="Pending sale">
          <span
            className="entityCard__info-content-pending-label"
            data-testid="entity-card-info-pending-label"
          >
            PS
          </span>
        </Tooltip>
      )}
    </Space>
  );
}

export default ColumnPuppyId;
