import React, { ReactElement } from 'react';
import moment, { MomentInput } from 'moment';
import { Form, DatePicker, Input, Modal, Grid } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker';
import { InfoCircleOutlined, InfoCircleFilled } from '@ant-design/icons';

import { EmptyFieldValidationHandler } from 'shared/fields/fieldValidationHandler';
import { FormsType, Nullable } from 'shared/types/general';
import date, { MMDDYYYY } from 'utilities/time/date';

import './FieldBirthday.scss';

type BirthdayProps = {
  label?: string;
  formType?: FormsType;
  minimumDate?: Nullable<string>;
  maximumDate?: Nullable<string>;
} & DatePickerProps;

export function FieldBirthday(props: BirthdayProps): ReactElement {
  const {
    formType,
    disabled,
    label,
    minimumDate,
    maximumDate,
    ...datePickerProps
  } = props;

  const screens = Grid.useBreakpoint();
  const handleDisabledDateRanges = (current: MomentInput) => {
    if (formType === 'litter') {
      if (minimumDate && maximumDate) {
        return (
          moment(current).isBefore(minimumDate, 'days') ||
          moment(current).isAfter(maximumDate, 'days')
        );
      }
      return moment(current).isBefore(
        moment().subtract(5, 'months').subtract(15, 'days'),
      );
    }

    return false;
  };

  const onInfoClick = () => {
    Modal.info({
      icon: <InfoCircleFilled />,
      width: screens.lg ? 500 : undefined,
      title:
        'Some dates may not be available because one of the following reasons: ',
      content: (
        <ul>
          <li>Has not passed 6 months since the Dam’s previous whelp.</li>
          <li>The Dam is too young.</li>
          <li>The Litter&apos;s DOB cannot be older than 5.5 months.</li>
          <li>The Dam has already reached 7 years.</li>
          <li>The Sire has already reached 9 years.</li>
        </ul>
      ),
    });
  };

  const extra = (
    <span>
      Why not all dates are available?
      <InfoCircleOutlined color="red" onClick={onInfoClick} />
    </span>
  );

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, curValues) =>
        prevValues.birthDate !== curValues.birthDate
      }
    >
      {(form) => {
        const fieldValue = form.getFieldValue(['birthDate']);
        const datePickerValue = fieldValue ? moment(fieldValue) : undefined;
        return (
          <Form.Item
            className="FieldBirthday"
            name="birthDate"
            label={label ? label : 'Birthday'}
            rules={[EmptyFieldValidationHandler('birthday')]}
            data-testid="birthDate-field"
            extra={formType === 'litter' && extra}
          >
            <>
              <Input data-testid="birthDate-input" hidden />
              <Form.Item style={{ marginBottom: 0 }}>
                <DatePicker
                  disabled={disabled}
                  disabledDate={handleDisabledDateRanges}
                  value={datePickerValue}
                  format={MMDDYYYY}
                  onChange={(datePicked) => {
                    form.setFieldsValue({
                      birthDate: datePicked
                        ? date.toYYYYMMDD(datePicked)
                        : undefined,
                    });
                    form.validateFields(['birthDate']);
                  }}
                  {...datePickerProps}
                />
              </Form.Item>
            </>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
}
