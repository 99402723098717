import {
  MyListingsFilters,
  MyListingsPagination,
  MyListingsSorter,
  MyListingsStateBase,
} from 'shared/types/myListings';
import {
  MyListingsPendingActions,
  SET_MY_LISTINGS_PENDING,
  SET_MY_LISTINGS_PENDING_FILTERS,
  SET_MY_LISTINGS_PENDING_LOADING,
  SET_MY_LISTINGS_PENDING_PAGINATION,
  SET_MY_LISTINGS_PENDING_SORTER,
  UPDATE_MY_LISTINGS_PENDING_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES,
} from 'store/myListingsPending/types';
import { listingUpdatePuppyRegistrationsWeightDueDates } from 'utilities/listing';
import { SortDirection } from 'utilities/const';

export const initialStateMyListingsPendingSorter: MyListingsSorter = {
  isSorting: false,
  sortBy: 'created_at',
  sortDirection: SortDirection.descend,
};

export const initialStateMyListingsPendingFilters: MyListingsFilters = {
  isFiltering: false,
  puppy_id: null,
  puppy_name: null,
};

export const initialStateMyListingsPendingPagination: MyListingsPagination = {
  pageSize: 25,
  current: 1,
  total: 0,
  loadMore: false,
};

const initialState: MyListingsStateBase = {
  loading: false,
  listings: [],
  pagination: initialStateMyListingsPendingPagination,
  filters: initialStateMyListingsPendingFilters,
  sorter: initialStateMyListingsPendingSorter,
};

export function myListingsPendingReducer(
  state = initialState,
  action: MyListingsPendingActions,
): MyListingsStateBase {
  switch (action.type) {
    case SET_MY_LISTINGS_PENDING_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_MY_LISTINGS_PENDING: {
      const { listings } = action;
      return {
        ...state,
        loading: false,
        listings,
      };
    }
    case SET_MY_LISTINGS_PENDING_PAGINATION: {
      const { pagination } = action;
      return {
        ...state,
        pagination,
      };
    }
    case SET_MY_LISTINGS_PENDING_SORTER: {
      const { sorter } = action;
      return {
        ...state,
        sorter,
      };
    }
    case SET_MY_LISTINGS_PENDING_FILTERS: {
      const { filters } = action;
      return {
        ...state,
        filters,
      };
    }
    case UPDATE_MY_LISTINGS_PENDING_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES: {
      const { puppy } = action;

      const updatedListings = state.listings.map((listing) =>
        listingUpdatePuppyRegistrationsWeightDueDates(listing, puppy),
      );

      return {
        ...state,
        listings: updatedListings,
      };
    }
    default:
      return state;
  }
}
