import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'antd';

import PageLayout from 'shared/baseLayout/pageLayout/PageLayout';
import { BreederRoutes } from 'shared/types/routes';

import './NotFound.scss';

export default function NotFound(): ReactElement {
  const history = useHistory();

  const goToDashboard = () => history.push(BreederRoutes.Dashboard);

  return (
    <PageLayout>
      <Row justify="center">
        <Col xs={24} xl={20}>
          <section className="NotFound">
            <h1 className="NotFound__title">404 page not found</h1>
            <p>We are sorry but the page you are looking for does not exist.</p>
            <Button onClick={goToDashboard}>Back to Dashboard</Button>
          </section>
        </Col>
      </Row>
    </PageLayout>
  );
}
