import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { isEmpty } from 'lodash';

import { RootState } from 'store/rootState';
import LitterForm from 'shared/litter/LitterForm';
import PageLayout from 'shared/baseLayout/pageLayout/PageLayout';
import { ParentForm } from 'shared/parents/ParentForm';
import { resetParents } from 'store/parents/actions';
import { resetLitter } from 'store/litter/actions';

import './NewLitter.scss';

const breadCrumbs = [
  {
    name: 'New Litter',
  },
];

export default function NewLitter(): ReactElement {
  const { parents, litter } = useSelector((state: RootState) => ({
    parents: state.parents,
    litter: state.litter,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetParents());
      dispatch(resetLitter());
    };
  }, [dispatch]);

  return (
    <PageLayout className="NewLitter" breadCrumbsNav={breadCrumbs}>
      <Row justify="center">
        <Col xs={24} xl={20}>
          <ParentForm
            gender="dam"
            fieldExistingParentOn
            collapsableType={parents.dam.id ? 'success' : 'warning'}
            isOpen={true}
          />
          <ParentForm
            gender="sire"
            fieldExistingParentOn
            collapsableType={
              parents.sire.id
                ? 'success'
                : parents.dam.id
                ? 'warning'
                : 'disabled'
            }
            collapsableDisabled={!Boolean(parents.dam.id)}
            isOpen={!isEmpty(parents.dam) && isEmpty(parents.sire)}
          />
          <LitterForm
            singleForm={true}
            collapsableType={
              parents.dam.id && parents.sire.id
                ? litter.id
                  ? 'success'
                  : 'warning'
                : 'disabled'
            }
            collapsableDisabled={!Boolean(parents.dam.id && parents.sire.id)}
            isOpen={!isEmpty(parents.dam) && !isEmpty(parents.sire)}
          />
        </Col>
      </Row>
    </PageLayout>
  );
}
