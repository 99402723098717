import { Listing, Puppy } from 'shared/types/puppy';
import {
  MyListingsFilters,
  MyListingsPagination,
  MyListingsSorter,
} from 'shared/types/myListings';
import {
  MyListingsHiddenActions,
  SET_MY_LISTINGS_HIDDEN,
  SET_MY_LISTINGS_HIDDEN_FILTERS,
  SET_MY_LISTINGS_HIDDEN_LOADING,
  SET_MY_LISTINGS_HIDDEN_PAGINATION,
  SET_MY_LISTINGS_HIDDEN_SORTER,
  UPDATE_MY_LISTINGS_HIDDEN_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES,
} from 'store/myListingsHidden/types';

export function setMyListingsHiddenLoading(): MyListingsHiddenActions {
  return {
    type: SET_MY_LISTINGS_HIDDEN_LOADING,
  };
}

export function setMyListingsHidden(
  listings: Listing[],
): MyListingsHiddenActions {
  return {
    type: SET_MY_LISTINGS_HIDDEN,
    listings,
  };
}

export function setMyListingsHiddenFilters(
  filters: MyListingsFilters,
): MyListingsHiddenActions {
  return {
    type: SET_MY_LISTINGS_HIDDEN_FILTERS,
    filters,
  };
}

export function setMyListingsHiddenSorter(
  sorter: MyListingsSorter,
): MyListingsHiddenActions {
  return {
    type: SET_MY_LISTINGS_HIDDEN_SORTER,
    sorter,
  };
}

export function setMyListingsHiddenPagination(
  pagination: MyListingsPagination,
): MyListingsHiddenActions {
  return {
    type: SET_MY_LISTINGS_HIDDEN_PAGINATION,
    pagination,
  };
}

export function updateMyListingsHiddenPuppyRegistrationsWeightDueDates(
  puppy: Puppy,
): MyListingsHiddenActions {
  return {
    type: UPDATE_MY_LISTINGS_HIDDEN_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES,
    puppy,
  };
}
