import React, { ReactElement } from 'react';
import { Form, Select } from 'antd';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import { RelativeSizeContent } from 'utilities/staticContent/types';

interface Props {
  disabled?: boolean;
}

export function FieldPuppySizeInLitter(props: Props): ReactElement {
  const { disabled } = props;
  const relativeSizes = staticContentStorage.getContent().relativeSizes;

  return (
    <Form.Item name="relativeSizeId" label="Puppy size in the litter">
      <Select
        disabled={disabled}
        placeholder="Select one..."
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {relativeSizes?.map((relativeSize: RelativeSizeContent) => (
          <Select.Option key={relativeSize.id} value={relativeSize.id}>
            {relativeSize.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}
