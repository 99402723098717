import moment from 'moment';

import { Nullable } from 'shared/types/general';
import {
  Documents,
  HealthDocsDueDateStatusColor,
  HealthDocsDueDateStatusSlugType,
} from 'shared/types/document';
import { YYYYMMDD } from 'utilities/time/date';
import { hasValidHealthDocs } from 'utilities/healthDocs';
import {
  HealthDocsDueDateDaysRange,
  HealthDocsDueDateStatusSlug,
} from 'utilities/const';

export const buildHealthDocDueDateProps = (
  documentDueDate: Nullable<string>,
  documents: Documents,
) => {
  const {
    DueToday,
    OneDay,
    TwoDays,
    ThreeDays,
    FiveDays,
    SixDays,
    SevenDays,
  } = HealthDocsDueDateDaysRange;
  const dateNow = moment().format(YYYYMMDD);
  const daysToDueDate = moment(documentDueDate).diff(dateNow, 'days');
  const isPastDue = daysToDueDate < DueToday;
  const isDueToday = daysToDueDate === DueToday;
  const isDueOneDay = daysToDueDate === OneDay;
  const isDueLessSevenDays = daysToDueDate > SevenDays;
  const validHealthDocs = hasValidHealthDocs(documents);

  const handleSetStatusColor = (): HealthDocsDueDateStatusColor => {
    if (
      (daysToDueDate >= SixDays && daysToDueDate <= SevenDays) ||
      validHealthDocs
    ) {
      return 'green';
    }

    if (daysToDueDate >= ThreeDays && daysToDueDate <= FiveDays) {
      return 'orange';
    }

    if (daysToDueDate >= DueToday && daysToDueDate <= TwoDays) {
      return 'red';
    }

    if (isPastDue) {
      return `black`;
    }

    return 'default';
  };

  const handleSetStatus = (): {
    label: string;
    slug: HealthDocsDueDateStatusSlugType | undefined;
  } => {
    if (!documentDueDate) {
      return { label: '', slug: undefined };
    }

    if (validHealthDocs) {
      return {
        label: 'Up to date',
        slug: HealthDocsDueDateStatusSlug.UpToDate,
      };
    }

    if (isDueLessSevenDays) {
      return {
        label: `${moment(documentDueDate, YYYYMMDD)
          .subtract(SevenDays, 'days')
          .format('MM/DD')} - ${moment(documentDueDate, YYYYMMDD).format(
          'MM/DD',
        )}`,
        slug: HealthDocsDueDateStatusSlug.DueLessSevenDays,
      };
    }

    if (isDueOneDay) {
      return {
        label: `Due in ${daysToDueDate} day`,
        slug: HealthDocsDueDateStatusSlug.DaysToDue,
      };
    }

    if (isDueToday) {
      return { label: `Due today`, slug: HealthDocsDueDateStatusSlug.DueToday };
    }

    if (isPastDue) {
      return { label: `Past due`, slug: HealthDocsDueDateStatusSlug.PastDue };
    }

    return {
      label: `Due in ${daysToDueDate} days`,
      slug: HealthDocsDueDateStatusSlug.DaysToDue,
    };
  };

  return {
    statusColor: handleSetStatusColor(),
    statusLabel: handleSetStatus().label,
    statusSlug: handleSetStatus().slug,
  };
};
