import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { RootState } from 'store/rootState';
import { PuppyOrder } from 'shared/types/puppy';
import { WorkflowEntityIdsTravel } from 'shared/types/puppyTravel';
import TravelProposalInfoMeetUp from 'shared/puppy/puppyTravel/proposal/proposalInfoContents/TravelProposalInfoMeetUp';
import TravelProposalInfoChaperone from 'shared/puppy/puppyTravel/proposal/proposalInfoContents/TravelProposalInfoChaperone';
import TravelProposalInfoGround from 'shared/puppy/puppyTravel/proposal/proposalInfoContents/TravelProposalInfoGround';
import TravelProposalInfoCharter from 'shared/puppy/puppyTravel/proposal/proposalInfoContents/TravelProposalInfoCharter';
import TravelProposalCargoInfo from 'shared/puppy/puppyTravel/proposal/proposalInfoContents/TravelProposalInfoCargo';
import puppyTravelStatus from 'utilities/puppy/puppyTravelState';

import 'shared/puppy/puppyTravel/PuppyTravel.scss';
import { testId } from 'testsUtilities/dataTestId';

function PuppyTravelProposal() {
  const { puppy } = useSelector((state: RootState) => state);

  const { travels } = puppy?.order as PuppyOrder;
  const travelInfo = travels[0] || {};
  const statusPuppyTravel = puppyTravelStatus(puppy);

  if (isEmpty(travelInfo)) return null;

  const travelProposal = travelInfo.travelProposals[0] || {};

  const renderProposal = () => {
    if (isEmpty(travelProposal)) return null;
    const proposalData = {
      arrivalTime: travelProposal.arrivalTime,
      departureTime: travelProposal.departureTime,
    };

    if (travelProposal.meetupTravelProposal) {
      return (
        <TravelProposalInfoMeetUp
          meetUpProposal={travelProposal.meetupTravelProposal}
          proposalData={proposalData}
        />
      );
    } else if (travelProposal?.chaperoneTravelProposal) {
      return (
        <TravelProposalInfoChaperone
          chaperoneProposal={travelProposal?.chaperoneTravelProposal}
          proposalData={proposalData}
        />
      );
    } else if (travelProposal.groundTravelProposal) {
      return (
        <TravelProposalInfoGround
          groundProposal={travelProposal?.groundTravelProposal}
          proposalData={proposalData}
        />
      );
    } else if (travelProposal.charterTravelProposal) {
      return (
        <TravelProposalInfoCharter
          charterProposal={travelProposal?.charterTravelProposal}
          proposalData={proposalData}
        />
      );
    } else if (travelProposal.cargoTravelProposal) {
      return (
        <TravelProposalCargoInfo
          cargoProposal={travelProposal?.cargoTravelProposal}
          proposalData={proposalData}
        />
      );
    }
  };

  const isConfirm =
    travelInfo.workflowEntity?.currentStatus?.id ===
    WorkflowEntityIdsTravel.Confirmed;

  return (
    <>
      <section
        className="PuppyTravel_header"
        data-testid={testId.puppyTravelProposal}
      >
        <h2 data-testid="puppy-travel-proposal-title">
          {travelInfo.travelMethod.name} proposal {isConfirm ? 'confirmed' : ''}
        </h2>
        {!statusPuppyTravel.isPuppyTravelConfirmed() && (
          <p>
            We will provide the exact times and dates prior to departure date.
          </p>
        )}
      </section>
      <section className="PuppyTravel_body PuppyTravel_body-proposal">
        <div className="PuppyTravel_title">
          <h4>Travel Proposal</h4>
        </div>
        <div
          className="PuppyTravel_proposal"
          data-testid="puppy-travel-proposal-box"
        >
          {renderProposal()}
        </div>
      </section>
    </>
  );
}

export default PuppyTravelProposal;
