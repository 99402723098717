import { Dispatch } from 'redux';
import { notification } from 'antd';

import { Listing } from 'shared/types/puppy';
import {
  setMyListingsHidden,
  setMyListingsHiddenFilters,
  setMyListingsHiddenLoading,
  setMyListingsHiddenPagination,
  setMyListingsHiddenSorter,
} from 'store/myListingsHidden/actions';
import {
  initialStateMyListingsHiddenFilters,
  initialStateMyListingsHiddenPagination,
  initialStateMyListingsHiddenSorter,
} from 'store/myListingsHidden/myListingsHiddenReducer';
import { RootState } from 'store/rootState';
import apiListing from 'utilities/http/api/apiListing';
import { BreederDashboardStatus } from 'utilities/const';
import { requestHasErrors } from 'utilities/http/http';

export function fetchMyListingsHidden() {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setMyListingsHiddenLoading());

    const {
      listings,
      pagination,
      sorter,
      filters,
    } = getState().myListingsHidden;

    const myListingsResp = await apiListing.getMyListings(
      BreederDashboardStatus.Hidden,
      pagination,
      sorter,
      filters,
    );

    if (requestHasErrors(myListingsResp)) {
      if (!filters.isFiltering) {
        notification.error({ message: `Error fetching my listings hidden` });
      }

      dispatch(setMyListingsHidden([]));
      dispatch(
        setMyListingsHiddenPagination({
          ...pagination,
          total: 0,
        }),
      );
      return;
    }

    const listingsResp = myListingsResp?.json.listings as Listing[];
    const paginationResp = myListingsResp?.json.paginationMeta;

    if (pagination.loadMore) {
      dispatch(setMyListingsHidden([...listings, ...listingsResp]));
    } else {
      dispatch(setMyListingsHidden(listingsResp));
    }

    dispatch(
      setMyListingsHiddenPagination({
        ...pagination,
        total: paginationResp.total,
      }),
    );
  };
}

export function setMyListingsHiddenInitialState() {
  return (dispatch: Dispatch) => {
    dispatch(setMyListingsHidden([]));
    dispatch(
      setMyListingsHiddenPagination(initialStateMyListingsHiddenPagination),
    );
    dispatch(setMyListingsHiddenFilters(initialStateMyListingsHiddenFilters));
    dispatch(setMyListingsHiddenSorter(initialStateMyListingsHiddenSorter));
  };
}
