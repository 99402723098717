import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, Input, Select, Space } from 'antd';
import { FormInstance } from 'antd/lib/form';

import { useLittersListingStarter } from 'pages/newListing/createListingStarter/useLittersListingStarter';
import { RootState } from 'store/rootState';
import { Puppy } from 'shared/types/puppy';
import { testId } from 'testsUtilities/dataTestId';
import date from 'utilities/time/date';
import {
  isUserBreederTierCommitmentShortTerm,
  isUserBreederTierSpotOn,
} from 'utilities/user/userBreederCheckers/userBreederCheckers';
import {
  getBreedId,
  isDelivered,
  isPending,
  isSold,
} from 'utilities/entities/puppy';
import { isListingLocked } from 'utilities/listing';
import apiLitter from 'utilities/http/api/apiLitter';

import './FieldChangePuppyLitter.scss';

interface Props {
  form: FormInstance;
  isEditing: boolean;
}

function FieldChangePuppyLitter(props: Props): ReactElement | null {
  const { form, isEditing } = props;
  const [changePuppyLitter, setChangePuppyLitter] = useState(false);
  const [hasPuppyLitterChanged, setHasPuppyLitterChanged] = useState(false);
  const [loadingChangePuppyLitter, setLoadingChangePuppyLitter] = useState(
    false,
  );
  const [litterLabel, setLitterLabel] = useState('');
  const puppy = useSelector((state: RootState) => state.puppy);
  const { isLoading, littersOptions } = useLittersListingStarter();

  const handleChangePuppyLitter = async (litterId: number) => {
    setLoadingChangePuppyLitter(true);
    const respLitter = await apiLitter.getLitterById(litterId);
    const litterInfo = respLitter.json.litter;

    form.setFieldsValue({
      litterId: litterId,
      breedId: litterInfo?.breedId,
      colorId: undefined,
      markingId: undefined,
      varietyId: undefined,
      dateAvailable: undefined,
      litterBirthDate: litterInfo?.birthDate,
      food: litterInfo?.food,
      listing: {
        cost: undefined,
      },
    });

    form.validateFields([['listing', 'cost']]);

    setHasPuppyLitterChanged(true);
    setChangePuppyLitter(false);
    setLoadingChangePuppyLitter(false);
  };

  const handleResetChangePuppyLitter = () => {
    const puppyPendingEdits = puppy.pendingEdit;

    form.setFieldsValue({
      litterId: puppyPendingEdits?.litterId || puppy.litter?.id,
      breedId: getBreedId(puppy),
      varietyId: puppyPendingEdits?.varietyId || puppy.varietyId,
      colorId: puppyPendingEdits?.colorId || puppy.colorId,
      markingId: puppyPendingEdits?.markingId || puppy.markingId,
      dateAvailable: puppyPendingEdits?.dateAvailable || puppy.dateAvailable,
      litterBirthDate: puppy.litter?.birthDate,
      food: puppy.litter?.food,
      listing: {
        cost: puppy.listing?.cost,
      },
    });

    setHasPuppyLitterChanged(false);
  };

  const setLitterParentsTitle = (litterId: number) => {
    const litter = puppy.pendingEdit?.litter
      ? puppy.pendingEdit?.litter
      : puppy.listing?.puppy?.litter;

    let litterParentsTitle = '';

    if (
      puppy.pendingEdit?.litterId === litterId ||
      puppy.litterId === litterId
    ) {
      litterParentsTitle = `${date.toMMDDYY(litter?.birthDate)} - ${
        litter?.dam?.name
      } & ${litter?.sire?.name}`;
    } else {
      const litterSelected = littersOptions.find((item) => {
        return item.id === litterId;
      });
      litterParentsTitle = `${date.toMMDDYY(litterSelected?.birthDate)} - ${
        litterSelected?.dam?.name
      } & ${litterSelected?.sire?.name}`;
    }

    setLitterLabel(litterParentsTitle);
  };

  const formItemHelp =
    changePuppyLitter || hasPuppyLitterChanged
      ? hasPuppyLitterChanged
        ? {
            help: (
              <>
                Your new selection will replace the old litter after clicking on
                the &quot;Save&quot; button on this form. Click{' '}
                <span
                  className="FieldChangePuppyLitter__link-reset"
                  onClick={handleResetChangePuppyLitter}
                >
                  here
                </span>{' '}
                to reset the original litter.
              </>
            ),
          }
        : {
            help: (
              <>
                Your new selection will replace the old litter after clicking on
                the “Save” button on this form.
              </>
            ),
          }
      : {};

  const formItemExtra =
    !puppy.breederDashboardStatus ||
    isDelivered(puppy) ||
    isSold(puppy as Puppy) ||
    (puppy.listing && isListingLocked(puppy.listing))
      ? {}
      : {
          extra: (
            <Space>
              <Button
                data-testid={
                  changePuppyLitter
                    ? testId.fieldChangePuppyLitterButtonCancel
                    : testId.fieldChangePuppyLitterButtonChange
                }
                type="default"
                size="small"
                onClick={() => setChangePuppyLitter(!changePuppyLitter)}
                style={{ marginTop: '10px' }}
                loading={loadingChangePuppyLitter}
                disabled={loadingChangePuppyLitter}
              >
                {changePuppyLitter ? 'Cancel' : 'Change'}
              </Button>
            </Space>
          ),
        };

  if (!isEditing) {
    return null;
  }

  if (
    (isUserBreederTierSpotOn() && !isPending(puppy)) ||
    (isUserBreederTierCommitmentShortTerm() && !isPending(puppy))
  ) {
    return null;
  }

  return (
    <>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) => {
          if (prevValues.litterId !== curValues.litterId) {
            setLitterParentsTitle(curValues.litterId);
          }
          return prevValues.litterId !== curValues.litterId;
        }}
      >
        {() => (
          <Form.Item
            name="litterId"
            label="Litter"
            {...formItemHelp}
            {...formItemExtra}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              {changePuppyLitter ? (
                <Select
                  data-testid={testId.fieldChangePuppyLitterSelect}
                  disabled={isLoading || isSold(puppy as Puppy)}
                  loading={isLoading}
                  placeholder="Select litter"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={handleChangePuppyLitter}
                >
                  {littersOptions?.map((litter) => (
                    <Select.Option
                      key={litter.id as number}
                      value={litter.id as number}
                    >
                      {`${date.toMMDDYY(litter?.birthDate)} - ${
                        litter?.dam?.name
                      } & ${litter?.sire?.name}`}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Input
                  data-testid={testId.fieldChangePuppyLitterInput}
                  value={litterLabel}
                  disabled
                />
              )}
            </Space>
          </Form.Item>
        )}
      </Form.Item>
    </>
  );
}

export default FieldChangePuppyLitter;
