import {
  LitterActionTypes,
  RESET_LITTER,
  SET_LITTER,
} from 'store/litter/types';
import { Litter } from 'shared/types/litter';

const initialState: Partial<Litter> = {};

export function litterReducer(
  state = initialState,
  action: LitterActionTypes,
): Partial<Litter> {
  switch (action.type) {
    case SET_LITTER:
      return action.litter;
    case RESET_LITTER:
      return initialState;

    default:
      return state;
  }
}
