import { Id } from 'shared/types/general';
import { Breeder } from 'shared/types/breeder';

export enum userRole {
  puppyListingsEditor = 'puppy-listings-editor',
  breederHubBreeder = 'breeder-hub-breeder',
  breederAdminInternal = 'breeder-admin-internal',
  breederAdminExternal = 'breeder-admin-external',
}

export interface User {
  email: string;
  firstName: string;
  lastName: string;
  id: Id;
  isActive?: number | null;
  breeders: Breeder[];
  roles: UserRole[];
  canImpersonateAnyBreeder: boolean;
  canImpersonateManagedBreeders: boolean;
}

export interface UserRole {
  applicationId: number;
  id: number;
  name: string;
  slug: string;
}
