import { Listing, Puppy } from 'shared/types/puppy';
import {
  MyListingsFilters,
  MyListingsPagination,
  MyListingsSorter,
} from 'shared/types/myListings';

export const SET_MY_LISTINGS_HIDDEN_LOADING = 'SET_MY_LISTINGS_HIDDEN_LOADING';
export const SET_MY_LISTINGS_HIDDEN = 'SET_MY_LISTINGS_HIDDEN';
export const SET_MY_LISTINGS_HIDDEN_FILTERS = 'SET_MY_LISTINGS_HIDDEN_FILTERS';
export const SET_MY_LISTINGS_HIDDEN_SORTER = 'SET_MY_LISTINGS_HIDDEN_SORTER';
export const SET_MY_LISTINGS_HIDDEN_PAGINATION =
  'SET_MY_LISTINGS_HIDDEN_PAGINATION';
export const UPDATE_MY_LISTINGS_HIDDEN_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES =
  'UPDATE_MY_LISTINGS_HIDDEN_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES';

export interface MyListingsMainState {
  loading: boolean;
  listings: Listing[];
  pagination: MyListingsPagination;
  filters: MyListingsFilters;
  sorter: MyListingsSorter;
}

interface SetMyListingsHiddenLoading {
  type: typeof SET_MY_LISTINGS_HIDDEN_LOADING;
}

interface SetMyListingsHidden {
  type: typeof SET_MY_LISTINGS_HIDDEN;
  listings: Listing[];
}

interface SetMyListingsHiddenFilters {
  type: typeof SET_MY_LISTINGS_HIDDEN_FILTERS;
  filters: MyListingsFilters;
}

interface SetMyListingsHiddenSorter {
  type: typeof SET_MY_LISTINGS_HIDDEN_SORTER;
  sorter: MyListingsSorter;
}

interface SetMyListingsHiddenPagination {
  type: typeof SET_MY_LISTINGS_HIDDEN_PAGINATION;
  pagination: MyListingsPagination;
}

interface UpdateMyListingsHiddenPuppyRegistrationsWeightDueDates {
  type: typeof UPDATE_MY_LISTINGS_HIDDEN_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES;
  puppy: Puppy;
}

export type MyListingsHiddenActions =
  | SetMyListingsHiddenLoading
  | SetMyListingsHidden
  | SetMyListingsHiddenFilters
  | SetMyListingsHiddenSorter
  | SetMyListingsHiddenPagination
  | UpdateMyListingsHiddenPuppyRegistrationsWeightDueDates;
