import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Badge } from 'antd';
import {
  CheckOutlined,
  HourglassOutlined,
  ExclamationOutlined,
} from '@ant-design/icons';

import { PuppyDetailTileStatus } from 'shared/types/puppy';

import './PuppyDetailTile.scss';

interface Props {
  title: string;
  icon: ReactElement;
  status?: PuppyDetailTileStatus;
  statusText?: string;
  onClick?: () => void;
  disabled?: boolean;
}

function PuppyDetailTile(props: Props): ReactElement {
  const { title, icon, status, statusText, disabled, onClick } = props;
  const [badgeIcon, setBadgeIcon] = useState<ReactNode>();

  const handleClassModifier = `PuppyDetailTile ${
    status ? `PuppyDetailTile--${status}` : ''
  } ${!disabled && onClick ? 'PuppyDetailTile--clickable' : ''}`;

  const dataIdModifier = `puppy-detail-tile-${title
    .split(' ')
    .join('-')
    .toLowerCase()}`;

  const handleOnClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  useEffect(() => {
    switch (status) {
      case 'success':
        setBadgeIcon(<CheckOutlined />);
        break;
      case 'pending':
        setBadgeIcon(<HourglassOutlined />);
        break;
      case 'error':
      case 'warning':
        setBadgeIcon(<ExclamationOutlined />);
        break;
      default:
        setBadgeIcon(undefined);
    }
  }, [status]);

  return (
    <section
      className={handleClassModifier}
      onClick={handleOnClick}
      data-testid={dataIdModifier}
    >
      <Badge count={badgeIcon}>{icon}</Badge>
      <h3 className="PuppyDetailTile__title">{title}</h3>
      <p className="PuppyDetailTile__status-text">{statusText}</p>
    </section>
  );
}

export default PuppyDetailTile;
