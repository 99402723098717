import {
  MyListingsSoldActions,
  SET_MY_LISTINGS_SOLD,
  SET_MY_LISTINGS_SOLD_FILTERS,
  SET_MY_LISTINGS_SOLD_LOADING,
  SET_MY_LISTINGS_SOLD_PAGINATION,
  SET_MY_LISTINGS_SOLD_SORTER,
  SET_PUPPY_HEALTH_DOC,
  UPDATE_MY_LISTINGS_SOLD_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES,
} from 'store/myListingsSold/types';
import { Listing, Puppy } from 'shared/types/puppy';
import {
  MyListingsFilters,
  MyListingsPagination,
  MyListingsSorter,
} from 'shared/types/myListings';
import { Document } from 'shared/types/document';

export function setMyListingsSoldLoading(): MyListingsSoldActions {
  return {
    type: SET_MY_LISTINGS_SOLD_LOADING,
  };
}

export function setMyListingsSold(listings: Listing[]): MyListingsSoldActions {
  return {
    type: SET_MY_LISTINGS_SOLD,
    listings,
  };
}

export function setMyListingsSoldFilters(
  filters: MyListingsFilters,
): MyListingsSoldActions {
  return {
    type: SET_MY_LISTINGS_SOLD_FILTERS,
    filters,
  };
}

export function setMyListingsSoldSorter(
  sorter: MyListingsSorter,
): MyListingsSoldActions {
  return {
    type: SET_MY_LISTINGS_SOLD_SORTER,
    sorter,
  };
}

export function setMyListingsSoldPagination(
  pagination: MyListingsPagination,
): MyListingsSoldActions {
  return {
    type: SET_MY_LISTINGS_SOLD_PAGINATION,
    pagination,
  };
}

export function setPuppyHealthDoc(
  documents: Document[],
  listingId: number,
): MyListingsSoldActions {
  return {
    type: SET_PUPPY_HEALTH_DOC,
    documents,
    listingId,
  };
}

export function updateMyListingsSoldPuppyRegistrationsWeightDueDates(
  puppy: Puppy,
): MyListingsSoldActions {
  return {
    type: UPDATE_MY_LISTINGS_SOLD_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES,
    puppy,
  };
}
