import {
  apiGetRequest,
  apiPostRequest,
  apiPutRequest,
} from 'utilities/http/api/apiHttp';
import { NoteTypes } from 'shared/types/general';
import { Puppy } from 'shared/types/puppy';
import { InventoryStatusIdTypes } from 'shared/types/statuses';

const apiPuppy = {
  postPuppy: (puppy: Partial<Puppy>) => {
    const payload = { puppy: puppy };
    return apiPostRequest(`puppy`, payload);
  },
  putPuppy: (puppyId: number, puppy: Partial<Puppy>) => {
    const payload = { puppy: puppy };
    return apiPutRequest(`puppy/${puppyId}`, payload);
  },
  putPuppyNote: (noteId: number, message: string) => {
    const payload = {
      note: {
        message: message,
        noteTypeId: NoteTypes.noteFromBreeder.id,
      },
    };
    return apiPutRequest(`note/${noteId}`, payload);
  },
  getPuppy: (puppyId: number) => {
    const param = `withDueDates=1&withFood=1&withListingNotes=1&withPuppyPendingEdits=1&withRecommendedPrice=1&withContractTerms=1`;
    return apiGetRequest(`puppy/${puppyId}`, param);
  },
  putPuppyPhotosViewOrder: (listingId: number, viewOrder: number[]) => {
    const payload = { viewOrder: viewOrder };
    return apiPutRequest(`listing/${listingId}/media/view-order`, payload);
  },
  putListing: (listingId: number, cost: number) => {
    const payload = { listing: { cost } };
    return apiPutRequest(`listing/${listingId}`, payload);
  },
  putPuppyInventoryStatus: (
    puppyId: number,
    inventoryStatus: InventoryStatusIdTypes,
  ) => {
    return apiPutRequest(`puppy/${puppyId}/status`, {
      inventoryStatus,
    });
  },
  getPuppyOrder: (puppyId: number) => {
    return apiGetRequest(`puppy/${puppyId}/order`);
  },

  getPuppyHealthDocs: (puppyId: number) => {
    return apiGetRequest(`puppy/${puppyId}/documents`);
  },

  getPuppyHealthDocsDueDate: (puppyId: number) => {
    return apiGetRequest(`puppy/${puppyId}/document-due-date`);
  },
};

export default apiPuppy;
