/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  environmentVariables,
  reactEnvironmentVariables,
} from 'utilities/environmentVariables';

export class Log {
  static debug(...msg: any) {
    if (
      environmentVariables.NODE_ENV === 'development' &&
      reactEnvironmentVariables.HTTP_REQUEST_LOG_CONSOLE
    ) {
      /* eslint-disable no-console */
      console.log('DEBUG: ', ...msg);
    }
  }
  static info(...msg: any) {
    if (
      environmentVariables.NODE_ENV === 'development' &&
      reactEnvironmentVariables.HTTP_REQUEST_LOG_CONSOLE
    ) {
      /* eslint-disable no-console */
      console.log('INFO: ', ...msg);
    }
  }

  static error(...msg: any) {
    /* eslint-disable no-console */
    console.log('ERROR: ', ...msg);
  }
}
