import { Listing, Puppy } from 'shared/types/puppy';
import {
  MyListingsFilters,
  MyListingsPagination,
  MyListingsSorter,
} from 'shared/types/myListings';
import {
  MyListingsPendingActions,
  SET_MY_LISTINGS_PENDING,
  SET_MY_LISTINGS_PENDING_FILTERS,
  SET_MY_LISTINGS_PENDING_LOADING,
  SET_MY_LISTINGS_PENDING_PAGINATION,
  SET_MY_LISTINGS_PENDING_SORTER,
  UPDATE_MY_LISTINGS_PENDING_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES,
} from 'store/myListingsPending/types';

export function setMyListingsPendingLoading(): MyListingsPendingActions {
  return {
    type: SET_MY_LISTINGS_PENDING_LOADING,
  };
}

export function setMyListingsPending(
  listings: Listing[],
): MyListingsPendingActions {
  return {
    type: SET_MY_LISTINGS_PENDING,
    listings,
  };
}

export function setMyListingsPendingFilters(
  filters: MyListingsFilters,
): MyListingsPendingActions {
  return {
    type: SET_MY_LISTINGS_PENDING_FILTERS,
    filters,
  };
}

export function setMyListingsPendingSorter(
  sorter: MyListingsSorter,
): MyListingsPendingActions {
  return {
    type: SET_MY_LISTINGS_PENDING_SORTER,
    sorter,
  };
}

export function setMyListingsPendingPagination(
  pagination: MyListingsPagination,
): MyListingsPendingActions {
  return {
    type: SET_MY_LISTINGS_PENDING_PAGINATION,
    pagination,
  };
}

export function updateMyListingsPendingPuppyRegistrationsWeightDueDates(
  puppy: Puppy,
): MyListingsPendingActions {
  return {
    type: UPDATE_MY_LISTINGS_PENDING_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES,
    puppy,
  };
}
