import React, { ReactElement } from 'react';
import { Typography } from 'antd';

import {
  DownloadHealthDocs,
  HealthDocBadgeStatus,
  HealthDocDueDate,
} from 'components/healthDocs';
import { Documents } from 'shared/types/document';
import { Nullable } from 'shared/types/general';
import { DocumentTypeId } from 'utilities/const';

import './HealthDocsStatus.scss';

interface Props {
  puppyId: number;
  documents: Documents;
  documentDueDate: Nullable<string>;
  isPuppyDelivered?: boolean;
}

function HealthDocsStatus(props: Props): ReactElement {
  const {
    puppyId,
    documents,
    documentDueDate,
    isPuppyDelivered = false,
  } = props;

  return (
    <section className="HealthDocsStatus">
      <Typography.Title className="HealthDocsStatus__title" level={5}>
        Documents Status
      </Typography.Title>
      <div className="HealthDocsStatus__wrapper">
        <div className="HealthDocsStatus__wrapper__left">
          <HealthDocBadgeStatus
            documentType={DocumentTypeId.HealthCertificate}
            documents={documents}
          />
          <HealthDocBadgeStatus
            documentType={DocumentTypeId.VeterinaryHealthReport}
            documents={documents}
          />
          <HealthDocBadgeStatus
            documentType={DocumentTypeId.VaccineRecord}
            documents={documents}
          />
          <DownloadHealthDocs puppyId={puppyId} iconOnly />
        </div>
        <div className="HealthDocsStatus__wrapper__right">
          {!isPuppyDelivered && (
            <HealthDocDueDate
              documentDueDate={documentDueDate}
              documents={documents}
            />
          )}
        </div>
      </div>
    </section>
  );
}

export default HealthDocsStatus;
