import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { isEmpty } from 'lodash';

import LoadingSection from 'components/loadingSection/LoadingSection';
import PageLayout from 'shared/baseLayout/pageLayout/PageLayout';
import PuppyForm from 'shared/puppy/PuppyForm';
import { ParentForm } from 'shared/parents/ParentForm';
import LitterForm from 'shared/litter/LitterForm';
import { Puppy } from 'shared/types/puppy';
import { FormProps } from 'shared/types/general';
import { RootState } from 'store/rootState';
import { getParent, resetParents } from 'store/parents/actions';
import { getLitter, resetLitter } from 'store/litter/actions';
import { resetPuppy, setPuppy } from 'store/puppy/actions';
import { buildDuplicatePuppyData } from 'utilities/puppy/buildDuplicatePuppyData/buildDuplicatePuppyData';
import CreateListingStarter from './createListingStarter/CreateListingStarter';

import './NewListing.scss';

const breadCrumbs = [
  {
    name: 'New Puppy listing',
  },
];

export default function NewListing(): ReactElement {
  const { parents, litter, puppy, newPuppy } = useSelector(
    (state: RootState) => ({
      parents: state.parents,
      litter: state.litter,
      puppy: state.puppy,
      newPuppy: state.newPuppy,
    }),
  );
  const [loading, setLoading] = useState(true);
  const [startNew, setStartNew] = useState(false);
  const dispatch = useDispatch();

  const storedDamId = parents?.dam?.id;
  const storedSireId = parents?.sire?.id;
  const storedLitterId = litter?.id;

  const formProps: {
    dam: FormProps;
    sire: FormProps;
    litter: FormProps;
    puppy: FormProps;
  } = {
    dam: {
      collapsableDisabled: startNew ? !startNew : !Boolean(storedDamId),
      collapsableType: storedDamId
        ? 'success'
        : startNew
        ? 'warning'
        : 'disabled',
      isOpen:
        startNew && isEmpty(parents?.sire) && isEmpty(litter) && isEmpty(puppy),
    },
    sire: {
      collapsableDisabled: storedSireId ? false : !storedDamId,
      collapsableType: storedSireId
        ? 'success'
        : storedDamId
        ? 'warning'
        : 'disabled',
      isOpen:
        !isEmpty(parents.dam) &&
        isEmpty(parents.sire) &&
        isEmpty(litter) &&
        isEmpty(puppy),
    },
    litter: {
      collapsableDisabled: storedLitterId ? false : !storedSireId,
      collapsableType: storedLitterId
        ? 'success'
        : storedSireId
        ? 'warning'
        : 'disabled',
      isOpen:
        !isEmpty(parents.dam) && !isEmpty(parents.sire) && isEmpty(litter),
    },
    puppy: {
      collapsableDisabled: !Boolean(storedLitterId),
      collapsableType: puppy.id
        ? 'success'
        : storedLitterId
        ? 'warning'
        : 'disabled',
      isOpen:
        isEmpty(puppy) && isEmpty(litter)
          ? false
          : isEmpty(puppy) && !isEmpty(litter)
          ? true
          : !puppy.id,
      action: 'create',
    },
  };

  const handleNewPuppy = useCallback(async () => {
    if (!newPuppy) {
      setLoading(false);
      return;
    }
    await dispatch(getParent(newPuppy.damId));
    await dispatch(getParent(newPuppy.sireId));
    await dispatch(getLitter(newPuppy.litterId));
    if (newPuppy.puppyId) {
      const duplicatePuppyData = await buildDuplicatePuppyData(
        newPuppy.puppyId,
      );
      dispatch(setPuppy(duplicatePuppyData as Puppy));
    }
    setLoading(false);
  }, [dispatch, newPuppy]);

  useEffect(() => {
    handleNewPuppy();

    return () => {
      dispatch(resetParents());
      dispatch(resetLitter());
      dispatch(resetPuppy());
    };
  }, [dispatch, handleNewPuppy]);

  return (
    <PageLayout breadCrumbsNav={breadCrumbs}>
      {loading ? (
        <LoadingSection />
      ) : (
        <Row justify="center">
          <Col xs={24} xl={20}>
            <CreateListingStarter setStartNew={setStartNew} />
            <ParentForm
              gender="dam"
              fieldExistingParentOn={startNew}
              {...formProps.dam}
            />
            <ParentForm
              gender="sire"
              fieldExistingParentOn={startNew}
              {...formProps.sire}
            />
            <LitterForm {...formProps.litter} />
            <PuppyForm {...formProps.puppy} />
          </Col>
        </Row>
      )}
    </PageLayout>
  );
}
