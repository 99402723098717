import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';

import {
  setPuppyPhotoHidden,
  setPuppyPhotosAdditional,
  setPuppyPhotosCover,
} from 'store/puppy/actions';
import { RootState } from 'store/rootState';
import { Media } from 'shared/types/media';
import { MediaUploaderItem } from 'shared/fields/fieldMediaUploader/MediaUploaderItem';
import { PuppyPhotoDraggableItemProps } from './types';
import { PuppyPhotos } from 'shared/types/puppy';

export default function PuppyPhotoDraggableItem(
  props: PuppyPhotoDraggableItemProps,
): ReactElement {
  const { type, index, photo, disabled } = props;
  const dispatch = useDispatch();
  const puppyPhotos = useSelector(
    (state: RootState) =>
      ({
        coverPhotos: state.puppy.photos?.coverPhotos,
        additionalPhotos: state.puppy.photos?.additionalPhotos,
      } as PuppyPhotos),
  );
  const action = {
    coverPhotos: (photos: Media[]) => dispatch(setPuppyPhotosCover(photos)),
    additionalPhotos: (photos: Media[]) =>
      dispatch(setPuppyPhotosAdditional(photos)),
  };

  const handleRemove = async (photo: Media) => {
    const index = photo && puppyPhotos[type]?.indexOf(photo);
    const newPhotoList = puppyPhotos[type]?.slice();
    newPhotoList?.splice(index as number, 1);

    photo.id && dispatch(setPuppyPhotoHidden(photo));
    action[type](newPhotoList as Media[]);
  };

  return (
    <Draggable
      draggableId={`${photo.id}`}
      index={index}
      disableInteractiveElementBlocking={disabled}
      isDragDisabled={disabled}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <MediaUploaderItem
            mediaData={photo}
            onClickDelete={() => handleRemove(photo)}
            disabled={disabled}
            type="photo"
            index={index + 1}
          />
        </div>
      )}
    </Draggable>
  );
}
