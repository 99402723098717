import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FieldData, Store } from 'rc-field-form/lib/interface';
import { Col, Form, Modal, notification, Row } from 'antd';
import { isEmpty } from 'lodash';

import { RootState } from 'store/rootState';
import { editLitter, getLitter } from 'store/litter/actions';
import {
  FieldBirthday,
  FieldFood,
  FieldCrateTraining,
  FieldPottyTraining,
  FieldSocialization,
  FieldNeuroStimulation,
  FieldLitterSize,
  SubmitButton,
} from 'shared/fields';
import { LitterFormFields } from 'shared/types/litter';
import { BreederRoutes } from 'shared/types/routes';
import { FormProps } from 'shared/types/general';
import { Collapsable } from 'shared/collapsable/Collapsable';
import litterFormHelpers from './litterFormHelpers';
import litterFormCreate from './litterFormCreate';
import moment from 'moment';
import date from 'utilities/time/date';

export default function LitterForm(props: FormProps): ReactElement {
  const {
    collapsableDisabled,
    collapsableType,
    isOpen,
    action = 'create',
    singleForm,
  } = props;
  const [collapsableOpen, setCollapsableOpen] = useState<boolean>(
    Boolean(isOpen),
  );
  const { parents } = useSelector((state: RootState) => ({
    parents: state.parents,
  }));

  const { litter } = useSelector((state: RootState) => ({
    litter: state.litter,
  }));
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const pathname = history.location.pathname;

  let minimumDateLitter = null;
  let maximumDateLitter = null;

  const dam1stBirthday = moment(parents?.dam?.birthDate).add(1, 'years');
  const monthsOlder = moment().subtract(5, 'months').subtract(15, 'days');
  const dam7thBirthday = moment(parents?.dam?.birthDate).add(7, 'years');
  const sire9thBirthday = moment(parents?.sire?.birthDate).add(9, 'years');

  if (action === 'create') {
    if (dam1stBirthday.isSameOrAfter(monthsOlder)) {
      minimumDateLitter = date.toYYYYMMDD(dam1stBirthday);
    } else {
      minimumDateLitter = date.toYYYYMMDD(monthsOlder);
    }

    if (dam7thBirthday.isSameOrBefore(sire9thBirthday)) {
      maximumDateLitter = date.toYYYYMMDD(dam7thBirthday);
    } else {
      maximumDateLitter = date.toYYYYMMDD(sire9thBirthday);
    }
  } else {
    minimumDateLitter = litter.dam?.minimumDateAllowedToAddPuppies;
  }

  const onModalClose = (litterId: unknown) => {
    history.push(`/litter/${litterId}`);
  };

  const handleSubmit = async (litterFormValues: Store) => {
    setIsSubmitting(true);
    setCollapsableOpen(true);

    if (action === 'create') {
      const litterId = await litterFormCreate.createLitter(
        litterFormValues as LitterFormFields,
        parents,
      );
      if (singleForm) {
        Modal.success({
          className: 'Modal__confirm--success',
          title: 'Litter Created',
          content: 'Litter Created Successfully',
          autoFocusButton: null,
          okText: 'OK',
          afterClose: () => onModalClose(litterId),
          okButtonProps: { type: 'primary' },
        });
      } else {
        notification.success({
          message: 'Litter created successfully!',
          className: 'notification-success-litter',
        });
      }

      await dispatch(getLitter(litterId));
    } else {
      await dispatch(editLitter(litterFormValues as LitterFormFields, litter));
    }

    setIsSubmitting(false);
    setCollapsableOpen(false);
    setDisableSubmit(true);
    return;
  };

  const handleOnFieldsChange = (changedFields: FieldData[]) => {
    litterFormHelpers.onFieldsChange(changedFields, form);
    setDisableSubmit(litterFormHelpers.disableSubmit(form));
  };

  useEffect(() => {
    !isEmpty(litter) && litterFormHelpers.setFormFields(litter, form);
    isEmpty(litter) && form.resetFields();
  }, [form, litter]);

  useEffect(() => {
    setCollapsableOpen(Boolean(isOpen));
  }, [isOpen]);

  const isDisabled = (name: string) => {
    if (!isEmpty(litter) && pathname === BreederRoutes.NewPuppy) {
      return true;
    }

    if (name === 'birthday') {
      const disableBirthdayField = litterFormHelpers.getSoldOrDeliveredPuppies(
        litter?.puppies || [],
      );

      return !isEmpty(disableBirthdayField);
    }

    return false;
  };

  return (
    <Collapsable
      id="litter"
      title={
        isEmpty(litter)
          ? 'Set up the Litter'
          : `${litter.dam?.name} & ${litter.sire?.name}`
      }
      icon="litter"
      isOpen={collapsableOpen}
      type={collapsableType}
      disabled={collapsableDisabled}
      handleCollapsableOpen={setCollapsableOpen}
    >
      <Row justify="center">
        <Col xs={24} xl={10} xxl={8}>
          <Form
            name="litter"
            data-testid="litter-form"
            layout="vertical"
            size="large"
            onFinish={handleSubmit}
            onFieldsChange={handleOnFieldsChange}
            scrollToFirstError
            form={form}
            className="LitterForm"
            initialValues={{
              isCrateTrained: 0,
            }}
          >
            <FieldBirthday
              label="Expected or actual birthday?"
              disabled={isDisabled('birthday')}
              formType="litter"
              minimumDate={minimumDateLitter}
              maximumDate={maximumDateLitter}
            />
            <FieldLitterSize disabled={isDisabled('litterSize')} />
            <FieldFood disabled={isDisabled('food')} />
            <FieldPottyTraining disabled={isDisabled('pottyTraining')} />
            <FieldNeuroStimulation disabled={isDisabled('neuroStimulation')} />
            <FieldSocialization disabled={isDisabled('socialization')} />
            <FieldCrateTraining disabled={isDisabled('crateTraining')} />

            <SubmitButton
              label={`Save Litter's info`}
              loading={isSubmitting}
              disabled={disableSubmit}
            />
          </Form>
        </Col>
      </Row>
    </Collapsable>
  );
}
