import React, { ReactElement } from 'react';
import { Form, Input, Select } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { SelectValue } from 'antd/lib/select';

import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import { EmptyFieldValidationHandler } from '../fieldValidationHandler';
import { WeightRangeContent } from 'utilities/staticContent/types';
import date from 'utilities/time/date';

interface Props {
  disabled?: boolean;
  warning?: boolean;
}

export function FieldWeightRange(props: Props): ReactElement {
  const { disabled, warning } = props;
  const weightRanges = staticContentStorage.getContent().weightRanges;
  const unknownWeightRangeId = 999999;

  const warningStatus = warning && {
    hasFeedback: true,
    validateStatus: 'warning' as ValidateStatus,
  };

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, curValues) =>
        prevValues.weightRangeId !== curValues.weightRangeId ||
        prevValues.puppyWeightSameRange !== curValues.puppyWeightSameRange
      }
    >
      {(form) => (
        <>
          <Form.Item
            name="weightRangeId"
            label="Weight"
            data-testid="field-weight-range"
            rules={[EmptyFieldValidationHandler('list')]}
            {...warningStatus}
          >
            <Select
              disabled={disabled || form.getFieldValue('puppyWeightSameRange')}
              placeholder="Select a range"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value: SelectValue) => {
                form.setFieldsValue({
                  lastWeighedAt: date.toYYYYMMDD(new Date()),
                  weightRangeId: value,
                });
              }}
            >
              {weightRanges.map(
                (weightRange: WeightRangeContent) =>
                  weightRange.id !== unknownWeightRangeId && (
                    <Select.Option key={weightRange.id} value={weightRange.id}>
                      {weightRange.name}
                    </Select.Option>
                  ),
              )}
            </Select>
          </Form.Item>
          <Form.Item name="lastWeighedAt" noStyle>
            <Input hidden />
          </Form.Item>
        </>
      )}
    </Form.Item>
  );
}
