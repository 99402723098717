import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import { isEmpty } from 'lodash';

import { ReactComponent as BHLogo } from 'assets/img/breeder-hub.svg';
import RegisterForm from 'pages/register/registerForm/RegisterForm';
import { AuthorizationFooter } from 'shared/authorizationForm/AuthorizationFooter';
import { Collapsable } from 'shared/collapsable/Collapsable';
import apiBreeder from 'utilities/http/api/apiBreeder';
import apiStaticContent from 'utilities/http/api/apiStaticContent';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';

import './Register.scss';

export default function Register(): ReactElement | null {
  const [questionary, setQuestionary] = useState([]);
  const mountedRef = useRef(true);

  const fetchQuestionary = async () => {
    try {
      const staticContentResponse = await apiStaticContent.getStaticContent();
      const staticContent = staticContentResponse?.json?.content;
      staticContentStorage.setContent(staticContent);

      const questionaryResponse = await apiBreeder.getQuestionary(1);
      const { Registration } = questionaryResponse?.json?.questions;

      if (mountedRef.current) {
        setQuestionary(Registration);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchQuestionary();
    return () => {
      mountedRef.current = false;
      staticContentStorage.removeContent();
    };
  }, []);

  return (
    <>
      <Row justify="center">
        <Col xs={22} lg={15} xxl={10}>
          <BHLogo />
          <h1>Hi new breeder!</h1>
          <h2>
            Thanks for starting the two-step registration process with
            PuppySpot.
          </h2>
          <ol>
            <li>
              <h3>Application form</h3>
              <p>
                Answer the questions in the form below to verify your breeding
                experience.
              </p>
            </li>
            <li>
              <h3>Phone call</h3>
              <p>
                You&apos;ll be contacted by a PuppySpot representative to
                validate additional information.
              </p>
            </li>
          </ol>
          {!isEmpty(questionary) ? (
            <Collapsable
              id="register-collapsable"
              title="Application Form"
              icon="userOutlined"
              isOpen={true}
            >
              <RegisterForm questionary={questionary} />
            </Collapsable>
          ) : (
            <Row justify="center">
              <Spin />
            </Row>
          )}
        </Col>
      </Row>
      {!isEmpty(questionary) && <AuthorizationFooter />}
    </>
  );
}
