import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Modal, notification } from 'antd';

import { BreederRoutes } from 'shared/types/routes';
import { RootState } from 'store/rootState';
import { testId } from 'testsUtilities/dataTestId';
import { requestHasErrors } from 'utilities/http/http';
import apiPuppy from 'utilities/http/api/apiPuppy';
import inventoryStatus from 'utilities/status/inventoryStatus';
import { InventoryStatusId } from 'utilities/const';

import './UnlistedRelistedPuppy.scss';

function UnlistedRelistedPuppy(): ReactElement {
  const puppy = useSelector((state: RootState) => state.puppy);
  const history = useHistory();
  const isSoldByBreeder = inventoryStatus.isSoldByBreeder(puppy);
  const isPendingSale = inventoryStatus.isPendingSale(puppy);
  const actionType = isSoldByBreeder ? 'relisted' : 'unlisted';
  const labelText = isSoldByBreeder ? 'relist' : 'unlist';
  const btnClassModifier = `unlisted-relisted-btn unlisted-relisted-btn--${actionType}`;

  const handleUnlistedRelisted = async () => {
    const puppyId = puppy.id as number;
    const inventoryStatus = isSoldByBreeder
      ? InventoryStatusId.Available
      : InventoryStatusId.SoldByBreeder;

    const resp = await apiPuppy.putPuppyInventoryStatus(
      puppyId,
      inventoryStatus,
    );

    if (requestHasErrors(resp)) {
      notification.error({
        key: 'unlisted-relisted-notification',
        message: `Error ${labelText}ing puppy`,
      });
      return;
    }

    notification.success({
      key: 'unlisted-relisted-notification',
      message: `Successfully puppy ${actionType}`,
    });

    history.push(BreederRoutes.MyListings);
  };

  const handleModalConfirm = () => {
    const title = `Are you sure you want to ${labelText} this puppy?`;

    const content = isSoldByBreeder ? (
      <p>The listing will be available in PuppySpot site again.</p>
    ) : (
      <>
        <p>You can relist this puppy within the next 48 hours by:</p>
        <ul className="unlisted-relisted-modal__list">
          <li>Going to the related litter details page</li>
          <li>
            Under the Puppies in this litter, search for the unlisted puppy
          </li>
          <li>Click the puppy name to go to the puppy details</li>
          <li>There you’ll find the “Relist” button</li>
        </ul>
      </>
    );

    Modal.confirm({
      className: `unlisted-relisted-modal unlisted-relisted-modal--${actionType}`,
      autoFocusButton: null,
      title,
      content,
      okText: 'Yes',
      onOk: handleUnlistedRelisted,
      cancelText: 'Discard',
      cancelButtonProps: { type: 'default' },
    });
  };

  return (
    <Button
      className={btnClassModifier}
      type="link"
      disabled={isPendingSale}
      onClick={handleModalConfirm}
      data-testid={testId.unlistedRelistedPuppyBtn}
    >
      {labelText} this puppy
    </Button>
  );
}

export default UnlistedRelistedPuppy;
