import {
  NewPuppyAction,
  NewPuppyState,
  SET_NEW_PUPPY,
} from 'store/newPuppy/types';

export function setNewPuppy(newPuppy: NewPuppyState): NewPuppyAction {
  return {
    type: SET_NEW_PUPPY,
    newPuppy: newPuppy,
  };
}
