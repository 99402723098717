import React, { ReactElement } from 'react';
import { Button, Typography, notification } from 'antd';

import { Listing } from 'shared/types/puppy';
import { getListingAskingPrice } from 'utilities/puppy/getPuppyUtils/getPuppyUtils';
import { isListingLocked } from 'utilities/listing';

interface Props {
  listing: Listing;
  updatePrice?: boolean;
  openModal?: (listing: Listing) => void;
}

function ColumnAskingPrice({
  listing,
  updatePrice = false,
  openModal,
}: Props): ReactElement {
  const price = getListingAskingPrice(listing);

  return !updatePrice ? (
    <Typography.Text>{price}</Typography.Text>
  ) : (
    <>
      <Button
        onClick={() => {
          const isLocked = isListingLocked(listing);
          if (isLocked) {
            notification.warning({
              message: `Warning!`,
              description: `The listing id ${listing?.id} is locked`,
            });
            return;
          }
          if (openModal) {
            openModal(listing);
          }
        }}
        data-testid={`modal-change-price`}
        className="price-button"
      >
        <Typography.Text>{price}</Typography.Text>
      </Button>
    </>
  );
}

export default ColumnAskingPrice;
