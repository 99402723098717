import {
  AddMoreListings,
  SetListings,
  SearchListing,
  SearchLoading,
  UpdatePuppyWeightAndRegistration,
  SetQueryObject,
  SetModalListing,
} from 'store/myListings/actions';
import { Nullable } from 'shared/types/general';
import { Listing, QueryObject } from 'shared/types/puppy';

export const SET_LISTINGS = 'SET_LISTINGS';
export const SET_MODAL_LISTING = 'SET_MODAL_LISTING';
export const ADD_MORE_LISTINGS = 'ADD_MORE_LISTINGS';
export const SEARCH_LISTING = 'SEARCH_LISTING';
export const CLEAN_SEARCH = 'CLEAN_SEARCH';
export const SEARCH_LOADING = 'SEARCH_LOADING';
export const UPDATE_PUPPY_WEIGHT_REGISTRATION =
  'UPDATE_PUPPY_WEIGHT_REGISTRATION';

export const SET_QUERY_OBJECT = 'SET_QUERY_OBJECT';

export interface MyListings {
  pending?: Nullable<Listing[]>;
  approved?: Nullable<Listing[]>;
  sold?: Nullable<Listing[]>;
  delivered?: Nullable<Listing[]>;
  hidden?: Nullable<Listing[]>;
  pastDue?: Nullable<Listing[]>;
  upcoming?: Nullable<Listing[]>;
  modalListing?: Nullable<Listing>;
}

export interface MyListingsState extends MyListings {
  searchResult?: Nullable<MyListings>;
  searchCriteria?: string | undefined;
  searchLoading?: boolean;
  searchError?: Nullable<string>;
  queryObject: {
    pending: QueryObject;
  };
}

export type ListingsActionTypes =
  | SetListings
  | AddMoreListings
  | SearchListing
  | SearchLoading
  | UpdatePuppyWeightAndRegistration
  | SetQueryObject
  | SetModalListing;
