import React, { ReactElement, useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';

import { getBreedMarkings } from 'utilities/breed';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import { MarkingContent } from 'utilities/staticContent/types';
import { EmptyFieldValidationHandler } from '../fieldValidationHandler';

interface Props {
  disabled?: boolean;
  form: FormInstance;
}

export function FieldMarkings(props: Props): ReactElement {
  const { disabled, form } = props;
  const staticContent = staticContentStorage.getContent();
  const staticMarkings = staticContent.markings as MarkingContent[];
  staticMarkings?.sort((a, b) => a.name.localeCompare(b.name));
  const [loading, setLoading] = useState(true);
  const [markings, setMarkings] = useState<MarkingContent[]>(staticMarkings);
  const breedId = form.getFieldValue('breedId');

  useEffect(() => {
    const handleGetBreedMarkings = async () => {
      const markingsByBreed = await getBreedMarkings(breedId);
      setMarkings(markingsByBreed);
      setLoading(false);
    };

    breedId && handleGetBreedMarkings();
  }, [breedId]);

  return (
    <Form.Item
      name="markingId"
      label="Markings"
      rules={[EmptyFieldValidationHandler('list')]}
    >
      <Select
        disabled={loading || disabled}
        loading={loading}
        placeholder="Select one..."
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {markings?.map((marking) => (
          <Select.Option key={marking.id} value={marking.id}>
            {marking.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}
