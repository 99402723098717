import React, { ReactElement } from 'react';
import { Button } from 'antd';
import { DeleteOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload';

import { Icon, setFileExtensionIcon } from 'shared/icon/Icon';
import { Media, MediaUploadTypes, Video } from 'shared/types';
import media, { mediaConfig } from 'utilities/media/media';
import date from 'utilities/time/date';

import './MediaUploader.scss';

interface Props {
  mediaData: Media | Video;
  onClickDelete?: (() => void) | undefined;
  onClickPreviewMedia?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  setUploadVideo?: (uploadVideo: UploadChangeParam) => void;
  disabled?: boolean;
  type?: MediaUploadTypes;
  index?: number;
  testId?: string;
  cloudflareVideoThumb?: string | undefined;
}

export function MediaUploaderItem(props: Props): ReactElement {
  const {
    mediaData,
    onClickDelete,
    onClickPreviewMedia,
    disabled,
    type,
    index,
    testId,
    cloudflareVideoThumb,
  } = props;

  const labelPhoto = !mediaData?.uploadedAt
    ? 'Uploaded: Unknown'
    : `Uploaded: ${date.toMMDDYYYY(mediaData?.uploadedAt)}`;
  const label = typeof mediaData.id === 'string' ? mediaData.name : labelPhoto;
  const isVideo = type === 'video';
  const mediaUploaderVideoThumb = mediaData.uid
    ? { backgroundColor: 'black' }
    : {
        backgroundImage: `url("${
          cloudflareVideoThumb ?? (mediaData as Video).thumbnail?.url
        }")`,
      };

  const openFile = () =>
    type === 'file' && window.open((mediaData as Media).dataUrl, '_blank');

  const renderRemoveMediaButton = () => {
    if (disabled || isVideo) return;

    return (
      <Button
        type="link"
        data-testid={`MediaUploader-delete-${testId}`}
        className="MediaUploader__tools-delete"
        onClick={onClickDelete}
      >
        <DeleteOutlined />
      </Button>
    );
  };

  const renderCardLabel = () => {
    switch (type) {
      case 'photo':
        return label;
      case 'video':
        const updateDate =
          (mediaData as Video)?.created || mediaData.uploadedAt;

        if (updateDate) {
          return (
            <div>
              <span data-testid="media-uploader-video-item-text">
                {mediaData?.name}
              </span>
              <span>Uploaded: {date.toMMDDYYYY(updateDate)}</span>
            </div>
          );
        } else {
          return mediaData?.name;
        }
      default:
        return mediaData?.name;
    }
  };

  return (
    <div>
      <section
        data-testid={`media-uploader-item-${type}`}
        className={`MediaUploader MediaUploader--item MediaUploader--item--${type}`}
      >
        {index && <div className="MediaUploader__indicator">{index}</div>}
        <div
          className="MediaUploader__content"
          role="button"
          onClick={openFile}
        >
          {type === 'file' ? (
            <div
              data-testid="media-uploader-content-thumb"
              className="MediaUploader__content-thumb"
            >
              <Icon name={setFileExtensionIcon(mediaData.name)} />
            </div>
          ) : type === 'video' ? (
            <div
              data-testid="media-uploader-content-thumb"
              className="MediaUploader__content-thumb"
              style={mediaUploaderVideoThumb}
              role="button"
              onClick={onClickPreviewMedia}
            >
              <PlayCircleOutlined />
            </div>
          ) : (
            <div
              data-testid="media-uploader-content-thumb"
              className="MediaUploader__content-thumb"
              style={{
                backgroundImage: `url(${media.getImage(
                  mediaData,
                  mediaConfig.photoSize.small,
                )})`,
              }}
            />
          )}
          <div
            data-testid="media-uploader-content-text"
            className="MediaUploader__content-text"
          >
            {renderCardLabel()}
          </div>
        </div>

        <div className="MediaUploader__tools">{renderRemoveMediaButton()}</div>
      </section>
    </div>
  );
}
