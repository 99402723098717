import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

import { RootState } from 'store/rootState';
import { getLitter } from 'store/litter/actions';
import { getParent } from 'store/parents/actions';
import { SetState } from 'shared/types/general';
import date from 'utilities/time/date';
import { useLittersListingStarter } from './useLittersListingStarter';

import './CreateListingStarter.scss';

interface Props {
  setStartNew: SetState<boolean>;
}

export default function CreateListingStarter(
  props: Props,
): ReactElement | null {
  const { setStartNew } = props;
  const { litter } = useSelector((state: RootState) => state);
  const useLitters = useLittersListingStarter();
  const [litterSelected, setLitterSelected] = useState<'new' | number>('new');
  const [modalVisible, setModalVisible] = useState(true);
  const [listingLoading, setListingLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleOnOk = async () => {
    if (litterSelected !== 'new') {
      if (isEmpty(useLitters.littersOptions)) return;
      setListingLoading(true);
      const litter = useLitters.littersOptions.find(
        (litter) => litter.id === litterSelected,
      );
      await dispatch(getParent(litter?.dam?.id as number));
      await dispatch(getParent(litter?.sire?.id as number));
      await dispatch(getLitter(litter?.id));
      setListingLoading(false);
      setModalVisible(false);
      setLitterSelected('new');
    } else {
      setStartNew(true);
      setModalVisible(false);
    }
    document.body.removeAttribute('style');
  };
  const hideModal = () => {
    setModalVisible(false);
    document.body.removeAttribute('style');
    history.push('/');
  };

  useEffect(() => {
    isEmpty(litter) && setModalVisible(true);
  }, [litter]);

  if (!isEmpty(litter)) {
    return null;
  }

  return (
    <Modal
      className="CreateListingStarter__modal"
      closable={false}
      maskClosable={false}
      visible={modalVisible}
      onOk={handleOnOk}
      width="416px"
      onCancel={hideModal}
      okText="START"
      cancelText="CANCEL"
      cancelButtonProps={{ type: 'default' }}
      confirmLoading={listingLoading}
      keyboard={false}
    >
      <div data-testid="create-listing-starter-modal">
        <div className="CreateListingStarter__modal-header">
          <ExclamationCircleOutlined />
          <span className="CreateListingStarterContent__modal-title">
            Add Puppy from new Litter or existing Litter
          </span>
        </div>
        <div className="CreateListingStarter__modal-content">
          <Select
            data-testid="create-listing-starter-select"
            value={litterSelected}
            loading={useLitters.isLoading}
            disabled={useLitters.isLoading}
            style={{ width: '100%' }}
            onChange={(value) => setLitterSelected(value)}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Select.Option
              data-testid="create-listing-starter-select-option-create-new"
              value="new"
            >
              Create New
            </Select.Option>
            {useLitters.littersOptions?.map((litter) => {
              const litterOptionLabel = `${date.toMMDDYYYY(
                litter.birthDate,
              )} – ${litter.dam?.name} & ${litter.sire?.name}`;
              return (
                <Select.Option
                  data-testid="create-listing-starter-select-option"
                  key={litter.id as number}
                  value={litter.id as number}
                >
                  {litterOptionLabel}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      </div>
    </Modal>
  );
}
