import { Nullable } from 'shared/types/general';
import { isEmpty } from 'lodash';
import { getContentDataById } from 'utilities/staticContent/content';

export const getLitterPottyTrainingTypes = (
  pottyTrainingTypes: Nullable<number[]>,
) => {
  if (!pottyTrainingTypes || isEmpty(pottyTrainingTypes))
    return `Not potty trained`;
  const trainingNames = pottyTrainingTypes?.map(
    (trainingId) => getContentDataById('pottyTrainingTypes', trainingId).name,
  );

  return trainingNames.join(', ');
};
