import React, { ReactElement } from 'react';
import { Typography } from 'antd';
import { isEmpty } from 'lodash';

import { Listing } from 'shared/types/puppy';
import date from 'utilities/time/date';

interface Props {
  listing: Listing;
}

function ColumnDeliveredDate(props: Props): ReactElement {
  const {
    listing: { puppy },
  } = props;

  return (
    <Typography.Text>
      {!isEmpty(puppy?.deliveredAt)
        ? date.toMMDDYYYY(puppy?.deliveredAt)
        : ' --/--/----'}
    </Typography.Text>
  );
}

export default ColumnDeliveredDate;
