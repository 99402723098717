import { Dispatch } from 'redux';
import { notification } from 'antd';

import { Listing } from 'shared/types/puppy';
import {
  setMyListingsApproved,
  setMyListingsApprovedFilters,
  setMyListingsApprovedLoading,
  setMyListingsApprovedPagination,
  setMyListingsApprovedSorter,
} from 'store/myListingsApproved/actions';
import {
  initialStateMyListingsApprovedFilters,
  initialStateMyListingsApprovedPagination,
  initialStateMyListingsApprovedSorter,
} from 'store/myListingsApproved/myListingsApprovedReducer';
import { RootState } from 'store/rootState';
import apiListing from 'utilities/http/api/apiListing';
import { BreederDashboardStatus } from 'utilities/const';
import { requestHasErrors } from 'utilities/http/http';

export function fetchMyListingsApproved() {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setMyListingsApprovedLoading());

    const {
      listings,
      pagination,
      sorter,
      filters,
    } = getState().myListingsApproved;

    const myListingsResp = await apiListing.getMyListings(
      BreederDashboardStatus.Approved,
      pagination,
      sorter,
      filters,
    );

    if (requestHasErrors(myListingsResp)) {
      if (!filters.isFiltering) {
        notification.error({ message: `Error fetching my listings approved` });
      }

      dispatch(setMyListingsApproved([]));
      dispatch(
        setMyListingsApprovedPagination({
          ...pagination,
          total: 0,
        }),
      );
      return;
    }

    const listingsResp = myListingsResp?.json.listings as Listing[];
    const paginationResp = myListingsResp?.json.paginationMeta;

    if (pagination.loadMore) {
      dispatch(setMyListingsApproved([...listings, ...listingsResp]));
    } else {
      dispatch(setMyListingsApproved(listingsResp));
    }

    dispatch(
      setMyListingsApprovedPagination({
        ...pagination,
        total: paginationResp.total,
      }),
    );
  };
}

export function setMyListingsApprovedInitialState() {
  return (dispatch: Dispatch) => {
    dispatch(setMyListingsApproved([]));
    dispatch(
      setMyListingsApprovedPagination(initialStateMyListingsApprovedPagination),
    );
    dispatch(
      setMyListingsApprovedFilters(initialStateMyListingsApprovedFilters),
    );
    dispatch(setMyListingsApprovedSorter(initialStateMyListingsApprovedSorter));
  };
}
