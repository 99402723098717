import { OfaCertifiedBodyParts } from 'utilities/staticContent/types';
import { Breed, Id, Nullable } from 'shared/types/general';
import { Media } from 'shared/types/media';
import { InventoryStatusIdTypes } from 'shared/types/statuses';
import { Litter } from './litter';

export type Gender = 'dam' | 'sire';
export type MyParentsTypes = 'dams' | 'sires';
export type GenderId = 1 | 2;
export enum Genders {
  sire = 1,
  dam = 2,
}

export interface Parent {
  id: Id;
  breed: Partial<Breed>;
  breedId: number;
  breederId: Id;
  name: string;
  genderId: GenderId;
  gender: Gender;
  birthDate: string;
  description: string;
  activities: string[];
  weightRangeId: number;
  isActive: number;
  registryId: number;
  registrationNumber: string | number;
  socializations: number[];
  media: Media[];
  isChampion: number;
  hasBeenShown: number;
  hasPendingEdits: boolean;
  hasChampionLineage: number;
  statusReasonId: number;
  colorId: number;
  litters: Partial<Litter>[];
  ofaCertifiedBodyParts: OfaCertifiedBodyParts[];
  inventoryStatusId?: InventoryStatusIdTypes;
  lockedByUserId: Nullable<number>;
  soldListings: number;
  liveListings: Nullable<number>;
  pendingEdit: Partial<Parent>;
  minimumDateAllowedToAddPuppies?: Nullable<string>;
}

export interface ParentFormFields {
  activities?: number[];
  birthDate: string;
  breedId: number;
  colorId: number;
  description?: string;
  genderId: GenderId;
  hasBeenShown?: number;
  isChampion: number;
  name: string;
  ofaCertifiedBodyParts?: Partial<OfaCertifiedBodyParts>[];
  pedigreeDoc?: Media;
  pedigreeDocHiddenId: Nullable<number>;
  mediaPhoto: Media;
  mediaPhotoHiddenId: Nullable<number>;
  registrationNumber?: string | number;
  registryId: number;
  weightRangeId: number;
}

export type ParentSearchBy = 'name_contains';

export interface ParentsSearchParams {
  searchBy: ParentSearchBy;
  searchByValue: string;
}
