import { Address } from 'shared/types/geolocation';
import { Breed, Id, Phone } from 'shared/types/general';
import { User } from 'shared/types/user';
import { BreederDashboardStatusTypes } from 'shared/types/statuses';

export interface Airport {
  id: number;
  code: string;
  name: string;
  pivot: {
    updatedAt: string;
  };
}

export interface Veterinarian {
  breederId?: Id;
  veterinarianName: string;
  veterinarianPhone: string;
  displayOrder: number;
}

export interface Breeder {
  id: number;
  userFullName: string;
  accountRep?: User;
  accountRepUserId?: Id;
  airports?: Airport[];
  airportIds?: number[];
  awardsReceived?: string;
  breederStatusId?: number;
  breederTierId?: number;
  breedingPracticesDescription?: string;
  companyName?: string;
  information?: string;
  kennelName?: string;
  officialIdentificationNumber?: string;
  primaryOccupation?: string;
  psBooksFlights?: number;
  recruiterUserId?: number;
  showsDogs?: number;
  totalChampionsShown?: number;
  transporterCompanyId?: number;
  usdaLicenseStatusId?: number;
  usdaNumber?: string;
  websiteUrl?: string;
  breederVeterinarians?: Veterinarian[];
  stateLicenseNumber?: string;
  users?: Partial<User>[];
  breeds?: Partial<Breed>[];
  addresses?: Partial<Address>[];
  phones?: Partial<Phone>[];
  phone?: string;
  phone2?: string;
}

export enum BreederTierId {
  general = 1,
  spotOn = 2,
  commitmentShortTerm = 3,
}

export type BreederListingArgs = {
  breederDashboardStatus: BreederDashboardStatusTypes;
  page?: number;
  sortBy?: string;
  sortDirection?: string;
  pageSize?: number;
};
