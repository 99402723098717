import React, { ReactElement } from 'react';

import { Icon } from 'shared/icon/Icon';
import { PuppyDetailTileStatus } from 'shared/types/puppy';
import { CollapsableStatusTypes } from 'shared/types/statuses';

interface CollapsablePanelHeaderProps {
  title: string;
  icon: string;
  type?: CollapsableStatusTypes | PuppyDetailTileStatus;
}

export function CollapsablePanelHeader(
  props: CollapsablePanelHeaderProps,
): ReactElement {
  const { title, icon, type } = props;
  return (
    <section className="CollapsablePanelHeader">
      {type && type !== 'disabled' && (
        <Icon
          className={`CollapsablePanelHeader__statusIcon CollapsablePanelHeader__statusIcon--${type}`}
          name={type}
        />
      )}
      <Icon name={icon} />
      <span className="CollapsablePanelHeader__title">{title}</span>
    </section>
  );
}
