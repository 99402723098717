import React, { ReactElement } from 'react';
import PuppyInventoryItem from './PuppyInventoryItem';
import { WidgetContainer } from 'pages/dashboard/widgetContainer/WidgetContainer';
import { PuppyInventoryProps } from 'pages/dashboard/Dashboard';
import './PuppyInventory.scss';
import { BreederDashboardStatus } from 'utilities/const';

export interface Props {
  statusCounts?: PuppyInventoryProps;
}

export default function PuppyInventory(props: Props): ReactElement | null {
  const { statusCounts } = props;
  if (!statusCounts) {
    return null;
  }
  return (
    <WidgetContainer
      title="Inventory Status"
      goto="my-listings"
      className="PuppyInventory"
    >
      <div className="PuppyInventory__content">
        <PuppyInventoryItem
          status={BreederDashboardStatus.Pending}
          numberStatus={statusCounts.pending}
          color="yellow"
        />
        <PuppyInventoryItem
          status={BreederDashboardStatus.Approved}
          numberStatus={statusCounts.approved}
          color="green"
        />
        <PuppyInventoryItem
          status={BreederDashboardStatus.Sold}
          numberStatus={statusCounts.sold}
          color="blue"
        />
        <PuppyInventoryItem
          status={BreederDashboardStatus.Delivered}
          numberStatus={statusCounts.delivered}
          color="orange"
        />
        <PuppyInventoryItem
          status={BreederDashboardStatus.Hidden}
          numberStatus={statusCounts.hidden}
          color="gray"
        />
      </div>
    </WidgetContainer>
  );
}
