import { Dispatch } from 'redux';

import { Puppy } from 'shared/types/puppy';
import {
  MyPuppyListingsColumns,
  MyUpcomingColumns,
} from 'shared/types/general';
import { MyListingsFilters } from 'shared/types/myListings';
import {
  setMyListingsPendingFilters,
  updateMyListingsPendingPuppyRegistrationsWeightDueDates,
} from 'store/myListingsPending/actions';
import {
  setMyListingsApprovedFilters,
  updateMyListingsApprovedPuppyRegistrationsWeightDueDates,
} from 'store/myListingsApproved/actions';
import {
  setMyListingsSoldFilters,
  updateMyListingsSoldPuppyRegistrationsWeightDueDates,
} from 'store/myListingsSold/actions';
import {
  setMyListingsDeliveredFilters,
  updateMyListingsDeliveredPuppyRegistrationsWeightDueDates,
} from 'store/myListingsDelivered/actions';
import {
  setMyListingsHiddenFilters,
  updateMyListingsHiddenPuppyRegistrationsWeightDueDates,
} from 'store/myListingsHidden/actions';
import { updatePuppyWeightAndRegistration } from 'store/myListings/actions';
import { BreederDashboardStatus } from 'utilities/const';

export const setMyListingsSearch = (filters: MyListingsFilters) => {
  return (dispatch: Dispatch) => {
    dispatch(setMyListingsPendingFilters(filters));
    dispatch(setMyListingsApprovedFilters(filters));
    dispatch(setMyListingsSoldFilters(filters));
    dispatch(setMyListingsDeliveredFilters(filters));
    dispatch(setMyListingsHiddenFilters(filters));
  };
};

export const setUpdateMyListingsPuppyRegistrationsWeightDueDates = (
  puppy: Puppy,
  columnName: MyPuppyListingsColumns | MyUpcomingColumns,
) => {
  return async (dispatch: Dispatch) => {
    switch (columnName) {
      case BreederDashboardStatus.Pending: {
        dispatch(
          updateMyListingsPendingPuppyRegistrationsWeightDueDates(puppy),
        );
        break;
      }
      case BreederDashboardStatus.Approved: {
        dispatch(
          updateMyListingsApprovedPuppyRegistrationsWeightDueDates(puppy),
        );
        break;
      }
      case BreederDashboardStatus.Sold: {
        dispatch(updateMyListingsSoldPuppyRegistrationsWeightDueDates(puppy));
        break;
      }
      case BreederDashboardStatus.Delivered: {
        dispatch(
          updateMyListingsDeliveredPuppyRegistrationsWeightDueDates(puppy),
        );
        break;
      }
      case BreederDashboardStatus.Hidden: {
        dispatch(updateMyListingsHiddenPuppyRegistrationsWeightDueDates(puppy));
        break;
      }
      case 'pastDue':
      case 'upcoming': {
        dispatch(updatePuppyWeightAndRegistration(puppy, columnName));
      }
    }
  };
};
