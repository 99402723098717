import { Listing, Puppy } from 'shared/types/puppy';
import {
  MyListingsFilters,
  MyListingsPagination,
  MyListingsSorter,
} from 'shared/types/myListings';

export const SET_MY_LISTINGS_PENDING_LOADING =
  'SET_MY_LISTINGS_PENDING_LOADING';
export const SET_MY_LISTINGS_PENDING = 'SET_MY_LISTINGS_PENDING';
export const SET_MY_LISTINGS_PENDING_FILTERS =
  'SET_MY_LISTINGS_PENDING_FILTERS';
export const SET_MY_LISTINGS_PENDING_SORTER = 'SET_MY_LISTINGS_PENDING_SORTER';
export const SET_MY_LISTINGS_PENDING_PAGINATION =
  'SET_MY_LISTINGS_PENDING_PAGINATION';
export const UPDATE_MY_LISTINGS_PENDING_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES =
  'UPDATE_MY_LISTINGS_PENDING_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES';

export interface MyListingsMainState {
  loading: boolean;
  listings: Listing[];
  pagination: MyListingsPagination;
  filters: MyListingsFilters;
  sorter: MyListingsSorter;
}

interface SetMyListingsPendingLoading {
  type: typeof SET_MY_LISTINGS_PENDING_LOADING;
}

interface SetMyListingsPending {
  type: typeof SET_MY_LISTINGS_PENDING;
  listings: Listing[];
}

interface SetMyListingsPendingFilters {
  type: typeof SET_MY_LISTINGS_PENDING_FILTERS;
  filters: MyListingsFilters;
}

interface SetMyListingsPendingSorter {
  type: typeof SET_MY_LISTINGS_PENDING_SORTER;
  sorter: MyListingsSorter;
}

interface SetMyListingsPendingPagination {
  type: typeof SET_MY_LISTINGS_PENDING_PAGINATION;
  pagination: MyListingsPagination;
}

interface UpdateMyListingsPendingPuppyRegistrationsWeightDueDates {
  type: typeof UPDATE_MY_LISTINGS_PENDING_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES;
  puppy: Puppy;
}

export type MyListingsPendingActions =
  | SetMyListingsPendingLoading
  | SetMyListingsPending
  | SetMyListingsPendingFilters
  | SetMyListingsPendingSorter
  | SetMyListingsPendingPagination
  | UpdateMyListingsPendingPuppyRegistrationsWeightDueDates;
