import { FormInstance } from 'antd/lib/form';
import { isEmpty, isNil, omit, omitBy, orderBy } from 'lodash';
import { FieldData } from 'rc-field-form/lib/interface';

import showNotificationError from 'shared/notifications/showNotificationError';
import { NoteTypes, Nullable, SetState } from 'shared/types/general';
import { Litter } from 'shared/types/litter';
import { Media } from 'shared/types/media';
import { Puppy, PuppyFormFields, PuppyPhotos } from 'shared/types/puppy';
import { getBreedId } from 'utilities/entities/puppy';
import apiBreed from 'utilities/http/api/apiBreed';
import apiListing from 'utilities/http/api/apiListing';
import apiMedia from 'utilities/http/api/apiMedia';
import apiPuppy from 'utilities/http/api/apiPuppy';
import { isListingLocked, isListingStatusHidden } from 'utilities/listing';
import {
  getExistingVideo,
  isImage,
  isMediaDeleted,
  isMediaHidden,
  isMediaPrivate,
  isMediaPublic,
} from 'utilities/media/mediaCheckers';
import notes from 'utilities/notes/notes';
import puppyTravelStatus from 'utilities/puppy/puppyTravelState';
import inventoryStatus from 'utilities/status/inventoryStatus';
import date from 'utilities/time/date';
import { isUserBreederTierGeneral } from 'utilities/user/userBreederCheckers/userBreederCheckers';

const puppyFormHelpers = {
  payloadBuilder: (puppyForm: PuppyFormFields, puppy?: Partial<Puppy>) => {
    let payload = puppyForm as Partial<Puppy>;

    if (puppyForm?.puppyWeightSameRange) {
      payload.lastWeighedAt = date.toYYYYMMDD(new Date());
    }

    if (puppy?.registrations?.[0]?.id) {
      payload.registrations = [
        {
          id: puppy.registrations[0].id,
          registryId: puppyForm.registryId,
          registrationNumber: puppyForm.registrationNumber?.toString() || '',
        },
      ];
    } else {
      payload.registrations = [
        {
          registryId: puppyForm.registryId,
          registrationNumber: puppyForm.registrationNumber?.toString(),
        },
      ];
    }

    if (
      !puppyForm?.listing?.hasDiscountOffer &&
      puppy?.listing?.discountOfferAmount &&
      isUserBreederTierGeneral()
    ) {
      payload.listing = {
        ...puppyForm?.listing,
        hasDiscountOffer: false,
        discountOfferAmount: null,
        discountOfferReason: null,
      };
    }

    if (!isUserBreederTierGeneral()) {
      payload.listing = {
        cost: puppyForm?.listing?.cost ? puppyForm?.listing?.cost : 0,
        hasDiscountOffer: puppyForm?.listing?.hasDiscountOffer ?? false,
        discountOfferAmount: puppyForm?.listing?.discountOfferAmount,
        discountOfferReason: puppyForm?.listing?.discountOfferReason,
      };
    }

    if (
      puppyForm.microchipRfid === undefined ||
      puppyForm.microchipRfid === null
    ) {
      payload.microchipRfid = '';
    }

    payload = omitBy(payload, isNil);
    payload = omit(payload, [
      'breedId',
      'noteFromBreeder',
      'puppyPhotos',
      'registrationNumber',
      'registryId',
    ]);

    return payload;
  },

  setFormFields: (
    puppy: Partial<Puppy>,
    litter: Litter,
    form: FormInstance,
    setIsFormFieldsLoaded: SetState<boolean>,
  ) => {
    const puppyPendingEdits = puppy.pendingEdit;
    const sortedNotes = notes.sortByOldest(puppy.listing?.notes);
    const medicalDetails =
      puppyPendingEdits?.medicalDetails ?? puppy.medicalDetails;
    const registrations =
      puppyPendingEdits?.registrations ?? puppy.registrations;

    form.setFieldsValue({
      litterId: puppyPendingEdits?.litterId || puppy.litter?.id || litter.id,
      breedId: getBreedId(puppy) || litter.breedId,
      breederName: puppyPendingEdits?.breederName || puppy.breederName,
      colorId: puppyPendingEdits?.colorId || puppy.colorId,
      genderId: puppyPendingEdits?.genderId || puppy.genderId,
      varietyId: puppyPendingEdits?.varietyId || puppy.varietyId,
      markingId: puppyPendingEdits?.markingId || puppy.markingId,
      weightRangeId: puppyPendingEdits?.weightRangeId || puppy.weightRangeId,
      lastWeighedAt: puppy.lastWeighedAt,
      relativeSizeId: puppy.relativeSizeId,
      litterBirthDate: puppy.litter?.birthDate || litter.birthDate,
      dateAvailable: puppyPendingEdits?.dateAvailable || puppy.dateAvailable,
      listing: {
        cost: puppy.listing?.cost,
        discountOfferAmount: puppy.listing?.discountOfferAmount,
        discountOfferReason: puppy.listing?.discountOfferReason?.toString(),
        hasDiscountOffer: puppy.listing?.hasDiscountOffer ?? true,
      },
      food: puppy.litter?.food || litter?.food,
      specialFeedingInstructions: puppy.specialFeedingInstructions?.toString(),
      registryId: registrations && registrations[0].registryId,
      registrationNumber: registrations && registrations[0].registrationNumber,
      medicalDetails: medicalDetails,
      microchipRfid: puppyFormHelpers.getPuppyMicrochip(puppy),
      healthIssuesDisclaimer:
        puppyPendingEdits?.healthIssuesDisclaimer?.toString() ||
        puppy.healthIssuesDisclaimer?.toString(),
      description:
        puppyPendingEdits?.description?.toString() ||
        puppy.description?.toString(),
      noteFromBreeder: sortedNotes
        ?.find((note) => note?.noteTypeId === NoteTypes.noteFromBreeder.id)
        ?.message?.toString(),
      puppyPhotos: [
        ...((puppy.photos?.coverPhotos as Media[]) || ''),
        ...((puppy.photos?.additionalPhotos as Media[]) || ''),
      ],
      puppyVideo: getExistingVideo(puppy.listing?.media)
        ? [getExistingVideo(puppy.listing?.media)]
        : [],
      puppyWeightSameRange: false,
    });
    setIsFormFieldsLoaded(true);
  },

  getPuppyMicrochip: (puppy: Puppy | Partial<Puppy>) => {
    if (
      puppy?.pendingEdit?.microchipRfid &&
      puppy?.pendingEdit?.microchipRfid !== 'undefined'
    )
      return puppy.pendingEdit.microchipRfid.toString();

    if (puppy?.microchipRfid !== 'undefined')
      return puppy.microchipRfid?.toString();

    return '';
  },

  setDisabledFields: (
    fieldName: string,
    puppy: Partial<Puppy>,
    isEditing: boolean,
  ): boolean => {
    const { breederDashboardStatus, listing } = puppy;

    const handleDisabledForContract = () => {
      const disableFields = ['listingCost'];

      return (
        disableFields.includes(fieldName) && !!listing?.breederContractTermId
      );
    };

    const handleDisabledModeForApprove = (): boolean => {
      const disableFields = ['breederName'];

      return disableFields.includes(fieldName);
    };

    const handleDisabledModeForSold = (): boolean => {
      const enabledFields = [
        'weightRange',
        'photos',
        'video',
        'microchipRfid',
        'registry',
      ];
      return !enabledFields.includes(fieldName);
    };

    const handleDisabledModeForListingHidden = (): boolean => {
      return handleDisabledModeForApprove();
    };

    switch (breederDashboardStatus) {
      case 'pending':
        return handleDisabledForContract() || false;
      case 'approved':
        return handleDisabledForContract() || handleDisabledModeForApprove();
      case 'sold':
        return handleDisabledModeForSold();
      case 'delivered':
        return true;
      default:
        if (!isEditing) {
          return false;
        }

        if (handleDisabledForContract()) return true;

        if (
          puppyTravelStatus(puppy).isOrderCanceled() ||
          (puppy?.listing && isListingLocked(puppy.listing)) ||
          inventoryStatus.isSoldByBreeder(puppy)
        ) {
          return true;
        }

        if (isListingStatusHidden(listing)) {
          return handleDisabledModeForListingHidden();
        }

        return true;
    }
  },

  onFieldsChange: (
    changedFields: FieldData[],
    form: FormInstance,
    setIsSameName?: SetState<boolean>,
  ) => {
    const fieldName = changedFields[0]?.name;
    if (typeof fieldName !== 'object') return;

    if (fieldName[0] === 'breedId') {
      form.resetFields(['colorId']);
      form.resetFields(['requiresHearingTest']);
      form.validateFields(['medicalDetails']);
    }

    if (fieldName[0] === 'registryId') {
      form.validateFields(['registrationNumber']);
      form.resetFields(['registrationNumber']);
    }

    if (fieldName[0] === 'listing' && fieldName[1] === 'hasDiscountOffer') {
      form.resetFields([
        ['listing', 'discountOfferAmount'],
        ['listing', 'discountOfferReason'],
      ]);
    }

    if (setIsSameName && fieldName[0] === 'breederName') {
      setIsSameName(false);
    }
  },

  disableSubmit: (form: FormInstance) => {
    return Boolean(form.getFieldsError().find((item) => item.errors[0]));
  },

  postPuppy: async (
    puppyPayload: Partial<Puppy>,
    setIsSameName?: SetState<boolean>,
  ) => {
    const resp = await apiPuppy.postPuppy(puppyPayload);
    const respError = resp?.json?.errors;
    if (!isEmpty(respError) || resp.status !== 200) {
      showNotificationError({
        message: `Create puppy error!`,
        error: respError,
      });

      if (
        setIsSameName &&
        respError.name[0] === 'Puppy name must be unique by breed'
      ) {
        setIsSameName(true);
      }
    }

    return resp?.json?.puppy;
  },

  postPuppyNote: async (listingId: number, message: string) => {
    const puppyNotePayload = {
      note: {
        message: message,
        noteTypeId: NoteTypes.noteFromBreeder.id,
      },
    };
    await apiListing.postPuppyNote(listingId, puppyNotePayload);
  },

  putPuppy: async (
    puppyId: number,
    puppyPayload: Partial<Puppy>,
    setIsSameName?: SetState<boolean>,
  ) => {
    const resp = await apiPuppy.putPuppy(puppyId, puppyPayload);
    const respError = resp?.json?.errors;
    if (!isEmpty(respError) || resp.status !== 200) {
      showNotificationError({
        message: `Edit puppy error!`,
        error: respError,
      });

      if (
        setIsSameName &&
        respError.name[0] === 'Puppy name must be unique by breed'
      ) {
        setIsSameName(true);
      }
    }

    return {
      puppyId: resp?.json?.puppy.id,
      listingId: resp?.json?.puppy.listing.id,
    };
  },

  putPuppyNote: async (noteId: number, message: string) => {
    await apiPuppy.putPuppyNote(noteId, message);
  },

  mapViewOrder: (fetchedPuppyMedia: Media[], localPuppy: Partial<Puppy>) => {
    const mapper = (localPhotos: Media[]) => {
      return (media: Media) => {
        const local = localPhotos.filter((p) => media.id === p.id);
        if (local?.length) {
          return { ...media, viewOrder: local[0].viewOrder };
        }
        return media;
      };
    };

    return fetchedPuppyMedia
      .map(mapper(localPuppy.photos?.coverPhotos || []))
      .map(mapper(localPuppy.photos?.additionalPhotos || []));
  },

  puppyPhotosSetViewOrder: async (
    puppyData: Partial<Puppy>,
    listingId?: Nullable<number>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deletedImages?: any[],
  ) => {
    const puppy = puppyData;

    const coverPhotosIds = puppy.photos
      ? puppy.photos.coverPhotos.map((p) => p.id)
      : [];
    const additionalPhotosIds = puppy.photos
      ? puppy.photos?.additionalPhotos.map((p) => p.id)
      : [];
    const hiddenPhotosIds = puppy.photos?.hiddenPhotos
      ? // eslint-disable-next-line
        puppy.photos.hiddenPhotos.map((p) => {
          if (!isMediaDeleted(p) && typeof p.id === 'number') {
            return p.id;
          }
        })
      : [];
    const puppyMediaOtherIds =
      puppy.listing && puppy.listing.media
        ? puppy.listing.media
            .filter((m) => !isImage(m) && typeof m.id === 'number')
            .map((m) => m.id)
        : [];
    // eslint-disable-next-line
    const hiddenImages = hiddenPhotosIds.filter((id) => {
      if (!deletedImages?.includes(id)) {
        return id;
      }
    });

    const allMedia = [
      ...coverPhotosIds,
      ...new Array(7 - coverPhotosIds.length).fill(null),
      ...additionalPhotosIds,
      ...hiddenImages,
      ...puppyMediaOtherIds,
    ].filter(Boolean);
    const viewOrder = allMedia.filter(
      (item, index) => allMedia.indexOf(item) === index,
    );

    const resp = await apiPuppy.putPuppyPhotosViewOrder(
      listingId || (puppy.listing?.id as number),
      viewOrder,
    );

    const respError = resp?.json?.errors;
    if (!isEmpty(respError) || resp.status !== 200) {
      showNotificationError({
        error: `Media set view order error!`,
      });
    }
  },

  puppyPhotoSetAsDefault: async (listingId: number, puppyPhotos: Media[]) => {
    const mediaId = puppyPhotos[0].id;
    if (!mediaId) return;
    await apiMedia.setMediaAsDefault(listingId, mediaId as number);
  },

  photosDelete: async (photos: Nullable<Media[]>) => {
    if (isEmpty(photos) || !photos) return;
    const recentlyDeletedImages = [];
    for (const photo of photos) {
      if (!isMediaHidden(photo) && typeof photo.id === 'number') {
        try {
          await apiMedia.hideMedia(photo.id as number, true);
          recentlyDeletedImages.push(photo.id);
        } catch (error) {
          throw new Error(`Image ${photo.id} could not be deleted`);
        }
      }
    }

    return recentlyDeletedImages;
  },

  buildPuppyPhotos: (puppyMedia: Media[]) => {
    const coverPhotos = puppyMedia.filter(
      (m) => isImage(m) && isMediaPublic(m) && !isEmpty(m.uploadedAt),
    );
    const additionalPhotos = puppyMedia.filter(
      (m) => isImage(m) && isMediaPrivate(m) && !isEmpty(m.uploadedAt),
    );
    const hiddenPhotos = puppyMedia.filter(
      (m) => (isImage(m) && isMediaHidden(m)) || isEmpty(m.uploadedAt),
    );
    const sortedCover = orderBy(coverPhotos, ['viewOrder'], ['asc']);
    const sortedAddition = orderBy(additionalPhotos, ['viewOrder'], ['asc']);
    return {
      coverPhotos: sortedCover,
      additionalPhotos: sortedAddition,
      hiddenPhotos,
    } as PuppyPhotos;
  },

  getBreederMaxCost: async (breedId: number) => {
    if (!breedId) return;
    const response = await apiBreed.getBreed(breedId);
    return response?.json.breed.breederMaxCost;
  },
};

export default puppyFormHelpers;
