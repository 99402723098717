import { isEmpty } from 'lodash';

import { store } from 'index';
import { Media } from 'shared/types/media';
import { mediaConfig } from 'utilities/media/media';

export const puppyPhotosValidationRules = () => {
  const isLive = store.getState()?.puppy.listing?.isLive;

  return {
    validator: (_: unknown, value: Media[]) => {
      if (Array.isArray(value)) {
        if (isLive && (isEmpty(value) || value?.length < 3)) {
          return Promise.reject(
            `This listing is currently live. You need to add at least 3 photos to continue`,
          );
        }
        const coverPhotos = value.filter(
          (photo: Media) =>
            photo.visibilityId === mediaConfig.visibilityId.public,
        );

        if (
          isLive &&
          !isEmpty(value) &&
          (isEmpty(coverPhotos) || coverPhotos?.length < 3)
        ) {
          return Promise.reject(
            `Please upload at least three images for the listing cover`,
          );
        }
      }

      return Promise.resolve();
    },
  };
};
