import { apiGetRequest } from 'utilities/http/api/apiHttp';

const apiBreed = {
  getBreed: (breedId: number) => {
    return apiGetRequest(`breed/${breedId}`);
  },

  getMarkingsByBreed: (breedId: number) => {
    return apiGetRequest(`breed/${breedId}/markings`);
  },
};

export default apiBreed;
