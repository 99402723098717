import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, notification } from 'antd';
import apiPuppy from 'utilities/http/api/apiPuppy';
import puppyFormHelpers from 'shared/puppy/puppyFormHelpers';
import { FieldPuppyCost } from 'shared/fields';
import { PuppyHealthFee } from 'components/puppy';

import { useSelector } from 'react-redux';
import { RootState } from 'store/rootState';
import './UpdatePriceModal.scss';
import { Listing } from 'shared/types';

interface Props {
  modalVisible: boolean;
  setModalVisible: (val: boolean) => void;
  callback?: () => void;
}

interface UpdatePriceValue {
  listing?: {
    cost?: number;
  };
}

export default function UpdatePriceModal({
  callback,
  modalVisible,
  setModalVisible,
}: Props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const { myListings: { modalListing: listing } = {} } = useSelector(
    (state: RootState) => state,
  );
  const initialCost = listing?.cost || 0;

  const handleUpdatePrice = async (value: UpdatePriceValue) => {
    const errorMessage = `Couldn't update price`;
    const successMessage = `${
      listing?.puppy?.displayName || ''
    } breeder price updated successfully.`;
    try {
      if (!value?.listing?.cost || !listing?.id) {
        throw new Error(errorMessage);
      }
      setLoading(true);

      const puppyResp = await apiPuppy.putListing(
        listing?.id,
        value?.listing?.cost,
      );

      if (puppyResp.json.errors) {
        throw new Error(errorMessage);
      }
      if (callback) callback();
      closeModal();
      notification.success({
        message: successMessage,
      });
    } catch (error) {
      if (error instanceof Error) {
        notification.error({
          message: `Error!`,
          description: error.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const isDisabled = (name: string) => {
    if (!listing) {
      return true;
    } else if (listing.breederContractTerms?.length) {
      return !!listing.breederContractTerms[0].id;
    } else {
      return puppyFormHelpers.setDisabledFields(name, listing?.puppy, true);
    }
  };

  useEffect(() => {
    if (modalVisible) {
      form.setFieldsValue({
        breedId: listing?.puppy?.litter?.breed?.id,
        listing: { cost: initialCost },
      });
    }
  }, [modalVisible, listing, initialCost, form]);

  return (
    <Modal
      className="updatePuppyWeight__modal"
      visible={modalVisible}
      closable={false}
      footer={false}
      width={449}
      afterClose={() => form.resetFields()}
    >
      <h3 className="modal-title">
        <b>Update Breeder Price</b>
      </h3>
      <Form
        onFinish={handleUpdatePrice}
        initialValues={{
          breedId: listing?.puppy?.litter?.breed?.id,
          listing: { cost: initialCost },
        }}
        layout="vertical"
        form={form}
      >
        <div className="modal-content-price-update">
          <FieldPuppyCost
            disabled={isDisabled('listingCost')}
            initialCost={initialCost}
            breederDashboardStatus={listing?.puppy?.breederDashboardStatus}
            minValueRecommendedPrice={listing?.puppy?.minRecommendedPrice || 0}
            maxValueRecommendedPrice={listing?.puppy?.maxRecommendedPrice || 0}
          />
          <PuppyHealthFee listingData={listing as Listing} />
        </div>
        <div className="modal-buttons">
          <Button
            onClick={closeModal}
            data-testid={`modal-close`}
            className="ant-btn ant-btn-default"
          >
            Cancel
          </Button>
          <Button
            data-testid={'submit-btn'}
            type="default"
            htmlType="submit"
            loading={loading}
            className="ant-btn ant-btn-primary"
          >
            Accept
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
