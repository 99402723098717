import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';

import BreedingForm from 'pages/myAccount/accountForm/breedingForm/BreedingForm';
import ContactForm from 'pages/myAccount/accountForm/contactForm/ContactForm';
import CredentialsForm from 'pages/myAccount/accountForm/credentialsForm/CredentialsForm';
import ShippingForm from './shippingForm/ShippingForm';
import { Collapsable } from 'shared/collapsable/Collapsable';
import { RootState } from 'store/rootState';

export default function AccountForm(): ReactElement {
  const { airports } = useSelector((state: RootState) => ({
    airports: state.breeder?.breeder?.airports,
  }));

  return (
    <>
      <Collapsable
        title="Account Information"
        id="account-information"
        disabled={false}
        isOpen={true}
        type="success"
        icon="userOutlined"
      >
        <Row justify="center">
          <Col xs={24} xl={10} xxl={8}>
            <CredentialsForm />
          </Col>
        </Row>
      </Collapsable>
      <Collapsable
        title="Contact Information"
        id="contact-information"
        disabled={false}
        isOpen={false}
        type="success"
        icon="idcardOutlined"
      >
        <Row justify="center">
          <Col xs={24} xl={10} xxl={8}>
            <ContactForm />
          </Col>
        </Row>
      </Collapsable>
      <Collapsable
        title="Breeding Information"
        id="breeding-information"
        disabled={false}
        isOpen={false}
        type="success"
        icon="puppy"
      >
        <Row justify="center">
          <Col xs={24} xl={10} xxl={8}>
            <BreedingForm />
          </Col>
        </Row>
      </Collapsable>
      <Collapsable
        title="Shipping Preferences"
        id="shipping-prefereces"
        disabled={false}
        isOpen={false}
        icon="travelCase"
        type={!airports || airports.length ? 'success' : 'warning'}
      >
        <Row justify="center">
          <Col xs={24} xl={10} xxl={8}>
            <ShippingForm />
          </Col>
        </Row>
      </Collapsable>
    </>
  );
}
