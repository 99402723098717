import React, { ReactElement, useState } from 'react';
import { Col, Form, notification, Row, Typography } from 'antd';

import apiBreeder from 'utilities/http/api/apiBreeder';
import date from 'utilities/time/date';
import FieldAirport from 'shared/fields/fieldAirport/FieldAirport';
import showNotificationError from 'shared/notifications/showNotificationError';
import { fetchBreeder } from 'store/breeder/actions';
import { RootState } from 'store/rootState';
import { SubmitButton } from 'shared/fields';
import { useDispatch, useSelector } from 'react-redux';

const errorMessage =
  "Couldn't change your shipping preferences. Please try again";

export default function ShippingForm(): ReactElement | null {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { breeder } = useSelector((state: RootState) => ({
    breeder: state.breeder?.breeder,
  }));

  const onSubmit = async (airports: {
    preferredAirport?: number;
    secondaryAirport?: number;
  }) => {
    setLoading(true);
    const airportIds = [
      airports.preferredAirport,
      airports.secondaryAirport,
    ].filter(Boolean) as number[];

    try {
      const updateResponse = await apiBreeder.updateBreeder({
        airportIds,
        id: breeder?.id,
      });
      if (updateResponse.status === 200) {
        notification.success({
          message: `Successfully updated your shipping preferences`,
        });
        dispatch(fetchBreeder());
      } else {
        showNotificationError({ error: errorMessage });
      }
    } catch (e) {
      showNotificationError({ error: errorMessage });
    } finally {
      setLoading(false);
    }
  };

  if (!breeder) {
    return null;
  }

  return (
    <Form
      scrollToFirstError
      size="large"
      data-testid="shipping-form"
      name="shippingForm"
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        preferredAirport: breeder.airports?.[0]?.id,
        secondaryAirport: breeder.airports?.[1]?.id,
      }}
    >
      <p>
        Please provide your shipping preferences below to help us create the
        best route to deliver the puppies.
      </p>
      <FieldAirport
        required
        name="preferredAirport"
        label="Preferred Airport"
      />
      <FieldAirport name="secondaryAirport" label="Secondary Airport" />
      <Row justify="center" align="middle">
        <Col xs={12}>
          <Typography.Text type="secondary">
            Last updated{' '}
            {date.toMMDDYYYY(breeder.airports?.[0]?.pivot?.updatedAt)}
          </Typography.Text>
        </Col>
        <Col xs={12}>
          <SubmitButton label="Save " loading={loading} />
        </Col>
      </Row>
    </Form>
  );
}
