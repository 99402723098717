import React from 'react';

import TravelInfoItem from 'shared/puppy/puppyTravel/TravelInfoItem';
import { CargoTravelProposal, TravelProposals } from 'shared/types/puppyTravel';
import { testId } from 'testsUtilities/dataTestId';
import { getContentDataById } from 'utilities/staticContent/content';
import date from 'utilities/time/date';

interface Props {
  cargoProposal: CargoTravelProposal;
  proposalData: Partial<TravelProposals>;
}

function TravelProposalCargoInfo(props: Props) {
  const { cargoProposal, proposalData } = props;
  return (
    <section data-testid={testId.travelProposalInfoCargo}>
      <TravelInfoItem label="Airline" value={cargoProposal?.airline?.name} />

      <TravelInfoItem
        label="Origin airport"
        value={
          cargoProposal.originAirport ? (
            <>
              <b>
                {
                  getContentDataById(
                    'states',
                    cargoProposal?.originAirport?.stateId,
                  )?.abbr
                }
              </b>
              {cargoProposal?.originAirport?.name}
            </>
          ) : (
            'TBD'
          )
        }
      />

      <TravelInfoItem
        label="Destination airport"
        value={
          <>
            <b>
              {
                getContentDataById(
                  'states',
                  cargoProposal?.destinationAirport?.stateId,
                )?.abbr
              }
            </b>
            {cargoProposal?.destinationAirport?.name}
          </>
        }
      />
      <TravelInfoItem
        label="First mile transporter"
        value={cargoProposal?.firstMileTransporterCompany?.name}
      />

      <TravelInfoItem
        label="Breeder Drop Off Location"
        value={cargoProposal?.dropOffLocation}
      />

      <TravelInfoItem
        label="Departure date"
        value={date.toFullMonthDDYYYY(proposalData?.departureTime)}
      />

      <TravelInfoItem
        label="Arrival date"
        value={date.toFullMonthDDYYYY(proposalData?.arrivalTime)}
      />

      <TravelInfoItem
        label="Crate size"
        value={
          getContentDataById('crateSizes', cargoProposal?.crateSizeId)?.name
        }
      />
    </section>
  );
}

export default TravelProposalCargoInfo;
