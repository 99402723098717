import React, { ReactElement, ReactNode } from 'react';
import { Affix, Grid, Layout } from 'antd';

import { useBaseLayoutAffixOffset } from 'hooks';
import AddFamilyNavigation from 'shared/addFamilyNavigation/AddFamilyNavigation';
import BreadCrumbs from 'shared/breadcrumbs/BreadCrumbs';
import { getElementHeight, getElementOffsetTop } from 'utilities/dom/element';

import './PageLayout.scss';

const { Header, Content } = Layout;
const { useBreakpoint } = Grid;

export interface BreadCrumb {
  url?: string;
  name: string;
}

interface PageLayoutProps {
  children: ReactNode;
  className?: string;
  breadCrumbsNav?: BreadCrumb[];
}

export default function PageLayout(props: PageLayoutProps): ReactElement {
  const { children, className, breadCrumbsNav } = props;
  const { offsetTopPageLayoutHeader } = useBaseLayoutAffixOffset();
  const screens = useBreakpoint();

  const contentOffsetTop = !screens.xl
    ? getElementOffsetTop('PageLayout__content') +
      getElementHeight('AddFamilyNavigation--mobile')
    : getElementOffsetTop('PageLayout__content');

  return (
    <Layout className={`PageLayout PageLayout__${className}`}>
      <Affix offsetTop={offsetTopPageLayoutHeader}>
        <Header className="PageLayout__header">
          <div>
            <BreadCrumbs breadCrumbsNav={breadCrumbsNav} />
          </div>
          {screens.xl && (
            <div className="PageLayout__toolbar">
              <AddFamilyNavigation />
            </div>
          )}
        </Header>
      </Affix>
      <Content
        className="PageLayout__content"
        style={{
          minHeight: `calc(100vh - ${contentOffsetTop}px)`,
        }}
      >
        {children}
      </Content>
      {!screens.xl && (
        <Affix offsetBottom={0}>
          <AddFamilyNavigation />
        </Affix>
      )}
    </Layout>
  );
}
