import {
  MyListingsFilters,
  MyListingsPagination,
  MyListingsSorter,
  MyListingsStateBase,
} from 'shared/types/myListings';
import {
  MyListingsApprovedActions,
  SET_MY_LISTINGS_APPROVED,
  SET_MY_LISTINGS_APPROVED_FILTERS,
  SET_MY_LISTINGS_APPROVED_LOADING,
  SET_MY_LISTINGS_APPROVED_PAGINATION,
  SET_MY_LISTINGS_APPROVED_SORTER,
  UPDATE_MY_LISTINGS_APPROVED_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES,
} from 'store/myListingsApproved/types';
import { listingUpdatePuppyRegistrationsWeightDueDates } from 'utilities/listing';
import { SortDirection } from 'utilities/const';

export const initialStateMyListingsApprovedSorter: MyListingsSorter = {
  isSorting: false,
  sortBy: 'created_at',
  sortDirection: SortDirection.descend,
};

export const initialStateMyListingsApprovedFilters: MyListingsFilters = {
  isFiltering: false,
  puppy_id: null,
  puppy_name: null,
};

export const initialStateMyListingsApprovedPagination: MyListingsPagination = {
  pageSize: 50,
  current: 1,
  total: 0,
  loadMore: false,
};

const initialState: MyListingsStateBase = {
  loading: false,
  listings: [],
  pagination: initialStateMyListingsApprovedPagination,
  filters: initialStateMyListingsApprovedFilters,
  sorter: initialStateMyListingsApprovedSorter,
};

export function myListingsApprovedReducer(
  state = initialState,
  action: MyListingsApprovedActions,
): MyListingsStateBase {
  switch (action.type) {
    case SET_MY_LISTINGS_APPROVED_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_MY_LISTINGS_APPROVED: {
      const { listings } = action;
      return {
        ...state,
        loading: false,
        listings,
      };
    }
    case SET_MY_LISTINGS_APPROVED_PAGINATION: {
      const { pagination } = action;
      return {
        ...state,
        pagination,
      };
    }
    case SET_MY_LISTINGS_APPROVED_SORTER: {
      const { sorter } = action;
      return {
        ...state,
        sorter,
      };
    }
    case SET_MY_LISTINGS_APPROVED_FILTERS: {
      const { filters } = action;
      return {
        ...state,
        filters,
      };
    }
    case UPDATE_MY_LISTINGS_APPROVED_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES: {
      const { puppy } = action;

      const updatedListing = state.listings.map((listing) =>
        listingUpdatePuppyRegistrationsWeightDueDates(listing, puppy),
      );

      return {
        ...state,
        listings: updatedListing,
      };
    }
    default:
      return state;
  }
}
