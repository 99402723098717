import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Grid } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import { Puppy } from 'shared/types/puppy';
import { BreederRoutes } from 'shared/types/routes';
import { setNewPuppy } from 'store/newPuppy/actions';
import { isDelivered, isSold } from 'utilities/entities/puppy';
import inventoryStatus from 'utilities/status/inventoryStatus';

import './DuplicatePuppy.scss';

interface Props {
  litterId: number;
  damId: number;
  sireId: number;
  puppy: Puppy;
  label?: string;
}

function DuplicatePuppy(props: Props): ReactElement {
  const { litterId, damId, sireId, puppy, label } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const screen = Grid.useBreakpoint();

  const handleDuplicatePuppy = async () => {
    dispatch(
      setNewPuppy({
        damId,
        sireId,
        litterId,
        puppyId: puppy.id as number,
      }),
    );
    history.push(BreederRoutes.NewPuppy);
  };

  const disabled =
    inventoryStatus.isSoldByBreeder(puppy) ||
    isDelivered(puppy) ||
    isSold(puppy);

  return (
    <Button
      type="link"
      icon={<CopyOutlined />}
      className="duplicate-puppy-btn"
      onClick={handleDuplicatePuppy}
      title={label ? '' : 'Duplicate Puppy'}
      disabled={disabled}
    >
      {screen.xs ? undefined : label}
    </Button>
  );
}

export default DuplicatePuppy;
