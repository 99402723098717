import React, { ReactNode } from 'react';

import { Nullable } from 'shared/types/general';

interface Props {
  label: string;
  value: Nullable<string> | ReactNode;
}

function TravelInfoItem(props: Props) {
  const { label, value } = props;
  return (
    <div className="PuppyTravelInfoItem">
      <h4 className="PuppyTravelInfoItem__label">{label}</h4>
      <p className="PuppyTravelInfoItem__value">{value || `To be confirmed`}</p>
    </div>
  );
}

export default TravelInfoItem;
