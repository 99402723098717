import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import BreederAdminExternalDashboard from 'pages/breederAdminExternal/dashboard/BreederAdminExternalDashboard';
import { BreederRoutes } from 'shared/types/routes';

function BreederAdminExternalRoutesHandler() {
  return (
    <Switch>
      <Route
        path={BreederRoutes.Dashboard}
        component={BreederAdminExternalDashboard}
      />
      <Redirect from="*" exact to={BreederRoutes.Dashboard} />
    </Switch>
  );
}

export default BreederAdminExternalRoutesHandler;
