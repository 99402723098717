import React from 'react';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import { HealthDocsListingCardBadge } from 'components/healthDocs';
import { Listing, PuppyUpdateButton } from 'shared/types/puppy';
import UpdatePuppyWeight from '../updatePuppyWeight/UpdatePuppyWeight';
import UpdatePuppyRegistration from '../updatePuppyRegistration/UpdatePuppyRegistration';
import UpdatePuppyPhotos from '../updatePuppyPhotos/UpdatePuppyPhotos';
import UpToDateInfoBarLabel from './upToDateInfoBarLabel/UpToDateInfoBarLabel';
import UpdatePuppyHealth from '../updatePuppyHealth/UpdatePuppyHealth';
import UpdatePuppyTravel from '../updatePuppyTravel/UpdatePuppyTravel';
import UpdatePuppyDocs from '../updatePuppyDocs/UpdatePuppyDocs';

import './UpToDateInfoBar.scss';

interface Props extends PuppyUpdateButton {
  setDrawerPuppy?: (listing: Listing | null) => void;
}

function UpToDateInfoBar(props: Props) {
  const { columnName, listing, isBacklog, setDrawerPuppy } = props;
  const screens = useBreakpoint();

  const dueDates = listing?.puppy?.dueDates;
  const showBtn = false;

  return (
    <section
      className={`UpToDateInfoBar UpToDateInfoBar--summary ${
        isBacklog ? 'UpToDateInfoBar--backlog' : ''
      }`}
    >
      {!isBacklog && <UpToDateInfoBarLabel dueDates={dueDates} />}
      <UpdatePuppyWeight columnName={columnName} listing={listing} />
      <UpdatePuppyRegistration columnName={columnName} listing={listing} />
      <UpdatePuppyPhotos columnName={columnName} listing={listing} />
      {isBacklog && showBtn && (
        <>
          <UpdatePuppyHealth columnName={columnName} listing={listing} />
          <UpdatePuppyTravel columnName={columnName} listing={listing} />
          {!screens.xl && (
            <UpdatePuppyDocs columnName={columnName} listing={listing} />
          )}
        </>
      )}
      <HealthDocsListingCardBadge
        listing={listing}
        setDrawerPuppy={setDrawerPuppy}
      />
    </section>
  );
}

export default UpToDateInfoBar;
