import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import { BaseContent, BreedContent } from 'utilities/staticContent/types';
import { Nullable } from 'shared/types/general';

export const getBreedMaxCost = (breedId: Nullable<number>) => {
  const breed: BreedContent = staticContentStorage
    .getContent()
    .breeds?.find((item: BaseContent) => item.id === breedId);

  return breed?.breederMaxCost || 0;
};
