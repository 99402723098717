import React, { ReactElement, useEffect, useState } from 'react';
import { Row, Col, Button, Form, Select } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import { BreedContent } from 'utilities/staticContent/types';
import { SelectOptionsContentProps } from 'shared/fields/typesFields';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { isEmpty } from 'lodash';
import { Breed } from 'shared/types/general';

interface Props {
  form: FormInstance;
  disabled?: boolean;
}

export default function FieldBreedsYouBreed({
  form,
  disabled,
}: Props): ReactElement {
  const breeds = staticContentStorage
    .getContent()
    .breeds?.sort((a: Breed, b: Breed) => a.name.localeCompare(b.name));
  const [breedsSelected, setBreedsSelected] = useState<number[]>([]);
  const [disableButton, setDisabledButton] = useState<boolean>(false);

  useEffect(() => {
    if (breedsSelected.length) {
      setDisabledButton(() =>
        Boolean(breedsSelected.filter((breed) => !Boolean(breed)).length),
      );
    }
  }, [breedsSelected]);

  const handleSelectedBreeds = () => {
    const fieldValues = form.getFieldValue('breeds');
    const selectedBreeds = fieldValues?.map(
      (item: SelectOptionsContentProps) => item?.id,
    );
    setBreedsSelected(selectedBreeds);
  };

  const handleAdd = (add: () => void) => {
    add();
    handleSelectedBreeds();
  };

  const handleRemove = (
    remove: (arg: number) => void,
    field: FormListFieldData,
  ) => {
    remove(field.name);
    handleSelectedBreeds();
  };

  return (
    <Form.Item label="What breeds do you breed?">
      <Form.List
        name="breeds"
        rules={[
          {
            validator: async (_, breeds) => {
              if (
                !breeds ||
                breeds.length < 1 ||
                isEmpty(breeds?.filter(Boolean))
              ) {
                return Promise.reject(new Error('Select at least 1 breed'));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map((field, idx) => (
                <Row key={field.name} align="middle">
                  <Col span={22}>
                    <Form.Item name={[idx, 'id']}>
                      <Select
                        onChange={handleSelectedBreeds}
                        disabled={disabled}
                      >
                        {breeds.map((breed: BreedContent) => (
                          <Select.Option
                            key={breed.id}
                            value={breed.id}
                            disabled={breedsSelected?.includes(breed.id)}
                          >
                            {breed.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={1} md={1} xl={2}>
                    <Form.Item>
                      <DeleteOutlined
                        style={{ marginLeft: 16 }}
                        onClick={() => handleRemove(remove, field)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col span={24}>
                  <Form.Item style={{ margin: '0' }}>
                    <Button
                      type="dashed"
                      onClick={() => handleAdd(add)}
                      style={{ width: '100%' }}
                      size="middle"
                      disabled={disableButton || disabled}
                    >
                      <PlusOutlined /> Add Breed
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </Col>
              </Row>
            </>
          );
        }}
      </Form.List>
    </Form.Item>
  );
}
