import {
  MyListingsFilters,
  MyListingsPagination,
  MyListingsSorter,
  MyListingsStateBase,
} from 'shared/types/myListings';
import {
  MyListingsHiddenActions,
  SET_MY_LISTINGS_HIDDEN,
  SET_MY_LISTINGS_HIDDEN_FILTERS,
  SET_MY_LISTINGS_HIDDEN_LOADING,
  SET_MY_LISTINGS_HIDDEN_PAGINATION,
  SET_MY_LISTINGS_HIDDEN_SORTER,
  UPDATE_MY_LISTINGS_HIDDEN_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES,
} from 'store/myListingsHidden/types';
import { listingUpdatePuppyRegistrationsWeightDueDates } from 'utilities/listing';
import { SortDirection } from 'utilities/const';

export const initialStateMyListingsHiddenSorter: MyListingsSorter = {
  isSorting: false,
  sortBy: 'created_at',
  sortDirection: SortDirection.descend,
};

export const initialStateMyListingsHiddenFilters: MyListingsFilters = {
  isFiltering: false,
  puppy_id: null,
  puppy_name: null,
};

export const initialStateMyListingsHiddenPagination: MyListingsPagination = {
  pageSize: 25,
  current: 1,
  total: 0,
  loadMore: false,
};

const initialState: MyListingsStateBase = {
  loading: false,
  listings: [],
  pagination: initialStateMyListingsHiddenPagination,
  filters: initialStateMyListingsHiddenFilters,
  sorter: initialStateMyListingsHiddenSorter,
};

export function myListingsHiddenReducer(
  state = initialState,
  action: MyListingsHiddenActions,
): MyListingsStateBase {
  switch (action.type) {
    case SET_MY_LISTINGS_HIDDEN_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_MY_LISTINGS_HIDDEN: {
      const { listings } = action;
      return {
        ...state,
        loading: false,
        listings,
      };
    }
    case SET_MY_LISTINGS_HIDDEN_PAGINATION: {
      const { pagination } = action;
      return {
        ...state,
        pagination,
      };
    }
    case SET_MY_LISTINGS_HIDDEN_SORTER: {
      const { sorter } = action;
      return {
        ...state,
        sorter,
      };
    }
    case SET_MY_LISTINGS_HIDDEN_FILTERS: {
      const { filters } = action;
      return {
        ...state,
        filters,
      };
    }
    case UPDATE_MY_LISTINGS_HIDDEN_PUPPY_REGISTRATIONS_WEIGHT_DUE_DATES: {
      const { puppy } = action;
      const updatedListings = state.listings.map((listing) =>
        listingUpdatePuppyRegistrationsWeightDueDates(listing, puppy),
      );
      return {
        ...state,
        listings: updatedListings,
      };
    }
    default:
      return state;
  }
}
