import React, { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Affix, Button, Form, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { useBaseLayoutAffixOffset } from 'hooks';
import { MyListingsFilters } from 'shared/types/myListings';
import { setMyListingsSearch } from 'store/myListings/actions.thunk';
import {
  fetchMyListingsPending,
  setMyListingsPendingInitialState,
} from 'store/myListingsPending/actions.thunk';
import {
  fetchMyListingsApproved,
  setMyListingsApprovedInitialState,
} from 'store/myListingsApproved/actions.thunk';
import {
  fetchMyListingsSold,
  setMyListingsSoldInitialState,
} from 'store/myListingsSold/actions.thunk';
import {
  fetchMyListingsDelivered,
  setMyListingsDeliveredInitialState,
} from 'store/myListingsDelivered/actions.thunk';
import { RootState } from 'store/rootState';
import { regExp } from 'utilities/regExp';

import './MyListingsSearch.scss';

const { Option } = Select;

interface SearchValues {
  searchBy: string;
  searchValue: string;
}

function MyListingsSearch(): ReactElement {
  const { offsetTopMyEntitySearch } = useBaseLayoutAffixOffset();
  const [inputType, setInputType] = useState<'text' | 'number'>('text');
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const {
    myListingsPending: { loading: listingsLoadingPending },
    myListingsApproved: { loading: listingsLoadingApproved },
    myListingsSold: { loading: listingsLoadingSold },
    myListingsDelivered: { loading: listingsLoadingDelivered },
  } = useSelector((state: RootState) => state);

  const disabledSearch =
    listingsLoadingPending ||
    listingsLoadingApproved ||
    listingsLoadingSold ||
    listingsLoadingDelivered;

  const handleFetchMyListings = () => {
    dispatch(fetchMyListingsPending());
    dispatch(fetchMyListingsApproved());
    dispatch(fetchMyListingsSold());
    dispatch(fetchMyListingsDelivered());
  };

  const handleSetMyListingsInitialState = () => {
    dispatch(setMyListingsPendingInitialState());
    dispatch(setMyListingsApprovedInitialState());
    dispatch(setMyListingsSoldInitialState());
    dispatch(setMyListingsDeliveredInitialState());
  };

  const handleSearchMyListings = ({ searchBy, searchValue }: SearchValues) => {
    if (!searchValue) return;

    const filters: MyListingsFilters = {
      isFiltering: true,
      puppy_id: searchBy === 'puppy_id' ? [Number(searchValue)] : null,
      puppy_name: searchBy === 'puppy_name' ? [searchValue] : null,
    };

    handleSetMyListingsInitialState();
    dispatch(setMyListingsSearch(filters));
    handleFetchMyListings();
  };

  const handleResetSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (disabledSearch) return;

    if (event.type === 'click') {
      handleSetMyListingsInitialState();
      handleFetchMyListings();
    }
  };

  const handleOnValuesChange = (changedValues: SearchValues) => {
    const { searchBy } = changedValues;

    if (searchBy === 'puppy_name') {
      setInputType('text');
    }

    if (searchBy === 'puppy_id') {
      setInputType('number');
    }

    if (searchBy) {
      form.resetFields(['searchValue']);
    }
  };

  const handleInputTypeFormat = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const regExpFormat =
      inputType === 'text' ? regExp.alphaAndNotSpecialChars : regExp.onlyNumber;
    const notValidKey = !regExpFormat.test(String.fromCharCode(event.which));

    if (notValidKey) {
      event.preventDefault();
    }
  };

  return (
    <Affix offsetTop={offsetTopMyEntitySearch}>
      <section className="MyListingsSearch">
        <Form
          layout="inline"
          form={form}
          initialValues={{ searchBy: 'puppy_name' }}
          onFinish={handleSearchMyListings}
          onValuesChange={handleOnValuesChange}
        >
          <Form.Item>
            <span className="MyListingsSearch__label" />
          </Form.Item>
          <Form.Item name="searchBy">
            <Select>
              <Option value="puppy_name">Puppy Name</Option>
              <Option value="puppy_id">Puppy ID</Option>
            </Select>
          </Form.Item>
          <Form.Item name="searchValue">
            <Input.Search
              placeholder="Type your search here"
              allowClear
              autoComplete="off"
              onKeyPress={handleInputTypeFormat}
              onChange={handleResetSearch}
              enterButton={
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={disabledSearch}
                  icon={<SearchOutlined />}
                />
              }
            />
          </Form.Item>
        </Form>
      </section>
    </Affix>
  );
}

export default MyListingsSearch;
