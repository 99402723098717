import React, { ComponentProps, useState } from 'react';
import { Modal, Form, Select, Typography } from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { useExistingParents } from 'shared/fields/fieldExistingParent/useExistingParents';
import { Nullable } from 'shared/types/general';
import { Gender, Genders, Parent } from 'shared/types/parent';
import { Litter } from 'shared/types/litter';
import { changeLitterDamOrSire } from 'store/litter/actions';
import { getNameContentFieldById } from 'utilities/staticContent/content';

import './ChangeParentModal.scss';

interface Props {
  modalVisible: boolean;
  hideModal: () => void;
  parent: Nullable<Partial<Parent>>;
  litter: Litter;
}

type OptionType = Parameters<
  Exclude<ComponentProps<typeof Select>['onSelect'], undefined>
>['1'];

export const ChangeParentModal = ({
  modalVisible,
  hideModal,
  parent,
  litter,
}: Props) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState<Nullable<OptionType>>(
    null,
  );
  const { isLoading, data } = useExistingParents(
    parent?.genderId ?? Genders.dam,
  );
  const [changeParentLoading, setChangeParentLoading] = useState(false);
  const parentLabelDefaultValue = `${parent?.name} - ${getNameContentFieldById(
    'breeds',
    parent?.breedId,
  )}`;

  const onSelect = (_value: string, option: OptionType) => {
    setSelectedOption(option);
  };

  const AllowedParents = (parent: Parent) => {
    if (litter.hasApprovedListings) {
      if (parent.genderId === Genders.dam) {
        if (parent.breedId === litter.dam?.breedId) {
          if (
            parent?.minimumDateAllowedToAddPuppies === null ||
            moment(litter.birthDate).isAfter(
              moment(parent.minimumDateAllowedToAddPuppies),
            )
          ) {
            return parent;
          }
        }
      } else {
        if (parent.breedId === litter.sire?.breedId) {
          return parent;
        }
      }
    } else if (parent.genderId === Genders.dam) {
      if (
        parent?.minimumDateAllowedToAddPuppies === null ||
        moment(litter.birthDate).isAfter(
          moment(parent.minimumDateAllowedToAddPuppies),
        )
      ) {
        return parent;
      }
    } else {
      return parent;
    }
  };

  const editLitterParent = async () => {
    const gender: Gender = parent?.genderId === Genders.dam ? 'dam' : 'sire';
    const parentId = Number(selectedOption?.key);

    if (parentId) {
      setChangeParentLoading(true);
      dispatch(
        changeLitterDamOrSire(
          litter.id as number,
          Number(selectedOption?.key || 0),
          gender,
        ),
      );
    }
  };

  return (
    <Modal
      title={<Typography.Text strong>Change Parent</Typography.Text>}
      visible={modalVisible}
      width="450px"
      onCancel={() => hideModal()}
      okText="APPLY"
      onOk={() => editLitterParent()}
      cancelText="CANCEL"
      cancelButtonProps={{
        type: 'default',
      }}
      okButtonProps={{
        loading: changeParentLoading,
        disabled:
          selectedOption === null || Number(selectedOption?.key) === parent?.id,
      }}
      keyboard={false}
    >
      <Form>
        <div>{parent?.genderId === Genders.dam ? 'Dam' : 'Sire'}</div>
        <Form.Item className="parentSelect">
          <Select
            loading={isLoading}
            disabled={isLoading}
            defaultValue={parentLabelDefaultValue}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onSelect={onSelect}
          >
            {data?.filter(AllowedParents).map((p) => (
              <Select.Option key={p.id as number} value={p.name}>
                {`${p.name} - ${p.breed.name}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
