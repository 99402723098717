import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Empty } from 'antd';
import { isEmpty } from 'lodash';

import LoadingSection from 'components/loadingSection/LoadingSection';
import ListingCard from 'pages/myListings/listingCard/ListingCard';
import { fetchMyListingsHidden } from 'store/myListingsHidden/actions.thunk';
import { setMyListingsHiddenPagination } from 'store/myListingsHidden/actions';
import { RootState } from 'store/rootState';
import { testId } from 'testsUtilities/dataTestId';
import { Listing } from 'shared/types';

import '../MyListingsCards.scss';

interface Props {
  openModal?: (listing: Listing) => void;
}

function MyListingsCardsHidden({ openModal }: Props): ReactElement {
  const {
    myListingsHidden: { loading, listings, pagination },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const handleLoadMore = () => {
    dispatch(
      setMyListingsHiddenPagination({
        ...pagination,
        current: pagination.current + 1,
        loadMore: true,
      }),
    );
    dispatch(fetchMyListingsHidden());
  };

  return (
    <section
      className="MyListingsCards__wrapper"
      data-testid={testId.myListingsHiddenCardWrapper}
    >
      {loading && <LoadingSection />}
      {!loading && isEmpty(listings) && <Empty />}
      {listings.map((l) => (
        <ListingCard
          key={l.puppyId}
          listing={l}
          columnName="hidden"
          openModal={openModal}
          updatePrice
        />
      ))}
      {!isEmpty(listings) && listings.length !== pagination.total && (
        <Button
          type="link"
          className="LoadMore"
          onClick={handleLoadMore}
          loading={loading}
        >
          Load More
        </Button>
      )}
    </section>
  );
}

export default MyListingsCardsHidden;
