import { useEffect, useState } from 'react';

import { Nullable } from 'shared/types/general';
import { apiGetRequest } from 'utilities/http/api/apiHttp';
import { ColorContent } from 'utilities/staticContent/types';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';

export function useBreedColors(breedId: Nullable<number>) {
  const [breedColors, setBreedColors] = useState<ColorContent[]>(
    staticContentStorage.staticContent.colors,
  );
  breedColors?.sort((a, b) => a.name.localeCompare(b.name));
  const [isBreedColorsLoading, setIsBreedColorsLoading] = useState(true);

  const apiBreedColorsContent = (breedId: number) => {
    return apiGetRequest(`content/breed/${breedId}/colors`);
  };

  useEffect(() => {
    const getBreedColors = async () => {
      const resp = await apiBreedColorsContent(breedId as number);
      setBreedColors(resp.json.breedColors);
      setIsBreedColorsLoading(false);
    };
    breedId && getBreedColors();
    !breedId && setIsBreedColorsLoading(false);
  }, [breedId]);

  return { breedColors, isBreedColorsLoading };
}

export default useBreedColors;
