import React, { ReactElement } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from 'pages/login/Login';
import ForgotPassword from 'pages/forgotPassword/ForgotPassword';
import Register from 'pages/register/Register';
import Agreement from 'pages/agreement/Agreement';
import { BreederRoutes, StaticContentPageRoute } from 'shared/types/routes';

function PublicRoutesHandler(): ReactElement {
  return (
    <Switch>
      <Route path={BreederRoutes.Login} component={Login} />
      <Route path={BreederRoutes.Register} component={Register} />
      <Route
        path={StaticContentPageRoute.MembershipAgreement}
        component={Agreement}
      />
      <Route path="/forgot-password/:token?" component={ForgotPassword} />
      <Redirect from="/" exact to={BreederRoutes.Login} />
      <Redirect from="*" exact to={BreederRoutes.Login} />
    </Switch>
  );
}

export default PublicRoutesHandler;
