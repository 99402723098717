import React, { ReactElement, useEffect } from 'react';
import PageLayout from 'shared/baseLayout/pageLayout/PageLayout';
import { useDispatch } from 'react-redux';
import { fetchMyUpdatesAndMapToListings } from 'store/myListings/actions';

import './MyUpdates.scss';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import MyUpdatesSmall from 'pages/myUpdates/myUpdatesSmall/MyUpdatesSmall';
import MyUpdatesLarge from 'pages/myUpdates/myUpdatesLarge/MyUpdatesLarge';

const breadCrumbs = [
  {
    name: 'My Upcoming Updates',
  },
];

export default function MyUpdates(): ReactElement {
  const dispatch = useDispatch();
  const screens = useBreakpoint();

  useEffect(() => {
    dispatch(fetchMyUpdatesAndMapToListings());
  }, [dispatch]);

  return (
    <PageLayout className="MyUpdates" breadCrumbsNav={breadCrumbs}>
      {screens.xl ? <MyUpdatesLarge /> : <MyUpdatesSmall />}
    </PageLayout>
  );
}
