import {
  apiDeleteRequest,
  apiGetRequest,
  apiPostRequest,
  apiPutRequest,
} from 'utilities/http/api/apiHttp';
import { Id, Nullable, RegisterBreederBody } from 'shared/types/general';
import { Breeder } from 'shared/types/breeder';
import userStorage from 'utilities/user/userStorage';
import { Address } from 'shared/types/geolocation';
import { getRequest } from '../http';
import { buildUrl } from '../api/apiHttp';

const apiBreeder = {
  getPayments: (breederId: number) => {
    return apiGetRequest(`breeder-payment/breeder/${breederId}`);
  },
  getPaymentsByDateRange: (
    breederId: Id,
    dateBegin: string,
    dateEnd: string,
  ) => {
    return apiGetRequest(
      `breeder-payment/breeder/${breederId}/completed/by-date-range`,
      `dateBegin=${dateBegin}&dateEnd=${dateEnd}`,
    );
  },
  getBreeder: (withRoles = true) => {
    return apiGetRequest(`user/breeders`, `withRoles=${withRoles}`);
  },

  updateBreeder: (breeder: Partial<Breeder>) => {
    return apiPutRequest(`breeder`, { breeder });
  },
  createAddress: (address: Address) => {
    const breederId = userStorage.getBreederId();
    return apiPostRequest(`breeder/${breederId}/address`, {
      address,
    });
  },
  updateAddress: (address: Address) => {
    const breederId = userStorage.getBreederId();
    return apiPutRequest(`breeder/${breederId}/address/${address.id}`, {
      address,
    });
  },
  deleteAddress: () => {
    const breederId = userStorage.getBreederId();
    return apiDeleteRequest(`breeder/${breederId}/address/${1296830}`);
  },
  postBreed: (breedId?: Id) => {
    if (!breedId) return;
    const breederId = userStorage.getBreederId();
    return apiPostRequest(`breeder/${breederId}/breed/${breedId}`, null);
  },
  deleteBreed: (breedId?: Id) => {
    if (!breedId) return;
    const breederId = userStorage.getBreederId();
    return apiDeleteRequest(`breeder/${breederId}/breed/${breedId}`);
  },
  getBreederInformation: () => {
    return apiGetRequest(`breeder/${userStorage.getBreederId()}`);
  },
  getAccountRepresentative: (accountRepUserId: number) => {
    return apiGetRequest(`access/user/${accountRepUserId}`);
  },
  getDashboardSummary: (breederId?: Nullable<number>) => {
    return apiGetRequest(
      `breeder/${breederId || userStorage.getBreederId()}/dashboard-summary`,
      `withBreederDashboardStatus=1&withPuppyParents=1`,
    );
  },
  register(body: RegisterBreederBody) {
    return apiPostRequest(`breeder/registration`, body);
  },
  getQuestionary(id: number) {
    return getRequest(buildUrl(`questionnaire/${id}/questions`));
  },
};

export default apiBreeder;
