import React, { ReactElement } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Col, Row, Modal, notification, Button } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import {
  DashboardOutlined,
  FileProtectOutlined,
  CalendarOutlined,
  CloseOutlined,
  StarOutlined,
} from '@ant-design/icons';

import {
  renderParentLockedBadge,
  renderParentPendingEditBadge,
} from 'components/entity';
import { Icon } from 'shared/icon/Icon';
import { MyParentsTypes, Parent } from 'shared/types/parent';
import { MarkLabel } from 'shared/markLabel/MarkLabel';
import EntityPhoto from 'shared/entitiesOverview/entityPhoto/EntityPhoto';
import { BreederRoutes } from 'shared/types/routes';
import date from 'utilities/time/date';
import time from 'utilities/time/time';
import { getContentDataById } from 'utilities/staticContent/content';
import media, { mediaConfig } from 'utilities/media/media';
import { testId } from 'testsUtilities/dataTestId';
import { isParentLocked } from 'utilities/parent';
import {
  isDam,
  isInactive,
  isRetired,
  StatusReasonId,
} from 'utilities/entities/parent';
import apiParent from 'utilities/http/api/apiParent';

import 'shared/entitiesOverview/entityCard.scss';
import './ParentCard.scss';
import { legacyRegistries } from 'shared/fields/fieldRegistry/legacyRegistries';

interface Props {
  parent: Parent;
  belongsToPageDetail?: boolean;
  parentsType?: MyParentsTypes;
}

export default React.memo(function ParentCard(props: Props): ReactElement {
  const { parent, belongsToPageDetail, parentsType } = props;
  const history = useHistory();
  const screens = useBreakpoint();
  const parentType = isDam(parent) ? 'dam' : 'sire';
  const parentMedia = media.getMainPhoto(parent?.media);

  const imagePath = parentMedia
    ? media.getImage(parentMedia, mediaConfig.photoSize.small)
    : media.getImageUrl(parentMedia);

  const yearsAgo = parent.birthDate
    ? `${time.differenceWithTimeUnit(parent.birthDate)} old`
    : '';

  const birthDate = parent?.pendingEdit?.birthDate
    ? parent?.pendingEdit?.birthDate
    : parent.birthDate;

  const retiredParent = isRetired(parent) || isInactive(parent);
  const isListingLocked = isParentLocked(parent);
  const cursorType = belongsToPageDetail
    ? 'cursor-default'
    : retiredParent
    ? 'cursor-not-allowed'
    : '';

  const cardLocked = isListingLocked ? 'entityCard--locked' : '';

  const showRetireError = (errorMessage?: string) => {
    const description =
      errorMessage ||
      'We could not retire the parent. Please try later or contact us at (844) 566-2886';

    notification.error({
      message: 'Something went wrong!',
      description,
    });

    history.push(BreederRoutes.MyParents);
  };

  const setParentRetired = async () => {
    try {
      const response = await apiParent.putMarkParentAsRetired(
        parent.id as number,
        {
          puppy_parent: {
            status_reason_id: StatusReasonId.Retired,
            is_active: false,
          },
        },
      );

      if (
        response.json &&
        response.status !== 200 &&
        response.json.errors?.puppy_parent.length
      ) {
        return showRetireError(response.json.errors?.puppy_parent[0]);
      }

      notification.success({
        message: 'Retiring this parent',
        description: 'Parent successfully retired.',
      });
      history.push(BreederRoutes.MyParents);
    } catch (error) {
      showRetireError();
    }
  };

  const onRetiredActionClick = () => {
    return Modal.confirm({
      title: 'Retiring this parent',
      content: (
        <>
          <div>
            This action will take this parent out of your inventory permanently
            and can&apos;t be used to make new litters anymore.
          </div>
          <span>Do you want to continue?</span>
        </>
      ),
      autoFocusButton: null,
      okButtonProps: { type: 'primary' },
      okText: 'Yes',
      cancelText: 'No',
      cancelButtonProps: { type: 'default' },
      onOk: setParentRetired,
    });
  };

  /**
   * Layout modifiers
   */
  const entityCardClassModifier = belongsToPageDetail
    ? `entityCard entityCard--page-detail ${cardLocked}`
    : `entityCard ${cardLocked}`;

  const entityPhotoHeight = screens.sm ? '112px' : '124px';

  const entityPhotoWidth = screens.sm ? '112px' : '124px';

  const entityCardTestId = parentsType
    ? parentsType === 'dams'
      ? testId.entityCardParentDam
      : testId.entityCardParentSire
    : testId.entityCardParent;

  const renderRegistry = (registryId: number) => {
    const legacyRegistry = legacyRegistries.find(
      (legacyRegistry) => legacyRegistry.id === registryId,
    );
    if (legacyRegistry) {
      return legacyRegistry.title;
    }

    return (
      getContentDataById('registries', parent.registryId)?.title ??
      'DELETED_REGISTRY'
    );
  };

  return (
    <section className={entityCardClassModifier} data-testid={entityCardTestId}>
      <Link
        className={`entityCard__info ${cursorType} parentCard__info`}
        data-testid="entity-card-link"
        to={
          retiredParent || belongsToPageDetail
            ? '#'
            : `/parent/${parentType}/${parent.id}`
        }
      >
        <Row align="middle" wrap={false}>
          <Col>
            <div className="entityCard__info-img">
              {renderParentLockedBadge(parent)}
              {renderParentPendingEditBadge(parent)}
              <EntityPhoto
                entity={parentType}
                imageSrc={imagePath}
                height={entityPhotoHeight}
                width={entityPhotoWidth}
              />
            </div>
          </Col>
          <Col flex="auto">
            <section className="entityCard__info-content parentCard__info-content">
              <h2
                className="entityCard__info-title"
                data-testid="entity-card-info-name"
              >
                {parent.name}
              </h2>
              <Row>
                <Col sm={12}>
                  <p>
                    <Icon name={parentType} />
                    {parent.breed.name}
                  </p>
                  {screens.xs && (
                    <>
                      <p>
                        <CalendarOutlined />
                        {yearsAgo}
                      </p>
                    </>
                  )}

                  {!screens.xs && (
                    <p>
                      <CalendarOutlined />
                      DOB: {date.toMMDDYYYY(birthDate)}
                    </p>
                  )}

                  <p>
                    {retiredParent ? (
                      <MarkLabel label="Retired" icon={<CloseOutlined />} />
                    ) : (
                      <>
                        <FileProtectOutlined />{' '}
                        {renderRegistry(parent.registryId)}
                      </>
                    )}
                  </p>
                  {screens.xs && (
                    <>
                      <p>
                        <DashboardOutlined />
                        {
                          getContentDataById(
                            'weightRanges',
                            parent.weightRangeId,
                          ).name
                        }
                      </p>
                    </>
                  )}
                </Col>
                <Col xs={0} sm={12}>
                  <p>
                    <DashboardOutlined />
                    {
                      getContentDataById('weightRanges', parent.weightRangeId)
                        .name
                    }
                  </p>
                  <p>
                    <CalendarOutlined />
                    {yearsAgo}
                  </p>

                  <p>
                    <StarOutlined />
                    {parent?.hasBeenShown
                      ? 'Has been shown'
                      : "Hasn't been shown"}
                  </p>
                </Col>
              </Row>
            </section>
          </Col>
        </Row>
      </Link>
      {belongsToPageDetail && (
        <section className="entityCard__footer">
          <Button
            className="entityCard__footer__btn entityCard__footer__btn--alert"
            type="link"
            onClick={onRetiredActionClick}
            data-testid={testId.entityCardParentRetireButton}
          >
            Mark as retired
          </Button>
        </section>
      )}
    </section>
  );
});
