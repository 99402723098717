import { isEmpty } from 'lodash';

import { DueDate } from 'shared/types/date';
import { DueDatesItems } from 'shared/types/general';
import date from 'utilities/time/date';
import time from 'utilities/time/time';

/**
 * Checks if puppy due dates is up to date.
 * Also by using the param byItem checks if only that item is up to date.
 *
 * @param value Puppy Due Dates values.
 * @param byItem  Optional Puppy Due Dates by item value.
 * @return Returns true if value is empty, else false.
 */
export const puppyDueDatesIsUpToDate = (
  dueDates: DueDate[],
  byItem?: DueDatesItems,
): boolean => {
  if (byItem) {
    const itemDueDates = dueDates?.filter((i) => i.item === byItem);

    return isEmpty(itemDueDates);
  }

  return isEmpty(dueDates);
};

/**
 * Checks if puppy due dates is past due.
 * Also by using the param byItem checks if only that item is past due.
 *
 * @param value Puppy Due Dates values.
 * @param byItem?  Optional Puppy Due Dates by item value.
 * @return Returns true if value is empty, else false.
 *
 */
export const puppyDueDatesIsPastDue = (
  dueDates: DueDate[],
  byItem?: DueDatesItems,
): boolean => {
  if (byItem) {
    const itemDueDate = dueDates?.find((i) => i.item === byItem)?.dueDate;
    return !date.isDateInFuture(itemDueDate as string);
  }

  const pastDates = dueDates?.filter((dd) => !date.isDateInFuture(dd.dueDate));

  return !isEmpty(pastDates);
};

/**
 * Counts days to due dates.
 *
 * @param value Puppy Due Dates values.
 * @return Returns plural o singular numbers of days – `1 day` or `10 days`.
 *
 */
export const daysToDueDate = (dueDates: DueDate[]): string => {
  const dueDatesOnly = dueDates?.map((i) => i.dueDate);
  const nearestDueDate = date.nearestDateInFuture(dueDatesOnly) as string;

  return time.differenceNowToFuture(nearestDueDate, 'day');
};

/**
 * Counts days past due dates.
 *
 * @param value Puppy Due Dates values.
 * @return Returns plural o singular numbers of days – `1 day` or `10 days`.
 *
 */
export const daysPastDue = (dueDates: DueDate[]): string => {
  const pastDates = dueDates?.filter((d) => !date.isDateInFuture(d.dueDate));
  const oldestDueDate = pastDates?.sort((a, b) =>
    date.sortByOldest(a.dueDate, b.dueDate),
  )[0]?.dueDate;

  return time.differenceWithTimeUnit(oldestDueDate, 'day');
};
