import { Listing, Puppy } from 'shared/types/puppy';
import { Nullable } from 'shared/types/general';
import { isPending } from 'utilities/entities/puppy';
import date from 'utilities/time/date';
import number from 'utilities/number/number';
import { reactEnvironmentVariables } from 'utilities/environmentVariables';
import {
  getNameContentFieldById,
  getBreedTypeId,
} from 'utilities/staticContent/content';

export const getPuppySiteName = (puppy: Partial<Puppy>) => {
  let salesName: Nullable<string> | undefined = '';

  if (!isPending(puppy)) {
    salesName = puppy.salesName || puppy.displayName || puppy.breederName;
  } else if (puppy?.pendingEdit?.breederName) {
    salesName = puppy?.pendingEdit?.breederName;
  } else {
    salesName = puppy.breederName;
  }

  return salesName;
};

export const getPuppyBreederName = (puppy: Partial<Puppy>) => {
  return puppy?.pendingEdit?.breederName
    ? puppy?.pendingEdit?.breederName
    : puppy.breederName;
};

export const getPuppyDamId = (puppy: Partial<Puppy>) => {
  return puppy.pendingEdit?.litter
    ? puppy.pendingEdit.litter.dam?.id
    : puppy.litter?.dam?.id || puppy?.listing?.puppy?.litter?.dam?.id;
};

export const getPuppyDamName = (puppy: Partial<Puppy>) => {
  return puppy.pendingEdit?.litter
    ? puppy.pendingEdit.litter.dam?.name
    : puppy.litter?.dam?.name || puppy?.listing?.puppy?.litter?.dam?.name;
};

export const getPuppySireId = (puppy: Partial<Puppy>) => {
  return puppy.pendingEdit?.litter
    ? puppy.pendingEdit.litter.sire?.id
    : puppy.litter?.sire?.id || puppy?.listing?.puppy?.litter?.sire?.id;
};

export const getPuppySireName = (puppy: Partial<Puppy>) => {
  return puppy.pendingEdit?.litter
    ? puppy.pendingEdit.litter.sire?.name
    : puppy.litter?.sire?.name || puppy?.listing?.puppy?.litter?.sire?.name;
};

export const getPuppyBirthDate = (puppy: Partial<Puppy>) => {
  const birthdate = puppy.pendingEdit?.litter
    ? puppy.pendingEdit.litter.birthDate
    : puppy.litter?.birthDate;

  return date.toMMDDYYYY(birthdate);
};

export const getListingAskingPrice = (listing: Listing) => {
  return number.displayCurrency(
    listing?.puppy?.adjustedAskingPrice || listing?.cost || 0,
  );
};

export const getPuppyCommerceSiteUrl = (puppy: Partial<Puppy>) => {
  const consumerSiteUrl = reactEnvironmentVariables.CONSUMER_SITE;
  const breedSlug = puppy.litter?.breed?.slug;
  const puppyId = puppy.id;
  return `${consumerSiteUrl}/puppies-for-sale/breed/${breedSlug}/puppy/${puppyId}`;
};

export const getPuppyBreed = (puppy: Partial<Puppy>) => {
  const breedId = puppy.pendingEdit?.litter
    ? puppy.pendingEdit?.litter.breedId
    : puppy.litter?.breedId;

  return getNameContentFieldById('breeds', breedId);
};

export const getPuppyBreedTypeId = (puppy: Partial<Puppy> | undefined) => {
  if (!puppy) {
    return;
  }

  const breedId = puppy?.pendingEdit?.litter
    ? puppy?.pendingEdit?.litter.breedId
    : puppy?.litter?.breedId;

  return getBreedTypeId(breedId);
};
