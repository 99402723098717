import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import Compressor from 'compressorjs';

import { Media } from 'shared/types/media';
import { PuppyPhotos } from 'shared/types/puppy';
import { mediaConfig } from 'utilities/media/media';
import { isMediaPrivate, isMediaPublic } from 'utilities/media/mediaCheckers';

let builtPhotosCounter = 0;
let builtPhotosList: Media[] = [];

interface UidBlob extends Blob {
  uid?: string;
}

export const buildUploadedPuppyPhotos = (
  uploadPhotos: UploadChangeParam,
  puppyPhotos: PuppyPhotos,
) => {
  return new Promise<PuppyPhotos>((resolve, reject) => {
    builtPhotosCounter = 0;
    builtPhotosList = [];
    const maxPhotoUploads = 8;
    const file = uploadPhotos.file;
    const newPhotos = uploadPhotos.fileList.filter((p: Media) => !p.id);
    const existingCoverPhotos = puppyPhotos.coverPhotos
      ? puppyPhotos.coverPhotos
      : [];
    const existingAdditionalPhotos = puppyPhotos.additionalPhotos
      ? puppyPhotos.additionalPhotos
      : [];
    const existingPhotos = [
      ...existingCoverPhotos,
      ...existingAdditionalPhotos,
    ];
    const reachLimitUploads =
      newPhotos.length + existingPhotos.length > maxPhotoUploads;

    if (reachLimitUploads) {
      return reject(`You can’t upload more than 8 photos`);
    }

    const onCompressSuccess = (resultImage: Blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(resultImage);

      const newFile: UidBlob = resultImage;
      newFile.uid = file.uid;

      reader.onload = () => {
        builtPhotosCounter = builtPhotosCounter + 1;
        const photoData = {
          id: file.uid,
          uid: file.uid,
          name: file.name,
          type: resultImage.type,
          size: resultImage.size,
          dataUrl: reader.result,
          encoded: reader.result,
          visibilityId:
            existingCoverPhotos.length + builtPhotosCounter <= 4
              ? mediaConfig.visibilityId.public
              : mediaConfig.visibilityId.private,
          file: newFile,
          isDefault: 0,
        };

        builtPhotosList.push(photoData as Media);

        if (newPhotos.length === builtPhotosList.length) {
          const newCoverPhotos = builtPhotosList.filter((p: Media) =>
            isMediaPublic(p),
          );
          const newAdditionalPhotos = builtPhotosList.filter((p: Media) =>
            isMediaPrivate(p),
          );

          const newPuppyPhotos = {
            coverPhotos: [...existingCoverPhotos, ...newCoverPhotos],
            additionalPhotos: [
              ...existingAdditionalPhotos,
              ...newAdditionalPhotos,
            ],
            hiddenPhotos: puppyPhotos.hiddenPhotos,
          };
          resolve(newPuppyPhotos);
        }
      };
    };

    new Compressor(file as RcFile, {
      quality: 0.8,
      convertSize: 5000,
      success: onCompressSuccess,
    });
  });
};
