import { Col, Form, Row } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import PuppyHealthFee from 'components/puppy/puppyHealthFee/PuppyHealthFee';
import { Collapsable } from 'shared/collapsable/Collapsable';
import {
  FieldBreed,
  FieldBreedVarieties,
  FieldColor,
  FieldDateAvailable,
  FieldDescription,
  FieldDiscount,
  FieldFoodInformation,
  FieldGenders,
  FieldHealthIssuesDisclaimer,
  FieldMarkings,
  FieldMedicalDetails,
  FieldMemberAgreement,
  FieldMicrochip,
  FieldName,
  FieldNoteFromBreeder,
  FieldPuppyCost,
  FieldPuppyLitter,
  FieldPuppySizeInLitter,
  FieldRegistry,
  FieldSpecialFeedingInstructions,
  FieldWeightRange,
  SubmitButton,
} from 'shared/fields';
import FieldChangePuppyLitter from 'shared/fields/fieldChangePuppyLitter/FieldChangePuppyLitter';
import { FieldWeightSameRage } from 'shared/fields/fieldWeightSameRange/FieldWeightSameRange';
import showNotificationError from 'shared/notifications/showNotificationError';
import SubmitModal from 'shared/puppy/formModals/SubmitModal';
import PuppyPhotos from 'shared/puppy/puppyMedia/puppyPhotos/PuppyPhotos';
import { DueDate } from 'shared/types/date';
import { FormProps } from 'shared/types/general';
import { RootState } from 'store/rootState';
import { isDelivered } from 'utilities/entities/puppy';
import { isListingLocked } from 'utilities/listing';
import { puppyDueDatesIsUpToDate } from 'utilities/puppy/puppyDueDates/puppyDueDates';
import puppyTravelStatus from 'utilities/puppy/puppyTravelState';
import inventoryStatus from 'utilities/status/inventoryStatus';
import puppyFormHelpers from './puppyFormHelpers';
import PuppyVideo from './puppyMedia/puppyVideo/PuppyVideo';

import { legacyRegistries } from 'shared/fields/fieldRegistry/legacyRegistries';
import './PuppyForm.scss';
import './puppyMedia/puppyMedia.scss';

export default function PuppyForm(props: FormProps): ReactElement {
  const {
    action,
    collapsableDisabled,
    collapsableType,
    setShowPuppyForm,
    isOpen,
    scrollSummary,
  } = props;

  const [form] = Form.useForm();
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [collapsableOpen, setCollapsableOpen] = useState(Boolean(isOpen));
  const [isFormFieldsLoaded, setIsFormFieldsLoaded] = useState(false);
  const [collapsableTitle, setCollapsableTitle] = useState('Upload your puppy');
  const [scrollToWarning, setScrollToWarning] = useState('');
  const [scrollPhoto, setScrollPhoto] = useState(false);
  const isEditing = action === 'edit';
  const [isSameName, setIsSameName] = useState(false);
  const { litter, puppy } = useSelector((state: RootState) => state);
  const puppyWeightRangeId = puppy?.weightRangeId;
  const dueDates = puppy?.dueDates || [];
  const fieldRef = React.useRef<HTMLInputElement>(null);
  const orderStatus = puppyTravelStatus(puppy);
  const withLegacyRegistry = legacyRegistries.find(
    (registry) => registry.id === form.getFieldValue('registryId'),
  );

  const handleDisableSubmit = () => {
    if (
      inventoryStatus.isSoldByBreeder(puppy) ||
      isDelivered(puppy) ||
      (puppy?.listing && isListingLocked(puppy.listing)) ||
      orderStatus.isOrderCanceled()
    ) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(puppyFormHelpers.disableSubmit(form));
    }
  };

  const handleOnFieldsChange = (changedFields: FieldData[]) => {
    puppyFormHelpers.onFieldsChange(changedFields, form, setIsSameName);

    handleDisableSubmit();
  };

  const isDisabled = (name: string) => {
    return puppyFormHelpers.setDisabledFields(name, puppy, isEditing);
  };

  const hasRegistryOrPendingRegistry = () => {
    const registrations = puppy?.registrations;

    if (
      registrations &&
      registrations.length > 0 &&
      registrations[0].registrationNumber
    ) {
      return true;
    }

    const pendingEditRegistration = puppy?.pendingEdit?.registrations;

    return !!(
      pendingEditRegistration &&
      pendingEditRegistration.length > 0 &&
      pendingEditRegistration[0]?.registrationNumber
    );
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (scrollPhoto && fieldRef.current) {
      setScrollPhoto(false);
      fieldRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollPhoto]);

  useEffect(() => {
    setCollapsableOpen(Boolean(isOpen));
  }, [isOpen]);

  useEffect(() => {
    puppyFormHelpers.setFormFields(puppy, litter, form, setIsFormFieldsLoaded);
  }, [litter]);

  useEffect(() => {
    if (isEditing) {
      const getErrors = async () => {
        if (!isFormFieldsLoaded) return;
        try {
          await form.validateFields();
        } catch (err) {
          showNotificationError({
            error: 'Some fields have errors, please fix it',
          });
        }
      };

      getErrors();
      if (isFormFieldsLoaded && scrollSummary === '') {
        if (!puppyDueDatesIsUpToDate(puppy.dueDates as DueDate[], 'Photos')) {
          setScrollPhoto(true);
        } else if (
          !puppyDueDatesIsUpToDate(puppy.dueDates as DueDate[], 'Weight')
        ) {
          setScrollToWarning('weightRangeId');
        } else if (
          !puppyDueDatesIsUpToDate(puppy.dueDates as DueDate[], 'Registration')
        ) {
          setScrollToWarning('registrationNumber');
        }
      }
    }
  }, [isFormFieldsLoaded]);

  useEffect(() => {
    if (puppy.id) {
      setCollapsableTitle(
        puppy.pendingEdit?.breederName || (puppy.breederName as string),
      );
    } else {
      setCollapsableTitle('Upload your puppy');
    }
  }, [puppy]);

  useEffect(() => {
    if (scrollToWarning) {
      form.scrollToField(scrollToWarning, {
        block: 'center',
        behavior: 'smooth',
      });
    }
  }, [scrollToWarning]);

  useEffect(() => {
    if (scrollSummary && scrollSummary !== '') {
      if (scrollSummary === 'puppyPhotos') {
        setScrollPhoto(true);
      } else {
        form.scrollToField(scrollSummary, {
          block: 'center',
          behavior: 'smooth',
        });
      }
    }
  }, [scrollSummary]);

  useEffect(() => {
    if (isSameName) {
      form.validateFields(['breederName']);
      setScrollToWarning('breederName');
    }
  }, [isSameName]);

  return (
    <Collapsable
      id="puppy"
      title={collapsableTitle}
      icon="puppy"
      isOpen={collapsableOpen}
      type={collapsableType}
      disabled={collapsableDisabled}
      customExpandIcon={setShowPuppyForm && 'Go Back'}
      handleCollapsableOpen={
        setShowPuppyForm ? setShowPuppyForm : setCollapsableOpen
      }
    >
      <Row justify="center">
        <Col xs={24} xl={20} xxl={18}>
          <Form
            data-testid="puppy-form"
            className="PuppyForm"
            layout="vertical"
            size="large"
            scrollToFirstError
            form={form}
            hideRequiredMark
            onFieldsChange={handleOnFieldsChange}
            onFinish={() => setShowSubmitModal(true)}
          >
            <Row gutter={48}>
              <Col xs={24} xl={12} className="border-right-lighter-gray">
                <FieldChangePuppyLitter form={form} isEditing={isEditing} />
                <FieldPuppyLitter />
                <FieldName
                  name="breederName"
                  label="Puppy name"
                  disabled={isDisabled('breederName')}
                  isSameName={isSameName}
                  max16String={!isDisabled('breederName')}
                />
                <FieldGenders disabled={isDisabled('gender')} />
                <FieldBreed disabled />
                <FieldBreedVarieties
                  disabled={isDisabled('variety')}
                  form={form}
                />
                <FieldColor
                  activeHearingTest
                  form={form}
                  disabled={isDisabled('color')}
                />
                <FieldMarkings disabled={isDisabled('marking')} form={form} />
                <FieldWeightRange
                  disabled={isDisabled('weightRange')}
                  warning={
                    !puppyDueDatesIsUpToDate(
                      puppy.dueDates as DueDate[],
                      'Weight',
                    )
                  }
                />
                <FieldWeightSameRage
                  puppyWeightRangeId={puppyWeightRangeId}
                  dueDates={dueDates}
                  disabled={isDisabled('weightRange')}
                />
                <FieldPuppySizeInLitter disabled={isDisabled('relativeSize')} />
                <FieldDateAvailable disabled={isDisabled('dateAvailable')} />
                <FieldFoodInformation />
                <FieldSpecialFeedingInstructions
                  disabled={isDisabled('specialFeedingInstructions')}
                />
                <FieldRegistry
                  limitationLabelOn
                  disabled={isDisabled('registry')}
                  warning={!hasRegistryOrPendingRegistry}
                  withLegacyRegistry={withLegacyRegistry}
                />
                <FieldMedicalDetails disabled={isDisabled('medicalDetails')} />
                <FieldMicrochip disabled={isDisabled('microchipRfid')} />
                <FieldHealthIssuesDisclaimer
                  disabled={isDisabled('healthIssuesDisclaimer')}
                />
                <FieldDescription disabled={isDisabled('description')} />
                <FieldNoteFromBreeder
                  disabled={isDisabled('noteFromBreeder')}
                />
              </Col>
              <Col xs={24} xl={12} ref={fieldRef}>
                <div className="PuppyForm__column">
                  <section className="PuppyForm__column-top">
                    <PuppyPhotos form={form} disabled={isDisabled('photos')} />
                    <PuppyVideo
                      isEditing={isEditing}
                      setFieldsValue={form.setFieldsValue}
                      disabled={isDisabled('video')}
                    />
                    <FieldPuppyCost
                      disabled={isDisabled('listingCost')}
                      initialCost={puppy.listing?.cost}
                      breederDashboardStatus={puppy.breederDashboardStatus}
                    />
                    <PuppyHealthFee />
                    <FieldDiscount disabled={isDisabled('hasDiscountOffer')} />
                  </section>
                  {!isEditing && (
                    <section className="PuppyForm__column-bottom">
                      <FieldMemberAgreement />
                    </section>
                  )}
                </div>
              </Col>
            </Row>
            <SubmitButton
              label="Save & submit puppy"
              disabled={disableSubmit}
            />
          </Form>
          <SubmitModal
            form={form}
            setShowSubmitModal={setShowSubmitModal}
            showSubmitModal={showSubmitModal}
            isEditing={isEditing}
            setIsFormFieldsLoaded={setIsFormFieldsLoaded}
            setIsSameName={setIsSameName}
          />
        </Col>
      </Row>
    </Collapsable>
  );
}
