import React, { ReactElement, useState } from 'react';
import { Form, Input } from 'antd';

import { Food } from 'shared/types/general';
import showNotificationError from 'shared/notifications/showNotificationError';
import { getFoodFormula, getFoodTypes } from 'utilities/http/api/apiFood';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import {
  FoodBrandContent,
  FoodFormulaContent,
  FoodTypesContent,
} from 'utilities/staticContent/types';

export function FieldFoodInformation(): ReactElement {
  const foodBrands = staticContentStorage.getContent().foodBrands;
  const [foodInformation, setFoodInformation] = useState('');

  const handleBuildFoodInformation = async (food: Food | undefined) => {
    try {
      if (food) {
        const foodBrand = foodBrands.find(
          (foodBrand: FoodBrandContent) => foodBrand.id === food.brandId,
        ).name;

        const foodTypes = await getFoodTypes(food.brandId);
        const foodTypeName = foodTypes.find(
          (foodType: FoodTypesContent) => foodType.id === food.typeId,
        ).name;

        const foodFormulas = await getFoodFormula(food.brandId, food.typeId);
        const foodFormulaName = foodFormulas.find(
          (foodFormula: FoodFormulaContent) =>
            foodFormula.id === food.formulaId,
        ).name;
        setFoodInformation(
          `${foodBrand} – ${foodTypeName} – ${foodFormulaName}`,
        );
      }
    } catch (e) {
      showNotificationError({
        error: "Couldn't load food information",
      });
    }
  };

  return (
    <>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) => {
          if (prevValues.food !== curValues.food) {
            handleBuildFoodInformation(curValues.food);
          }
          return prevValues.food !== curValues.food;
        }}
      >
        {() => (
          <Form.Item name="food" noStyle label="Food information">
            <Input hidden />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item label="Food information">
        <Input.TextArea
          autoSize={{ minRows: 3, maxRows: 6 }}
          value={foodInformation}
          disabled
        />
      </Form.Item>
    </>
  );
}
