import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import LitterDetailPuppies from 'components/litter/litterDetailPuppies/LitterDetailPuppies';
import LitterDetailSummary from 'components/litter/litterDetailSummary/LitterDetailSummary';
import PageLayout from 'shared/baseLayout/pageLayout/PageLayout';
import LitterForm from 'shared/litter/LitterForm';
import { BreederRoutes } from 'shared/types/routes';
import { Id, ReactRouterParameter } from 'shared/types/general';
import { getLitter } from 'store/litter/actions';
import { RootState } from 'store/rootState';

const breadCrumbs = [
  {
    url: BreederRoutes.MyLitters,
    name: 'My Litters',
  },
  {
    name: 'Litter Detail',
  },
];

export default function LitterDetail() {
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: ReactRouterParameter }>();
  const { litter } = useSelector((state: RootState) => ({
    litter: state.litter,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    const handleDispatchGetLitter = async () => {
      await dispatch(getLitter(id as Id));
      setLoading(false);
    };

    handleDispatchGetLitter();
  }, [id, dispatch]);

  if (isEmpty(litter)) return null;

  return (
    <PageLayout className="LitterDetail" breadCrumbsNav={breadCrumbs}>
      <Row justify="center" data-testid="page-litter-detail">
        <Col xs={24} xl={20} xxl={15}>
          {!loading && (
            <>
              <LitterDetailSummary litter={litter} />
              <LitterDetailPuppies />
              <LitterForm
                action="edit"
                isOpen={true}
                collapsableType="success"
              />
            </>
          )}
        </Col>
      </Row>
    </PageLayout>
  );
}
