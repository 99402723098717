import {
  ADD_MORE_LITTERS,
  LittersActionTypes,
  LittersState,
  RESET_MY_LITTERS,
  SET_LITTERS,
} from 'store/myLitters/types';

const initialState: LittersState = {
  past: null,
  current: null,
  upcoming: null,
  new: null,
};

export function myLittersReducer(
  state = initialState,
  action: LittersActionTypes,
): LittersState {
  switch (action.type) {
    case SET_LITTERS: {
      const { litters, litterStatus } = action;
      return {
        ...state,
        [litterStatus]: litters,
      };
    }
    case ADD_MORE_LITTERS: {
      const { litters, litterStatus } = action;
      const initialLitters = state[litterStatus] || [];

      return {
        ...state,
        [litterStatus]: [...initialLitters, ...litters],
      };
    }
    case RESET_MY_LITTERS: {
      return initialState;
    }

    default:
      return state;
  }
}
