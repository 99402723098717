import React, { ReactElement } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { Icon } from 'shared/icon/Icon';
import { MediaUploadTypes } from 'shared/types/media';

import './MediaUploader.scss';

interface MediaUploadBtnProps {
  type?: MediaUploadTypes | string;
  label?: string;
  disabled?: boolean;
  loading?: boolean;
}

export function MediaUploaderBtn(props: MediaUploadBtnProps): ReactElement {
  const { type, label, disabled, loading } = props;

  return (
    <Button
      data-testid={`media-uploader-btn-${type}`}
      className="MediaUploader MediaUploader--btn"
      type="link"
      size="large"
      disabled={disabled}
    >
      <div
        className={
          loading
            ? 'MediaUploader__indicator MediaUploader__indicator--loading'
            : 'MediaUploader__indicator'
        }
      >
        {loading ? <Icon name="spinner" /> : <PlusOutlined />}
      </div>
      <div className="MediaUploader__content">
        <span className="MediaUploader__content-thumb">
          <Icon name={type || 'upload'} />
        </span>
        <span className="MediaUploader__content-text">
          {label || `Upload ${type}`}
        </span>
      </div>
    </Button>
  );
}
