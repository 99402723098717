import { apiPostRequest, apiPutRequest } from 'utilities/http/api/apiHttp';
import { User } from 'shared/types/user';
import { Credentials } from 'pages/login/Login';

export interface AuthLogin {
  email: string;
  password: string;
  application: string | undefined;
}

export interface RefreshToken {
  application: string | undefined;
}

export interface Forgot {
  email: string;
}

export interface Reset {
  email: string;
  password: string;
  passwordConfirmation: string;
  token: string;
  application: string | undefined;
}

const apiUser = {
  authLogin(body: AuthLogin) {
    return apiPostRequest(`auth/login`, body);
  },
  apiRefreshToken(body: RefreshToken) {
    return apiPostRequest(`auth/refresh`, body);
  },
  apiRecovery(body: Forgot) {
    return apiPostRequest(`auth/recovery`, body);
  },
  apiReset(body: Reset) {
    return apiPostRequest(`auth/reset`, body);
  },
  updateUserCredentials(credentials: Credentials) {
    return apiPutRequest(`user`, {
      user: credentials,
    });
  },
  update(user: User) {
    return apiPutRequest(`user`, {
      user: { ...user },
    });
  },
};

export default apiUser;
