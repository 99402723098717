import React from 'react';

import { TransporterSequence } from 'shared/types/puppyTravel';
import TravelInfoItem from 'shared/puppy/puppyTravel/TravelInfoItem';
import date from 'utilities/time/date';
import { testId } from 'testsUtilities/dataTestId';

interface Props {
  transporterSequence: TransporterSequence;
}

function TravelSequenceInfoTransporter(props: Props) {
  const { transporterSequence } = props;

  return (
    <section data-testid={testId.travelSequenceInfoTransporter}>
      <TravelInfoItem
        label="Transporter name"
        value={transporterSequence.transporterCompany.name}
      />

      <TravelInfoItem
        label="Phone"
        value={transporterSequence.transporterCompany.phone}
      />

      {transporterSequence.additionalPhone && (
        <TravelInfoItem
          label="Additional phone"
          value={transporterSequence.additionalPhone}
        />
      )}

      <TravelInfoItem
        label="Departure date"
        value={date.toFullMonthDDYYYY(transporterSequence.departureTime)}
      />

      <TravelInfoItem
        label="Departure time"
        value={date.toTimeHMMA(transporterSequence.departureTime)}
      />

      <TravelInfoItem
        label="Arrival date"
        value={date.toFullMonthDDYYYY(transporterSequence.arrivalTime)}
      />

      <TravelInfoItem
        label="Arrival time"
        value={date.toTimeHMMA(transporterSequence.arrivalTime)}
      />
    </section>
  );
}

export default TravelSequenceInfoTransporter;
