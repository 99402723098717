import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { HeartOutlined } from '@ant-design/icons';

import { PuppyDetailTile } from 'components/puppy';
import { useHealthDocsTileStatus } from 'components/healthDocs';
import { SetState } from 'shared/types/general';
import { RootState } from 'store/rootState';
import { isDelivered } from 'utilities/entities/puppy';

interface Props {
  setShowPuppyHealthDocs: SetState<boolean>;
}

function PuppyDetailHealthDocsTile(props: Props): ReactElement {
  const { setShowPuppyHealthDocs } = props;
  const puppy = useSelector((state: RootState) => state.puppy);
  const {
    healthDocsTileStatus,
    healthDocTileStatusText,
  } = useHealthDocsTileStatus(
    puppy.documents,
    puppy.listing?.documentDueDate,
    isDelivered(puppy),
  );

  const handleShowPuppyHealthDocs = () => {
    setShowPuppyHealthDocs((prevState) => !prevState);
  };

  return (
    <PuppyDetailTile
      title="Health Documents"
      icon={<HeartOutlined />}
      status={healthDocsTileStatus}
      statusText={healthDocTileStatusText}
      onClick={handleShowPuppyHealthDocs}
    />
  );
}

export default PuppyDetailHealthDocsTile;
