/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  InputNumber,
  notification,
  Modal,
} from 'antd';
import { Store } from 'rc-field-form/lib/interface';
import { RadioChangeEvent } from 'antd/lib/radio';
import { FieldData } from 'rc-field-form/lib/interface';
import { isEmpty } from 'lodash';

import FieldDogsPurchasedFromPSpot from 'pages/register/fieldDogsPurchasedFromPSpot/FieldDogsPurchasedFromPSpot';
import { FieldName } from 'shared/fields';
import FieldEmail from 'shared/fields/fieldEmail/FieldEmail';
import FieldPassword from 'shared/fields/fieldPassword/FieldPassword';
import { RegisterBreederBody } from 'shared/types/general';
import { BreederRoutes, StaticContentPageRoute } from 'shared/types/routes';
import apiBreeder from 'utilities/http/api/apiBreeder';
import FieldPhone from 'shared/fields/fieldPhone/FieldPhone';
import FieldSelectBreeds from './FieldSelectBreeds/FieldSelectBreeds';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import { CountryContent } from 'utilities/staticContent/types';
import { FieldSelectState } from './FieldSelectState/FieldSelectState';

import './RegisterForm.scss';

const { Option } = Select;
const { Item } = Form;

const pleaseSelectAField = 'Please select a field';
const pleaseFillField = 'Please fill out this field';

interface QuestionIds {
  [index: string]: number;
}

interface Props {
  questionary: any[];
}

export default function RegisterForm(props: Props): ReactElement | null {
  const [showExtra, setShowExtra] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const countries = staticContentStorage.getContent().countries;

  const { questionary: registration } = props;

  const hadleOnFinish = async (values: Store) => {
    try {
      setSubmittingForm(true);
      const openQuestionsId = [16];
      const questionAnswers: unknown[] = [];
      const questionaireKeys = Object.keys(values).filter((k) =>
        k.startsWith('$'),
      );

      questionaireKeys.forEach((qk) => {
        const type = openQuestionsId.includes(questionIds[qk])
          ? 'answer'
          : 'answer_option_id';

        const item = {
          questionnaire_question_id: questionIds[qk],
          answers: [buildAnswerValue(values[qk], type)],
        };

        questionAnswers.push(item);
      });

      const breedsSelected = values.breeds
        ?.filter((breed: { id: number }) => Boolean(breed.id))
        .map((breed: { id: number }) => breed.id);

      const body: RegisterBreederBody = {
        user: {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password,
        },
        breeder: {
          kennel_name: values.kennel_name,
          breeds: breedsSelected,
        },
        question_answers: questionAnswers,
        membership_agreement: Number(values.membership_agreement),
        phone_number_home: values.phone_number_home,
        phone_number_mobile: values.phone_number_mobile,
        phone_number_office: values.phone_number_office,
        address: {
          address: values.address,
          address2: values.address2,
          city: values.city,
          state_id: values.state_id,
          postal_code: values.postal_code,
          country_id: values.country_id,
        },
      };

      const resgisterResponse = await apiBreeder.register(body);

      if (!isEmpty(resgisterResponse?.json?.errors)) {
        notification.error({
          message: 'Something went wrong! Please check the form',
        });
      } else {
        showModal();
      }
    } catch (error) {
      notification.error({ message: 'Something went wrong!' });
    } finally {
      setSubmittingForm(false);
    }
  };

  const showModal = () => {
    Modal.warning({
      title: (
        <>
          <span>Thank you!</span>
        </>
      ),
      content: (
        <>
          <p>
            We have successfully received your application and will contact you
            shortly.
          </p>
          <p>
            If you have any questions, please reach out to us at{' '}
            <b>(844-566-2886)</b>.
          </p>
        </>
      ),
      onOk: () => history.push(BreederRoutes.Login),
    });
  };

  const questionIds: QuestionIds = {
    $yearsBreeding: registration[0].questionnaire_question_id,
    $littersBred: registration[1].questionnaire_question_id,
    $intactDams: registration[2].questionnaire_question_id,
    $usdaLicense: registration[3].questionnaire_question_id,
    $anyDogPurchasedFromPS: registration[4].questionnaire_question_id,
    $anyDogsNotPurchasedWithPuppySpot:
      registration[5].questionnaire_question_id,
  };

  const handleRadioOnChange = (e: RadioChangeEvent) => {
    setShowExtra(e?.target?.value === 1);
  };

  const buildAnswerValue = (value: number | string, type: string) => {
    return { [type]: value };
  };

  const onFieldsChange = (changedFields: FieldData[]) => {
    if (!changedFields || isEmpty(changedFields)) return;
    const fieldName = changedFields[0]?.name;
    const value = changedFields[0]?.value;
    if (typeof fieldName !== 'object') return;

    switch (fieldName[0]) {
      case '$anyDogPurchasedFromPS' && value:
        form.setFieldsValue({
          $anyDogsNotPurchasedWithPuppySpot: 1,
        });
        break;

      case 'country_id':
        form.setFieldsValue({
          state_id: null,
        });
        break;
      default:
        break;
    }
  };

  return (
    <Row justify="center">
      <Col xs={24} xl={20} xxl={20}>
        <Form
          className="RegisterForm"
          data-testid="register-form"
          layout="vertical"
          size="middle"
          scrollToFirstError
          form={form}
          hideRequiredMark={true}
          initialValues={{
            $usdaLicense: registration[3].question_options[4].id,
            $anyDogsNotPurchasedWithPuppySpot:
              registration[5].question_options[0].id,
            $anyDogPurchasedFromPS: registration[4].question_options[1].id,
            $intactDams: 1,
            country_id: countries?.[0].id,
          }}
          onFinish={hadleOnFinish}
          onFieldsChange={onFieldsChange}
        >
          <FieldName name="first_name" label="First Name" placeholder="" />
          <FieldName
            name="last_name"
            label="Last Name"
            placeholder=""
            testId="-last-name"
          />
          <FieldEmail placeholder="" withPrefix={false} />
          <FieldPhone
            name="phone_number_home"
            label="Primary Phone Number"
            required={true}
            setFieldsValue={form.setFieldsValue}
            placeholder="i.e 233-826-3333"
          />
          <FieldPhone
            name="phone_number_mobile"
            label="Alternative Phone Number 1 (Optional)"
            setFieldsValue={form.setFieldsValue}
            placeholder="i.e 233-826-3333"
          />
          <FieldPhone
            name="phone_number_office"
            label="Alternative Phone Number 2 (Optional)"
            setFieldsValue={form.setFieldsValue}
            placeholder="i.e 233-826-3333"
          />
          <FieldPassword
            label="Create a password"
            placeholder=""
            withPrefix={false}
          />
          <Item
            name="$yearsBreeding"
            label={registration[0].question}
            rules={[
              {
                required: true,
                message: pleaseSelectAField,
              },
            ]}
          >
            <Select data-testid="year-start-breeding">
              {registration[0].question_options.map((option: any) => (
                <Option key={option.id} value={option.id}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Item>
          <Item
            name="$littersBred"
            label="How many litters have you bred?"
            rules={[
              {
                required: true,
                message: pleaseSelectAField,
              },
            ]}
          >
            <Select data-testid="litters-bred">
              {registration[1].question_options.map((option: any) => (
                <Option key={option.id} value={option.id}>
                  {option.label} litter
                </Option>
              ))}
            </Select>
          </Item>

          <FieldSelectBreeds form={form} />

          <Item
            name="kennel_name"
            label="Kennel Name"
            rules={[
              {
                required: true,
                message: pleaseFillField,
              },
            ]}
          >
            <Input data-testid="kennel-name" />
          </Item>

          <Item
            name="address"
            label="Street Address"
            rules={[
              {
                required: true,
                message: pleaseFillField,
              },
            ]}
          >
            <Input data-testid="street-address" />
          </Item>

          <Item name="address2" label="Suit or Apt (Optional)">
            <Input data-testid="suit-or-apt" />
          </Item>

          <Item label="Country" name="country_id">
            <Select>
              {countries?.map((country: CountryContent) => (
                <Select.Option key={country.id} value={country.id}>
                  {country.name}
                </Select.Option>
              ))}
            </Select>
          </Item>

          <Item
            name="postal_code"
            label="Zip"
            rules={[
              {
                required: true,
                message: pleaseFillField,
              },
            ]}
          >
            <Input data-testid="zip" />
          </Item>

          <FieldSelectState />

          <Item
            name="city"
            label="City"
            rules={[
              {
                required: true,
                message: pleaseFillField,
              },
            ]}
          >
            <Input data-testid="city" />
          </Item>
          <Item
            name="$usdaLicense"
            data-testid="usda-license"
            label="Are you USDA Licensed?"
          >
            <Radio.Group
              options={[
                {
                  label: 'No',
                  value: registration[3].question_options[4].id,
                },
                {
                  label: 'Yes',
                  value: registration[3].question_options[0].id,
                },
              ]}
            />
          </Item>
          <Item
            name="$intactDams"
            label="How many intact female dogs do you have in your breeding program?"
            rules={[
              {
                required: true,
                message: pleaseSelectAField,
              },
            ]}
          >
            <InputNumber
              data-testid="intact-dams"
              style={{ width: '70%' }}
              min={1}
            />
          </Item>
          <FieldDogsPurchasedFromPSpot
            onChange={handleRadioOnChange}
            selected={showExtra}
            label="Were any of your dogs in your breeding program purchased from PuppySpot?"
          >
            <Radio
              data-testid="purchased-from-ps-no"
              value={registration[4].question_options[1].id}
            >
              {registration[4].question_options[1].label}
            </Radio>
            <Radio
              data-testid="purchased-from-ps-yes"
              value={registration[4].question_options[0].id}
            >
              {registration[4].question_options[0].label}
            </Radio>
          </FieldDogsPurchasedFromPSpot>
          <Item
            data-testid="not-purchased-from-ps"
            className="RegisterForm__long-label"
            name="$anyDogsNotPurchasedWithPuppySpot"
            label={registration[5].question}
            style={!showExtra ? { display: 'none' } : undefined}
          >
            <Radio.Group>
              <Radio
                data-testid="not-purchased-from-ps-no"
                value={registration[5].question_options[1].id}
              >
                {registration[5].question_options[1].label}
              </Radio>
              <Radio
                data-testid="not-purchased-from-ps-yes"
                value={registration[5].question_options[0].id}
              >
                {registration[5].question_options[0].label}
              </Radio>
            </Radio.Group>
          </Item>
          <Item
            name="membership_agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject('Please accept the terms and conditions'),
              },
            ]}
          >
            <Checkbox>
              By checking this box, you agree to our{' '}
              <a
                href={StaticContentPageRoute.MembershipAgreement}
                target="_blank"
                rel="noopener noreferrer"
              >
                Breeder Membership Agreement
              </a>
              ,{' '}
              <a
                href="https://www.puppyspot.com/terms-of-use"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>
              , and{' '}
              <a
                href="https://www.puppyspot.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              ?
            </Checkbox>
          </Item>
          <Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              className="RegisterForm_submit"
              data-testid="register-submit"
              loading={submittingForm}
            >
              Submit
            </Button>
          </Item>
        </Form>
      </Col>
    </Row>
  );
}
