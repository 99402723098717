import React, { ReactElement, useState } from 'react';
import { Row, Col, Button, Form, Select, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { SelectOptionsContentProps } from '../typesFields';
import { EmptyFieldValidationHandler } from '../fieldValidationHandler';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';
import { OfaBodyPartContent } from 'utilities/staticContent/types';

interface FieldOFACertificationsProps {
  form: FormInstance;
  disabled?: boolean;
}

export function FieldOFACertifications(
  props: FieldOFACertificationsProps,
): ReactElement {
  const { form, disabled } = props;
  const ofaBodyParts = staticContentStorage.getContent().ofaBodyParts;
  const [bodyPartsSelected, setBodyPartsSelected] = useState<number[]>([]);

  const handleSelectedBodyPartsContent = () => {
    const fieldValues = form.getFieldValue('ofaCertifiedBodyParts');
    const selectedBodyParts = fieldValues?.map(
      (item: SelectOptionsContentProps) => item?.id,
    );
    setBodyPartsSelected(selectedBodyParts);
  };

  const handleAdd = (add: () => void) => {
    add();
    handleSelectedBodyPartsContent();
  };

  const handleRemove = (
    remove: (arg: number) => void,
    field: FormListFieldData,
  ) => {
    remove(field.name);
    handleSelectedBodyPartsContent();
  };

  return (
    <Form.Item label="OFA certification">
      <Form.List name="ofaCertifiedBodyParts">
        {(ofaFields, { add, remove }) => {
          return (
            <>
              {ofaFields.map((field, idx) => (
                <Row key={idx} gutter={16}>
                  <Col xs={11} md={11} xl={11}>
                    <Form.Item
                      name={[idx, 'id']}
                      rules={[EmptyFieldValidationHandler('list')]}
                    >
                      <Select
                        placeholder="Select an option"
                        onChange={handleSelectedBodyPartsContent}
                        disabled={disabled}
                      >
                        {ofaBodyParts.map((ofaBodyPart: OfaBodyPartContent) => (
                          <Select.Option
                            key={ofaBodyPart.id}
                            value={ofaBodyPart.id}
                            disabled={bodyPartsSelected?.includes(
                              ofaBodyPart.id,
                            )}
                          >
                            {ofaBodyPart.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={disabled ? 13 : 11} xl={disabled ? 13 : 11}>
                    <Form.Item
                      name={[idx, 'ofaNumber']}
                      rules={[EmptyFieldValidationHandler('input')]}
                    >
                      <Input
                        placeholder="Certification number"
                        autoComplete="off"
                        disabled={disabled}
                      />
                    </Form.Item>
                  </Col>
                  {!disabled && (
                    <Col xs={1} md={1} xl={2}>
                      <DeleteOutlined
                        style={{ marginTop: '14px' }}
                        onClick={() => handleRemove(remove, field)}
                      />
                    </Col>
                  )}
                </Row>
              ))}
              {ofaFields.length !== ofaBodyParts.length && (
                <Row>
                  <Col span={24}>
                    <Form.Item style={{ margin: '0' }}>
                      <Button
                        type="dashed"
                        onClick={() => handleAdd(add)}
                        style={{ width: '100%' }}
                        size="large"
                        disabled={disabled}
                      >
                        <PlusOutlined /> Click here to add a new OFA
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </>
          );
        }}
      </Form.List>
    </Form.Item>
  );
}
