import React, { ReactNode } from 'react';

import './MarkLabel.scss';

interface Props {
  label: string;
  icon: ReactNode;
}

export const MarkLabel = (props: Props) => {
  const { label, icon } = props;

  return (
    <span className="MarkLabel">
      <span className="MarkLabel__info" data-testid="mark-label">
        {icon}
        {label}
      </span>
    </span>
  );
};
