import React, { ReactElement, useEffect, useState } from 'react';
import { StaticPageProps } from 'shared/staticPage/StaticPage';
import { Collapse } from 'antd';
import { isEmpty } from 'lodash';
import date from 'utilities/time/date';

const { Panel } = Collapse;

interface QuestionAnswer {
  answer: string;
  question: string;
}

export default function Faq(props: StaticPageProps): ReactElement {
  const { staticData } = props;
  const [questionAnswer, setQuestionAnswer] = useState<QuestionAnswer[]>([]);
  const [faqDate, setFaqDate] = useState('');

  useEffect(() => {
    if (staticData) {
      setQuestionAnswer(staticData.questionAnswer);
      setFaqDate(staticData.dateUpdated);
    }
  }, [staticData]);

  return (
    <div className="StaticPage StaticPage--faq">
      {faqDate && (
        <p>Last updated: {date.timeZone(faqDate, 'America/New_York')}</p>
      )}

      <Collapse ghost className="StaticPage__collapse" bordered={false}>
        {!isEmpty(questionAnswer) &&
          questionAnswer.map((item, idx) => (
            <Panel
              showArrow={false}
              header={item.question}
              key={idx}
              className="StaticPage__collapse-panel"
            >
              <div dangerouslySetInnerHTML={{ __html: item.answer }} />
            </Panel>
          ))}
      </Collapse>
    </div>
  );
}
