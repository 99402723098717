import { apiGetRequest } from './apiHttp';
import { isEmpty } from 'lodash';
import showNotificationError from 'shared/notifications/showNotificationError';

export const getFoodTypes = async (foodBrandId: number) => {
  const resp = await apiGetRequest('content/food/type', `brand=${foodBrandId}`);
  const respError = resp?.json?.errors;
  if (!isEmpty(respError)) {
    showNotificationError({
      message: `Get food type error!`,
      error: respError,
    });
  }
  return resp?.json?.foodTypes;
};

export const getFoodFormula = async (
  foodBrandId: number,
  foodTypeId: number,
) => {
  const resp = await apiGetRequest(
    'content/food/formula',
    `brand=${foodBrandId}&type=${foodTypeId}`,
  );
  const respError = resp?.json?.errors;
  if (!isEmpty(respError)) {
    showNotificationError({
      message: `Get food formula error!`,
      error: respError,
    });
  }
  return resp?.json?.foodFormulas;
};
