import { Puppy } from 'shared/types/puppy';
import { WorkflowEntityIdsTravel } from 'shared/types/puppyTravel';
import { OrderStatusesId } from 'shared/types/order';

const puppyTravelStatus = (puppy: Partial<Puppy>) => {
  const currentStatus =
    puppy?.order?.travels[0]?.workflowEntity?.currentStatus?.id;

  return {
    isPuppyTravelRejected: (): boolean => {
      return (
        currentStatus === WorkflowEntityIdsTravel.RejectedByBreeder ||
        currentStatus === WorkflowEntityIdsTravel.RejectedByCustomer ||
        currentStatus === WorkflowEntityIdsTravel.RejectedByPuppySpot
      );
    },
    isPuppyTravelProposed: (): boolean => {
      return currentStatus === WorkflowEntityIdsTravel.Proposed;
    },
    isPuppyTravelConfirmed: (): boolean => {
      return currentStatus === WorkflowEntityIdsTravel.Confirmed;
    },
    isPuppyTravelFinalized: (): boolean => {
      return currentStatus === WorkflowEntityIdsTravel.Finalized;
    },
    isOrderCanceled: () => {
      return puppy?.order?.orderStatusId === OrderStatusesId.canceled;
    },
  };
};
export default puppyTravelStatus;
