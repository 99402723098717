import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Space } from 'antd';
import {
  CalendarOutlined,
  ClearOutlined,
  TableOutlined,
} from '@ant-design/icons';

import { Icon } from 'shared/icon/Icon';
import { ZeroOrOne } from 'shared/types/general';
import { Litter } from 'shared/types/litter';
import EntityPhoto from 'shared/entitiesOverview/entityPhoto/EntityPhoto';
import { testId } from 'testsUtilities/dataTestId';
import date from 'utilities/time/date';
import {
  getContentDataById,
  zeroOneToYesNo,
} from 'utilities/staticContent/content';
import media, { mediaConfig } from 'utilities/media/media';
import { getLitterPottyTrainingTypes } from 'utilities/litter/getLitterPottyTrainingTypes/getLitterPottyTrainingTypes';

interface Props {
  litter: Litter;
}

export default function LitterCard(props: Props): ReactElement {
  const { litter } = props;
  const { dam, sire } = litter;

  const damPhotoSrc = media.getMainPhotoUrl(
    dam?.media,
    mediaConfig.photoSize.small,
  );
  const sirePhotoSrc = media.getMainPhotoUrl(
    sire?.media,
    mediaConfig.photoSize.small,
  );
  const crateTrainingTypes = getLitterPottyTrainingTypes(
    litter.pottyTrainingTypes,
  );

  const columnInfoSize = 24;
  const entityPhotoHeight = '75px';
  const entityPhotoWidth = '90px';

  return (
    <section
      className="entityCard entityCard--litter"
      data-testid={testId.entityCardLitter}
    >
      <Link
        className="entityCard__info"
        data-testid="entity-card-link"
        to={`/litter/${litter.id}`}
      >
        <Row gutter={16} align="middle" wrap={false}>
          <Col>
            <Space size={8} direction="vertical">
              <div className="entityCard__info-img">
                <EntityPhoto
                  entity="dam"
                  imageSrc={damPhotoSrc}
                  height={entityPhotoHeight}
                  width={entityPhotoWidth}
                  label="Dam"
                />
              </div>

              <div className="entityCard__info-img">
                <EntityPhoto
                  entity="sire"
                  imageSrc={sirePhotoSrc}
                  height={entityPhotoHeight}
                  width={entityPhotoWidth}
                  label="Sire"
                />
              </div>
            </Space>
          </Col>
          <Col flex="auto">
            <section className="entityCard__info-content">
              <h2
                className="entityCard__info-title"
                data-testid={testId.entityCardLitterInfoParentsName}
              >
                {dam?.name} & {sire?.name}
              </h2>
              <Row>
                <Col span={columnInfoSize}>
                  <p data-testid={testId.entityCardLitterInfoBreed}>
                    <Icon name="puppy" />
                    {getContentDataById('breeds', litter?.breedId)?.name}
                  </p>
                  <p data-testid={testId.entityCardLitterInfoLitterSize}>
                    <Icon name="litter" />
                    Litter size: {litter.size}
                  </p>
                  <p data-testid={testId.entityCardLitterInfoBirthdate}>
                    <CalendarOutlined />
                    DOB: {date.toMMDDYYYY(litter.birthDate)}
                  </p>
                </Col>
                <Col span={columnInfoSize}>
                  <p data-testid={testId.entityCardLitterInfoCrateTrained}>
                    <TableOutlined /> Crate trained:{' '}
                    {zeroOneToYesNo(litter?.isCrateTrained as ZeroOrOne)}
                  </p>
                  {crateTrainingTypes && (
                    <p
                      data-testid={
                        testId.entityCardLitterInfoCrateTrainingTypes
                      }
                    >
                      <ClearOutlined /> {crateTrainingTypes}
                    </p>
                  )}
                </Col>
              </Row>
            </section>
          </Col>
        </Row>
      </Link>
    </section>
  );
}
