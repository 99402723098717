import React, { ReactElement } from 'react';
import { Form, Input } from 'antd';

interface Props {
  disabled?: boolean;
}

export function FieldDogPersonality(props: Props): ReactElement {
  const { disabled } = props;
  return (
    <Form.Item name="description" label="Add a description">
      <Input.TextArea
        placeholder="Type the description here"
        autoSize={{ minRows: 6, maxRows: 6 }}
        maxLength={1000}
        disabled={disabled}
      />
    </Form.Item>
  );
}
