import { useEffect, useState } from 'react';
import moment from 'moment';

import { Parent } from 'shared/types/parent';
import apiParent from 'utilities/http/api/apiParent';
import { isDam } from 'utilities/entities/parent';

export function useExistingParents(genderId: number) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<Parent[]>([]);

  const checkParentAllowedDOB = (parent: Parent) => {
    const maxDamAgeInMonths = 89.5; // equivalent to 7 years and 5.5 months
    const maxSireAgeInMonths = 108; // equivalent to 9 years
    const parentBirthDate = parent.pendingEdit?.birthDate ?? parent.birthDate;

    const parentType = isDam(parent) ? 'dam' : 'sire';
    const monthsSinceBirthDate = parseFloat(
      moment().diff(parentBirthDate, 'months', true).toFixed(2),
    );

    const allowedDam =
      parentType === 'dam' && monthsSinceBirthDate <= maxDamAgeInMonths;
    const allowedSire =
      parentType === 'sire' && monthsSinceBirthDate <= maxSireAgeInMonths;

    return allowedDam || allowedSire;
  };

  useEffect(() => {
    const setParents = async () => {
      const urlParamsRecord = {
        pageSize: `500`,
        active: `1`,
        excludeRetiredParents: `1`,
        excludeUnavailableDams: `1`,
        showMinimumDateAllowedToAddPuppiesToDams: `1`,
        withMedia: `0`,
        genderId: `${genderId}`,
        withPendingEdits: `1`,
      };

      const appendParams = new URLSearchParams(urlParamsRecord).toString();

      const resp = await apiParent.getParents(appendParams);

      const parents = resp.json?.puppyParents || [];

      const AllowedParents = parents.filter((parent: Parent) =>
        checkParentAllowedDOB(parent),
      );

      setData([...AllowedParents]);
      setIsLoading(false);
    };
    setParents();
  }, [genderId]);

  return {
    isLoading,
    data,
  };
}
