import { UploadPuppyHealthDoc } from 'shared/types/document';
import media, { mediaConfig } from 'utilities/media/media';
import healthDoc from 'utilities/http/api/apiHealthDocument';

export const uploadPuppyHealthDoc = async ({
  healthDocType,
  healthDocument,
  orderId,
  puppyId,
}: UploadPuppyHealthDoc): Promise<void> => {
  const mediaUploaderParams = {
    mediaEntityId: orderId,
    mediaEntity: 'order',
  };

  const healthDocumentUploaderParams = {
    ...mediaUploaderParams,
    mediaTypeId: mediaConfig.mediaType.healthDocument,
  };

  const mediaId = await media.uploader(
    healthDocument,
    healthDocumentUploaderParams,
  );

  if (mediaId) {
    for (const documentTypeId of healthDocType) {
      await healthDoc.postHealthDocument({
        documentTypeId,
        puppyId,
        mediaId,
      });
    }
  }
};
