/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Col, Row, Grid, Form, Tooltip } from 'antd';
import {
  CalendarOutlined,
  ClockCircleOutlined,
  IdcardOutlined,
  DashboardOutlined,
  DollarOutlined,
  GlobalOutlined,
} from '@ant-design/icons';

import UnlistedRelistedPuppy from 'components/puppy/unlistedRelistedPuppy/UnlistedRelistedPuppy';
import { UnlistedTag } from 'components/tags';
import UpToDateInfoBarButton from 'pages/myListings/listingCard/upToDateInfoBar/upToDateInfoBarButton/UpToDateInfoBarButton';
import UpToDateInfoBarLabel from 'pages/myListings/listingCard/upToDateInfoBar/upToDateInfoBarLabel/UpToDateInfoBarLabel';
import { Icon } from 'shared/icon/Icon';
import {
  renderListingLockedBadge,
  renderListingPendingEditBadge,
} from 'components/entity';
import EntityPhoto from 'shared/entitiesOverview/entityPhoto/EntityPhoto';
import { SetState } from 'shared/types/general';
import { Listing, Puppy } from 'shared/types/puppy';
import { RootState } from 'store/rootState';
import date from 'utilities/time/date';
import media from 'utilities/media/media';
import { getNameContentFieldById } from 'utilities/staticContent/content';
import {
  getPuppyBirthDate,
  getPuppyBreed,
  getPuppyBreederName,
  getPuppyCommerceSiteUrl,
  getPuppyDamId,
  getPuppyDamName,
  getPuppySireId,
  getPuppySireName,
  getPuppySiteName,
} from 'utilities/puppy/getPuppyUtils/getPuppyUtils';
import { isDelivered, isPending, isSold } from 'utilities/entities/puppy';
import inventoryStatus from 'utilities/status/inventoryStatus';
import number from 'utilities/number/number';
import { ListingStatusReasonId } from 'utilities/const';
import { PuppyGenderIcon } from 'components/puppy';

import './PuppySummary.scss';

interface Props {
  setShowPuppyForm?: SetState<boolean>;
  setScrollSummary?: SetState<string>;
  soldView?: boolean;
}

const { useBreakpoint } = Grid;

export default function PuppySummary(props: Props): ReactElement | null {
  const { setShowPuppyForm, setScrollSummary, soldView } = props;
  const [form] = Form.useForm();

  const { puppy } = useSelector((state: RootState) => state);
  const screens = useBreakpoint();
  const puppyImageSize = screens.sm ? `120px` : `105px`;

  const pendingEdits = puppy.pendingEdit;

  const puppyPhotoPath = media.getDefaultPuppyPhoto(puppy.listing?.media);

  const isSoldByBreeder = inventoryStatus.isSoldByBreeder(puppy);
  const isPendingSale = inventoryStatus.isPendingSale(puppy);

  const isPuppySold = isSold(puppy as Puppy);
  const isPuppyDelivered = isDelivered(puppy as Puppy);
  const puppyHasBeenRemoved =
    puppy.listing &&
    puppy.listing.listingStatusReasonId &&
    puppy.listing?.listingStatusReasonId >= ListingStatusReasonId.Expired &&
    puppy.listing?.listingStatusReasonId <= ListingStatusReasonId.Other;

  const showFooter = !(isPuppySold || isPuppyDelivered || puppyHasBeenRemoved);

  const disableLink = isPending(puppy as Puppy) ? 'disableLink' : '';

  const isMale = 1;
  const genderObj = pendingEdits?.genderId || puppy.genderId;
  const puppyGender = genderObj === isMale ? 'sire' : 'dam';

  const scrollWarning = (scrollToWarning: any) => {
    if (soldView) {
      setShowPuppyForm?.(true);
      setScrollSummary?.(scrollToWarning);
    } else {
      if (scrollToWarning === 'puppyPhotos') {
        const ele = document.querySelector('.PuppyPhotos');
        if (ele) ele.scrollIntoView({ block: 'center', behavior: 'smooth' });
      } else {
        form.scrollToField(scrollToWarning, {
          block: 'center',
          behavior: 'smooth',
        });
      }
    }
  };

  const getCalculatedAskingPrice = () => {
    const cost = puppy.listing?.cost || 0;

    const listingFees = puppy.listing?.listingFees || [];
    let feeAmount = 0;

    if (!isEmpty(listingFees)) {
      feeAmount = listingFees[0].feeAmount;
    }

    const adjustedPrice = cost - feeAmount;

    return number.displayCurrency(adjustedPrice || 0);
  };

  if (isEmpty(puppy)) return null;

  const detailExpand = (column: number) => {
    return (
      <>
        <Col span={column}>
          <p>
            <Icon name="dam" />
            <a
              className="entityCard__link"
              href={`/parent/dam/${getPuppyDamId(puppy)}`}
              data-testid="entity-card-info-dam-name"
            >
              {getPuppyDamName(puppy)}
            </a>
          </p>
          <p>
            <Icon name="litter" />
            {getPuppyBreed(puppy)}
          </p>
          <p data-testid="puppy-summary-info-weight">
            <DashboardOutlined />
            {screens.xl && 'Weight: '}
            {getNameContentFieldById('weightRanges', puppy.weightRangeId)}
          </p>
        </Col>
        <Col span={column}>
          <p data-testid="entity-card-info-sire-name">
            <Icon name="sire" />
            <a
              className="entityCard__link"
              href={`/parent/sire/${getPuppySireId(puppy)}`}
            >
              {getPuppySireName(puppy)}
            </a>
          </p>
          <p data-testid="puppy-summary-info-asking-price">
            <DollarOutlined />
            {screens.xl && 'Adjusted Breeder Price: '}
            {getCalculatedAskingPrice()}
          </p>
          {isSoldByBreeder ? (
            <UnlistedTag />
          ) : (
            <p data-testid="puppy-summary-info-available">
              <ClockCircleOutlined />
              {screens.xl && 'Available: '}
              {date.toMMDDYYYY(puppy.dateAvailable)}
            </p>
          )}
        </Col>
      </>
    );
  };

  return (
    <section
      className="entityCard entityCard--page-detail puppy-summary"
      data-testid="puppy-summary"
    >
      <section className="entityCard__info">
        <Row gutter={16} align="middle" wrap={false}>
          <Col>
            <div className="entityCard__info-img">
              {renderListingLockedBadge(puppy.listing as Listing)}
              {renderListingPendingEditBadge(puppy.listing as Listing)}
              <EntityPhoto
                entity="puppy"
                imageSrc={puppyPhotoPath}
                width={puppyImageSize}
                height={puppyImageSize}
              />
            </div>
          </Col>
          <Col flex="auto">
            <div className="entityCard__info-content">
              <div className="entityCard__info-title-container">
                <PuppyGenderIcon gender={puppyGender} />
                <h2
                  className="entityCard__info-title"
                  data-testid="puppy-summary-info-name"
                >
                  {getPuppyBreederName(puppy)}
                </h2>
              </div>
              <Row gutter={40}>
                <Col xl={8}>
                  <p>
                    <GlobalOutlined />
                    {screens.xl && "Site's name: "}
                    <a
                      href={getPuppyCommerceSiteUrl(puppy)}
                      target="_blank"
                      className={`entityCard__link ${disableLink}`}
                      rel="noopener noreferrer"
                      data-testid="puppy-summary-info-consumer-site-link"
                    >
                      {getPuppySiteName(puppy)}
                    </a>
                  </p>
                  <p data-testid="puppy-summary-info-id">
                    <IdcardOutlined />
                    ID: {puppy.id}
                    {isPendingSale && (
                      <Tooltip title="Pending sale">
                        <span
                          className="entityCard__info-content-pending-label"
                          data-testid="entity-card-info-pending-label"
                        >
                          PS
                        </span>
                      </Tooltip>
                    )}
                  </p>
                  <p data-testid="puppy-summary-info-birthdate">
                    <CalendarOutlined />
                    DOB:&nbsp;{getPuppyBirthDate(puppy)}
                  </p>
                </Col>
                {screens.xl && detailExpand(8)}
              </Row>
            </div>
          </Col>
        </Row>
        {!screens.xl && (
          <div className="entityCard__info-content entityCard__info-content--mobile">
            <Row>{detailExpand(12)}</Row>
          </div>
        )}
      </section>
      {puppy?.listing && (
        <section className="UpToDateInfoBar UpToDateInfoBar--summary">
          <Row style={{ flex: 1 }} align="middle">
            <UpToDateInfoBarLabel dueDates={puppy.dueDates} />
            <UpToDateInfoBarButton
              btnType="Weight"
              copyLabel={true}
              onClick={() => scrollWarning('weightRangeId')}
              dueDates={puppy.dueDates}
            />
            <UpToDateInfoBarButton
              btnType="Registration"
              copyLabel={true}
              onClick={() => scrollWarning('registrationNumber')}
              dueDates={puppy.dueDates}
              puppy={puppy}
            />
            <UpToDateInfoBarButton
              btnType="Photos"
              copyLabel={true}
              onClick={() => scrollWarning('puppyPhotos')}
              dueDates={puppy.dueDates}
              media={puppy.listing?.media}
            />
          </Row>
        </section>
      )}

      {showFooter && (
        <section className="entityCard__footer">
          <UnlistedRelistedPuppy />
        </section>
      )}
    </section>
  );
}
