import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Empty, Table } from 'antd';
import {
  Key,
  SorterResult,
  SortOrder,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { SearchOutlined } from '@ant-design/icons';

import {
  ColumnAskingPrice,
  ColumnBreed,
  ColumnPuppyId,
  ColumnPuppyDam,
  ColumnPuppyName,
  ColumnSearch,
  ColumnViews,
  ColumnPuppySire,
  ColumnPuppyUpdates,
  ColumnSitesName,
  ColumnDeliveredDate,
} from 'components/myListings/myListiningsTable/myListingsTableColumns';
import { MyListingsFilters, MyListingsSorter } from 'shared/types/myListings';
import { Listing } from 'shared/types/puppy';
import {
  setMyListingsDeliveredFilters,
  setMyListingsDeliveredPagination,
  setMyListingsDeliveredSorter,
} from 'store/myListingsDelivered/actions';
import { initialStateMyListingsDeliveredSorter } from 'store/myListingsDelivered/myListingsDeliveredReducer';
import { RootState } from 'store/rootState';
import { getNameContentFieldById } from 'utilities/staticContent/content';
import { BreederDashboardStatus, SortDirection } from 'utilities/const';
import { testId } from 'testsUtilities/dataTestId';

function MyListingsTableDelivered(): ReactElement {
  const {
    myListingsDelivered: { loading, listings, pagination, sorter, filters },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const handleOnChangeTable = (
    tablePagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: SorterResult<Listing> | SorterResult<Listing>[],
  ) => {
    window.scrollTo({ top: 0 });

    dispatch(
      setMyListingsDeliveredPagination({
        ...pagination,
        current: tablePagination.current as number,
        pageSize: tablePagination.pageSize as number,
        loadMore: false,
      }),
    );

    const newSorter = sorter as SorterResult<Listing>;
    const listingSorter = newSorter.column
      ? ({
          sortBy: newSorter.columnKey,
          sortDirection: newSorter.order
            ? SortDirection[newSorter.order]
            : initialStateMyListingsDeliveredSorter.sortDirection,
          isSorting: true,
        } as MyListingsSorter)
      : (initialStateMyListingsDeliveredSorter as MyListingsSorter);

    dispatch(setMyListingsDeliveredSorter(listingSorter));

    const isFiltering = Boolean(filters.puppy_id || filters.puppy_name);

    dispatch(
      setMyListingsDeliveredFilters({
        ...filters,
        isFiltering,
      } as MyListingsFilters),
    );
  };

  return (
    <Table
      data-testid={testId.myListingsDeliveredTable}
      loading={loading}
      rowKey="puppyId"
      dataSource={listings}
      scroll={{ x: true }}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <>
                No Data <br />
                Puppies Delivered in the past 6 months.
              </>
            }
          />
        ),
      }}
      columns={[
        {
          title: 'Puppy ID',
          key: 'puppy_id',
          render: (record: Listing) => <ColumnPuppyId listing={record} />,
          sorter: true,
          sortOrder:
            sorter.sortBy === 'puppy_id'
              ? (`${sorter.sortDirection}end` as SortOrder)
              : null,
          filterIcon: <SearchOutlined />,
          filterDropdown: (props) => (
            <ColumnSearch {...props} numeric placeholder="Search by Puppy ID" />
          ),
          filteredValue: filters.puppy_id,
        },
        {
          title: 'Name',
          key: 'puppy_name',
          render: (record: Listing) => <ColumnPuppyName listing={record} />,
          sorter: true,
          sortOrder:
            sorter.sortBy === 'puppy_name'
              ? (`${sorter.sortDirection}end` as SortOrder)
              : null,
          filterIcon: <SearchOutlined />,
          filterDropdown: (props) => (
            <ColumnSearch {...props} placeholder="Search by Name" />
          ),
          filteredValue: filters.puppy_name,
        },
        {
          title: "Site's name",
          key: 'sites_name',
          render: (record: Listing) => <ColumnSitesName listing={record} />,
        },
        {
          title: 'Breed',
          key: 'breed_name',
          render: (record: Listing) => <ColumnBreed listing={record} />,
          sorter: true,
          sortOrder:
            sorter.sortBy === 'breed_name'
              ? (`${sorter.sortDirection}end` as SortOrder)
              : null,
        },
        {
          title: 'Views',
          render: (record: Listing) => <ColumnViews listing={record} />,
        },
        {
          title: 'Price',
          render: (record: Listing) => <ColumnAskingPrice listing={record} />,
        },
        {
          title: 'Weight',
          dataIndex: ['puppy', 'weightRangeId'],
          render: (weightRangeId) =>
            getNameContentFieldById('weightRanges', weightRangeId),
        },
        {
          title: 'Delivered',
          render: (record: Listing) => <ColumnDeliveredDate listing={record} />,
        },
        {
          title: 'Mom',
          render: (record: Listing) => <ColumnPuppyDam listing={record} />,
        },
        {
          title: 'Dad',
          render: (record: Listing) => <ColumnPuppySire listing={record} />,
        },
        {
          title: 'Actions required',
          render: (record: Listing) => (
            <ColumnPuppyUpdates
              listing={record}
              breederDashboardStatus={BreederDashboardStatus.Delivered}
            />
          ),
        },
      ]}
      pagination={{
        hideOnSinglePage: true,
        showQuickJumper: true,
        defaultPageSize: pagination.pageSize,
        current: pagination.current,
        total: pagination.total,
      }}
      onChange={handleOnChangeTable}
    />
  );
}

export default MyListingsTableDelivered;
