import {
  IncreasePage,
  ResetPage,
  SetItemsOnPageAndPerPage,
} from 'store/paginations/actions';

export const INCREASE_PAGE = 'INCREASE_PAGE';
export const RESET_PAGE = 'RESET_PAGE';
export const SET_ITEMS_ON_PAGE_AND_PER_PAGE = 'SET_ITEMS_ON_PAGE_AND_PER_PAGE';

export type PaginationMeta = {
  currentPage: number;
  itemsOnPage: number;
  itemsPerPage: number;
  totalItems?: number;
};

export interface PaginationsState {
  pending: PaginationMeta;
  approved: PaginationMeta;
  sold: PaginationMeta;
  delivered: PaginationMeta;
  dams: PaginationMeta;
  sires: PaginationMeta;
  [columnName: string]: PaginationMeta;
}

export type PaginationsActions =
  | IncreasePage
  | SetItemsOnPageAndPerPage
  | ResetPage;
