import React, { ReactElement } from 'react';
import { Form, Select } from 'antd';

import type { AirportContent } from 'utilities/staticContent/types';
import staticContentStorage from 'utilities/staticContent/staticContentStorage';

interface Props {
  disabled?: boolean;
  label?: string;
  name?: string;
  required?: boolean;
}

export default function FieldAirport({
  label,
  name,
  disabled = false,
  required = false,
}: Props): ReactElement {
  const staticContent = staticContentStorage.getContent();
  const airports = (staticContent.airports as AirportContent[]) ?? [];

  return (
    <Form.Item
      name={name ?? 'airport'}
      label={label ?? 'Airport'}
      rules={
        required
          ? [{ required: true, message: 'Please select an airport' }]
          : []
      }
    >
      <Select
        showSearch
        disabled={disabled}
        filterOption={true}
        optionFilterProp="label"
        placeholder="Select one..."
        options={airports.map((airport) => ({
          value: airport.id,
          label: `${airport.name} (${airport.code})`,
        }))}
      />
    </Form.Item>
  );
}
