import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';

import { Collapsable } from 'shared/collapsable/Collapsable';
import PuppyTravelProposal from 'shared/puppy/puppyTravel/proposal/PuppyTravelProposal';
import PuppyTravelSequence from 'shared/puppy/puppyTravel/sequence/PuppyTravelSequence';
import PuppyTravelPreparing from 'shared/puppy/puppyTravel/preparing/PuppyTravelPreparing';
import { SetState } from 'shared/types/general';
import { RootState } from 'store/rootState';
import puppyTravelStatus from 'utilities/puppy/puppyTravelState';

import './PuppyTravel.scss';

interface Props {
  setShowTravelPlan: SetState<boolean>;
}

function PuppyTravel(props: Props) {
  const { setShowTravelPlan } = props;
  const puppy = useSelector((state: RootState) => state.puppy);

  const statusPuppyTravel = puppyTravelStatus(puppy);
  const statusColor =
    statusPuppyTravel.isPuppyTravelConfirmed() ||
    statusPuppyTravel.isPuppyTravelFinalized()
      ? 'success'
      : 'pending';

  const renderStatusTravel = () => {
    if (statusPuppyTravel.isPuppyTravelFinalized()) {
      return <PuppyTravelSequence />;
    } else if (
      statusPuppyTravel.isPuppyTravelProposed() ||
      statusPuppyTravel.isPuppyTravelConfirmed()
    ) {
      return <PuppyTravelProposal />;
    } else {
      return <PuppyTravelPreparing />;
    }
  };

  return (
    <Collapsable
      id="travel-plans-collapsable"
      type={statusColor}
      title="Travel Plans"
      icon="travelCase"
      isOpen
      handleCollapsableOpen={setShowTravelPlan}
      customExpandIcon="Go Back"
    >
      <Row justify="center">
        <Col xs={24} xl={20} xxl={15}>
          {renderStatusTravel()}
        </Col>
      </Row>
    </Collapsable>
  );
}

export default PuppyTravel;
