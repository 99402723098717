import React, { ReactElement, useEffect } from 'react';
import { Form, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { isEmpty } from 'lodash';

import { useBreedVarieties } from 'hooks';
import { VarietyContent } from 'utilities/staticContent/types';
import { EmptyFieldValidationHandler } from '../fieldValidationHandler';

interface FieldBreedVarietiesProps {
  disabled?: boolean;
  form: FormInstance;
}

export function FieldBreedVarieties(
  props: FieldBreedVarietiesProps,
): ReactElement {
  const { disabled, form } = props;
  const varietyId = form.getFieldValue('varietyId');
  const breedId = form.getFieldValue('breedId');
  const { breedVarieties } = useBreedVarieties(breedId);

  useEffect(() => {
    if (varietyId && !isEmpty(breedVarieties)) {
      const variety = breedVarieties?.find((bv) => bv.id === varietyId);
      form.setFieldsValue({ varietyId: variety?.id });
    }
  }, [varietyId, breedVarieties, form]);

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, curValues) =>
        prevValues.breedId !== curValues.breedId
      }
    >
      {() => {
        if (isEmpty(breedVarieties)) {
          return null;
        }

        return (
          <Form.Item
            name="varietyId"
            label="Breed Variety"
            rules={[EmptyFieldValidationHandler('list')]}
          >
            <Select
              disabled={disabled}
              placeholder="Select one..."
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {breedVarieties?.map((variety: VarietyContent) => (
                <Select.Option key={variety.id} value={variety.id}>
                  {variety.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
}
