import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, Modal, notification, Row } from 'antd';
import { DashboardOutlined } from '@ant-design/icons';

import { FieldWeightRange } from 'shared/fields';
import { PuppyUpdateButton } from 'shared/types/puppy';
import { FieldWeightSameRage } from 'shared/fields/fieldWeightSameRange/FieldWeightSameRange';
import { setUpdateMyListingsPuppyRegistrationsWeightDueDates } from 'store/myListings/actions.thunk';
import { isListingLocked } from 'utilities/listing';
import apiPuppy from 'utilities/http/api/apiPuppy';
import date from 'utilities/time/date';
import UpToDateInfoBarButton from '../upToDateInfoBar/upToDateInfoBarButton/UpToDateInfoBarButton';

import './UpdatePuppyWeight.scss';

function UpdatePuppyWeight(props: PuppyUpdateButton) {
  const { columnName, listing } = props;
  const puppyId = listing?.puppy?.id;
  const puppyName = listing?.puppy?.breederName;
  const puppyWeightRangeId = listing?.puppy?.weightRangeId;
  const dueDates = listing?.puppy?.dueDates || [];
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleUpdateWeight = async (value: {
    weightRangeId: number;
    lastWeighedAt: string;
  }) => {
    const errorMessage = `Couldn't update ${puppyName} weight`;
    const successMessage = `${puppyName} weight updated successfully`;
    try {
      setLoading(true);
      const puppyResp = await apiPuppy.putPuppy(puppyId as number, {
        weightRangeId: value.weightRangeId,
        lastWeighedAt: date.toYYYYMMDD(new Date()),
      });

      if (puppyResp.json.errors) {
        throw new Error(errorMessage);
      }

      dispatch(
        setUpdateMyListingsPuppyRegistrationsWeightDueDates(
          puppyResp.json.puppy,
          columnName,
        ),
      );

      notification.success({
        message: successMessage,
      });
    } catch (e) {
      notification.error({
        message: `Error!`,
        description: e.message,
      });
    } finally {
      handleOnExit();
    }
  };

  const handleOnExit = () => {
    setLoading(false);
    setModalVisible(false);
  };

  const setDisabled = () => {
    if (columnName === 'delivered') return true;

    if (isListingLocked(listing)) return true;
  };

  return (
    <>
      <UpToDateInfoBarButton
        btnType="Weight"
        dueDates={dueDates}
        onClick={() => setModalVisible(true)}
        disabled={setDisabled()}
      />
      <Modal
        className="updatePuppyWeight__modal"
        visible={modalVisible}
        closable={false}
        footer={false}
        width={380}
        afterClose={() => form.resetFields()}
      >
        <h3 className="updatePuppyWeight__modal__title">
          <DashboardOutlined />
          <b>{puppyName}</b> weight update
        </h3>
        <Form
          onFinish={handleUpdateWeight}
          initialValues={{
            weightRangeId: puppyWeightRangeId,
            puppyWeightSameRange: false,
          }}
          layout="vertical"
          form={form}
        >
          <FieldWeightRange />
          <FieldWeightSameRage
            puppyWeightRangeId={puppyWeightRangeId}
            dueDates={dueDates}
          />
          <Form.Item
            shouldUpdate={(prevValues, curValues) => {
              return (
                prevValues.weightRangeId !== curValues.weightRangeId ||
                prevValues.puppyWeightSameRange !==
                  curValues.puppyWeightSameRange
              );
            }}
            noStyle
          >
            {(form) => (
              <Row justify="end">
                <Button type="default" htmlType="button" onClick={handleOnExit}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={
                    puppyWeightRangeId ===
                      form.getFieldValue('weightRangeId') &&
                    !form.getFieldValue('puppyWeightSameRange')
                  }
                >
                  Submit
                </Button>
              </Row>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default UpdatePuppyWeight;
