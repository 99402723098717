import React from 'react';

import {
  MeetupTravelProposal,
  TravelProposals,
} from 'shared/types/puppyTravel';
import TravelInfoItem from 'shared/puppy/puppyTravel/TravelInfoItem';
import { testId } from 'testsUtilities/dataTestId';
import date from 'utilities/time/date';

interface Props {
  meetUpProposal: MeetupTravelProposal;
  proposalData: Partial<TravelProposals>;
}

function TravelProposalInfoMeetUp(props: Props) {
  const { meetUpProposal, proposalData } = props;

  return (
    <section data-testid={testId.travelProposalInfoMeetUp}>
      <TravelInfoItem
        label="Departure date"
        value={date.toFullMonthDDYYYY(proposalData?.departureTime)}
      />
      <TravelInfoItem
        label="Meetup date"
        value={date.toFullMonthDDYYYY(proposalData?.arrivalTime)}
      />
      <TravelInfoItem
        label="Meetup time"
        value={date.toTimeHMMA(proposalData?.arrivalTime)}
      />
      <TravelInfoItem
        label="Meetup address"
        value={meetUpProposal?.meetupLocation}
      />
    </section>
  );
}

export default TravelProposalInfoMeetUp;
