import React, { ReactElement } from 'react';
import { Form, Radio } from 'antd';

import { FormFieldProps } from 'shared/types/form';

export function FieldCrateTraining({ disabled }: FormFieldProps): ReactElement {
  return (
    <Form.Item name="isCrateTrained" label="Crate training">
      <Radio.Group disabled={disabled}>
        <Radio value={0}>No</Radio>
        <Radio value={1}>Yes</Radio>
      </Radio.Group>
    </Form.Item>
  );
}
