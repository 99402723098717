import React, { ReactElement } from 'react';
import { Affix, Col, Row, Tabs } from 'antd';

import { useBaseLayoutAffixOffset } from 'hooks';
import ListingsColumn from 'pages/myListings/listingsColumn/ListingsColumn';
import 'shared/entitiesOverview/entityColumnsSmall.scss';

const { TabPane } = Tabs;

export default function MyUpdatesSmall(): ReactElement {
  const { offsetTopMyEntityTabBar } = useBaseLayoutAffixOffset();

  return (
    <Row justify="center">
      <Col span={24}>
        <Tabs
          className="entity-columns-small__tabs"
          centered
          data-testid="my-updates-tabs"
          renderTabBar={(props, DefaultTabBar) => (
            <Affix offsetTop={offsetTopMyEntityTabBar}>
              <DefaultTabBar {...props} />
            </Affix>
          )}
        >
          <TabPane
            className="entity-columns-small__tab-column"
            tab="Past due Updates"
            key="1"
          >
            <ListingsColumn columnName="pastDue" />
          </TabPane>
          <TabPane
            className="entity-columns-small__tab-column"
            tab="Upcoming Updates"
            key="2"
          >
            <ListingsColumn columnName="upcoming" />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
}
