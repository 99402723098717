export const environmentVariables = {
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_CI_ENVIRONMENT: process.env.REACT_APP_CI_ENVIRONMENT,
};
export const reactEnvironmentVariables = {
  MEDIA_BUCKET_URL: process.env.REACT_APP_MEDIA_BUCKET_URL,
  API_HOST: process.env.REACT_APP_API_HOST,
  APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
  HTTP_REQUEST_LOG_CONSOLE: process.env.REACT_APP_HTTP_REQUEST_LOG_CONSOLE,
  PAGE_SIZE_MY_PARENTS: process.env.REACT_APP_PAGE_SIZE_MY_PARENTS
    ? Number(process.env.REACT_APP_PAGE_SIZE_MY_PARENTS)
    : 25,
  PAGE_SIZE_MY_LITTERS: process.env.REACT_APP_PAGE_SIZE_MY_LITTERS
    ? Number(process.env.REACT_APP_PAGE_SIZE_MY_LITTERS)
    : 25,
  PAGE_SIZE_MY_LISTING: process.env.REACT_APP_PAGE_SIZE_MY_LISTING
    ? Number(process.env.REACT_APP_PAGE_SIZE_MY_LISTING)
    : 25,
  CONSUMER_SITE: process.env.REACT_APP_CONSUMER_SITE,
  RAYGUN_KEY: process.env.REACT_APP_RAYGUN_KEY,
  AUTH_USER: process.env.REACT_APP_AUTH_USER,
  AUTH_PASSWORD: process.env.REACT_APP_AUTH_PASSWORD,
  GTM_KEY: process.env.REACT_APP_GTM_KEY,

  KUSTUMER_API_KEY: process.env.REACT_APP_KUSTUMER_API_KEY,
  KUSTUMER_JWT_SECRET_KEY: process.env.REACT_APP_KUSTUMER_JWT_SECRET_KEY,
  KUSTUMER_BRAND_ID: process.env.REACT_APP_KUSTUMER_BRAND_ID,
} as const;

export const isProduction = () =>
  environmentVariables.REACT_APP_CI_ENVIRONMENT === 'production';
