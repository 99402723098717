import React, { ReactElement } from 'react';
import { Listing } from 'shared/types/puppy';
import { Space } from 'antd';

import {
  HealthDocBadgeStatus,
  DownloadHealthDocs,
} from 'components/healthDocs';
import { DocumentTypeId } from 'utilities/const';

interface Props {
  listing: Listing;
  setDrawerPuppy: (puppy: Listing | null) => void;
}

function ColumnHealthDocs(props: Props): ReactElement {
  const { listing, setDrawerPuppy } = props;

  return (
    <div className="ColumnHealthDoc">
      <Space size={12}>
        <div onClick={() => setDrawerPuppy(listing)}>
          <Space className="ColumnHealthDoc__badge-status-wrapper" size={12}>
            <HealthDocBadgeStatus
              documentType={DocumentTypeId.HealthCertificate}
              documents={listing.puppy.documents}
            />
            <HealthDocBadgeStatus
              documentType={DocumentTypeId.VeterinaryHealthReport}
              documents={listing.puppy.documents}
            />
            <HealthDocBadgeStatus
              documentType={DocumentTypeId.VaccineRecord}
              documents={listing.puppy.documents}
            />
          </Space>
        </div>
        <DownloadHealthDocs puppyId={listing.puppy.id as number} iconOnly />
      </Space>
    </div>
  );
}

export default ColumnHealthDocs;
