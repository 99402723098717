import React, { ReactElement, useState } from 'react';
import AuthorizationForm from 'shared/authorizationForm/AuthorizationForm';
import { Alert, Button, Form, notification } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { Store } from 'rc-field-form/lib/interface';
import apiUser, { Forgot } from 'utilities/http/api/apiUser';
import userStorage from 'utilities/user/userStorage';
import FormItemSubmit from 'shared/authorizationForm/formItems/FormItemSubmit';

import './RecoveryPassword.scss';
import FieldEmail from 'shared/fields/fieldEmail/FieldEmail';

interface SubmitInfo {
  email: string;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default function RecoveryPassword(): ReactElement {
  const [errorAlert, setErrorAlert] = useState<string | null>(null);
  const [submitText, setSubmitText] = useState<string>('Submit');
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const onSubmit = async (info: SubmitInfo | Store) => {
    setSubmitLoading(true);
    const { email } = info;
    const body: Forgot = {
      email,
    };
    try {
      const authResponse = await apiUser.apiRecovery(body);

      if (authResponse.status !== 200)
        throw new Error('Wrong login credentials. Please try again');

      setSubmitLoading(false);
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          type="primary"
          size="large"
          onClick={() => notification.close(key)}
        >
          OK
        </Button>
      );

      notification.open({
        message: 'Password recovery requested',
        description:
          "In a few minutes, you'll receive an email with the instructions to recover your password. If you don't see the email in your inbox, don't forget to check the spam folder.",
        btn,
        key,
        icon: <CheckCircleFilled style={{ color: '#52C41A' }} />,
      });
      setSubmitText('Submitted');
    } catch (e) {
      userStorage.removeUserAndToken();
      setErrorAlert(e.message);
      setSubmitLoading(false);
    }
  };
  return (
    <AuthorizationForm
      title="Recover your password"
      mainPage={false}
      subtitle="Please type in your registered email to send you a link to reset your password"
      className="RecoveryPage"
    >
      <Form
        className="RecoveryPassword"
        {...layout}
        size="large"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
      >
        {errorAlert && <Alert message={errorAlert} type="error" showIcon />}
        <FieldEmail formId="forgot" placeholder="Type your email address" />
        <FormItemSubmit
          submitLoading={submitLoading}
          submitText={submitText}
          formId="forgot"
        />
      </Form>
    </AuthorizationForm>
  );
}
