import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Form, InputNumber } from 'antd';

import { BreederDashboardStatusTypes } from 'shared/types/statuses';
import { RootState } from 'store/rootState';
import { testId } from 'testsUtilities/dataTestId';
import { getBreedMaxCost } from 'utilities/breed';
import number from 'utilities/number/number';
import { FieldPuppyCostPopOver } from './FieldPuppyCostPopOver';
import { FieldPuppyCostValidationRules } from './FieldPuppyCostValidation';

interface Props {
  disabled?: boolean;
  initialCost?: number;
  minValueRecommendedPrice?: number;
  maxValueRecommendedPrice?: number;
  breederDashboardStatus?: BreederDashboardStatusTypes;
}

export function FieldPuppyCost({
  disabled,
  initialCost,
  breederDashboardStatus,
  minValueRecommendedPrice = 0,
  maxValueRecommendedPrice = 0,
}: Props): ReactElement | null {
  const {
    puppy,
    litter,
    myListings: { modalListing },
  } = useSelector((state: RootState) => state);
  let minRecommendedPrice = 0;
  let maxRecommendedPrice = 0;

  const puppyHasRecommendedPrice =
    puppy?.minRecommendedPrice && puppy?.maxRecommendedPrice;
  const litterHasRecommendedPrice =
    litter?.minRecommendedPrice && litter?.maxRecommendedPrice;

  if (puppyHasRecommendedPrice) {
    minRecommendedPrice = puppy.minRecommendedPrice || 0;
    maxRecommendedPrice = puppy.maxRecommendedPrice || 0;
  } else if (litterHasRecommendedPrice) {
    minRecommendedPrice = litter.minRecommendedPrice || 0;
    maxRecommendedPrice = litter.maxRecommendedPrice || 0;
  } else if (minValueRecommendedPrice && maxValueRecommendedPrice) {
    minRecommendedPrice = minValueRecommendedPrice;
    maxRecommendedPrice = maxValueRecommendedPrice;
  }

  const committedListing =
    !!puppy.listing?.breederContractTermId ||
    !!modalListing?.breederContractTerms?.[0]?.id;

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, curValues) =>
        prevValues.breedId !== curValues.breedId ||
        prevValues.listing?.cost !== curValues.listing?.cost
      }
    >
      {(form) => {
        const breedId = form.getFieldValue('breedId');
        const breedMaxCost = getBreedMaxCost(breedId);

        return (
          <>
            {committedListing && (
              <p className="recommended-price">
                This is a committed listing. Call us if you want to lower the
                price.
              </p>
            )}
            {!committedListing &&
              minRecommendedPrice > 0 &&
              maxRecommendedPrice > 0 && (
                <p className="recommended-price">
                  The suggested breeder price for this puppy is between{' '}
                  <strong>${minRecommendedPrice}</strong> and{' '}
                  <strong>${maxRecommendedPrice}</strong>, based on its breed,
                  age, and current market trends.
                </p>
              )}
            <Form.Item
              className="field-item-inline field-puppy-cost"
              style={{
                marginBottom: 0,
                marginTop:
                  minRecommendedPrice > 0 && maxRecommendedPrice > 0
                    ? '15px'
                    : '40px',
              }}
              name={['listing', 'cost']}
              label={<FieldPuppyCostPopOver />}
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              rules={[
                () =>
                  FieldPuppyCostValidationRules(
                    initialCost,
                    breedMaxCost,
                    puppy,
                    breederDashboardStatus,
                    disabled,
                  ),
              ]}
            >
              <InputNumber
                data-testid={testId.fieldPuppyCostInput}
                disabled={disabled}
                formatter={number.formatNumberAsCurrency}
                parser={number.removeNonDigits}
                placeholder="Breeder price"
                style={{ width: '100%' }}
                min={0}
              />
            </Form.Item>
          </>
        );
      }}
    </Form.Item>
  );
}
