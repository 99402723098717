import { FormInstance } from 'antd/lib/form';
import { RcFile } from 'antd/lib/upload/interface';
import { FieldData } from 'rc-field-form/lib/interface';
import { isEmpty, isNil, omit, omitBy } from 'lodash';

import { SetState } from 'shared/types/general';
import { UploadedMediaObject, MediaUploaderParams } from 'shared/types/media';
import { Parent, ParentFormFields } from 'shared/types/parent';
import { BreederRoutes } from 'shared/types/routes';
import media from 'utilities/media/media';
import apiMedia from 'utilities/http/api/apiMedia';
import userStorage from 'utilities/user/userStorage';
import apiParent from 'utilities/http/api/apiParent';

const parentFormHelpers = {
  setFormFields: (
    parent: Partial<Parent>,
    form: FormInstance,
    setIsFormFieldsLoaded: SetState<boolean>,
  ) => {
    const parentPendingEdits = parent.pendingEdit;
    form.setFieldsValue({
      name: parentPendingEdits?.name || parent?.name,
      birthDate: parentPendingEdits?.birthDate || parent?.birthDate,
      breedId: parentPendingEdits?.breedId || parent?.breed?.id,
      colorId: parentPendingEdits?.colorId || parent?.colorId,
      weightRangeId: parentPendingEdits?.weightRangeId || parent?.weightRangeId,
      registryId: parentPendingEdits?.registryId || parent?.registryId,
      registrationNumber:
        parentPendingEdits?.registrationNumber || parent?.registrationNumber,
      isChampion:
        parentPendingEdits?.hasChampionLineage || parent?.hasChampionLineage,
      hasBeenShown: parentPendingEdits?.hasBeenShown || parent?.hasBeenShown,
      description:
        parentPendingEdits?.description?.toString() ||
        parent?.description?.toString(),
      activities: parentPendingEdits?.activities || parent?.activities,
      ofaCertifiedBodyParts:
        parentPendingEdits?.ofaCertifiedBodyParts ||
        parent?.ofaCertifiedBodyParts,
      mediaPhoto: media.getMainPhoto(parent?.media),
      pedigreeDoc: media.getPedigreeDoc(parent?.media),
    });
    setIsFormFieldsLoaded(true);
  },

  onFieldsChange: (changedFields: FieldData[], form: FormInstance) => {
    const fieldName = changedFields[0]?.name;
    const value = changedFields[0]?.value;

    if (typeof fieldName === 'object') {
      if (fieldName[0] === 'isChampion' && value === 0) {
        form.validateFields(['pedigreeDoc']);
      }

      if (fieldName[0] === 'breedId') {
        form.resetFields(['colorId']);
      }

      if (fieldName[0] === 'registryId') {
        form.validateFields(['registrationNumber']);
        form.resetFields(['registrationNumber']);
      }

      if (fieldName[0] === 'breedId' && form.isFieldTouched(['registryId'])) {
        form.validateFields(['registryId']);
      }
    }
  },

  disableSubmit: (form: FormInstance) => {
    const hasError = Boolean(
      form.getFieldsError().find((item) => item.errors[0]),
    );
    return hasError;
  },

  buildUploadedMedia: (file: RcFile): Promise<UploadedMediaObject> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const media: UploadedMediaObject = {
          id: file.uid,
          uid: file.uid,
          name: file.name,
          type: file.type,
          size: file.size,
          encoded: reader.result,
          visibilityId: 2,
          isDefault: 1,
          file,
        };

        resolve(media);
      };
    });
  },

  uploadMedia: async (params: MediaUploaderParams) => {
    const { files } = params;
    if (files && files.length > 0) {
      for (const file of files) {
        if (file.uid) {
          await media.uploader(file, params);
        } else {
          await apiMedia.putMedia(file);
        }
      }
    }
  },

  payloadBuilder: (parentFormFields: ParentFormFields) => {
    let newParent = (parentFormFields as unknown) as Partial<Parent>;
    newParent.breederId = userStorage.getBreederId();
    newParent.isActive = 1;
    newParent.hasChampionLineage = parentFormFields.isChampion;
    newParent.registrationNumber =
      newParent.registrationNumber?.toString() || '';
    newParent = omitBy(newParent, isNil);
    newParent = omit(newParent, [
      'mediaPhoto',
      'mediaPhotoHiddenId',
      'pedigreeDoc',
      'pedigreeDocHiddenId',
      'lastWeighedAt',
    ]);
    return newParent;
  },

  postParent: async (parentPayload: Partial<Parent>) => {
    const resp = await apiParent.postParent(parentPayload);
    const respError = resp?.json?.errors;
    if (!isEmpty(respError) || resp.status !== 200) {
      throw respError;
    }
    return resp?.json?.puppyParent?.id;
  },

  putParent: async (parentId: number, parentPayload: Partial<Parent>) => {
    const resp = await apiParent.putParent(parentId, parentPayload);
    const respError = resp?.json?.errors;
    if (!isEmpty(respError) || resp.status !== 200) {
      throw respError;
    }
  },

  setDisabledFields: (
    fieldName: string,
    parent: Partial<Parent>,
    pathname: string,
  ) => {
    let disabled;
    const hasSoldPuppies = parent.soldListings !== 0;

    if (hasSoldPuppies) {
      const disabledFields = ['name', 'breed', 'birthday'];
      disabled = disabledFields.includes(fieldName);
    }

    if (fieldName === 'isChampion') {
      disabled = Boolean(parent.isChampion) || hasSoldPuppies;
    }

    if (fieldName === 'pedigreeDocs' && hasSoldPuppies) {
      disabled = !isEmpty(media.getPedigreeDoc(parent?.media));
    }

    if (
      (!isEmpty(parent) && pathname === BreederRoutes.NewLitter) ||
      (!isEmpty(parent) && pathname === BreederRoutes.NewPuppy)
    ) {
      disabled = true;
    }
    return disabled;
  },
};

export default parentFormHelpers;
