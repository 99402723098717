import React, { ReactElement } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Button, Col, Row } from 'antd';

import PageLayout from 'shared/baseLayout/pageLayout/PageLayout';

import './ErrorFallback.scss';

export default function ErrorFactory({
  error,
  resetErrorBoundary,
}: FallbackProps): ReactElement {
  return (
    <PageLayout>
      <Row justify="center">
        <Col xs={24} xl={20}>
          <section className="ErrorBoundary">
            <h1 className="ErrorBoundary__title">Something went wrong:</h1>
            <pre>{error.message}</pre>
            <Button onClick={resetErrorBoundary}>Back to Dashboard</Button>
          </section>
        </Col>
      </Row>
    </PageLayout>
  );
}
