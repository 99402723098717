import { isEmpty } from 'lodash';

import {
  Document,
  Documents,
  DocumentTypeIdTypes,
} from 'shared/types/document';
import { DocumentTypeId, DocumentWorkflowStatusId } from 'utilities/const';
import { getLatestUpdatedHealthDoc } from '..';

export const isHealthDocExpired = (document: Document): boolean => {
  const { expiresAt } = document;

  if (!expiresAt) return false;

  return new Date() > new Date(expiresAt);
};

export const isHealthDocValid = (document: Document): boolean => {
  if (isHealthDocExpired(document)) {
    return false;
  }

  if (document.workflowEntity) {
    const { Approved, HealthReview, PendingReview } = DocumentWorkflowStatusId;
    const { currentWorkflowStatusId } = document.workflowEntity;

    switch (currentWorkflowStatusId) {
      case Approved:
      case PendingReview:
      case HealthReview: {
        return true;
      }
    }
  }

  return false;
};

export const isHealthCertificateDocValid = (documents: Document[]): boolean => {
  const healthCertificateDoc = getLatestUpdatedHealthDoc(
    documents,
    DocumentTypeId.HealthCertificate,
  );

  if (!healthCertificateDoc) return false;

  return isHealthDocValid(healthCertificateDoc);
};

export const isVeterinaryHealthReportDocValid = (
  documents: Document[],
): boolean => {
  const veterinaryHealthReportDoc = getLatestUpdatedHealthDoc(
    documents,
    DocumentTypeId.VeterinaryHealthReport,
  );

  if (!veterinaryHealthReportDoc) return false;

  return isHealthDocValid(veterinaryHealthReportDoc);
};

export const isVaccineRecordDocValid = (documents: Document[]): boolean => {
  const vaccineRecordDoc = getLatestUpdatedHealthDoc(
    documents,
    DocumentTypeId.VaccineRecord,
  );

  if (!vaccineRecordDoc) return false;

  return isHealthDocValid(vaccineRecordDoc);
};

export const hasValidHealthDocs = (documents: Documents) => {
  if (!documents || isEmpty(documents)) return false;

  return (
    isHealthCertificateDocValid(documents) &&
    isVeterinaryHealthReportDocValid(documents) &&
    isVaccineRecordDocValid(documents)
  );
};

export const isHealthDocApproved = (
  documents: Documents,
  documentType: DocumentTypeIdTypes,
) => {
  const document = getLatestUpdatedHealthDoc(documents, documentType);
  const { Approved } = DocumentWorkflowStatusId;

  if (document && isHealthDocExpired(document)) {
    return false;
  }

  return document?.workflowEntity?.currentWorkflowStatusId === Approved;
};

export const isHealthCertificateDocApproved = (
  documents: Documents,
): boolean => {
  return isHealthDocApproved(documents, DocumentTypeId.HealthCertificate);
};

export const isVeterinaryHealthReportDocApproved = (
  documents: Documents,
): boolean => {
  return isHealthDocApproved(documents, DocumentTypeId.VeterinaryHealthReport);
};

export const isVaccineRecordDocApproved = (documents: Documents): boolean => {
  return isHealthDocApproved(documents, DocumentTypeId.VaccineRecord);
};

export const hasAllHealthDocsApproved = (documents: Documents): boolean => {
  return (
    isHealthCertificateDocApproved(documents) &&
    isVeterinaryHealthReportDocApproved(documents) &&
    isVaccineRecordDocApproved(documents)
  );
};

export const isHealthDocPendingReview = (
  documents: Documents,
  documentType: DocumentTypeIdTypes,
) => {
  const document = getLatestUpdatedHealthDoc(documents, documentType);
  const { PendingReview, HealthReview } = DocumentWorkflowStatusId;

  if (document && isHealthDocExpired(document)) {
    return false;
  }

  return (
    document?.workflowEntity?.currentWorkflowStatusId === PendingReview ||
    document?.workflowEntity?.currentWorkflowStatusId === HealthReview
  );
};

export const isHealthCertificateDocPendingReview = (documents: Documents) => {
  return isHealthDocPendingReview(documents, DocumentTypeId.HealthCertificate);
};

export const isVeterinaryHealthReportDocPendingReview = (
  documents: Documents,
) => {
  return isHealthDocPendingReview(
    documents,
    DocumentTypeId.VeterinaryHealthReport,
  );
};

export const isVaccineRecordDocPendingReview = (documents: Documents) => {
  return isHealthDocPendingReview(documents, DocumentTypeId.VaccineRecord);
};
