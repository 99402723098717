import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Col, Layout, notification, Row, Space } from 'antd';

import { User } from 'shared/types/user';
import userStorage, { getUserFullName } from 'utilities/user/userStorage';
import {
  isBreederImpersonateMode,
  switchBreederImpersonateMode,
} from 'utilities/breederImpersonate/breederImpersonate';

import './BreederImpersonateHeader.scss';

const { Header } = Layout;

function BreederImpersonateHeader(): ReactElement | null {
  const [activeImpersonateHeader, setActiveImpersonateHeader] = useState(false);
  const [switchImpersonateLoading, setSwitchImpersonateLoading] = useState(
    false,
  );
  const user = userStorage.getUser();
  const leggedAsName = getUserFullName(user as User);

  const handleSwitchBreederAdminImpersonate = async () => {
    setSwitchImpersonateLoading(true);
    try {
      await switchBreederImpersonateMode();
    } catch (e) {
      setSwitchImpersonateLoading(false);
      notification.error({
        message: e.message,
      });
    }
  };

  const handleLogout = () => {
    userStorage.removeUserAndToken();
    window.location.reload();
  };

  useEffect(() => {
    setActiveImpersonateHeader(isBreederImpersonateMode());
  }, []);

  if (!activeImpersonateHeader) return null;

  return (
    <Header className="BreederImpersonateHeader">
      <Row justify="space-between" align="middle">
        <Col span={12}>
          <span className="BreederImpersonateHeader__logged-label">
            Logged in as <b>{leggedAsName}</b>
          </span>
        </Col>
        <Col span={12}>
          <Space>
            <Button
              type="default"
              size="small"
              onClick={handleSwitchBreederAdminImpersonate}
              loading={switchImpersonateLoading}
            >
              Admin View
            </Button>

            <Button size="small" type="text" onClick={handleLogout}>
              Log out
            </Button>
          </Space>
        </Col>
      </Row>
    </Header>
  );
}

export default BreederImpersonateHeader;
