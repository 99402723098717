import { Dispatch } from 'redux';
import { notification } from 'antd';

import showNotificationError from 'shared/notifications/showNotificationError';
import { Listing, Puppy, QueryObject } from 'shared/types/puppy';
import { DueDate, UpcomingUpdate } from 'shared/types/date';
import { Nullable } from 'shared/types/general';
import {
  MyPuppyListingsColumns,
  MyUpcomingColumns,
} from 'shared/types/general';
import { BreederDashboardStatusTypes } from 'shared/types/statuses';
import {
  ADD_MORE_LISTINGS,
  ListingsActionTypes,
  MyListings,
  SEARCH_LISTING,
  SET_LISTINGS,
  SET_MODAL_LISTING,
  SEARCH_LOADING,
  UPDATE_PUPPY_WEIGHT_REGISTRATION,
  SET_QUERY_OBJECT,
} from 'store/myListings/types';
import apiBreeder from 'utilities/http/api/apiBreeder';
import { mediaConfig } from 'utilities/media/media';
import date from 'utilities/time/date';

export interface SetListings {
  type: typeof SET_LISTINGS;
  columnName: string;
  listings: Listing[] | null;
}

export function setListings(
  columnName: string,
  listings: Listing[] | null,
): ListingsActionTypes {
  return {
    type: SET_LISTINGS,
    columnName,
    listings,
  };
}

export interface SetModalListing {
  type: typeof SET_MODAL_LISTING;
  listing: Listing | null;
}

export function setModalListing(listing: Listing | null): ListingsActionTypes {
  return {
    type: SET_MODAL_LISTING,
    listing,
  };
}

export interface AddMoreListings {
  type: typeof ADD_MORE_LISTINGS;
  breederDashboardStatus: BreederDashboardStatusTypes;
  listings: Listing[];
}

export interface SearchListing {
  type: typeof SEARCH_LISTING;
  searchBy: string;
  searchResult: Nullable<MyListings>;
  searchCriteria: string | undefined;
}

export interface SearchLoading {
  type: typeof SEARCH_LOADING;
  loading: boolean;
}

export interface SetQueryObject {
  type: typeof SET_QUERY_OBJECT;
  queryObject: QueryObject;
  breederDashboardStatus: BreederDashboardStatusTypes;
}

export function setQueryObject(
  queryObject: QueryObject,
  breederDashboardStatus: BreederDashboardStatusTypes,
): ListingsActionTypes {
  return {
    type: SET_QUERY_OBJECT,
    queryObject,
    breederDashboardStatus,
  };
}

export function searchLoading(loading: boolean): ListingsActionTypes {
  return {
    type: SEARCH_LOADING,
    loading,
  };
}
export interface UpdatePuppyWeightAndRegistration {
  type: typeof UPDATE_PUPPY_WEIGHT_REGISTRATION;
  puppy: Puppy;
  columnName: MyPuppyListingsColumns | MyUpcomingColumns;
}

export function updatePuppyWeightAndRegistration(
  puppy: Puppy,
  columnName: MyPuppyListingsColumns | MyUpcomingColumns,
): ListingsActionTypes {
  return {
    type: UPDATE_PUPPY_WEIGHT_REGISTRATION,
    puppy,
    columnName,
  };
}

export function addMoreListings(
  breederDashboardStatus: BreederDashboardStatusTypes,
  listings: Listing[],
): ListingsActionTypes {
  return {
    type: ADD_MORE_LISTINGS,
    breederDashboardStatus,
    listings,
  };
}

export function showLoadedPuppiesNotification(
  page: number | undefined,
  itemsOnPage: number,
  entityLabel: 'puppy' | 'parent' | 'litter',
  entityLabelPlural: 'puppies' | 'parents' | 'litters',
) {
  if (!page || page === 1) return;

  const pluralOrSingular = itemsOnPage === 1 ? entityLabel : entityLabelPlural;

  let message = `${itemsOnPage} ${pluralOrSingular} loaded`;
  if (itemsOnPage === 0) {
    message = `All ${entityLabelPlural} loaded`;
  }
  notification.success({ message });
}

export const filterPast = (listings: Listing[]) => {
  return listings.filter((l: Listing) => {
    const listingDueDates = l.puppy.dueDates?.map((d: DueDate) => d.dueDate);
    const foundOneInPast = listingDueDates?.find(
      (d: string) => !date.isDateInFuture(d),
    );
    return Boolean(foundOneInPast);
  });
};

export const filterByPastOrUpcoming = (
  listings: Listing[],
  dispatch: Dispatch,
) => {
  const pastListings = filterPast(listings);
  const pastIds = pastListings.map((l) => l.id);
  const upcomingListings = listings.filter((l) => !pastIds.includes(l.id));
  dispatch(setListings('pastDue', pastListings));
  dispatch(setListings('upcoming', upcomingListings));
};

export function cleanMediaThumbnailUrl(mediaThumbnailUrl: string) {
  const splitBySlash = mediaThumbnailUrl.split('/');
  splitBySlash.splice(0, 3);

  const filePath = splitBySlash.join('/');
  return filePath;
}

export function convertToMediaDefaultImageType(filePath: string) {
  const listingMedia = [
    { filePath, mediaTypeId: mediaConfig.mediaType.photo, isDefault: 1 },
  ];
  return listingMedia;
}

export function convertUpdateMediaToListingMedia(mediaThumbnailUrl: string) {
  const filePath = cleanMediaThumbnailUrl(mediaThumbnailUrl);
  return convertToMediaDefaultImageType(filePath);
}

export function mapUpcomingUpdatesToListings(
  upcomingUpdates: UpcomingUpdate[],
): Partial<Listing>[] {
  return upcomingUpdates.map((update: UpcomingUpdate) => {
    const media = convertUpdateMediaToListingMedia(update.mediaThumbnailUrl);
    const mappedListing: Partial<Listing> = {
      id: update.puppyId,
      price: 0,
      lockedByUserId: update.lockedByUserId,
      hasPendingEdits: update.hasPendingEdits,
      cost: update.cost,
      media,
      puppy: {
        breederDashboardStatus: update.breederDashboardStatus,
        displayName: update.displayName,
        id: update.puppyId,
        breederName: update.name,
        dueDates: update.dueDates,
        weightRangeId: update.weightRangeId,
        registrations: update.registrations,
        litter: update.litter,
        pendingEdit: update.pendingEdit,
      },
    };
    return mappedListing;
  });
}

export const fetchMyUpdatesAndMapToListings = () => {
  return async function (dispatch: Dispatch) {
    try {
      const response = await apiBreeder.getDashboardSummary();
      const upcomingUpdates: UpcomingUpdate[] = Array.from(
        Object.values(response?.json?.dashboardSummary?.upcomingDueDates),
      );
      const listings = mapUpcomingUpdatesToListings(upcomingUpdates);
      filterByPastOrUpcoming(listings as Listing[], dispatch);
    } catch (error) {
      showNotificationError({ error: "Couldn't fetch data for your updates" });
    }
  };
};
