import React, { ReactElement, ReactNode } from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

interface BaseLayoutContentProps {
  children: ReactNode;
}

export default function BaseLayoutContent(
  props: BaseLayoutContentProps,
): ReactElement {
  const { children } = props;
  return <Content>{children}</Content>;
}
